export default {
  orderMangement_00001: 'Gestión de tickets',
  orderMangement_00002: "Notificaremos a los manejadores y seguidores de los cambios de estado del ticket en la cuenta oficial de WeChat de XCHARGE. '",
  orderMangement_00003: "Utilice WeChat para escanear el código QR de la cuenta oficial a la izquierda y siga la cuenta oficial de WeChat de XCHARGE. '",
  orderMangement_00004: 'En el menú de funciones debajo de la cuenta oficial, busque y abra la MiniApp "Smart Maintenance". Inicie sesión en la MiniApp "Smart Maintenance" con su cuenta de la plataforma.',
  orderMangement_00005: 'Puede recibir notificaciones de tickets para la cuenta correspondiente a través de la MiniApp o la cuenta oficial "Smart Charge". ',
  orderMangement_00006: 'Puede recibir la notificación de mantenimiento de la cuenta correspondiente a través de la MiniApp o la barra de notificaciones del servicio WeChat. ',
  orderMangement_00007: 'Tickets míos',
  orderMangement_00008: 'Tickets procesados',
  orderMangement_00009: 'Tickets seguidos por mí',
  orderMangement_00010: 'Tickets sin cerrar',
  orderMangement_00011: 'Todos los tickets',
  orderMangement_00012: 'Iniciar ticket',
  orderMangement_00013: 'Palabras clave de descripción del problema',
  orderMangement_00014: 'Nombre de la estación',
  orderMangement_00015: 'Todos',
  orderMangement_00016: 'Categoría superior',
  orderMangement_11116: 'Categoría secundaria',
  orderMangement_00017: 'Número de ticket',
  orderMangement_00018: 'Todos fabricantes',
  orderMangement_00019: 'Todos los modelos',
  orderMangement_00020: 'Tickets pendientes',
  orderMangement_00021: 'Procesando tickets',
  orderMangement_00022: 'Tickets resueltos',
  orderMangement_00023: 'Tickets cerrados',
  orderMangement_00024: 'Lista de tickets',
  orderMangement_00025: 'Exportar tickets',
  orderMangement_00026: 'Número de tickets',
  orderMangement_00027: 'Título de los tickets',
  orderMangement_00028: 'Categorías',
  orderMangement_00029: 'Prioridad',
  orderMangement_00030: 'Estado',
  orderMangement_00031: 'Estación con problema',
  orderMangement_00032: 'Procesador',
  orderMangement_00033: 'Iniciador',
  orderMangement_00034: 'Hora de inicio',
  orderMangement_00035: 'Hora de actualización',
  orderMangement_00036: 'Origen del problema',
  orderMangement_00037: 'Operación',
  orderMangement_00038: 'Rechazar',
  orderMangement_00039: 'Resuelto',
  orderMangement_00040: 'Aceptar',
  orderMangement_00041: 'Transferir',
  orderMangement_00042: 'Cerrar',
  orderMangement_00043: 'Agregar al banco de conocimientos',
  orderMangement_00044: 'Detalles',
  orderMangement_00045: 'Muy urgente',
  orderMangement_00046: 'Urgente',
  orderMangement_00047: 'General',
  orderMangement_00048: 'Pendiente',
  orderMangement_00049: 'En proceso',
  orderMangement_00050: 'Procesado',
  orderMangement_00051: 'Cerrado',
  orderMangement_00052: 'Observaciones',
  orderMangement_00053: 'Por favor, introduzca el contenido',
  orderMangement_00054: 'Solución',
  orderMangement_00055: 'Procesador de transferencia',
  orderMangement_00056: 'Aceptar ticket',
  orderMangement_00057: '¿Aceptar el ticket?',
  orderMangement_00058: 'Confirmar aceptación',
  orderMangement_00059: 'Transferir ticket',
  orderMangement_00060: 'Confirmar transferencia',
  orderMangement_00061: 'Motivos de la transferencia',
  orderMangement_00062: 'Cerrar ticket',
  orderMangement_00063: '¿Está seguro de cerrar el ticket?',
  orderMangement_00064: 'Confirmar',
  orderMangement_00065: 'Motivo del cierre',
  orderMangement_00066: 'Rechazar ticket',
  orderMangement_00067: '¿Está seguro de rechazar el ticket?',
  orderMangement_00068: 'Confirmar rechazo',
  orderMangement_00069: 'Motivo de devolución',
  orderMangement_00070: 'El ticket ha sido resuelto',
  orderMangement_00071: '¿Confirma que ¿Se ha resuelto el ticket?',
  orderMangement_00072: 'Confirmación resuelta',
  orderMangement_00073: 'Por favor, rellene todos los campos',
  orderMangement_00074: 'Operación exitosa',
  orderMangement_00075: 'Informe del personal',
  orderMangement_00076: 'Informe del cliente',
  orderMangement_00077: 'Informe del cargador',
  orderMangement_00078: 'Activación automática',
  orderMangement_00079: 'Otro',
  orderMangement_00080: 'Prioridad de los tickets',
  orderMangement_00081: 'Estado de los tickets',
  orderMangement_00082: 'Conviértase en seguidor',
  orderMangement_00083: 'Fabricante del cargador',
  orderMangement_00084: 'Le enviaremos un notificar el ticket a través del buzón de correo vinculado al iniciador, procesador y seguidor',
  orderMangement_00085: 'Código de repuesto o nombre de repuesto',
  orderMangement_00086: 'Finalización confirmada',
  orderMangement_00087: 'Agregar repuesto',
  orderMangement_00088: 'Todos los CPO',
  orderMangement_00089: 'Todas las estaciones',
  orderMangement_00090: 'Nombre del CPO',
  orderMangement_00091: 'Fabricante del cargador',
  orderMangement_00092: 'Notificaremos la orden de trabajo a través del buzón de correo vinculado al iniciador, procesador y seguidor',
  orderMangement_00093: 'Nombre del material',
  orderMangement_00094: 'Número de material',
  orderMangement_00095: 'Número de serie del material',
  orderMangement_00096: 'Ingrese el número de serie del material utilizado, uno por línea',
  orderMangement_10000: 'Número de serie del cargador',
  orderMangement_10001: 'Procesador actual',
  //工单新加
  work_00058: 'Número de ticket',
  work_00059: 'Descripción de la acción',
  work_00060: 'Acciones históricas',
  work_10006: 'Descripción del problema',
  work_10007: 'Otro cargador',
  work_10008: 'Agregar una pregunta',
  work_10009: 'Dirección de la estación',
  work_10010: 'Aviso de admisión',
  work_10011: 'Transacción relacionada',
  work_10012: 'Seleccionar transacción',
  work_10013: 'Información del error',
  work_10014: 'Código de error',
  work_10015: 'Estaciones',
  work_10016: 'ID de transacción',
  work_10017: 'ID de transacción',
  work_10020: 'Procese todos los problemas',
  work_00007: 'Número de ticket',
  work_00008: 'Estación de problema',
  work_00009: 'Marca y modelo del sitio de problema',
  work_00010: 'Origen del problema',
  work_00011: 'Iniciador',
  work_00012: 'Hora de inicio',
  work_00013: 'Hora de actualización',
  work_00014: 'Procesador actual',
  work_00016: 'General',
  work_00017: 'Urgente',
  work_00018: 'Muy urgente',
  work_00050: 'Móvil: {mobile}',
  work_00051: 'Recibir notificaciones',
  }