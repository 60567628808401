export default {
  resetPassword_00001: "Verificación de información",
  resetPassword_00002: "Modificación de contraseña",
  resetPassword_00003: "Modificación completada",
  resetPassword_00004: "Cuenta",
  resetPassword_00005: "Ingrese una cuenta que requiera restablecer contraseña",
  resetPassword_00006: "Número de móvil",
  resetPassword_00007: "Número de teléfono de 11 dígitos",
  resetPassword_00008: "Ingrese el número de móvil asociado a esta cuenta",
  resetPassword_00009: "Enviar código",
  resetPassword_00010: "Recuperar después de {0} s",
  resetPassword_00011: "Código de verificación por SMS",
  resetPassword_00012: "6 dígitos",
  resetPassword_00013: "Ingrese el código de verificación por SMS",
  resetPassword_00014: "Siguiente",
  resetPassword_00015: "Nueva contraseña",
  resetPassword_00016: "Contiene al menos 6 caracteres, incluyendo al menos 1 dígito, 1 letra y 1 carácter especial",
  resetPassword_00017: "Ingrese una nueva contraseña",
  resetPassword_00018: "Confirmar contraseña",
  resetPassword_00019: "Igual que la nueva contraseña",
  resetPassword_00020: "Ingrese nuevamente la nueva contraseña",
  resetPassword_00021: "Guardar",
  resetPassword_00022: "Modificada exitosamente",
  resetPassword_00023: "Iniciar sesión en la plataforma operativa",
  resetPassword_00024: "Por favor, introduzca el código de verificación",
  resetPassword_00025: "Por favor, introduzca un valor numérico",
  resetPassword_00026: "Por favor, introduzca 6 dígitos",
  resetPassword_00027: "Por favor, introduzca la nueva contraseña de nuevo",
  resetPassword_00028: "¡Las nuevas contraseñas introducidas dos veces no coinciden!",
  resetPassword_00029: "Por favor, introduzca el número de teléfono",
  resetPassword_00030: "Por favor, introduzca el número de teléfono correcto de 11 dígitos",
  resetPassword_00031: "Por favor, introduzca la cuenta",
  resetPassword_00032: "Por favor, introduzca una contraseña",
  resetPassword_00033: "",
  resetPassword_00034: "¡El número de teléfono ingresado no es el número de teléfono asociado a la cuenta! ¡Comuníquese con el administrador si es necesario!",
  resetPassword_00035: "Código de verificación incorrecto",
  resetPassword_00036: "El código de verificación no es válido",
  resetPassword_00037: "El código de verificación ha expirado. Obtenga el código de verificación nuevamente",
  resetPassword_00038: "Restablecer contraseña",
  resetPassword_00039: "¡Ingrese una nueva contraseña de acuerdo con las reglas!",
  
  resetPassword_00040: "Buzón de correo",
  resetPassword_00041: "¡Ingrese el correo electrónico asociado con esta cuenta!",
  resetPassword_00042: "¡Ingrese el correo electrónico correcto!",
  
  resetPassword_00043: "¡El correo electrónico que ingresó no está asociado con esta cuenta!¡Comuníquese con el administrador si es necesario!" ,
  resetPassword_00044: "Código de verificación de correo electrónico",
  resetPassword_00045: "Ingrese el código de verificación de correo electrónico",
  
  }