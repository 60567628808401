export default {
  work_00001: 'Detalles del ticket',
  work_00002: 'Diagnóstico',
  work_00003: 'Sistema de tickets',
  work_00004: 'Gestión de tickets',
  work_00005: 'Muy urgente',
  work_00006: 'Enviar',
  work_00007: 'Número de ticket',
  work_00008: 'Estación de emisión',
  work_00009: 'Marca y modelo del sitio de emisión',
  work_00010: 'Origen de la emisión',
  work_00011: 'Iniciador',
  work_00012: 'Hora de inicio',
  work_00013: 'Hora de actualización',
  work_00014: 'Procesador actual',
  work_00015: 'Emisión descripción',
  work_00016: 'Cargador relacionado',
  work_00017: 'Registro anormal del cargador relacionado',
  work_00018: 'Registro de evaluación de seguridad relacionada',
  work_00019: 'Usuario relacionado',
  work_00020: 'Resolviendo la solución',
  work_00021: 'Proceso de ticket',
  work_00022: 'Completado',
  work_00023: 'Pendiente',
  work_00024: 'Resuelto',
  work_00025: 'Cerrado',
  work_00026: 'Transferencia',
  work_00027: 'Cerrar',
  work_00028: 'Consulta de registro de comunicación de la plataforma',
  work_00029: 'Código de excepción de falla',
  work_00030: 'Descripción de excepción de falla',
  work_00031: 'Urgente',
  work_00032: 'General',
  work_00033: 'Pendiente',
  work_00034: 'En proceso',
  work_00035: 'Resuelto',
  work_00036: 'Cerrado',
  work_00037: 'Número de equipo',
  work_00038: 'Transacción relacionada',
  work_00039: 'Informes del personal',
  work_00040: 'Comentarios del cliente',
  work_00041: 'Informes de equipo',
  work_00042: 'Disparador automático',
  work_00043: 'Otro',
  work_00044: 'Enviar',
  work_00045: 'No se puede restaurar después de eliminar un ticket',
  work_00046: 'Agregar al banco de conocimientos',
  work_00047: 'Devolución',
  work_00048: 'Recibiendo',
  work_00049: 'Iniciar ticket',
  work_00050: 'Móvil: {mobile}',
  
  work_00058: 'Número de ticket',
  work_00059: 'Descripción de la acción',
  work_00060: 'Acciones históricas',
  work_00061: 'Por favor, introduzca el contenido',
  
  // Se ha realizado un pedido antes
  orderMangement_00052: 'Observaciones',
  orderMangement_00053: 'Por favor, introduzca el contenido',
  orderMangement_00054: 'Solución',
  orderMangement_00055: 'Procesador de transferencia',
  orderMangement_00056: 'Aceptar ticket',
  orderMangement_00057: '¿Aceptar el ticket?',
  orderMangement_00058: 'Confirmar aceptación',
  orderMangement_00059: 'Transferir tickets',
  orderMangement_00060: 'Confirmar transferencia',
  orderMangement_00061: 'Razones de la transferencia',
  orderMangement_00062: 'Cerrar el ticket',
  orderMangement_00063: '¿Está seguro de cerrar los tickets?',
  orderMangement_00064: 'Confirmar para cerrar',
  orderMangement_00065: 'Razón del cierre',
  orderMangement_00066: 'Volver a tickets',
  orderMangement_00067: '¿Está seguro de volver a tickets?',
  orderMangement_00068: 'Confirmar rechazo',
  orderMangement_00069: 'Motivo de la devolución',
  orderMangement_00070: 'El ticket ha sido resuelto',
  orderMangement_00071: '¿Confirma que el ticket ha sido resuelto?',
  orderMangement_00072: 'Confirmación resuelta',
  orderMangement_00073: 'Por favor, rellene todos los campos',
  orderMangement_00074: 'Operación exitosa',
  orderMangement_00082: 'Conviértase en seguidor',
  orderMangement_00083: 'Fabricante del cargador',
  
  work_10006: 'Descripción del problema',
  work_10007: 'Otro cargador',
  work_10008: 'Agregar una pregunta',
  work_10009: 'Dirección de la estación',
  work_10010: 'Aviso de admisión',
  work_10011: 'Relacionado Transacción',
  work_10012: 'Seleccionar transacción',
  work_10013: 'Información de error',
  work_10014: 'Código de error',
  work_10015: 'Estaciones',
  work_10016: 'ID de transacción',
  work_10018: 'Confirmación de cierre de problema',
  work_10019: 'Tomar medidas',
  work_10020: 'Realice las acciones necesarias para todos los problemas',
  work_10021: "Sin mensaje",
  work_10022: "imagen",
  work_10023: "video",
  detailDepot_00035: 'Imagen real',
  detailDepot_00036: 'Dirección, calle y nombre de la casa',
  detailDepot_00037: 'Buscar',
  detailDepot_00038: 'Nombre',
  detailDepot_00039: 'Dirección detallada',
  detailDepot_00040: 'Posición actual',
  detailDepot_00041: 'Cancelar',
  detailDepot_00042: 'Usar esta ubicación',
  detailDepot_00043: 'Seleccione las coordenadas de la estación de carga',
  detailDepot_00044: 'La posición seleccionada no coincide con la dirección',
  detailDepot_00045: "Aviso",
  detailDepot_00046: "Ya veo",
  detailDepot_00047: "No se puede encontrar la dirección",
  detailDepot_00048: "Se ha producido un error desconocido en el mapa de Google",
  
  work_21000: "Código de material",
  work_21001: "Nombre del material",
  work_21002: "Especificación y modelo",
  work_21003: "Número",
  
  }