export default {
  userDetail_00001: "Actualizando mensajes de WeChat",
  userDetail_00002: "Hombre",
  userDetail_00003: "Mujer",
  userDetail_00004: "Modificar",
  userDetail_00005: "Guardar",
  userDetail_00006: "Cancelar",
  userDetail_00007: "OpenID de WeChat del usuario",
  userDetail_00008: "Hora de unirse",
  userDetail_00009: "Número público",
  userDetail_000010: "Seguido",
  userDetail_000011: "Dejado de seguir",
  userDetail_000012: "Hora del último cargo",
  userDetail_000013: "Pago gratuito de WeChat Pay",
  userDetail_000014: "Autorizado",
  userDetail_000015: "No autorizado",
  userDetail_000016: "VIN de enlace EV",
  userDetail_000017: "Cargando",
  userDetail_000018: "Saldo de la billetera",
  userDetail_000019: "€",
  userDetail_000020: "Ver historial de recargas",
  userDetail_000021: "Ver historial de reembolsos",
  userDetail_000022: "Aceptar límites de carga del sitio",
  userDetail_000023: "Vehículos del usuario",
  userDetail_000024: "Modelo",
  userDetail_000025: "Número de matrícula",
  userDetail_000026: "Grupos a los que se unió",
  userDetail_000027: "Datos de carga de los últimos 30 días",
  userDetail_000028: "¡¡Por favor guarde la operación anterior!!!" ,
  userDetail_000029: "El número de teléfono móvil introducido no puede estar vacío",
  userDetail_000030: "El número de matrícula introducido no puede estar vacío",
  userDetail_000031: "Por favor, introduzca el formato correcto: marca|modelo del vehículo",
  userDetail_000032: "Por favor, seleccione la marca o modelo del vehículo",
  userDetail_000033: "Modificado correctamente",
  userDetail_000034: "Tiempos de carga:",
  userDetail_000035: "Potencia de carga:",
  userDetail_000036: "Coste de carga:",
  userDetail_000037: "Tiempos",
  userDetail_000038: "kwh",
  userDetail_000039: "Seleccione marca",
  userDetail_000040: "Seleccione modelo",
  userDetail_000041: "Fabricación|Modelo",
  userDetail_000042: "Ingresar manualmente",
  userDetail_000043: "Usuario sin rango",
  userDetail_000044: "A la actualización a {title} le faltan {num}",
  userDetail_000045: "A la actualización a {title} le faltan {num}",
  userDetail_000046: "No se requiere tarea de relegación",
  userDetail_000047: "Monto del obsequio",
  userDetail_000048: "Unidades",
  userDetail_000049: "Contraseña de inicio de sesión",
  userDetail_000050: "Obligatorio",
  userDetail_000051: "Ingrese su contraseña",
  userDetail_000052: "Observación",
  userDetail_000053: "Obligatorio, no más de 20 caracteres",
  userDetail_000054: "Por favor, introduzca el contenido",
  userDetail_000055: "Confirmar",
  userDetail_000056: "Añadido a lista negra de usuarios",
  userDetail_000057: "Centro de Usuarios",
  userDetail_000058: "Información Básica",
  userDetail_000059: "Vouchers de Tarjeta en Posesión",
  userDetail_000060: "Consulta sobre órdenes de cobro",
  userDetail_000061: "Consulta sobre órdenes de ocupación",
  userDetail_000062: "Añadir a lista negra de usuarios",
  userDetail_000063: "Operación realizada con éxito",
  userDetail_000064: "Nivel de afiliación",
  userDetail_000065: "Ajustar nivel",
  userDetail_000066: "Abrir",
  userDetail_000067: "Desactivado",
  userDetail_000068: "Recarga tu cuenta",
  userDetail_000069: "Ajustar nivel de membresía",
  userDetail_000070: "Ingresa hasta 20 caracteres para notas",
  userDetail_000071: "Ingresa un comentario",
  userDetail_000072: "Ingresa el monto de la recarga o el monto del bono",
  userDetail_000073: "Ingresa el monto correcto para el regalo",
  userDetail_000073: "Ingresa el monto correcto de la recarga",
  userDetail_000074: "Agregar a la lista negra de usuarios",
  userDetail_000075: "¡Después de unirse a la lista negra de usuarios, la microseñal y el número de teléfono móvil del usuario, así como otras microseñales correspondientes al mismo número de teléfono móvil, no pueden cargar el vehículo!" ,
  userDetail_000076: "Fecha de finalización",
  userDetail_000077: "Motivo de la incorporación",
  userDetail_000078: "Seleccionar fecha",
  userDetail_000079: "Por favor, introduzca el motivo de la incorporación a la lista negra (obligatorio)",
  userDetail_000080: "Añadir a",
  userDetail_000081: "¡El motivo no puede estar vacío!",
  userDetail_000082: "¡La fecha de finalización no puede estar vacía!",
  userDetail_000083: "Contiene un regalo de {num}€",
  userDetail_000084: "Incluye asignación de grupo de {num}{unit}€",
  userDetail_000085: "Usuario",
  userDetail_000086: "Gestión de usuarios",
  userDetail_000087: "Administrador",
  userDetail_000088: "Desvincular VIN",
  userDetail_000089: "Comentarios de apodo",
  userDetail_000090: "El comentario de apodo ingresado no puede estar vacío",
  userDetail_000091: "Asocie su número de teléfono móvil",
  userDetail_000092: "No hay ningún número de teléfono móvil vinculado",
  userDetail_000093: "No hay grupos unidos",
  userDetail_000094: "Ingrese su contraseña",
  userDetail_000095: "Ingrese un nombre de comentario de menos de 10 caracteres",
  userDetail_000096: "Ingrese una nota de hasta 10 caracteres",
  userDetail_000097: "Origen del usuario",
  userDetail_000098: "Estado",
  userDetail_000099: "Nombre",
  userDetail_000100: "Fecha de vencimiento",
  userDetail_000101: "Método de adquisición",
  userDetail_000102: "Hora de adquisición",
  userDetail_000103: "Acción",
  userDetail_000104: "Detalles del cupón",
  userDetail_000105: "Detalles del cupón de descuento",
  userDetail_000106: "Detalles del evento",
  userDetail_000107: "Crédito restante",
  userDetail_000108: "Detalles del Power Pack",
  userDetail_000109: "Detalles de los créditos",
  userDetail_000110: "Vouchers",
  userDetail_000111: "Descuentos",
  userDetail_000112: "Boleto de electricidad",
  userDetail_000113: "Créditos",
  userDetail_000114: "Todos",
  userDetail_000115: "Por validar",
  userDetail_000116: "Disponible",
  userDetail_000117: "Usado",
  userDetail_000118: "Caducado",
  userDetail_000119: "Invitar a un amigo: recompensa por aceptar una invitación",
  userDetail_000120: "Invitar a un amigo: recompensa por aceptar una invitación",
  userDetail_000121: "kwh",
  userDetail_000122: "Comprar",
  userDetail_000123: "Canjear",
  userDetail_000124: "Se ha completado el descenso",
  userDetail_000135: "Anulación por lotes de tarjetas y vales",
}