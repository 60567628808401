export default {
  stationRangeManage_00001: "Rango de la estación de administración",
  stationRangeManage_00002: "cuenta",
  stationRangeManage_00003: "Rango de la estación de carga",
  stationRangeManage_00004: "Nombre de la estación de carga",
  stationRangeManage_00005: "Operar",
  stationRangeManage_00006: "Agregar sitio de carga",
  stationRangeManage_00007: "Operar",
  stationRangeManage_00008: "Detalles del sitio",
  stationRangeManage_000024: "Operación exitosa",
  stationRangeManage_000025: "Ingrese el nombre del sitio de carga",
  stationRangeManage_000026: "Sitio de carga agregado exitosamente",
  stationRangeManage_000027: "Sitio de carga seleccionado",
  stationRangeManage_000028: "Eliminación exitosa",
  stationRangeManage_000029: "¿Está seguro de que desea eliminar?",
  stationRangeManage_000030: "Eliminación cancelada",
  }