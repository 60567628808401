export default {
  not_begin: "Aún no iniciado",
  in_progress: "En progreso",
  paused: "En pausa",
  stoped: "Terminado",
  all: "Todo",
  promotion_type1:"Efectivo(después de la recarga)",
  promotion_type2:"Efectivo y cupón(después de la recarga)",
  promotion_type3:"Cupón(después de la recarga)",
  promotion_type4:"Cupón",
  promotion_type5: "Cupón de venta",
  copy_link_success:"Copiar cadena externa exitosamente",
  member_00006: 'Abrir',
  member_00007: 'Cerrar',
  
  handle_giv: "Manual",
  active_limit:"Restricciones",
  user_scope:"Usuarios calificados",
  level_giv:"Regalo directo",
  add_givuser_text1: "El objeto del regalo",
  
  add_givuser_text2: "El objeto del regalo aún no ha sido añadido.",
  giv: "give",
  giv_error_info:"Causa del error",
  card_search_giv: "Por favor, introduzca el apodo o el teléfono para buscar al usuario.",
  search: "Buscar",
  head_portrait: "Perfil",
  Nickname: "Nombre",
  cell_phone_number: "Teléfono",
  
  success_giv: "Enviar correctamente",
  success_giv1: "¡Enviar correctamente!",
  error_giv: "Enviar sin éxito",
  
  stored_00001: 'Centro de marketing',
  stored_00002: 'Actividad',
  stored_00003: 'Nombre',
  stored_00004: 'estado',
  stored_00005: 'Restablecer',
  stored_00006: 'Buscar',
  stored_00007: 'Lista de actividades',
  stored_00008: 'crear',
  stored_00009: 'Nombre de la actividad',
  stored_00010: 'Hora de la actividad',
  stored_00011: 'estado',
  stored_00012: 'Entrada de la actividad',
  stored_00013: 'Enlace especial de salida de la actividad',
  stored_00014: 'Copiar enlace',
  stored_00015: 'Mostrar o no',
  stored_00016: 'Propietario del coche - mi cartera - recarga',
  stored_00017: 'Operación',
  stored_00018: 'Detalles',
  stored_00019: 'Manual',
  stored_00020: 'Actividad de devolución de valor almacenado',
  stored_00021: 'Actividades de recogida gratuita',
  stored_00022: 'ID de actividad',
  stored_00023: 'Entrada a la actividad (cuenta pública)',
  stored_00024: 'Período de vigencia',
  stored_00025: 'Todo el día',
  stored_00026: '',
  stored_00027: '',
  stored_00028: '',
  stored_00029: '',
  stored_00030: '',
}