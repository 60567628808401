export default {
    energyStatistics_00001: "Cantidad de descarga de NZS",
    energyStatistics_00002: "Uso de la red",
    energyStatistics_00003: "Cantidad de carga de EV (kwh)",
    energyStatistics_00004: "Trenza de ingresos de energía",
    energyStatistics_00005: "Ingresos totales de carga de EV",
    energyStatistics_00006: "Carga de NZS (kwh)",
    energyStatistics_00007: "Análisis",
    energyStatistics_00008: "Costo de descarga de parte de la red",
    energyStatistics_00009: "Costo de carga de parte de la red",
    energyStatistics_00010: "Ingresos de energía",
    energyStatistics_00011: "Cantidad de electricidad de carga de EV",
    energyStatistics_00012: "Uso de carga de EV (kwh)",
    energyStatistics_00013: "Ingresos totales de energía",
    energyStatistics_00014: "Ingresos totales de energía",
} 