export default {
  maintenanceHome_00001: "Número actual de cargadores con fallas",
  maintenanceHome_00002: "Número actual de cargadores fuera de línea",
  maintenanceHome_00003: "El número de trabajo no está cerrado",
  maintenanceHome_00004: "Número de cargadores que necesitan realizar mantenimiento al módulo de potencia",
  maintenanceHome_00005: "Número de cargadores que necesitan realizar mantenimiento a otras partes",
  maintenanceHome_00006: "Ver",
  maintenanceHome_00007: "Nombre de la estación",
  maintenanceHome_00008: "Monitoreo en tiempo real",
  maintenanceHome_00009: "Tasa anormal de responsabilidad del cargador hoy",
  maintenanceHome_000010: "Tasa anormal de responsabilidad del vehículo hoy",
  maintenanceHome_000011: "Análisis de tasa de anomalías",
  maintenanceHome_000012: "Análisis en línea",
  
  }