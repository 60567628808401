export default {
  weChatApp_00001: "Cuentas oficiales de Wechart",
  weChatApp_00002: "Pago de WeChat",
  weChatApp_00003: "Información de la cuenta oficial",
  weChatApp_00004: "Nombre de la cuenta oficial",
  weChatApp_00005: "Foto de perfil de la cuenta oficial",
  weChatApp_00006: "Reglas de visualización del sitio",
  weChatApp_00007: "Visualización de sitios de terceros en la cuenta oficial",
  weChatApp_00008: "Compartir mi sitio con otras plataformas",
  weChatApp_00009: "Configuración del menú inferior de la cuenta oficial de WeChat",
  weChatApp_000010: "Ingrese a la plataforma oficial de WeChat, agregue un menú en el 'Menú personalizado', seleccione 'Ir a la Web' para el contenido del menú y copie la dirección URL a continuación en la 'Página Dirección'.",
  weChatApp_000011: "Buscar la dirección del sitio de cobro",
  weChatApp_000012: "Dirección del Centro Personal",
  weChatApp_000013: "Ir a la Plataforma Pública WeChat",
  weChatApp_000014: "Pautas de acceso a pagos WeChat",
  weChatApp_000015: "Seguir las pautas para completar las operaciones pertinentes para usar el servicio de pago WeChat.",
  weChatApp_000016: "Configuración del directorio de autorización de pago del número de comerciante de pagos WeChat",
  weChatApp_000017: "Ir a la Plataforma de Comerciantes WeChat",
  weChatApp_000018: "Vaya a la Plataforma de Comerciantes WeChat Payments y copie y agregue la siguiente dirección al 'Directorio de Autorización de Pago' de 'Pagos Públicos' en la 'Configuración de Desarrollo' del 'Centro de Productos'. Nota: Seleccione 'https://' en la lista desplegable.",
  weChatApp_000019: "Dirección del directorio de autorización",
  weChatApp_000020: "Copiar enlace",
  weChatApp_000021: "Configuración del número de comerciante de pago de la plataforma comercial WeChat",
  weChatApp_000022: "Ingrese a la plataforma comercial WeChat y agregue 'Número de comerciante WeChat Pay' a 'Información básica de la cuenta' en 'Información del comerciante' en 'Centro de cuentas'.",
  weChatApp_000023: "Número de comerciante de pago WeChat",
  weChatApp_000024: "Configuración de la clave secreta de la API de la plataforma comercial WeChat",
  weChatApp_000025: "Ingrese a la plataforma comercial WeChat y copie la clave secreta delegada en 'Configuración de clave secreta' de 'Seguridad de API' en el 'Centro de cuentas'.",
  weChatApp_000026: "Modificar clave secreta",
  weChatApp_000027: "Generar una nueva clave secreta",
  weChatApp_000028: "Después de generar una nueva clave secreta, debe ingresar a la Plataforma comercial WeChat, copiar la clave secreta generada a continuación en 'Configuración de clave secreta' de 'Clave secreta de API' en 'Centro de cuentas' y luego regresar a la plataforma para guardar la clave secreta para garantizar la recolección normal",
  weChatApp_000029: "Cargar certificado",
  weChatApp_000030: "La función de reembolso automático se puede habilitar después de cargar el certificado",
  // ***** weChatApp_000031
  // ；<br/>El usuario puede acceder a la plataforma de WeChat Merchant sin problemas, lo que le permite acceder a la plataforma de WeChat Merchant ... href='http://kf.qq.com/faq/161222NneAJf161222U7fARv.html' target=_blank>该微信文档</a>申请证书后，在本页面内上传 .p12格式的证书文件.
   weChatApp_000031: "Inicie sesión en WeChat Payment Merchant Platform, haga clic en [Centro de cuentas] -> [Configuración de cuenta] -> [Seguridad API], si ve el botón [Descargar certificado], descargue el archivo del certificado directamente y cárguelo en esta página.",
   weChatApp_000032: "Certificado cargado",
   weChatApp_000033: "Haga clic para cargar",
  weChatApp_000034: "Asegúrese de que el certificado sea la última versión (siga las notificaciones de la plataforma comercial WeChat)",
  weChatApp_000035: "XCharge solo utilizará este certificado para operaciones de reembolso",
  weChatApp_000036: "Recordatorio especial: los certificados de pago son información confidencial, utilice esta función con precaución",
  weChatApp_000037: "Después de configurar el número de comerciante de pago de la plataforma comercial WeChat, puede configurar 'Sí' para mostrar el método de pago WeChat en el número público WeChat aquí, de lo contrario, los usuarios no podrán usar el pago WeChat en el número público WeChat",
  weChatApp_000038: "WeChat Public News admite el método de pago WeChat",
  weChatApp_000039: "¡Guarde la operación anterior!" ,
  weChatApp_000040: "Copiada - Dirección de la página 'Estación de carga'",
  weChatApp_000041: "Copiada - Dirección de la página 'Historial de facturación'",
  weChatApp_000042: "Modificado correctamente, actualice la dirección de Buscar estación de carga en el menú en la parte inferior de la página pública de WeChat",
  weChatApp_000043: "Modificado correctamente",
  weChatApp_000044: "Copiada - Dirección de la página 'Directorio de autorización de pago'",
  weChatApp_000045: "Clave de autorización copiada",
  weChatApp_000046: "Copiada - 'Clave de autorización'",
  weChatApp_000047: "Confirmar guardado de clave",
  weChatApp_000048: "Nota especial",
  weChatApp_000049: "Si guarda los cambios directamente sin modificar la clave secreta en la Plataforma comercial de WeChat, provocará que la orden de cobro no se pueda pagar, haga clic en 'Cancelar' para abandonar el proceso de guardado. Si confirma que se han realizado los cambios, haga clic en 'Guardar' después de la cuenta regresiva de 30 segundos" ,
  weChatApp_000050: "Haga clic para cargar",
  weChatApp_000051: "Cargar",
  weChatApp_000052: "Debe ser un archivo en formato zip o p12",
  weChatApp_000053: "Carga exitosa",
  weChatApp_000054: "¿Está seguro de eliminar el certificado?" ,
  weChatApp_000055: "Recordatorio",
  weChatApp_000056: "No hay permiso para operar",
  weChatApp_000057: "Error al cargar",
  
  }