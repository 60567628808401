export default {
alipayApp_00001: "Plataforma abierta Anthem para crear aplicaciones",
alipayApp_00002: "Para agregar el método de pago Alipay a la cuenta oficial de WeChat, debe crear una aplicación en la Plataforma abierta Anthem antes de poder usarla. Use su cuenta de Alipay para iniciar sesión en la Plataforma abierta Anthem (se requiere una cuenta de Alipay empresarial certificada con nombre real). La primera vez, seleccione 'Estado de residente' como Desarrollador autónomo.",
alipayApp_00003: "Haga clic para ver el proceso de membresía por primera vez en la plataforma abierta de Anthem.",
alipayApp_00004: "Cree la aplicación [Acceso de pago] en [Centro de desarrolladores] - [Aplicación web y móvil]. Cuando cree la aplicación, si ha seleccionado el escenario de uso, seleccione el escenario de uso como [Aplicación de uso propio]. Agregue la función [Pago en sitio web móvil]. Puede registrarse inmediatamente o después de agregar la aplicación correctamente.",
alipayApp_00005: "Haga clic para ver el proceso de creación de una aplicación.",
alipayApp_00006: "Al crear la aplicación, seleccione 'Clave secreta RSA (SHA256)' como método de firma de interfaz para configurar la clave pública de la aplicación. Antes de configurar la clave pública de la aplicación, genere primero la clave secreta. Al generar la clave secreta, elija PKCS8 (aplicable a JAVA) como formato de clave secreta y 2048 como longitud de clave secreta.",
alipayApp_00007: "Haga clic para ver el proceso de generación de clave secreta.",
alipayApp_00008: "Después de agregar la aplicación, complete la configuración de APPID y la clave secreta de la aplicación en la plataforma de operaciones y luego solicite la aplicación en línea.",
alipayApp_00009: "Vaya a Anthem Open Platform",
alipayApp_000010: "Configuración de ID de aplicación de la aplicación",
alipayApp_000011: "Después de terminar de crear la aplicación, vaya a Anthem Open Platform y agregue el APPID de la aplicación creada aquí en [Centro para desarrolladores] - [Aplicación web y móvil].",
alipayApp_000012: "APPID",
alipayApp_000013: "Configuración de clave secreta",
alipayApp_000014: "Después de terminar de crear la aplicación, agregue la clave privada de la aplicación generada por la configuración [Agregar método de firma] [Clave secreta RSA(SHA256)] cuando agregue la aplicación aquí. Verifique también la clave pública de Alipay y agregue la clave pública de Alipay aquí. Más tarde, si cambia la clave secreta en Anthem Open Platform, agréguela aquí nuevamente; de ​​lo contrario, WeChat Public no podrá usar la función de pago de Alipay",
alipayApp_000015: "Clave privada de la aplicación y clave pública de Alipay",
alipayApp_000016: "Configurar",
alipayApp_000017: "Establecer la hora:",
alipayApp_000018: "Mostrar el método de pago de Alipay en la cuenta oficial de WeChat",
alipayApp_000019: "Después de crear la aplicación en Anthem Open Platform, puede solicitar que la aplicación esté en línea en Anthem Open Platform después de completar la configuración de la APPID de la aplicación y la configuración de la clave secreta en la plataforma de operación. Una vez que la aplicación se haya iniciado correctamente en Anthem Open Platform, puede configurar 'Sí' para mostrar el método de pago de Alipay en el número público de WeChat; de lo contrario, los usuarios no podrán usar el pago de Alipay en el número público de WeChat",
alipayApp_000020: "Configuración",
alipayApp_000021: "Agregue aquí la clave privada de la aplicación (obligatorio)",
alipayApp_000022: "Agregue aquí la clave pública de Paypal (obligatorio)",
alipayApp_000023: "No configurado",
alipayApp_000024: 'Primero configure "No" en "Mostrar el método de pago de Alipay en el sitio web público de WeChat"',
alipayApp_000025: "",
alipayApp_000026: "",

}