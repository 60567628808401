export default {
  powerMaint_00001: 'El cargador necesita realizar mantenimiento a los módulos de energía',
  powerMaint_00002: '',
  powerMaint_00003: 'Recordatorio para el mantenimiento del módulo de energía',
  powerMaint_00004: 'Tiempo acumulado desde el tiempo de reinicio',
  powerMaint_00005: 'Tiempo de reinicio: ',
  powerMaint_00006: '360 horas',
  powerMaint_00007: 'Normal',
  powerMaint_00008: 'Con falla',
  powerMaint_00009: "¿Está seguro de haber completado este mantenimiento para el módulo de energía de este punto de carga? Después de hacer clic en Aceptar, el módulo de energía necesita mantenimiento para volver a programar el tiempo.",
  powerMaint_00010: 'Consejos',
  powerMaint_00011: 'Confirmar',
  powerMaint_00012: 'Cancelar',
  powerMaint_00013: 'Éxito',
  powerMaint_10000: 'El cargador necesita mantenimiento',
  powerMaint_10001: 'Tiempo de luz',
  powerMaint_10002: 'Usado',
  powerMaint_10003: 'hora',
  powerMaint_10004: 'tiempo',
  powerMaint_10005: 'Umbral de aviso de tiempo de mantenimiento: ',
  powerMaint_10006: 'El tiempo de uso acumulado desde el tiempo de reinicio es mayor o igual a',
  }