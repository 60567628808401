export default {
    gia_00001:'Finanzas',
    gia_00002:'Funciones comunes',
    gia_00003:'Los tipos de facturas solicitadas son todas las facturas ordinarias de impuesto al valor agregado, excluyendo el monto de reembolso y el monto de descuento.',
    gia_00004:'estado de la solicitud',
    gia_00005:'Todos',
    gia_00006:'Pendiente',
    gia_00007:'Abierto',
    gia_00008:'Cancelado',
    gia_00009:'grupo',
    gia_000010:'Consultar',
    gia_000011:'Reiniciar',
    gia_000012:'exportar datos',
    gia_000013:'Número de transacciones pendientes',
    gia_000014:'Monto total a procesar (yuanes)',
    gia_000015:'Número de facturas',
    gia_000016:'Monto total facturado (yuanes)',
    gia_000017:'Nombre de usuario',
    gia_000018:'Tipo de solicitud',
    gia_000019:'Método de facturación',
    gia_000020:'Monto de la solicitud',
    gia_000021:'Consultar orden de cobro',
    gia_000022:'Consultar factura de ocupación de plaza de aparcamiento',
    gia_000023:'Motivo del rechazo',
    gia_000024:'Detalles de la factura',
    gia_000025:'Factura',
    gia_000026:'N.º de empresa',
    gia_000027:'Dirección',
    gia_000028:'Rechazar',
    gia_000029:'Información',
    gia_000030:'Tel',
    gia_000031:'Correo',
    gia_000032:'Mostrar en todas las páginas',
    gia_000033:'Botón de aviso para rechazar factura',
    gia_000034:'Por favor, rellene el motivo del rechazo',
    gia_000035:'Botón de recordatorio de que la factura ha sido abierta',
    gia_000036:'Factura en papel',
    gia_000037:'Factura electrónica',
    gia_000038:'pista',
    gia_000039:'Nombre de usuario/Tel.',
    gia_000040:'hora de solicitud:',
    gia_000041:'fecha de inicio',
    gia_000042:'Fecha de finalización',
    gia_000043:'Factura de empresa',
    gia_000044:'Factura personal',
    gia_000045:'Número de teléfono oficial',
    gia_000046:'Cuenta bancaria',
    gia_000047:'Cuenta',
    gia_000048:'destinatario',
    gia_000049:'Dirección de envío',
    gia_000050:'Sin datos',
    gia_000051:'Esta operación descargará el archivo Excel de la factura, ¿desea continuar?',
    gia_000052:'Pista',
    gia_000053:'Aceptar',
    gia_000054:'Cancelar',
    gia_000055:'Iniciar descarga',
    gia_000056:'Cancelar descarga',
    gia_000057:'Lista de facturas',
    gia_000058:'Factura en papel y factura electrónica'
}