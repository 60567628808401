export default {
  marketingGuidance_00001: "Escribir a amigos operadores",
  marketingGuidance_00002: "Estimados amigos operadores, hola",
  marketingGuidance_00003: "Sabemos que la comercialización de estaciones de carga no es una tarea fácil. Aunque el camino es largo y no fácil, sigues avanzando con extraordinaria perseverancia. Zhichong, como tu sólido respaldo, siempre avanzará contigo.",
  marketingGuidance_00004: "Esperamos que a través de esta función, usted tenga ideas de marketing más claras, domine estrategias eficientes y prácticas, y resuelva los problemas de marketing de las estaciones de carga que le han preocupado uno por uno.",
  marketingGuidance_00005: "¿Cómo conseguir tráfico?",
  marketingGuidance_00006: "La estación de carga está abierta para introducir tráfico de terceros. Una combinación de actividades preferenciales. La estación tiene clientes de flota estables. Adquisición de clientes de Fission: invita a amigos.",
  marketingGuidance_00007: "¿Cómo aumentar el flujo de caja en poco tiempo?",
  marketingGuidance_00008: "Evento de reembolso por grandes cantidades de valor almacenado. Canales de terceros venden tarjetas y cupones. Vender miembros privilegiados.",
  marketingGuidance_00009: "¿Cómo hacer que los propietarios de automóviles vengan a cobrar con frecuencia? Configure un sistema de actualización, mantenimiento y crecimiento de usuarios.",
  marketingGuidance_00010: "Marketing de clasificación jerárquica precisa del usuario.",
  marketingGuidance_00011: "Guía de uso",
 
 }