export default {
  knowledge_00001: 'Banco de conocimiento',
  knowledge_00002: 'Título/Descripción del problema/Solución',
  knowledge_00003: 'Todas las fábricas',
  knowledge_00004: 'Todos los modelos',
  knowledge_00005: 'Lista',
  knowledge_00006: 'Crear conocimiento',
  knowledge_00007: 'Título del conocimiento',
  knowledge_00008: 'Categorías',
  knowledge_00009: 'Cargadores relacionados',
  knowledge_00010: '',
  knowledge_00011: '',
  knowledge_00012: '',
  knowledge_00013: '',
  knowledge_00014: 'Categoría secundaria',
  knowledge_00015: 'Todas las categorías',
  knowledge_00016: 'Categoría superior',
}