export default {
  platformLog_00001: "Hora de activación",
  platformLog_00002: "Fecha de inicio",
  platformLog_00003: "Fecha de finalización",
  platformLog_00004: "N.º de serie del cargador",
  platformLog_00005: "ID de transacción",
  platformLog_00006: "Protocolo de comunicación",
  platformLog_00007: "Método de comunicación",
  platformLog_00008: "Valor de la clave del mensaje",
  platformLog_00009: "Datos del mensaje",
  platformLog_00010: "Traducción del mensaje",
  platformLog_00011: "Entrega en la nube",
  platformLog_00012: "Informe del dispositivo",
  platformLog_00013: "Ver",
  platformLog_00014: "Cambiar el original",
  platformLog_00015: 'Coincidencia aproximada, múltiples separados por comas',
  platformLog_00016: "El rango de fechas no puede superar los 90 días, ¡seleccione nuevamente!",
  platformLog_00018: "¡Seleccione una fecha!",
  platformLog_00019: "Ingrese contenido",
  platformLog_00020: "Contenido del mensaje",
  platformLog_00021: "Ingrese el segmento de datos del mensaje",
  platformLog_00022: "Datos firmados",
  platformLog_00023: "Datos firmados",
  platformLog_00024: "Clave pública",
  }