export default {
  onlineAnalysis_00001: "tarifa en línea de cargadores por estación",
  onlineAnalysis_00002: "Hora del informe",
  onlineAnalysis_00003: " - ",
  onlineAnalysis_00004: "Análisis",
  onlineAnalysis_00005: "Equipo de la estación",
  onlineAnalysis_00006: "Tarifa en línea = (1 - duración fuera de línea en este período de tiempo de consulta / todo el período de tiempo de consulta)",
  onlineAnalysis_00007: "Nombre del equipo",
  onlineAnalysis_00008: "Modelo del equipo",
  onlineAnalysis_00009: "Número de serie del equipo",
  onlineAnalysis_000010: "Tarifa en línea",
  onlineAnalysis_000011: "Operación",
  onlineAnalysis_000012: "Detalles del equipo",
  onlineAnalysis_000013: "Registro de errores del equipo",
  }