export default {
    depotMap_00001: "Estaciones",
    depotMap_00002: "Usuario que carga actualmente",
    depotMap_00003: "Potencia total",
    depotMap_00004: "Precio(€)",
    depotMap_00005: "Electricidad(kWh)",
    depotMap_00006: "Mostrar todo",
    depotMap_00007: "Potencia total(kW)",
    depotMap_00008: "Usuario de WeChat",
    depotMap_00009: "Tarjeta de inicio/parada sin conexión",
    depotMap_000010: "Tarjeta de valor almacenado sin conexión",
    depotMap_000011: "Tarjeta de identidad en línea",
    depotMap_000012: "Administrador",
    depotMap_000013: "Usuario de terceros",
    depotMap_000014: "Tarjeta de crédito",
    depotMap_000015: "Carga inactiva",
    depotMap_000016: "Carga en uso",
    depotMap_000017: "Carga anormal",
}