export default {
    chargeMonitor_00001: 'Seleccionar estaciones',
    chargeMonitor_00002: 'Nombre de la estación de carga',
    chargeMonitor_00003: 'CPO',
    chargeMonitor_00004: 'Monitor de cargador',
    chargeMonitor_00005: 'Transacciones',
    chargeMonitor_00006: 'Información de la transacción',
    chargeMonitor_00007: 'Información de carga',
    chargeMonitor_00008: 'Transacción actual',
    chargeMonitor_00009: 'Monto cargado',
    chargeMonitor_00010: "Puerta de enlace",
    chargeMonitor_00011: "Disponible",
    chargeMonitor_00012: "En uso",
    chargeMonitor_00013: "Error/Fuera de línea",
    chargeMonitor_00014: "No disponible",
    chargeMonitor_00015: "Disponible",
    chargeMonitor_00016: "En línea",
    chargeMonitor_00017: "Fuera de línea",
    chargeMonitor_00018: "Con fallas",
    chargeMonitor_00019: "Reservado",
    chargeMonitor_00020: "En preparación",
    chargeMonitor_00021: "En carga",
    chargeMonitor_00022: "Pendiente de retiro del vehículo",
    chargeMonitor_00023: "Terminando",
    chargeMonitor_00024: "Ocupando",
    chargeMonitor_00025: "Ingrese el nombre del CPO",
    chargeMonitor_00026: "Devuelto",
    chargeMonitor_00027: "No devuelto",
    chargeMonitor_00028: "Ver todos los cargadores con fallas",
    chargeMonitor_00029: "Ver todos los cargadores sin conexión",
    chargeMonitor_00030: "Agregar nueva pestaña a la ventana",
    chargeMonitor_00031: "Guardar y abrir",
    chargeMonitor_00032: "¿Desea abrir todas las estaciones de monitoreo según la última configuración de la pestaña del navegador?",
    chargeMonitor_00033: "Seleccione una estación para monitorear en la ventana actual",
    chargeMonitor_00034: "Ayuda de configuración",
    chargeMonitor_00035: "Indique las estaciones que desea monitorear (hasta 10) en cada pestaña, haga clic en el botón 'Abrir' y se abrirá la pestaña del navegador correspondiente para mostrar las estaciones de monitoreo según su configuración",
    chargeMonitor_00036: 'Si su navegador detuvo la ventana recién abierta, verifique la configuración de su navegador y permita ventanas emergentes',
    chargeMonitor_00037: 'Seleccionar todo',
    chargeMonitor_00038: 'Estaciones seleccionadas en la página actual',
    chargeMonitor_00039: 'Botón de emergencia activado, carga suspendida',
    chargeMonitor_00040: 'Avería en el cargador, carga suspendida',
    chargeMonitor_00041: 'Cargado completamente o se alcanzaron las condiciones establecidas',
    chargeMonitor_00042: 'Avería en el cargador, carga detenida',
    chargeMonitor_00043: 'Carga detenida de forma remota por operadores',
    chargeMonitor_00044: 'Detener la carga desde la estación de carga',
    chargeMonitor_00045: 'Cargando',
    chargeMonitor_00046: 'Preparando',
    chargeMonitor_00047: 'Terminando',
    chargeMonitor_00048: 'Ocupando',
    chargeMonitor_00049: 'Detenido de forma anormal',
    chargeMonitor_00050: 'Carga completada',
    chargeMonitor_00051: 'Esperando energía disponible',
    chargeMonitor_00052: 'Error Log',
    chargeMonitor_00053: "Estimado",
    chargeMonitor_00054: "Completamente cargado",
    chargeMonitor_00055: 'Cargado',
    chargeMonitor_00056: "Cambie la ventana de la pestaña actual para eliminar la etiqueta",
    chargeMonitor_00057: "No se puede eliminar el primer elemento",


    eqDetails_30015: "Confirmar y recuperarse de una parada de emergencia",
    eqDetails_30016: "El cargador está en uso, la autoinspección se canceló, opere después de que esté inactivo",
    eqDetails_30017: "El cargador actual está fuera de línea",
    eqDetails_30018: "Se agotó el tiempo de autoinspección del cargador, intente nuevamente",
    eqDetails_30020: "Más deficiente",
    eqDetails_30021: "Moderado",
    eqDetails_30022: "Mejor",
    eqDetails_30023: "Esta inspección requiere que el número de versión de firmware de la aplicación Android para el cargador sea 3.0.0 o superior",
    eqDetails_30024: "No se encontró el último resultado de la autoinspección",
    eqDetails_30025: "Recuperación inmediata",
    eqDetails_30026: "Primero verifique el cargador y luego considere la recuperación",
    eqDetails_30027: "Descargo de responsabilidad",
    eqDetails_30028: "Para garantizar el funcionamiento seguro del cargador, le recomendamos que realice una verificación de hardware antes de restablecer el botón de parada de emergencia. Si encuentra alguna falla de hardware o peligro de seguridad, deje de usar el cargador y comuníquese con nuestro equipo de soporte técnico. Le brindaremos una solución lo antes posible para garantizar que su cargador pueda funcionar de manera segura y estable",
    eqDetails_30029: "Tenga en cuenta que este aviso legal tiene como objetivo recordarle que elegir restaurar directamente el botón de parada de emergencia sin determinar primero el estado del cargador puede implicar ciertos riesgos y no seremos responsables de las consecuencias resultantes.",
    eqDetails_30030: "Confirmar la recuperación directa",
    eqDetails_30031: "Elementos de autocomprobación",
    eqDetails_30032: "Estado",
    eqDetails_30033: "{msg},{code},la autoinspección está cancelada. Opere después del mantenimiento",
}