export default {
  orderMangement_00001: 'Disparador automático de tickets',
  orderMangement_00002: 'Nombre del disparador',
  orderMangement_00003: 'Si el disparador está habilitado',
  orderMangement_00004: 'Todos',
  orderMangement_00005: 'Habilitar',
  orderMangement_00006: 'Disparador de estadísticas de operación',
  orderMangement_00007: 'Disparador de informe de cobrador',
  orderMangement_00008: 'Categoría de ticket',
  orderMangement_00009: 'Condición del disparador',
  orderMangement_00010: 'Procesador de tickets',
  orderMangement_00011: 'Seguidor de tickets',
  orderMangement_00012: 'Hora de notificación de tickets',
  orderMangement_00013: 'Nuevo disparador',
  orderMangement_00014: 'Cuando el cargador informa que hay una falla en el cargador o que el cargador es anormal, si el disparador no vuelve a la normalidad después del intervalo de tiempo establecido por el disparador, iniciará automáticamente un ticket de resolución de problemas. (Nota: Algunos errores de cargador o desconexión se recuperarán automáticamente, si el intervalo de tiempo se establece demasiado corto, es fácil generar falsas alarmas)',
  orderMangement_00015: 'Todas las categorías',
  orderMangement_00016: 'Clasificación de tickets',
  orderMangement_00017: 'Número de serie del dispositivo',
  orderMangement_00018: 'Todos los cargadores por fabricante',
  orderMangement_00019: 'Todos los modelos',
  orderMangement_00020: 'Ticket pendiente',
  orderMangement_00021: 'Procesando ticket',
  orderMangement_00022: 'Tickets resueltos',
  orderMangement_00023: 'Tickets cerrados',
  orderMangement_00024: 'Lista de tickets',
  orderMangement_00025: 'Exportar ticket',
  orderMangement_00026: 'Número de ticket',
  orderMangement_00027: 'Título del ticket',
  orderMangement_00028: 'Clasificación',
  orderMangement_00029: 'Prioridad',
  orderMangement_00030: 'Estado',
  orderMangement_00031: 'Estación con problemas',
  orderMangement_00032: 'Procesador',
  orderMangement_00033: 'Originador',
  orderMangement_00034: 'Hora de origen',
  orderMangement_00035: 'Hora de actualización',
  orderMangement_00036: 'Origen del problema',
  orderMangement_00037: 'Operación',
  orderMangement_00038: 'Volver al ticket',
  orderMangement_00039: 'Procesado',
  orderMangement_00040: 'Aceptar',
  orderMangement_00041: 'Transferir ticket',
  orderMangement_00042: 'Cerrar el pedido',
  orderMangement_00043: 'Agregar al banco de conocimiento',
  orderMangement_00044: 'Detalles',
  orderMangement_00045: 'Muy urgente',
  orderMangement_00046: 'Urgente',
  orderMangement_00047: 'General',
  
  orderMangement_00048: 'Cuando el sistema detecta que una determinada cantidad de transacciones anormales de los cargadores ha alcanzado un determinado número en unos pocos días, iniciará automáticamente un ticket para su investigación. ',
  orderMangement_00049: 'Cuando el sistema detecta que una determinada cantidad de transacciones anormales de usuarios alcanza un cierto número en unos pocos días, iniciará automáticamente un ticket para su investigación. ',
  orderMangement_00050: 'Cuando el sistema detecta que en unos pocos días hay más de un cargador en una determinada estación sin pedido y tampoco muestra ningún código de error, iniciará automáticamente un ticket para su investigación. ',
  orderMangement_00051: 'Estadísticas de transacción anormales-cargador',
  orderMangement_00052: 'Estadísticas de transacción anormales-usuario',
  orderMangement_00053: 'No se está cargando el cargador',
  orderMangement_00054: 'Disparador del cargador',
  orderMangement_00055: '',
  orderMangement_00056: '',
  orderMangement_00057: '',
  orderMangement_00058: '',
  orderMangement_00059: '',
  orderMangement_00060: '',
  orderMangement_00061: '',
  orderMangement_00062: '',
  orderMangement_00063: '',
  orderMangement_00064: '',
  orderMangement_00065: 'Razonamiento de cierre',
  orderMangement_00066: 'Devolver al ticket',
  orderMangement_00067: '¿Está seguro de devolver el ticket?',
  orderMangement_00068: 'Confirmar rechazo',
  orderMangement_00069: 'Razón de devolución',
  orderMangement_00070: 'El ticket ha sido procesado',
  orderMangement_00071: '¿Confirma que el ticket ha sido procesado?',
  orderMangement_00072: 'La confirmación ha sido procesada',
  orderMangement_00073: 'Por favor, complete el formulario',
  orderMangement_00074: 'La operación fue exitosa',
  orderMangement_00075: 'Anormal',
  orderMangement_00076: 'Continuo',
  orderMangement_00077: 'Configuración',
  orderMangement_00078: 'Estaciones',
  orderMangement_00079: 'día(s)',
  orderMangement_00080: 'transacciones anormales',
  orderMangement_00081: 'minutos después',
  orderMangement_00082: 'Descripción',
  orderTriggerList_00001:"Cargador fuera de línea",
  orderTriggerList_00002:"Botón de parada de emergencia presionado",
  orderTriggerList_00003:"Módulo de potencia en alarma",
  orderTriggerList_00004:"Error en la operación de habilitación/desbloqueo de la cerradura electromagnética",
  }