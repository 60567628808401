export default {
  orderClassify_00001: "Categoría de ticket",
  orderClassify_00002: "Lista de categorías",
  orderClassify_00003: "Nueva categoría",
  orderClassify_00004: "Nombre de la categoría",
  orderClassify_00005: "Categoría principal",
  orderClassify_00006: "Nivel de categoría",
  orderClassify_00007: "Nivel superior",
  orderClassify_00008: "Segundo nivel",
  orderClassify_00009: "Procesador de categoría de ticket predeterminado",
  orderClassify_00010: "Seguidor de categoría de ticket predeterminado",
  orderClassify_00011: "Operación",
  orderClassify_00012: "Crear subcategoría",
  orderClassify_00013: "Editar",
  orderClassify_00014: "Eliminar",
  orderClassify_00015: "Ninguno",
  orderClassify_00016: "No se puede recuperar después de eliminar una categoría",
  orderClassify_00017: "No se puede eliminar la categoría actual",
  orderClassify_00018: "Nivel superior",
  orderClassify_00019: "Nombre de la categoría",
  orderClassify_00020: "Tickets de la configuración predeterminada de la categoría",
  orderClassify_00021: "Descripción del problema",
  orderClassify_00022: "Procesador predeterminado",
  orderClassify_00023: "Seguidor predeterminado",
  orderClassify_00024: "Todos",
  orderClassify_00025: "Editar categoría",
  orderClassify_00026: "Por favor, rellene el nombre de la clasificación",
  orderClassify_00027: "Por favor, seleccione el encuestado del problema",
  orderClassify_00028: "Por favor, seleccione un seguidor",
  orderClassify_00029: "Nuevo éxito",
  orderClassify_00030: "Modificado con éxito",
  orderClassify_00031: "No se puede eliminar",
  }