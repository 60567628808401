export default {
  userOrderQuery_00001: "Nombre del grupo",
  userOrderQuery_00002: "Consulta de orden de cobro",
  userOrderQuery_00003: "Número de pedido",
  userOrderQuery_00004: "Restablecer",
  userOrderQuery_00005: "Ingresos (€)",
  userOrderQuery_00006: "Importe del pedido (€)",
  userOrderQuery_00007: "Importe de electricidad (€)",
  userOrderQuery_00008: "Importe del cargo por servicio (€)",
  userOrderQuery_00009: "Importe de la tarifa de extensión (€)",
  userOrderQuery_00010: "Potencia de cobro (kWh)",
  userOrderQuery_00011: "Duración de cobro (min)",
  userOrderQuery_00012: "Tiempos de cobro (veces)",
  userOrderQuery_00013: "Hasta",
  userOrderQuery_00014: "Fecha de inicio",
  userOrderQuery_00015: "Fecha de finalización",
  userOrderQuery_00016: "Lista de pedidos",
  userOrderQuery_00017: "Exportar pedidos",
  userOrderQuery_00018: "Puesto de carga - puerto de carga",
  userOrderQuery_00019: "Horas de carga",
  userOrderQuery_00020: "Eliminado",
  userOrderQuery_00021: "Iniciar carga",
  userOrderQuery_00022: "Finalizar carga",
  userOrderQuery_00023: "Cantidad de carga (kwh)",
  userOrderQuery_00024: "Creado",
  userOrderQuery_00025: "Cancelado",
  userOrderQuery_00026: "Cargo en curso",
  userOrderQuery_00027: "Pago pendiente",
  userOrderQuery_00028: "Pagado",
  userOrderQuery_00029: "Grupo",
  userOrderQuery_00030: "Usuario de WeChat",
  userOrderQuery_00031: "Administrador",
  userOrderQuery_00032: "Monto actual del pedido",
  userOrderQuery_00033: "Monto real del pago",
  userOrderQuery_00034: "Detalles del pedido",
  userOrderQuery_00035: "Esta operación descargará un archivo Excel de la factura, ¿desea continuar?" ,
  userOrderQuery_00036: "Recordatorio",
  userOrderQuery_00037: "OK",
  userOrderQuery_00038: "Cancelar",
  userOrderQuery_00039: "Los datos se están preparando, tenga paciencia. Cuando los datos estén listos, se cerrará automáticamente esta ventana emergente y comenzará la descarga. No cierre ni actualice la página mientras tanto..." ,
  userOrderQuery_00040: "Cancelar descarga",
  userOrderQuery_00041: "Días",
  userOrderQuery_00042: "Horas",
  userOrderQuery_00043: "Minutos",
  userOrderQuery_00044: "Iniciar/detener tarjeta",
  userOrderQuery_00045: "Tarjeta de valor almacenado",
  userOrderQuery_00046: "Hacer copia de seguridad de los datos",
  userOrderQuery_00047: "Tarjeta de identidad",
  userOrderQuery_00048: "Pedido de doble carga",
  userOrderQuery_00049: "Potencia inferior a 1 kwh",
  userOrderQuery_00050: "La duración de la carga es demasiado larga",
  userOrderQuery_00051: "El cargador finalizó de forma anormal",
  userOrderQuery_00052: "El vehículo eléctrico finalizó de forma anormal",
  userOrderQuery_00053: "",
  userOrderQuery_00054: "",
  userOrderQuery_00055: "",
  userOrderQuery_00056: "",
  userOrderQuery_00057: "",
  userOrderQuery_00058: "",
  userOrderQuery_00059: "",
  userOrderQuery_00060: "",
  }