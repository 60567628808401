export default {
  userInvoice_00001: "Nombre del grupo",
  userInvoice_00002: "Detalles de facturación",
  userInvoice_00003: "Información básica",
  userInvoice_00004: "Estado de la solicitud",
  userInvoice_00005: "Todos",
  userInvoice_00006: "Pendiente",
  userInvoice_00007: "Factura emitida",
  userInvoice_00008: "Cancelada",
  userInvoice_00009: "Consulta",
  userInvoice_00010: "Factura en papel",
  userInvoice_00011: "Factura electrónica",
  userInvoice_00012: "Facturas en papel y facturas electrónicas",
  userInvoice_00013: "Para",
  userInvoice_00014: "Fecha de inicio",
  userInvoice_00015: "Fecha de finalización",
  userInvoice_00016: "Baja del usuario",
  userInvoice_00017: "Apodo",
  userInvoice_00018: "Tipo de aplicación",
  userInvoice_00019: "Método de facturación",
  userInvoice_00020: "Importe solicitado",
  userInvoice_00021: "Detalles de la factura",
  userInvoice_00022: "Factura a pagar",
  userInvoice_00023: "Número de identificación fiscal de la empresa",
  userInvoice_00024: "Dirección de la empresa",
  userInvoice_00025: "Número de empresa",
  userInvoice_00026: "Banco de la cuenta",
  userInvoice_00027: "Número de cuenta",
  userInvoice_00028: "Información del contacto",
   userInvoice_00029: "Destinatario",
   userInvoice_00030: "Número de teléfono móvil",
   userInvoice_00031: "Dirección de recepción",
   userInvoice_00032: "Factura personal",
   userInvoice_00033: "Factura corporativa",
   userInvoice_00034: "Consulta de pedido",
   userInvoice_00035: "Correo electrónico",
   userInvoice_00036: "",
   userInvoice_00037: "",
   userInvoice_00038: "",
   userInvoice_00039: "",
   userInvoice_00040: "",
   factura_usuario_00041: "",
   userInvoice_00042: "",
   factura_usuario_00043: "",
   userInvoice_00044: "",
   userInvoice_00045: "",
   userInvoice_00046: "",
   userInvoice_00047: "",
   userInvoice_00048: "",
   userInvoice_00049: "",
   userInvoice_00050: "",
   }