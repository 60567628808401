export default {
  outlineCard_00001: 'Gestión de tarjetas de recarga CPO',
  outlineCard_00002: 'Detalles de la tarjeta de recarga plug and play',
  outlineCard_00003: 'Detalles de la tarjeta de recarga de identidad en línea',
  outlineCard_00004: 'Detalles de la tarjeta de valor almacenado sin conexión',
  outlineCard_00005: 'Detalles de la tarjeta de inicio y parada sin conexión',
  outlineCard_00006: 'Información básica',
  outlineCard_00007: 'Flota',
  outlineCard_00008: 'Reg. recarga',
  outlineCard_00009: 'Recarga de tarjeta de recarga plug and play',
  outlineCard_00010: 'Tarjeta de recarga',
  outlineCard_00011: 'Transacciones',
  outlineCard_00012: 'Cancel. pérdida',
  outlineCard_00013: 'Informe de pérdida de tarjeta',
  outlineCard_00014: 'Recarga',
  outlineCard_00015: 'Tipo de EV{type}：',
  outlineCard_00016: 'Número de EV:',
  outlineCard_00017: 'Nombre de contacto:',
  outlineCard_00018: 'Número de móvil:',
  outlineCard_00019: 'Saldo actual:',
  outlineCard_00020: 'Recarga',
  outlineCard_00021: 'Unidad',
  outlineCard_00022: 'Ingrese el monto de recarga',
  outlineCard_00023: 'Contraseña de inicio de sesión',
  outlineCard_00024: 'Requerido',
  outlineCard_00025: 'Ingrese la contraseña',
  outlineCard_00026: 'Recarga exitosa',
  outlineCard_00027: 'Recarga',
  outlineCard_00028: 'Completar',
  outlineCard_00029: 'Conectar y cargar la información de la tarjeta',
  outlineCard_00030: 'Información de la tarjeta de identificación',
  outlineCard_00031: '¿Está seguro de informar la pérdida de esta tarjeta?',
  outlineCard_00032: '¿Está seguro de cancelar el informe de pérdida de esta tarjeta?',
  outlineCard_00033: 'confirmar',
  outlineCard_00034: 'cancelar',
  outlineCard_00035: 'Informe de pérdida exitoso',
  outlineCard_00036: 'Cancelación exitosa del informe de pérdida',
  outlineCard_00037: 'La operación fue cancelada',
  outlineCard_00038: 'Complete',
  outlineCard_00039: 'Número de tarjeta',
  outlineCard_00040: 'No se puede modificar',
  outlineCard_00041: 'Tipo de tarjeta',
  outlineCard_00042: 'saldo',
  outlineCard_00043: 'Sujeto a la hoja de reembolso final',
  outlineCard_00044: 'Estado de la tarjeta',
  outlineCard_00045: 'reportar la pérdida',
  outlineCard_00046: 'normal',
  outlineCard_00047: 'Número auxiliar',
  outlineCard_00048: 'Por favor, introduzca el número',
  outlineCard_00049: 'guardar',
  outlineCard_00050: 'modificar',
  outlineCard_00051: 'Origen de la tarjeta',
  outlineCard_00052: 'Grupo de tarjetas de cobro',
  outlineCard_00053: 'Restricciones de cobro en la estación',
  outlineCard_00054: 'sí',
  outlineCard_00055: 'no',
  outlineCard_00056: 'Información del vehículo eléctrico',
  outlineCard_00057: 'Número del vehículo eléctrico',
  outlineCard_00058: 'Introduzca el número de matrícula',
  outlineCard_00059: 'Ubicación',
  outlineCard_00060: 'Modelo',
  outlineCard_00061: 'Introducción manual',
  outlineCard_00062: 'Marca y modelo del vehículo eléctrico',
  outlineCard_00063: 'Información de contacto',
  outlineCard_00064: 'Nombre',
  outlineCard_00065: 'Número de móvil',
  outlineCard_00066: 'Tarjeta de valor almacenado sin conexión',
  outlineCard_00067: 'Tarjeta de arranque y parada sin conexión',
  outlineCard_00068: "Tarjeta de configuración XCharge",
  outlineCard_00069: 'Conectar y cargar',
  outlineCard_00070: 'Tarjeta de identificación',
  outlineCard_00071: "Incluyendo cashback{promotionBalance}{unit}",
  outlineCard_00072: "Incluir asignaciones de grupo{num}{unit}",
  outlineCard_00073: "¿Está seguro de que desea liberar el VIN?",
  outlineCard_00074: "Piénselo de nuevo",
  outlineCard_00075: "Liberación exitosa",
  outlineCard_00076: "Guarde la operación anterior",
  outlineCard_00077: "El parámetro es nulo",
  outlineCard_00078: "¡Formato de número de matrícula incorrecto!",
  outlineCard_00079: '"Enchufar y cargar" no está permitido para una fuente de tarjeta de identificación simple',
  outlineCard_00080: "Seleccione el tipo de EV",
  outlineCard_00081: "El número de móvil o el número de matrícula están vacíos",
  outlineCard_00082: "Modificado exitosamente",
  outlineCard_00083: "Flotas",
  outlineCard_00084: "Agregar a la flota",
  outlineCard_00085: "Eliminar de las flotas",
  outlineCard_00086: "flota",
  outlineCard_00087: "Pago",
  outlineCard_00088: "Máximo de transacciones pendientes de pago",
  outlineCard_00089: "Miembros",
  outlineCard_00090: "Operación",
  outlineCard_00091: "Detalles de la flota",
  outlineCard_00092: "Eliminar",
  outlineCard_00093: "Importe máximo pendiente de pago ({unit})",
  outlineCard_00094: "Unirse",
  outlineCard_00095: "Unido",
  outlineCard_00096: "Unirse correctamente",
  outlineCard_00097: "¿Está seguro de eliminar todos los grupos seleccionados?",
  outlineCard_00098: "Propinas",
  outlineCard_00099: "¿Está seguro de eliminar el grupo actual?",
  outlineCard_00100: "Eliminación exitosa",
  outlineCard_00101: "Pagado por la flota",
  outlineCard_00102: "Pagado por el individuo",
  outlineCard_00103: "Lista de recargas",
  outlineCard_00104: "Fecha de recarga",
  outlineCard_00105: "Monto total de recarga ({unit})",
  outlineCard_00106: "Principal de recarga ({0})",
  outlineCard_00107: "Monto de regalo de recarga ({0})",
  outlineCard_00108: "Modo de recarga",
  outlineCard_00109: "Cuenta oficial de WeChat (Alipay)",
  outlineCard_00110: "Cuenta oficial de WeChat (pago por WeChat)",
  outlineCard_00111: "Plataforma",
  outlineCard_00112: "CPO",
  outlineCard_00113: "Liberar",
  outlineCard_00114: "¿Está seguro de eliminar la tarjeta?",
  outlineCard_00115: "eliminar",
}