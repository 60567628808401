export default {
  eventlogsnzs_00001: "Transaction List",
  eventlogsnzs_00002: "Export Data",
  eventlogsnzs_00003: "Transaction ID",
  eventlogsnzs_00004: "Start Date",
  eventlogsnzs_00005: "End Date",
  eventlogsnzs_00006: "Reset",
  eventlogsnzs_00007: "Query",
  eventlogsnzs_00008: "Status",
  eventlogsnzs_00009: "All",
  eventlogsnzs_00010: "Bill Type",
  eventlogsnzs_00011: "Billing",
  eventlogsnzs_00012: "Recharge",
  eventlogsnzs_00013: "Discharge",
  eventlogsnzs_00014: "Energy Storage Device SN",
  eventlogsnzs_00015: "All Energy Storage Cabinets",
  eventlogsnzs_00016: "Recharge Energy (kWh)",
  eventlogsnzs_00017: "Recharge Time (minutes)",
  eventlogsnzs_00018: "Recharge Count (times)",
  eventlogsnzs_00019: "Discharge Energy (kWh)",
  eventlogsnzs_00020: "Discharge Time (minutes)",
  eventlogsnzs_00021: "Discharge Count (times)",
  eventlogsnzs_00022: "Transaction Curve",
  eventlogsnzs_00023: "Day",
  eventlogsnzs_00024: "Hour",
  eventlogsnzs_00025: "Minute",
  eventlogsnzs_00026: "Device",
  eventlogsnzs_00027: "Recharge Duration",
  eventlogsnzs_00028: "Energy Discharged",
  eventlogsnzs_00029: "Energy Recharged",
  eventlogsnzs_00030: "Discharging",
  eventlogsnzs_00031: "Discharge Completed",
  eventlogsnzs_00032: "Recharging",
  eventlogsnzs_00033: "Recharge Completed",
  eventlogsnzs_00034: "This operation will download the transaction's Excel file, continue?",
  eventlogsnzs_00035: "Confirm",
  eventlogsnzs_00036: "Cancel",
  eventlogsnzs_00037: "Start Download",
  eventlogsnzs_00038: "Cancel Download",
  eventlogsnzs_00039: "Prompt",
  eventlogsnzs_00040: "Cumulative Energy",
  eventlogsnzs_00041: "Instantaneous Power",
  eventlogsnzs_00042: "Average Power",
  eventlogsnzs_00043: "Maximum Power",
  eventlogsnzs_00044: "Energy Storage Transaction Log",
  eventlogsnzs_00045: "",
  billDetail_00025: "Charging Curve",
  billDetail_00026: "Vehicle Demand Voltage (Dashed Line)",
  billDetail_00027: "Vehicle Demand Current (Dashed Line)",
  billDetail_00028: "Vehicle Measured Voltage (Dashed Line)",
  billDetail_00029: "Vehicle Measured Current (Dashed Line)",
  billDetail_00030: "Battery Initial Charge",
  billDetail_00031: "Battery Current Charge",
  billDetail_00032: "Instantaneous Power",
  billDetail_00033: "Average Power",
  billDetail_00034: "Maximum Power",
  billDetail_00035: "Vehicle Model",
  billDetail_00036: "Power",
  billDetail_00037: "Current",
  billDetail_00038: "Voltage",
  billDetail_00039: "Cumulative Energy",
  billDetail_00042: "kWh",
  bill_00059: "Today",
  bill_00060: "7 Days Ago",
  bill_00061: "15 Days Ago",
  bill_00077: "Yesterday",
  bill_00078: "30 Days Ago"
}
