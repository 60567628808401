export default {
  stationMaintenanceAnalysis_00001: "análisis de tasa de anomalías de la estación",
  stationMaintenanceAnalysis_00002: "página de inicio de mantenimiento",
  stationMaintenanceAnalysis_00003: "tasa anormal de responsabilidad de la pila = número de órdenes de carga anormales de esta estación en este día / número de órdenes de carga de esta estación en este día",
  stationMaintenanceAnalysis_00004: "tasa anormal de responsabilidad del vehículo = número de órdenes de carga anormales de esta estación / número de órdenes de carga de esta estación",
  stationMaintenanceAnalysis_00005: "análisis de tendencias",
  stationMaintenanceAnalysis_00006: "análisis diario",
  stationMaintenanceAnalysis_00007: "Tasa de emisión de cargadores",
  stationMaintenanceAnalysis_00008: "Tasa de emisión de vehículos",
  stationMaintenanceAnalysis_00009: "Tasa de emisión de equipos",
  stationMaintenanceAnalysis_00010: "orden anormal de responsabilidad de pila",
  stationMaintenanceAnalysis_00011: "código de excepción de responsabilidad de pila",
  stationMaintenanceAnalysis_00012: "índice anormal de responsabilidad de pila {num}%",
  stationMaintenanceAnalysis_00013: "número de orden",
  stationMaintenanceAnalysis_00014: "excepción",
  stationMaintenanceAnalysis_00015: "puerto de carga",
  stationMaintenanceAnalysis_00016: "registro de excepción de dispositivo",
  stationMaintenanceAnalysis_00017: "detalles de la orden",
  stationMaintenanceAnalysis_00018: "detalles del dispositivo",
  stationMaintenanceAnalysis_00019: "iniciar orden de trabajo",
  stationMaintenanceAnalysis_00020: "orden anormal de responsabilidad del vehículo",
  stationMaintenanceAnalysis_00021: "equipo de la estación",
  stationMaintenanceAnalysis_00022: "tasa anormal de responsabilidad de pila = la cantidad de órdenes de carga anormales de esta responsabilidad de pila en este día / la cantidad de órdenes de carga de esta pila en este día",
  stationMaintenanceAnalysis_00023: "tasa anormal de responsabilidad de vehículo = la cantidad de órdenes de carga anormales de esta pila este día / la cantidad de órdenes de carga de esta pila este día",
  stationMaintenanceAnalysis_00024: "análisis de tasa anormal",
  stationMaintenanceAnalysis_00025: "VIN del vehículo",
  stationMaintenanceAnalysis_00026: "número de matrícula",
  stationMaintenanceAnalysis_00027: "tipo de vehículo",
  stationMaintenanceAnalysis_00028: "registro de carga del vehículo",
  stationMaintenanceAnalysis_00029: "nombre del dispositivo",
  stationMaintenanceAnalysis_00030: "modelo de equipo",
  stationMaintenanceAnalysis_00031: "número de serie del equipo",
  stationMaintenanceAnalysis_00032: "tasa anormal de responsabilidad de la pila",
  stationMaintenanceAnalysis_00033: "tasa anormal de responsabilidad del vehículo",
  stationMaintenanceAnalysis_00034: "ver orden de trabajo",
  stationMaintenanceAnalysis_10000: "tasa anormal de responsabilidad del vehículo {num}%",
  stationMaintenanceAnalysis_10001: "código de excepción de responsabilidad del vehículo",
  stationMaintenanceAnalysis_10002: "sin excepción temporalmente",
  stationMaintenanceAnalysis_10003: "análisis de la tasa de anormalidad del equipo",
  stationMaintenanceAnalysis_10004: "proporción de códigos de excepción",
  stationMaintenanceAnalysis_10005: "excepción código",
  }