export default {
  userAllocate_00001: "Nombre del grupo",
  userAllocate_00002: "Asignación de saldo",
  userAllocate_00003: "Información básica",
  userAllocate_00004: "Nombre de usuario, número de tarjeta, número de móvil",
  userAllocate_00005: "Tipo de usuario",
  userAllocate_00006: "Todos",
  userAllocate_00007: "Usuario de WeChat",
  userAllocate_00008: "Tarjeta de identificación en línea",
  userAllocate_00009: "Consulta",
  userAllocate_00010: "Restablecer",
  userAllocate_00011: "Asignado {1}{3} a {0} personas, quedan {2}{3}",
  userAllocate_00012: "Aceptar para asignar",
  userAllocate_00013: "Avatar",
  userAllocate_00014: "Usuario (número de móvil)",
  userAllocate_00015: "Número de tarjeta",
  userAllocate_00016: "Tipo",
  userAllocate_00017: "Saldo de la cuenta ({0})",
  userAllocate_00018: "Monto de la asignación ({0})",
  userAllocate_00019: "Lista de usuarios",
  userAllocate_00020: "¡Ingrese el monto de la asignación para los miembros del grupo de usuarios!",
  userAllocate_00021: "¡El saldo de la cuenta del grupo de usuarios no es suficiente para la asignación!",
  userAllocate_00022: "Solicitar",
  userAllocate_00023: "Continuar con la asignación",
  userAllocate_00024: "Confirmar",
  userAllocate_00025: "",
  }