import zh from "../../assets/i18n/zh-cn.js";
import en from "../../assets/i18n/en.js";
import de from "../../assets/i18n/de.js";
import constants from "../../assets/utils/constants.js";
import "@/assets/font/iconfont.css";
// 当前环境
const productArea = constants.product_area;
let lang = window.localStorage.lang;
if (!lang) {
    let language = navigator.language.slice(0, 2);
    if (["ch", "en", "de", "es"].includes(language)) {
        lang = language;
    } else {
        lang = productArea == "eu" ? "en" : "zh";
    }
}
const list = {
    'zh': zh,
    'en': en,
    'de': de,
};
const m = list[lang] || zh;

const keyToContent = {
    1001: m.common_73000,
    1002: m.common_73001,
    2001: m.common_73002,
    2002: m.common_73003,
    2003: m.common_73004,
    2004: m.common_73005,
    2005: m.common_73006,
    3001: m.common_73007,
    10001: m.common_73009,
    20001: m.common_73010,
    20002: m.common_73011,
    20003: m.common_73012,
    20004: m.common_73013,
    30001: m.common_73014,
    40001: m.common_73015,
    40002: m.common_73016,
    50001: m.common_73017,
};

export default keyToContent;