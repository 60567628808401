export default {
  login_00001: "iniciar sesión en la plataforma del operador",
  login_00002: "iniciar sesión en la cuenta",
  login_00003: "ingresar contraseña",
  login_00004: "iniciar sesión",
  login_00005: "el nombre de usuario y la contraseña no pueden estar vacíos",
  login_00006: "plataforma del operador",
  login_00007: "Me he registrado y ahora inicio sesión",
  login_00008: "iniciar sesión",
  registerEmail_00001: "registro",
  registerEmail_00002: "operador franquiciado",
  registerEmail_00003: "autenticación del buzón",
  registerEmail_00004: "completar la información de registro",
  registerEmail_00005: "encontrar operador de cobro",
  registerEmail_00006: "consulta",
  registerEmail_00007: "por favor ingrese el código de invitación que le envió el operador",
  registerEmail_00008: "nombre del operador",
  registerEmail_00009: "moneda de liquidación",
  registerEmail_00010: "únase al operador",
  registerEmail_00011: "únase a la alianza de operadores de cobro",
  registerEmail_00012: "llene el buzón",
  registerEmail_00013: "le hemos enviado un correo electrónico a {email} que usted llenó. Haga clic en el enlace de verificación en el correo electrónico para completar la verificación y llenar la información de registro",
  registerEmail_00014: "nombre de contacto | cómo llamarlo",
  registerEmail_00015: "por favor ingrese su nombre",
  registerEmail_00016: "nombre del operador | nombre visto por el usuario que realiza el cargo",
  registerEmail_00017: "no más de {0} palabras",
  registerEmail_00018: "cuenta de inicio de sesión | como xcharge",
  registerEmail_00019: "use letras y números",
  registerEmail_00020: "contraseña de inicio de sesión | contiene al menos 6 caracteres, incluidos al menos 1 dígito, 1 letra y 1 carácter especial",
  registerEmail_00021: "confirmar contraseña | consistente con la contraseña de inicio de sesión",
  registerEmail_00022: "verificación del número de móvil",
  registerEmail_00023: "vincular cuenta oficial de WeChat",
  registerEmail_00024: "se recomienda chino",
  registerEmail_00025: "ingrese el nombre del operador",
  registerEmail_00026: "no se encontraron operadores calificados",
  registerEmail_00027: "micro señal",
  registerEmail_00028: "código de invitación utilizado",
  registerEmail_00029: "el código de invitación no existe",
  registerEmail_00030: "el buzón no puede estar vacío",
  registerEmail_00031: "ingrese su dirección de correo electrónico para que podamos comunicarnos con usted",
  registerEmail_00032: "rellene el correo electrónico correcto",
  registerEmail_00033: "operación fallida",
  registerEmail_00034: "tiempo de espera de respuesta del servidor",
  registerEmail_00035: "obtenga primero la cuenta oficial",
  registerEmail_10036: "plataforma del operador",
  registerEmail_10037: "seleccione un idioma",
  registerEmail_10038: "me he registrado y ahora inicio sesión",
  registerEmail_10039: "iniciar sesión",
  registerEmail_10040: "iniciar sesión en la plataforma del operador",
  registerEmail_10041: "iniciar sesión en la plataforma de administración de grupos de usuarios",
  registerEmail_10042: "configuración exitosa",
  registerEmail_10043: "tipo de moneda",
  registerEmail_10044: "ingrese la contraseña nuevamente",
  registerEmail_10045: "las dos contraseñas son inconsistente",
  registerEmail_10046: "por favor ingrese el código de verificación",
  registerEmail_10047: "por favor ingrese un valor numérico",
  registerEmail_10048: "por favor ingrese 4 dígitos",
  registerEmail_10049: "por favor ingrese el nombre de contacto correcto",
  registerEmail_10050: "el formato de la cuenta es una combinación de números y letras",
  registerEmail_10051: "la contraseña es una combinación de letras o números",
  registerEmail_10052: "por favor ingrese el nombre del contacto",
  registerEmail_10053: "por favor ingrese el nombre del operador",
  registerEmail_10054: "el nombre no excede {0} caracteres",
  registerEmail_10055: "por favor ingrese una cuenta",
  registerEmail_10056: "por favor ingrese contraseña",
  registerEmail_10057: "longitud entre 6 y 20 caracteres",
  registerEmail_10058: "Primero lea atentamente la política de privacidad para confirmar si está de acuerdo",
  registerEmail_10059: "Introduzca su número de teléfono móvil",
  registerEmail_10060: "Introduzca el número de teléfono móvil correcto",
  registerEmail_20050: "Verificación del número de móvil",
  registerEmail_20051: "He leído y acepto",
  registerEmail_20052: "Política de privacidad de la plataforma del operador de carga inteligente",
  registerEmail_20053: "moneda",
  registerEmail_20054: "moneda de transacción vista por el usuario que carga",
  registerEmail_20055: "Seleccione",
  // *****
  registerEmail_20056: "Complete la dirección de correo electrónico correcta y abra el enlace en el correo electrónico para verificación.",
  registerEmail_20057: "Por favor, rellene toda la información a la derecha para completar el registro.",
  registerEmail_20058: "Por favor, rellene toda la información a la derecha para pasar al siguiente paso.",
  registerEmail_20059: "Verifique el número de teléfono para completar la vinculación",
  registerEmail_20060: "Vinculación de la cuenta pública de WeChat",
  registerEmail_20061: "Verifique el número de teléfono para el siguiente paso",
  // ****2022/1/4
  registerEmail_30058: "Por favor, seleccione la moneda",
  registerEmail_30059: "Por favor, vuelva a introducirla",
  // ****绑定公众号回显--当前页面独有
  registerEmail_40000: "Un número de teléfono desconocido error",
  registerEmail_40001: "La cuenta pública ha sido vinculada por otro operador.",
  registerEmail_40002: "Está vinculado a esta cuenta pública.",
  registerEmail_40003: "Está vinculado a una cuenta pública:",
  registerEmail_40004: "prompt",
  registerEmail_40005: "determine",
  // 注册码
  registerEmail_21000: "Código de registro | Si necesita un código de registro, contáctenos: 4008770227",
  registerEmail_21001: "Ingrese el código de registro",
  registerEmail_21002: "Error de código de registro",
  registerEmail_21003: "Siguiente",
  registerEmail_21004: "Código de registro",
  registerEmail_21005: "Si necesita un código de registro, contáctenos nosotros.",
  registerEmail_21006: "Por favor, establezca una contraseña",
  }