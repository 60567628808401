export default {
  addDevice_00001: "Seleccione o cree una nueva estación para agregar el cargador",
  addDevice_00002: "Seleccione una estación",
  addDevice_00003: "Agregar cargador a la estación",
  addDevice_00004: "Seleccione una estación para agregar el cargador",
  addDevice_00005: "",
  addDevice_00006: "",
  addDevice_00007: "",
  addDevice_00008: "",
  addDevice_00009: "",
  addDevice_00010: "",
};