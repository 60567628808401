export default {
  eventlogsgrid_00001: "事务列表",
  eventlogsgrid_00002: "导出数据",
  eventlogsgrid_00003: "事务编号",
  eventlogsgrid_00004: "开始日期",
  eventlogsgrid_00005: "结束日期",
  eventlogsgrid_00006: "重置",
  eventlogsgrid_00007: "查询",
  eventlogsgrid_00008: "事务状态",
  eventlogsgrid_00009: "全部",
  eventlogsgrid_00010: "账单类型",
  eventlogsgrid_00011: "计费中",
  eventlogsgrid_00012: "购电",
  eventlogsgrid_00013: "售电",
  eventlogsgrid_00014: "储能设备SN",
  eventlogsgrid_00015: "全部储能柜",
  eventlogsgrid_00016: "购电电量(度)",
  eventlogsgrid_00017: "购电时间(分钟)",
  eventlogsgrid_00018: "购电次数(次)",
  eventlogsgrid_00019: "售电电量(度)",
  eventlogsgrid_00020: "售电时间(分钟)",
  eventlogsgrid_00021: "售电次数(次)",
  eventlogsgrid_00022: "事务曲线",
  eventlogsgrid_00023: "天",
  eventlogsgrid_00024: "小时",
  eventlogsgrid_00025: "分钟",
  eventlogsgrid_00026: "设备",
  eventlogsgrid_00027: "补电时长",
  eventlogsgrid_00028: "已售电量",
  eventlogsgrid_00029: "已购电量",
  eventlogsgrid_00030: "购电中",
  eventlogsgrid_00031: "已购完",
  eventlogsgrid_00032: "售电中",
  eventlogsgrid_00033: "已售完",
  eventlogsgrid_00034: "此操作将下载事务的Excel文件，是否继续?",
  eventlogsgrid_00035: "确定",
  eventlogsgrid_00036: "取消",
  eventlogsgrid_00037: "开始下载",
  eventlogsgrid_00038: "取消下载",
  eventlogsgrid_00039: "提示",
  eventlogsgrid_00040: "累计电量",
  eventlogsgrid_00041: "瞬时功率",
  eventlogsgrid_00042: "平均功率",
  eventlogsgrid_00043: "最高功率",
  eventlogsgrid_00044: "电网事务日志",
  eventlogsgrid_00045: "",

  billDetail_00025: "充电曲线",
    billDetail_00026: "车端需求电压(虚线)",
    billDetail_00027: "车端需求电流(虚线)",
    billDetail_00028: "车端测量电压(虚线)",
    billDetail_00029: "车端测量电流(虚线)",
    billDetail_00030: "电池初始电量",
    billDetail_00031: "电池当前电量",
    billDetail_00032: "瞬时功率",
    billDetail_00033: "平均功率",
    billDetail_00034: "最高功率",
    billDetail_00035: "车型",
    billDetail_00036: "功率",
    billDetail_00037: "电流",
    billDetail_00038: "电压",
    billDetail_00039: "累计电量",
    billDetail_00042: "度",
    bill_00059: "今天",
    bill_00060: "7天前",
    bill_00061: "15天前",
    bill_00077: "昨天",
    bill_00078: "30天前",
}