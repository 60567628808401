export default {
    auto_00001: "Please scan the QR code on the left with WeChat to follow the 'xcharger' official account for customized notifications.",
    auto_00002: "1. In the bottom menu of the official account, click 'Charging Maintenance' - 'Maintenance Order' to enter the 'Smart Maintenance' mini-program.",
    auto_00003: "2. Log in to the 'Smart Maintenance' mini-program using your platform account.",
    auto_00004: "3. After completing the above steps, you will receive maintenance notifications related to your account through the 'xcharger' official account. All notifications are sent via the official account's template message function.",
    auto_00005: "Equipment Failure (This error will resulted in charging function disable)",
    auto_00006: "Equipment Abnormal (This error will not resulted in charging function disable)",
    auto_00007: "Charger Offline",
    auto_00008: "For example: emergency stop button pressed, chassis temperature alarm, etc.",
    auto_00009: "For example: electromagnetic lock failed to lock, power module alarm, etc.",
    auto_00010: "Notification Category",
    auto_00011: "Notification Target Group",
    auto_00012: "Action",
    auto_00013: "Edit Notification Target Group",
    auto_00014: "All",
    auto_00015: "Notify Automatically",
    auto_00016: "Remove",
    auto_00017: "Role",
    auto_00018: "Account",
    auto_00019: "Action",
    auto_00020: "Set notification frequency",
    auto_00021: "Every",
    auto_00022: "day",
    auto_00023: "Each charger will notify each fault only",
    auto_00024: "Each charger will notify all faults only",
    auto_00025: "times",
    auto_00026: "Default",
    auto_00027: "Each charger is notified of offline faults only",
    auto_00031: "After following, please follow the steps below:",
}