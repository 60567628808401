export default {
    eventlogsgrid_00001: "Lista de facturas",
    eventlogsgrid_00002: "Exportar pedidos",
    eventlogsgrid_00003: "Nº de factura",
    eventlogsgrid_00004: "Fecha de inicio",
    eventlogsgrid_00005: "Fecha de finalización",
    eventlogsgrid_00006: "restablecer",
    eventlogsgrid_00007: "buscar",
    eventlogsgrid_00008: "Estado de la factura",
    eventlogsgrid_00009: "todos",
    eventlogsgrid_00010: "Tipo de factura",
    eventlogsgrid_00011: "Cargando",
    eventlogsgrid_00012: "a pagar",
    eventlogsgrid_00013: "pagado",
    eventlogsgrid_00014: "Bloqueo directo al suelo del extremo del pilote",
    eventlogsgrid_00015: "Bloqueo directo al suelo de la plataforma",
    eventlogsgrid_00016: "Importe de la factura (€)",
    eventlogsgrid_00017: "Número de factura de ocupación",
    eventlogsgrid_00018: "Duración de la ocupación (min)",
    eventlogsgrid_00019: "Pila de carga - puerto de carga",
    eventlogsgrid_00020: "Hora de inicio de la ocupación",
    eventlogsgrid_00021: "Hora de finalización de la ocupación",
    eventlogsgrid_00022: "Duración de la carga",
    eventlogsgrid_00023: "día",
    eventlogsgrid_00024: "hora",
    eventlogsgrid_00025: "minuto",
    eventlogsgrid_00026: "Usuarios de WeChat",
    eventlogsgrid_00027: "grupo",
    eventlogsgrid_00028: "Tarjeta de valor almacenado sin conexión",
    eventlogsgrid_00029: "Tarjeta de identificación en línea",
    eventlogsgrid_00030: "Importe de factura actual",
    eventlogsgrid_00031: "Importe de pago real",
    eventlogsgrid_00032: "Detalles de la factura",
    eventlogsgrid_00033: "Propinas",
    eventlogsgrid_00034: "Esta operación descargará el archivo de Excel de la factura. ¿Desea continuar?",
    eventlogsgrid_00035: "confirmar",
    eventlogsgrid_00036: "cancelar",
    eventlogsgrid_00037: "Iniciar descarga",
    eventlogsgrid_00038: "Cancelar descarga",
    eventlogsgrid_00039: "Sobrenombre de usuario, número de teléfono móvil y número de matrícula",
    eventlogsgrid_00040: "sobrenombre",
    eventlogsgrid_00041: "teléfono",
    eventlogsgrid_00042: "número de matrícula",
    eventlogsgrid_00043: "Importe total de la factura",
    eventlogsgrid_00044: "Registro de transacciones de la red",
    bill_00059: "hoy",
    bill_00060: "hace 7 días",
    bill_00061: "hace 15 días",
    bill_00077: "ayer",
    bill_00078: "hace 30 días",
}