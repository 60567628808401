export default {
  user_export_remind_1: "Esta operación descargará el archivo Excel del usuario, ¿desea continuar?" ,
  remember: "prompt",
  confirm: "Confirmar",
  cancel: "Cancelar",
  start_download: "Iniciar descarga",
  cancel_download: "Cancelar descarga",
  
  userLevel_00001: 'Ver actualmente',
  userLevel_00002: 'Exportar usuario',
  userLevel_00003: 'Ver usuarios',
  userLevel_00004: 'Gestión de miembros',
  userLevel_00005: 'Ver usuarios',
  userLevel_00006: 'Perfil con foto',
  userLevel_00007: 'Apodo',
  userLevel_00008: 'Género',
  userLevel_00009: 'Número de móvil',
  userLevel_00010: 'Número de matrícula',
  userLevel_00011: 'Saldo',
  userLevel_00012: 'Verificar pedido',
  userLevel_00013: 'Detalles',
  userLevel_00014: 'Hombre',
  userLevel_00015: 'Mujer',
  userLevel_00016: '',
  userLevel_00017: '',
  userLevel_00018: '',
  }