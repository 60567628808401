export default {
    deviceMessageTemplate_00001: "Plantilla de mensaje de configuración de edición personalizada, puede agregar la plantilla a continuación directamente en la estación de carga o editarla nuevamente en función de la plantilla",
    deviceMessageTemplate_00002: 'Información de configuración de pantalla de la estación de carga C1 (pantalla de 4,3")',
    deviceMessageTemplate_00003: 'Información de configuración de pantalla de la estación de carga C1 (pantalla de 7")',
    deviceMessageTemplate_00004: "Sistema de carga inteligente",
    deviceMessageTemplate_00005: "Ver la imagen grande",
    deviceMessageTemplate_00006: "Logotipo de arranque",
    deviceMessageTemplate_00007: "Logotipo superior",
    deviceMessageTemplate_00008: "IU",
    deviceMessageTemplate_00009: "No más de 16 palabras",
    deviceMessageTemplate_000010: "Información de configuración de la pantalla del cargador C2",
    deviceMessageTemplate_000011: "Título de la interfaz del código de escaneo",
    deviceMessageTemplate_000012: "Escanee el código en WeChat para comenzar a cargar",
    deviceMessageTemplate_000013: "Escanee el código QR de la izquierda con WeChat y siga las indicaciones para continuar con el siguiente paso",
    deviceMessageTemplate_000014: "Texto de la interfaz del código de escaneo",
    deviceMessageTemplate_000015: "No más de 10 palabras",
    deviceMessageTemplate_000016: "No más de 24 palabras",
    deviceMessageTemplate_000017: "Información de configuración de la pantalla del cargador C4",
    deviceMessageTemplate_000018: "Información de configuración de la pantalla del cargador C6",
    deviceMessageTemplate_000019: "Configuración de la pantalla del cargador C6s información",
    deviceMessageTemplate_000020: "Bienvenido a usar el sistema de carga inteligente",
    deviceMessageTemplate_000021: "Utilice el código de escaneo de WeChat para cargar o use la tarjeta NFC para cargar",
    deviceMessageTemplate_000022: "Use el botón táctil para seleccionar el punto de carga",
    deviceMessageTemplate_000023: "Palabras principales",
    deviceMessageTemplate_000024: "Título de bienvenida",
    deviceMessageTemplate_000025: "No más de {num} palabras",
    deviceMessageTemplate_000026: "Texto de bienvenida",
    deviceMessageTemplate_000027: "Texto inferior",
    deviceMessageTemplate_000028: "Arrastre el archivo aquí o haga clic para cargar",
    deviceMessageTemplate_000029: "Solo se admiten imágenes en formato PNG, el tamaño es {area}px, no más de {size}kb.",
    deviceMessageTemplate_000030: "Modificar imagen",
    deviceMessageTemplate_000031: "Subir imagen",
    deviceMessageTemplate_000032: "Subir imagen",
    deviceMessageTemplate_000033: "La imagen que ha seleccionado es demasiado grande, vuelva a seleccionarla",
    deviceMessageTemplate_000034: "Elija una imagen en formato png para subir",
    deviceMessageTemplate_000035: "Suba una imagen que coincida con {0}px",
    deviceMessageTemplate_000036: "Modificar logotipo superior",
    deviceMessageTemplate_000037: "El logotipo superior puede utilizar formato de texto o imagen, elija uno de los dos",
    deviceMessageTemplate_000038: "Usar texto",
    deviceMessageTemplate_000039: "Introduzca texto",
    deviceMessageTemplate_000040: "Dentro de {num} caracteres (incluyendo puntuación)",
    deviceMessageTemplate_000041: "Usar imagen",
    deviceMessageTemplate_000042: "X-CHARGE",
    deviceMessageTemplate_000043: "El texto en la pantalla superior es demasiado largo",
    deviceMessageTemplate_000044: "Primero seleccione 'Usar imagen'",
    deviceMessageTemplate_000045: "La imagen que seleccionó es demasiado grande, vuelva a seleccionarla",
    deviceMessageTemplate_000046: "Seleccione la imagen que desea cargar en formato png",
    deviceMessageTemplate_000047: "El parámetro está vacío",
    deviceMessageTemplate_000048: "La longitud del parámetro es demasiado larga",
    deviceMessageTemplate_000049: "Escanee el código para cargar o use NFC para pasar la tarjeta para cargar",
    deviceMessageTemplate_000050: "El título de la interfaz del código de escaneo no puede superar los 10 dígitos",
    deviceMessageTemplate_000051: "El título de la interfaz del código de escaneo no puede superar los 24 dígitos",
    deviceMessageTemplate_000052: "Escanear para iniciar la carga",
    deviceMessageTemplate_000053: "Escanee el código QR de la izquierda y siga las instrucciones para continuar con el siguiente paso",
    deviceMessageTemplate_000054: "Guarde el paso anterior",
    deviceMessageTemplate_000055: "No se pueden ingresar más de 7 dígitos de texto",
}