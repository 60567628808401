export default {
    billDetail_00001: "订单中心",
    billDetail_00002: "充电订单详情",
    billDetail_00003: "全部充电订单",
    billDetail_00004: "通知停车场",
    billDetail_00005: "订单信息",
    billDetail_00006: "车辆及电池信息",
    billDetail_00007: "充电信息",
    billDetail_00008: "设备当前收费标准",
    billDetail_00009: "订单轨迹",
    billDetail_00010: "账单明细",
    billDetail_00011: "车辆识别码",
    billDetail_00012: "电池识别码",
    billDetail_00013: "电池厂家",
    billDetail_00014: "电池种类",
    billDetail_00015: "电池最高允许温度",
    billDetail_00016: "电池最高允许充电电压",
    billDetail_00017: "电池单体最高允许电压",
    billDetail_00018: "电池最高允许充电总电压",
    billDetail_00019: "电池最高允许充电电流",
    billDetail_00020: "整车动力蓄电池电压",
    billDetail_00021: "整车动力蓄电池额定电压",
    billDetail_00022: "整车动力蓄电池标称总能量",
    billDetail_00023: "整车动力蓄电池额定容量",
    billDetail_00024: "充电曲线对比",
    billDetail_00025: "充电曲线",
    billDetail_00026: "车端需求电压(虚线)",
    billDetail_00027: "车端需求电流(虚线)",
    billDetail_00028: "车端测量电压(虚线)",
    billDetail_00029: "车端测量电流(虚线)",
    billDetail_00030: "电池初始电量",
    billDetail_00031: "电池当前电量",
    billDetail_00032: "瞬时功率",
    billDetail_00033: "平均功率",
    billDetail_00034: "最高功率",
    billDetail_00035: "车型",
    billDetail_00036: "功率",
    billDetail_00037: "电流",
    billDetail_00038: "电压",
    billDetail_00039: "电池电量",
    billDetail_00040: "最高功率范围，功率范围{powerBegin}-{powerEnd}Kw，共{minutes}分钟(占总时长的{timePercent})，共{powerCharged}度电，占总电量{chargePercent}",
    billDetail_00041: "累计电量",
    billDetail_00042: "度",
    billDetail_00043: "创建充电订单",
    billDetail_00044: "充电桩开始充电",
    billDetail_00045: "开始计费",
    billDetail_00046: "充电桩停止充电",
    billDetail_00047: "充电桩结束充电",
    billDetail_00048: "开始收取延时费",
    billDetail_00049: "充电完成",
    billDetail_00050: "结束计费",
    billDetail_00051: "支付完成",
    billDetail_00052: "(急停按钮被按下，暂停充电)",
    billDetail_00053: "(设备故障，暂停充电)",
    billDetail_00054: "(已充满，或已达到设定的结束条件)",
    billDetail_00055: "(设备故障，结束充电)",
    billDetail_00056: "(人为远程结束充电)",
    billDetail_00057: "(在充电桩上操作结束充电，例如刷卡、按急停等)",
    billDetail_00058: "设备当前收费标准",
    billDetail_00059: "已删除",
    billDetail_00060: "充电电量",
    billDetail_00061: "总费用({0})",
    billDetail_00062: "电费({0})",
    billDetail_00063: "服务费({0})",
    billDetail_00064: "用户信息",
    billDetail_00065: "订单编号",
    billDetail_00066: "{pay}",
    billDetail_00067: "启动方式",
    billDetail_00068: "支付方式",
    billDetail_00069: "微信支付",
    billDetail_00070: "微信余额支付",
    billDetail_00071: "团体资金账户余额支付",
    billDetail_00072: "第三方APP支付",
    billDetail_00073: "用户资金账户",
    billDetail_00074: "离线储值卡支付",
    billDetail_00075: "在线身份卡支付",
    billDetail_00076: "微信公众号（支付宝）",
    billDetail_00077: "优惠方式",
    billDetail_00078: "获赠奖励",
    billDetail_00079: "本订单未使用优惠券",
    billDetail_00080: "本订单未获得额外奖励",
    billDetail_00081: "订单备注",
    billDetail_00082: "请填写订单备注",
    billDetail_00083: "您确定删除此条订单备注？",
    billDetail_00084: "确认删除",
    billDetail_00085: "提示",
    billDetail_00086: "天",
    billDetail_00087: "小时",
    billDetail_00088: "分钟",
    billDetail_00089: "当前订单金额",
    billDetail_00090: "实际订单金额",
    billDetail_00091: "最高功率范围，功率范围{powerBegin}-{powerEnd}Kw，共{minutes}分钟(占总时长的{timePercent})，共{powerCharged}度电，占总电量{chargePercent}",
    billDetail_00092: "微信公众号（微信支付免密支付）",
    billDetail_00093: "微信公众号（支付宝）",
    billDetail_00094: "只记账",
    billDetail_00095: "单次充电总费用不打折",
    billDetail_00096: "单次充电服务费不打折",
    billDetail_00097: "单次充电服务费{des}折",
    billDetail_00098: "单次充电总费用{des}折",
    billDetail_00099: "单次充电总费用全免",
    billDetail_00100: "单次充电服务费全免",
    billDetail_00101: "成长等级特权",
    billDetail_00102: "支付时赠送金额抵扣{money}元",
    billDetail_00103: "支付时团体分配金额抵扣{money}元",
    billDetail_00104: "本次订单待支付原因：",
    billDetail_00105: "第三方系统未通知充电订单已支付。",
    billDetail_00106: "因收取延时费导致订单金额大于当时用户账户余额。",
    billDetail_00107: "在团体允许最多未支付的规则范围内。",
    billDetail_00108: "充电订单未按结束条件精准结束（在容错范围内），导致订单金额稍大于当时用户账户余额。",
    billDetail_00109: "充电时充电桩离线，上线后补报订单金额大于当时用户账户余额。",
    billDetail_00110: "充电站的用户充电支付模式为后付费模式。",
    billDetail_00111: "未知原因导致订单金额大于当时用户账户余额。",
    billDetail_00112: "待支付订单的处理：",
    billDetail_00113: "1、账户充值后余额大于待支付订单金额，系统自动结算订单。<br/>2、下次充电前会提醒用户支付，未支付订单无法充电。<br/>3、如果用户长期未支付，您可以修改金额小于等于账户余额，系统会自动结算订单。",
    billDetail_00114: "优惠券名称",
    billDetail_00115: "优惠券类型",
    billDetail_00116: "使用条件",
    billDetail_00117: "优惠券管理",
    billDetail_00118: "已删除",
    billDetail_00119: "优惠券详情",
    billDetail_00120: "折扣方式",
    billDetail_00121: "用电量同时抵用电费及服务费",
    billDetail_00122: "用电量抵用服务费",
    billDetail_00123: "用现金抵用电费及服务费",
    billDetail_00124: "用现金抵用服务费",
    billDetail_00125: "抵用{power}度充电量，优惠{money}元",
    billDetail_00126: "优惠{money}元",
    billDetail_00127: "电量包",
    billDetail_00128: "代金券",
    billDetail_00129: "启停卡",
    billDetail_00130: "储值卡",
    billDetail_00131: "身份卡",
    billDetail_00132: "智充配置卡",
    billDetail_00133: "集团配置卡",
    billDetail_00134: "离线数据读取卡",
    billDetail_00135: "已支付",
    billDetail_00136: "待拔枪",
    billDetail_00137: "充电中",
    billDetail_00138: "待支付",
    billDetail_00139: "延误计费中",
    billDetail_00140: "处理停滞订单",
    billDetail_00141: "暂时取消停滞订单",
    billDetail_00142: "结束并生成账单",
    billDetail_00143: "如果由于该订单的账单没有及时上报导致用户无法继续充电或充电口无法恢复空闲，请选择下面处理方式:",
    billDetail_00144: "1. 暂时取消订单: 先暂时取消订单，等待充电桩后续上报账单。如果充电桩已经较长时间没有上报账单或已经重启过，暂时取消订单后，有可能不会再上报账单，建议您使用第2种方式 - 结束并生成账单。",
    billDetail_00145: "2. 结束并生成账单: 由运营平台系统结束订单，按照当前费用自动生成账单并尝试扣款支付。请注意: 在此生成账单后，充电桩后续上报的真实账单将会被忽略。",
    billDetail_00146: "用户车牌号",
    billDetail_00147: "通知信息维护",
    billDetail_00148: "输入的车牌号不能为空",
    billDetail_00149: "请正确输入车牌号",
    billDetail_00150: "第三方订单编号",
    billDetail_00151: "补推订单",
    billDetail_00152: "修改金额",
    billDetail_00153: "订单总金额",
    billDetail_00154: "请输入付款金额",
    billDetail_00155: "修改后的金额",
    billDetail_00156: "折扣后金额",
    billDetail_00157: "折扣",
    billDetail_00158: "第三方支付",
    billDetail_00159: "推送失败",
    billDetail_00160: "推送成功",
    billDetail_00165: "修改的金额格式不符",
    billDetail_00166: "双枪订单",
    billDetail_00167: "即插即充",

    // 团体管理员
    billDetail_00200: "团体名称",
    billDetail_00201: "充电订单查询",
    // pos机
    billDetail_00202: 'POS机流水号',
    billDetail_00203: '银行卡',
    billDetail_00204: '启动方式',
    billDetail_00205: 'POS机用户',
    billDetail_00206: '只免除延时费',
    billDetail_00207: '充电总费用打{dis}折',
    billDetail_00208: '充电服务费打{dis}折',
    billDetail_00209: '充电总费用全免',
    billDetail_00210: '充电服务费全免',
    voucher_coupon: "抵用券",
    discount_coupon: "折扣券",
    fee_total: "总费用",
    service_fee: "服务费",
    promotion_use_coupon_condition: "单次充电{msg}满足{feeAtLeast}元",
    billDetail_00211: "取消订单",
    billDetail_00212: "取消订单前，如用户已支付订单，请先将用户已支付的费用退还用户。您确定取消此订单？取消后订单数据不列入统计。",
    billDetail_00213: "开始充电时电表数",
    billDetail_00214: "结束充电时电表数",
    billDetail_00215: "已取消订单",
    bill_00071: "电量小于1度",
    bill_00072: "充电时间超长",
    bill_00073: "设备异常结束",
    bill_00074: "车辆异常结束",
    bill_00101: "电量大于1000度",
    bill_00102: "平均充电功率超出该型号设备的最大输出充电功率",
    billDetail_10216: "{username}将订单金额{oldFeeTotal}{currencyType}修改为{feeTotal}{currencyType}。",
    billDetail_10217: "{username}处理停滞订单,处理方式为暂时取消订单",
    billDetail_10218: "{username}处理停滞订单,处理方式为结束并生成账单",
    billDetail_00217: 'OCPP订单编号',
    billDetail_10221: "应收${0}，因超出预授权或账户额度而减免",
    billDetail_01001: 'AuxNumber',
    billDetail_01002: '充电车辆及电池信息',
}