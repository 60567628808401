export default {
  eventlogsnzs_00001: "事务列表",
  eventlogsnzs_00002: "导出数据",
  eventlogsnzs_00003: "事务编号",
  eventlogsnzs_00004: "开始日期",
  eventlogsnzs_00005: "结束日期",
  eventlogsnzs_00006: "重置",
  eventlogsnzs_00007: "查询",
  eventlogsnzs_00008: "事务状态",
  eventlogsnzs_00009: "全部",
  eventlogsnzs_00010: "账单类型",
  eventlogsnzs_00011: "计费中",
  eventlogsnzs_00012: "补电",
  eventlogsnzs_00013: "放电",
  eventlogsnzs_00014: "储能设备SN",
  eventlogsnzs_00015: "全部储能柜",
  eventlogsnzs_00016: "补电电量(度)",
  eventlogsnzs_00017: "补电时间(分钟)",
  eventlogsnzs_00018: "补电次数(次)",
  eventlogsnzs_00019: "放电电量(度)",
  eventlogsnzs_00020: "放电时间(分钟)",
  eventlogsnzs_00021: "放电次数(次)",
  eventlogsnzs_00022: "事务曲线",
  eventlogsnzs_00023: "天",
  eventlogsnzs_00024: "小时",
  eventlogsnzs_00025: "分钟",
  eventlogsnzs_00026: "设备",
  eventlogsnzs_00027: "补电时长",
  eventlogsnzs_00028: "已放电量",
  eventlogsnzs_00029: "已补电量",
  eventlogsnzs_00030: "放电中",
  eventlogsnzs_00031: "已放完",
  eventlogsnzs_00032: "补电中",
  eventlogsnzs_00033: "已补完",
  eventlogsnzs_00034: "此操作将下载事务的Excel文件，是否继续?",
  eventlogsnzs_00035: "确定",
  eventlogsnzs_00036: "取消",
  eventlogsnzs_00037: "开始下载",
  eventlogsnzs_00038: "取消下载",
  eventlogsnzs_00039: "提示",
  eventlogsnzs_00040: "累计电量",
  eventlogsnzs_00041: "瞬时功率",
  eventlogsnzs_00042: "平均功率",
  eventlogsnzs_00043: "最高功率",
  eventlogsnzs_00044: "储能事务日志",
  eventlogsnzs_00045: "",
  billDetail_00025: "充电曲线",
  billDetail_00026: "车端需求电压(虚线)",
  billDetail_00027: "车端需求电流(虚线)",
  billDetail_00028: "车端测量电压(虚线)",
  billDetail_00029: "车端测量电流(虚线)",
  billDetail_00030: "电池初始电量",
  billDetail_00031: "电池当前电量",
  billDetail_00032: "瞬时功率",
  billDetail_00033: "平均功率",
  billDetail_00034: "最高功率",
  billDetail_00035: "车型",
  billDetail_00036: "功率",
  billDetail_00037: "电流",
  billDetail_00038: "电压",
  billDetail_00039: "累计电量",
  billDetail_00042: "度",
  bill_00059: "今天",
  bill_00060: "7天前",
  bill_00061: "15天前",
  bill_00077: "昨天",
  bill_00078: "30天前",
}