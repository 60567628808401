export default {
  createKnowledge_00001: 'Crear conocimiento',
  createKnowledge_00002: 'Prohibición de conocimiento',
  createKnowledge_00003: 'Categoría de tickets relacionada',
  createKnowledge_00004: 'Tickets relacionados',
  createKnowledge_00005: 'Título del ticket',
  createKnowledge_00006: 'Título del ticket',
  createKnowledge_00007: 'Marca y modelo del equipo relacionado',
  createKnowledge_00008: '',
  createKnowledge_00009: 'Todos los modelos',
  createKnowledge_00010: 'Descripción del problema',
  createKnowledge_00011: 'Solución',
  createKnowledge_00012: 'Complete completamente',
  createKnowledge_00013: 'Creado con éxito',
  createKnowledge_00014: 'Compartir con CPO relacionados',
  createKnowledge_00015: 'Editar conocimiento',
  createKnowledge_00017: 'Transacción relacionada',
  createKnowledge_00018: 'Código con error',
}