export default {
  pileDetail_00001: 'Configuración de Push',
  pileDetail_00002: 'Detalles de NZS',
  pileDetail_00003: 'Inf. del cargador',
  pileDetail_00004: 'Configuración del cargador',
  pileDetail_00005: 'Gestión del punto de carga',
  pileDetail_00006: 'Mant. del cargador',
  pileDetail_00007: 'Reinicie el cargador',
  pileDetail_00008: 'Se envió el comando de reinicio y el proceso tardará aproximadamente 5 minutos. Por favor, permanezca en esta página, no actualice la página',
  pileDetail_00009: 'Confirmar',
  pileDetail_00010: 'Iniciar carga',
  pileDetail_00011: 'El cargador se reinició correctamente',
  pileDetail_00012: 'Inicio remoto',
  pileDetail_00013: 'Error al reiniciar el cargador',
  pileDetail_00014: 'Nombre del cargador',
  pileDetail_00015: 'Sitio de carga propio',
  pileDetail_00016: 'Ver',
  pileDetail_00017: 'Número de serie del cargador',
  pileDetail_00018: 'Fabricante del cargador',
  pileDetail_00019: 'Tipo de cargador',
  pileDetail_00020: 'Ingrese el nombre del cargador',
  pileDetail_00021: 'Después de que se modifique la configuración, guardada, la configuración debe enviarse al dispositivo. Después de guardar la modificación de la configuración, la configuración debe enviarse al dispositivo y la configuración puede tener efecto solo después de que el dispositivo responda.',
  pileDetail_00022: 'Enviar configuración',
  pileDetail_00023: 'Enviar al cargador',
  pileDetail_00024: 'Habilitar el cargador',
  pileDetail_00025: 'Cerradura electromagnética',
  pileDetail_00026: 'Cerradura electromagnética falló pero permite carga',
  pileDetail_00027: 'Radar de estacionamiento',
  pileDetail_00028: 'Cargador abierto',
  pileDetail_00029: 'Hora de inicio',
  pileDetail_00030: 'Hora de finalización',
  pileDetail_00031: 'Corriente de carga máxima (A)',
  pileDetail_00032: 'Potencia de carga máxima (KW)',
  pileDetail_00033: 'Error al enviar',
  pileDetail_00034: 'El cargador está fuera de línea',
  pileDetail_00035: 'Esperando la respuesta del cargador',
  pileDetail_00036: 'Enviar correctamente',
  pileDetail_00037: 'Configuración de fecha incorrecta',
  pileDetail_00038: 'El módulo de potencia necesita un recordatorio de tiempo de mantenimiento: acumule el tiempo de carga desde el tiempo de reinicio',
  pileDetail_00039: '{hours}Horas ',
  pileDetail_00040: 'Tiempo de reinicio:',
  pileDetail_00041: 'Normal',
  pileDetail_00042: 'Defectuoso',
  pileDetail_00043: '¿Está seguro de que se ha completado este mantenimiento para estos módulos de potencia? Después de hacer clic en Aceptar, los módulos de potencia necesitan un aviso de mantenimiento para volver a cronometrar.',
  pileDetail_00044: 'Recordatorio',
  pileDetail_00045: 'Nombre del punto de carga',
  pileDetail_00046: 'Código QR de carga',
  pileDetail_00047: 'Código QR dinámico',
  pileDetail_00048: 'Código QR estático',
  pileDetail_00049: 'Actualizar a código QR estático',
  pileDetail_00050: 'Código QR personalizado',
  pileDetail_00051: 'Control remoto',
  pileDetail_00052: 'Reiniciar el punto de carga',
  pileDetail_00053: 'Detener la carga',
  pileDetail_00054: 'Establecer en disponible',
  pileDetail_00055: 'Estado del punto de carga',
  pileDetail_00056: 'Dispositivo de gestión de vehículos eléctricos',
  pileDetail_00057: 'Ninguno',
  pileDetail_00058: 'Bloqueo de estacionamiento controlado por el cargador',
  pileDetail_00059: 'Bloqueo de estacionamiento controlado por la nube',
  pileDetail_00060: 'Información del equipo de gestión de plazas de aparcamiento',
  pileDetail_00061: 'Introduzca el código del cargador',
  pileDetail_00062: 'Habilitar dispositivo de gestión de estacionamiento',
  pileDetail_00063: 'Dispositivo de gestión de estacionamiento por control remoto',
  pileDetail_00064: 'Subir',
  pileDetail_00065: 'Bajar',
  pileDetail_00066: 'Usuario de facturación de tarifa de inactividad de plaza de aparcamiento actual',
  pileDetail_00067: 'Hora de inicio de ocupación',
  pileDetail_00068: 'Detalle del usuario',
  pileDetail_00069: 'Detener manualmente esta facturación por ocupación',
  pileDetail_00070: 'Código OR para dispositivo de gestión de plazas de aparcamiento',
  pileDetail_00071: 'Generar código QR estático',
  pileDetail_00072: 'Ocupado',
  pileDetail_00073: 'Deshabilitado',
  pileDetail_00074: 'Fuera de línea',
  pileDetail_00075: 'Defectuoso',
  pileDetail_00076: 'Reservado',
  pileDetail_00077: 'Disponible',
  pileDetail_00078: 'Conectado',
  pileDetail_00079: 'Cargando',
  pileDetail_00080: 'Esperando para mover el EV',
  pileDetail_00081: 'Esperando a que se desconecte el enchufe',
  pileDetail_00082: 'Cargando punto',
  pileDetail_00083: 'Iniciar carga',
  pileDetail_00084: 'Guarde el paso anterior',
  pileDetail_00085: 'El espacio de estacionamiento actual tiene un usuario de facturación que está ocupando el espacio de estacionamiento, ¡modifíquelo más tarde!',
  pileDetail_00086: 'El parámetro está vacío',
  pileDetail_00087: 'Máx. 20 caracteres',
  pileDetail_00088: 'Se ha enviado el comando de inicio de carga',
  pileDetail_00089: 'Se ha enviado el comando de detención de carga',
  pileDetail_00090: 'Enviado correctamente',
  pileDetail_00091: 'Actualizar a código QR estático',
  pileDetail_00092: 'Generar código QR correctamente',
  pileDetail_00093: 'Habilitado',
  pileDetail_00094: 'Deshabilitado',
  pileDetail_00095: 'Bloqueo de estacionamiento controlado por la nube',
  pileDetail_00096: 'Bloqueo de estacionamiento controlado por EVSE',
  pileDetail_00097: 'Ingrese el parámetro de código QR, el código QR se mostrará en el cargador',
  pileDetail_00097: 'Ingrese el parámetro del código QR, el código QR se mostrará en el cargador',
  pileDetail_00098: 'Aviso: solo se puede generar el código QR estático, no se permite volver al estado original',
  pileDetail_00099: 'El código QR personalizado no puede estar vacío',
  pileDetail_00100: 'El código QR se personalizó correctamente',
  pileDetail_00101: 'Error en la configuración',
  pileDetail_00102: 'La duración no debe ser mayor a {0} ',
  pileDetail_00103: 'Duración de recarga actual (minutos)',
  pileDetail_00104: 'Tarifa actual de pre-inactividad ({0})',
  pileDetail_00105: 'Tarifa de inactividad modificada ({0})',
  pileDetail_00106: 'Ingrese el precio',
  pileDetail_00107: 'Ingrese la tarifa por inactividad modificada',
  pileDetail_00108: 'Ingrese el número correcto',
  pileDetail_00109: 'Finalizar manualmente la tarifa por inactividad',
  pileDetail_00110: 'La cantidad máxima de códigos QR es 100 000 y no se puede reciclar. Opere con cuidado. ¿Está seguro de actualizar?',
  pileDetail_00111: 'Actualización exitosa',
  pileDetail_00112: 'Finalizar',
  pileDetail_00113: 'Si se debe generar un código QR fijo para el dispositivo de administración de estacionamiento',
  pileDetail_00114: 'Aún no hay código QR',
  pileDetail_00115: 'Si el cargador está fuera de línea, solo se admite la lista blanca',
  pileDetail_00116: 'Borrar lista blanca',
  pileDetail_00117: 'Enviar lista blanca',
  pileDetail_00118: 'ID del cargador',
  pileDetail_00119: 'versión del sistema',
  pileDetail_00120: 'Versión de APK',
  pileDetail_00121: 'Versión de DCB',
  pileDetail_00122: 'Red',
  pileDetail_00123: 'Modo de carga de la batería',
  pileDetail_00124: 'Nombre de la caja de la batería',
  pileDetail_00125: 'Capacidad de la caja de la batería',
  pileDetail_00126: 'Tiempo del ciclo de la batería',
  pileDetail_00127: 'SOC de la caja de la batería',
  pileDetail_00128: 'SOH de la caja de la batería',
  pileDetail_00129: 'Temperatura de la caja de la batería',
  pileDetail_00130: 'Estado de la caja de la batería',
  pileDetail_00131: 'Iniciar la carga de la batería',
  pileDetail_00132: 'Detener la carga de la batería',
  pileDetail_00133: 'Iniciar la descarga de la batería',
  pileDetail_00134: 'Detener la descarga de la batería',
  pileDetail_00135: 'Modo de funcionamiento del aire acondicionado',
  pileDetail_00136: 'Modo económico',
  pileDetail_00137: 'Modo de eficiencia',
  pileDetail_00138: 'Modo de máxima potencia',
  pileDetail_00139: 'Modo económico ecológico',
  pileDetail_00140: 'Modo automático',
  pileDetail_00141: 'Usar el dispositivo de almacenamiento de energía para llenar el vacío de potencia de carga y cargar rápidamente el vehículo',
  pileDetail_00142: 'Almacenamiento de energía cuando la tarifa de electricidad es baja y servicios cuando la tarifa de electricidad es alta, reduciendo los costos de electricidad',
  pileDetail_00143: 'El sistema equilibra automáticamente los requisitos de potencia y costes',
  pileDetail_00144: 'Más información',
  pileDetail_00145: 'Gestión de NZS',
  pileDetail_00146: 'Caja de batería de NZS',
  pileDetail_00147: "Carga de batería de NZS",
  pileDetail_00148: "Descarga de batería de NZS",
  pileDetail_00149: "Disponible",
  }