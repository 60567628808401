export default {
    detailDepot_00001: 'Detalles de la estación',
    detailDepot_00002: "Automóvil particular",
    detailDepot_00003: "Vehículo logístico",
    detailDepot_00004: "Furgoneta",
    detailDepot_00005: "Autobús",
    detailDepot_00006: "Bicicleta eléctrica",
    detailDepot_00007: "Público",
    detailDepot_00008: "Privado",
    detailDepot_00009: "Autobús (especial)",
    detailDepot_00010: "Saneamiento (especial)",
    detailDepot_00011: "Logística (especial)",
    detailDepot_00012: "Taxi (especial)",
    detailDepot_00013: 'Arrendamiento de tiempo compartido (dedicado) (extensión estándar de interconexión)',
    detailDepot_00014: 'Compartir celdas (Dedicado) (Extensión del estándar de interconexión)',
    detailDepot_00015: 'Bit (Dedicado) (Extensión del estándar de interconexión)',
    detailDepot_00016: 'Otros',
    detailDepot_00017: 'seleccione',
    detailDepot_00018: 'Comunidad residencial',
    detailDepot_00019: 'Instituciones públicas',
    detailDepot_00020: 'Organización',
    detailDepot_00021: 'Edificio de oficinas',
    detailDepot_00022: 'Área industrial',
    detailDepot_00023: 'Centro de transporte',
    detailDepot_00024: 'Gran comunidad estilística',
    detailDepot_00025: 'Ecologización de la ciudad',
    detailDepot_00026: 'Gran estacionamiento de construcción',
    detailDepot_00027: 'Lugar de estacionamiento en la calle',
    detailDepot_00028: 'Área de servicio de Intercity Express',
    detailDepot_00029: 'Consulte la norma nacional recomendada GB/T 2260-2007',
    detailDepot_00030: 'Edición exitosa',
    detailDepot_00031: 'Formato de código de país incorrecto (2 letras mayúsculas)',
    detailDepot_00032: 'Imagen modificada exitosamente',
    detailDepot_00033: 'Cargar imagen',
    detailDepot_00034: 'Solo admite imágenes PNG, JPG y GIF. El tamaño máximo es 1 MB.',
    detailDepot_00035: 'Imagen real',
    detailDepot_00036: 'Dirección, calle y nombre de la casa',
    detailDepot_00037: 'Buscar',
    detailDepot_00038: 'Nombre',
    detailDepot_00039: 'Dirección detallada',
    detailDepot_00040: 'Posición actual',
    detailDepot_00041: 'Cancelar',
    detailDepot_00042: 'Usar esta ubicación',
    detailDepot_00043: 'Seleccione las coordenadas de la estación de carga',
    detailDepot_00044: 'La posición seleccionada no coincide con la dirección',
    detailDepot_00045: "Aviso",
    detailDepot_00046: "Ya veo",
    detailDepot_00047: "No se puede encontrar la dirección",
    detailDepot_00048: "Se ha producido un error desconocido en el mapa de Google",
    detailDepot_00049: "Centro de depósitos",
    detailDepot_00050: "Gestión de depósitos",
    detailDepot_00051: 'Información de la estación',
    detailDepot_00052: 'Gestión de cargadores',
    detailDepot_00053: 'Tarifas establecidas',
    detailDepot_00054: 'Políticas',
    detailDepot_00055: 'Tráfico abierto',
    detailDepot_00056: 'Atraque gubernamental',
    detailDepot_00057: 'Distribución de energía',
    detailDepot_00058: 'Carga ordenada de buses',
    detailDepot_00059: 'Información básica',
    detailDepot_00060: 'Guardar',
    detailDepot_00061: 'Editar',
    detailDepot_00062: 'Eliminar estación',
    detailDepot_00063: 'Nombre de la estación',
    detailDepot_00064: 'Posición de la estación',
    detailDepot_00065: 'Ubicación en el mapa',
    detailDepot_00066: 'Código de área',
    detailDepot_00067: 'Código de país',
    detailDepot_00068: 'Tipo de EV',
    detailDepot_00069: 'Tipo de sitio',
    detailDepot_00070: 'Contacto del depósito',
    detailDepot_00071: 'Sitio de construcción',
    detailDepot_00072: 'Ubicación del estacionamiento',
    detailDepot_00073: 'Número de espacios de estacionamiento',
    detailDepot_00074: 'Imágenes reales',
    detailDepot_00075: 'Nombre',
    detailDepot_00076: 'Teléfono',
    detailDepot_00077: 'Correo electrónico',
    detailDepot_00078: 'Tarifa de estacionamiento',
    detailDepot_00079: 'Cargador sin conexión',
    detailDepot_00080: 'Iniciar carga',
    detailDepot_00081: 'En línea',
    detailDepot_00082: 'Sin conexión',
    detailDepot_00083: 'Fallo',
    detailDepot_00084: 'Cargando',
    detailDepot_00085: "Todo",
    detailDepot_00086: "Cambiar a disponible",
    detailDepot_00087: "El cargador no está habilitado",
    detailDepot_00088: "Ocupando",
    detailDepot_00089: "Idioma",
    detailDepot_00090: "Zona horaria",
    detailDepot_00091: "Horario de verano",
    detailDepot_00092: "Bloqueo electromagnético del cargador de CA",
    detailDepot_00093: "Bloqueo electromagnético del cargador de CC",
    detailDepot_00094: "Si falla la cerradura electromagnética",
    detailDepot_00095: "Radar de plazas de aparcamiento",
    detailDepot_00096: "Tiempo de espera del dispositivo de CA",
    detailDepot_00097: "Tiempo de espera del dispositivo de CC",
    detailDepot_00098: "Importe máximo de cargo por inactividad",
    detailDepot_00099: "Política de cobro con retraso de tiempo",
    detailDepot_00100: "Dispositivo de CA",
    detailDepot_00101: "Dispositivo de CC",
    detailDepot_00102: "Dispositivo de CA",
    detailDepot_00103: "Dispositivo de CC",
    detailDepot_00104: "Disponible",
    detailDepot_00105: "No disponible",
    detailDepot_00106: "No recargable",
    detailDepot_00107: "Política de cobro por tarifa por inactividad",
    detailDepot_00108: "Cobro de tarifa en cualquier caso",
    detailDepot_00109: "Sin cargo por falla de pila",
    detailDepot_00110: "{num}min",
    detailDepot_00111: "Establecer en 0 para que no haya límite",
    detailDepot_00112: "Recargable",
    detailDepot_00113: "10 minutos",
    detailDepot_00114: "15 minutos",
    detailDepot_00115: "20 minutos",
    detailDepot_00116: "30 minutos",
    detailDepot_00117: "Si el dispositivo está sin conexión, solo los usuarios de la lista blanca pueden comenzar a cargar",
    detailDepot_00118: "Aplicar lista blanca",
    detailDepot_00119: "Borrar lista blanca",
    detailDepot_00120: "Ver lista blanca",
    detailDepot_00121: "No",
    detailDepot_00122: "Sí",
    detailDepot_00123: "Error en el formato de entrada",
    detailDepot_00124: "No se pueden ingresar números negativos",
    detailDepot_00125: "Error en la inserción",
    detailDepot_00126: "Se aplicaron un total de {allDevice} cargadores, {sucDevice} cargadores tuvieron éxito y {faiDevice} cargadores fallaron",
    detailDepot_00127: "No existe dicho cargador",
    detailDepot_00128: "{num} cargadores",
    detailDepot_00129: "Editar información de configuración",
    detailDepot_00130: "Cargador C1 (4.3)",
    detailDepot_00131: "Cargador C1 (7 pulgadas)",
    detailDepot_00132: "Cargador C2",
    detailDepot_00133: "Cargador C4",
    detailDepot_00134: "Cargador C6",
    detailDepot_00135: "Cargador C6s",
    detailDepot_00136: "{0} error informado, código de error: {1}",
    detailDepot_00137: "La cantidad máxima de cargas con retraso de tiempo solo puede ser un número",
    detailDepot_00251: "Cargador C9Pro",
    zh: "Chinese",
    en: "English",
    de: "German",
    fr: "French",
    detailDepot_00138: "El cargador se reinició correctamente",
    detailDepot_00139: "Reinicio remoto",
    detailDepot_00140: "Error al reiniciar el cargador",
    processing: 'Reinicio en progreso',
    detailDepot_00141: "Seleccione el formato png o jpg para cargar",
    detailDepot_00142: "Hasta 6 cargas",
    detailDepot_00143: "La imagen que ha seleccionado es demasiado grande, vuelva a seleccionarla",
    detailDepot_00144: "Cargar imagen",
    detailDepot_00145: "Rotación de imágenes múltiples o método de video mp4, una de dos opciones",
    detailDepot_00146: "Usar rotación de imagen",
    detailDepot_00147: "Haga clic para cargar",
    detailDepot_00148: "Usando el video",
    detailDepot_00149: "5 segundos",
    detailDepot_00150: "10 segundos",
    detailDepot_00151: "15 segundos",
    detailDepot_00152: "Subir hasta 6 imágenes",
    detailDepot_00153: "Subir hasta 1 vídeo",
    detailDepot_00154: "El vídeo que ha seleccionado es demasiado grande, vuelva a seleccionarlo",
    detailDepot_00155: "Solo se admiten imágenes en formato PNG, JPG y GIF que no superen {size}M",
    detailDepot_00156: "Finalizar",
    detailDepot_00157: "Modificado correctamente",
    detailDepot_00158: "Modificar ubicación",
    detailDepot_00159: "Consulte la norma nacional recomendada GB/T 2260-2007 para completar",
    detailDepot_00160: "Consulte la norma ISO 3166-1 alpha-2 para completar",
    detailDepot_00161: "¡Guarde la operación anterior!",
    detailDepot_00162: "El parámetro está vacío",
    detailDepot_00163: "Ingrese el importe correcto (mayor que 0)",
    detailDepot_00164: "Fecha configurada incorrectamente",
    detailDepot_00165: "Ingrese el código de país",
    detailDepot_00166: "Error en el formato del código de país (2 letras mayúsculas)",
    detailDepot_00167: "Seleccione el tipo de estación",
    detailDepot_00168: "Ingrese el número de plazas de aparcamiento de la estación",
    detailDepot_00169: "Error al ingresar el formato del número de plazas de aparcamiento",
    detailDepot_00170: "El número de plazas de aparcamiento excede el límite (<=10000)",
    detailDepot_00171: "La descripción de la tarifa de estacionamiento no puede superar los 200 caracteres",
    detailDepot_00172: "Por favor, introduzca el número de teléfono de atención al cliente correcto",
    detailDepot_00173: "Hasta 20 dígitos",
    detailDepot_00174: "Por favor, seleccione el tipo de usuario y establezca el porcentaje",
    detailDepot_00175: "Por favor, introduzca un número entero positivo inferior a 100",
    detailDepot_00176: "Confirmación",
    detailDepot_00177: "Cancelar",
    detailDepot_00178: "Eliminación exitosa",
    detailDepot_00179: "Operación cancelada",
    detailDepot_00180: "¿Está seguro de eliminar este campo?",
    detailDepot_00181: "Total {num} hojas",
    detailDepot_00182: "El sitio de carga admite la lista blanca",
    detailDepot_00183: "Aplicar resultados",
    detailDepot_00184: "Se aplicaron un total de {allDevice} cargadores, {sucDevice} cargadores tuvieron éxito y {faiDevice} cargadores fallaron",
    detailDepot_00185: "N.º de cargador",
    detailDepot_00186: "Nombre del cargador",
    detailDepot_00187: "Tarifas de CA",
    detailDepot_00188: "Seleccionar tarifas",
    detailDepot_00189: "Nuevas tarifas de CA",
    detailDepot_00190: "Tarifas de CC",
    detailDepot_00191: "Nuevas tarifas de CC",
    detailDepot_00192: "Tarifas de uso actual:",
    detailDepot_00193: "Hora de inicio",
    detailDepot_00194: "Hora de finalización",
    detailDepot_00195: 'Electricidad fee\n({type}/{unit})',
    detailDepot_00196: "Tarifa de servicio\n({type}/{unit})",
    detailDepot_00197: "Tarifa por inactividad\n({type}/min)",
    detailDepot_00198: "Tipo de período",
    detailDepot_00199: 'Tipo de período',
    detailDepot_00200: 'undefined',
    detailDepot_00201: 'Super Peak',
    detailDepot_00202: 'Peak',
    detailDepot_00203: 'Off-Peak',
    detailDepot_00204: 'Super Off-Peak',
    detailDepot_00205: 'Nombre de tarifa',
    detailDepot_00206: 'Seleccionar',
    detailDepot_00207: 'Nuevas tarifas',
    detailDepot_00208: 'Por favor, introduzca el nombre de la tarifa',
    detailDepot_00209: 'Este nombre de tarifa ya está ocupado',
    detailDepot_00210: 'Añadir franja horaria',
    detailDepot_00211: 'Se pueden añadir hasta {0} franjas horarias',
    detailDepot_00212: 'Operación',
    detailDepot_00213: 'Eliminar',
    detailDepot_00214: 'Cancelar',
    detailDepot_00215: 'Aceptar',
    detailDepot_00216: 'Seleccione o cree una nueva tarifa',
    detailDepot_00217: 'Se pueden agregar hasta 12 franjas horarias',
    detailDepot_00218: 'Complete las tarifas',
    detailDepot_00219: 'El período de tarifas debe comenzar en 0 y finalizar en 24',
    detailDepot_00220: 'Complete las tarifas correctas',
    detailDepot_00221: 'Nueva creación exitosa',
    detailDepot_00222: 'Aplicar tarifas',
    detailDepot_00223: 'Nombre de la estación de carga',
    detailDepot_00224: 'CC',
    detailDepot_00225: 'CA',
    detailDepot_00226: 'Éxito',
    detailDepot_00227: 'Aplicación en proceso',
    detailDepot_00228: 'Error',
    detailDepot_00229: 'Número de dispositivos',
    detailDepot_00230: 'Resultados de la aplicación del cargador',
    detailDepot_00231: 'Nombre de la flota',
    detailDepot_00232: 'Tarifas actuales',
    detailDepot_00233: 'Descuento actual',
    detailDepot_00234: 'Prioridad (de mayor a menor)',
    detailDepot_00235: 'Subir',
    detailDepot_00236: 'Bajar',
    detailDepot_00237: 'Establecer',
    detailDepot_00238: 'Aplicar',
    detailDepot_00239: 'Eliminar',
    detailDepot_00240: 'Sin descuento en el costo total de una sesión de carga única',
    detailDepot_00241: 'Sin descuento en la tarifa de servicio de una sesión de carga única',
    detailDepot_00242: 'Descuento en el costo total de una sesión de carga única {des}',
    detailDepot_00243: 'Descuento en la tarifa de servicio de una sesión de carga única {des}',
    detailDepot_00244: 'La tarifa de servicio de una sesión de carga única es totalmente gratuita',
    detailDepot_00245: 'Se elimina por completo el costo total de una sesión de carga única',

    detailDepot_00246: 'Eliminar',
    detailDepot_00247: 'Eliminar',
    detailDepot_00248: 'Eliminar',
    detailDepot_00249: 'Eliminar',
    detailDepot_00250: 'Eliminar',

    detailDepot_00401: "Le permite agregar un grupo de asignación de energía, haga clic para agregar los cargadores de la estación al grupo, establezca la potencia de carga límite total del grupo por período de tiempo, la potencia de carga de los cargadores en el grupo se asigna a partir de la potencia de carga límite total del grupo para cada período de tiempo y no excede la potencia de carga límite total del grupo para cada período de tiempo.",
    detailDepot_00402: "Agregar grupo de energía",
    detailDepot_00403: "¡Hay 0 cargadores para aplicar!",
    detailDepot_00404: "Para admitir la asignación de energía del cargador fuera de línea, es necesario aplicar la potencia de carga fuera de línea máxima del conector del cargador (potencia de carga reservada) al cargador.",
    detailDepot_00405: "Aplicar configuración offline",
    detailDepot_00406: "Cargador sin asignar en la estación",
    detailDepot_00407: "¿Está seguro de que desea eliminar este grupo de asignación de energía?",
    detailDepot_00408: "Haga clic para agregar al grupo de asignación de energía",
    detailDepot_00409: "Ingrese el nombre del grupo de asignación de energía",
    detailDepot_00410: "Nombre del grupo de asignación de energía",

    abled: "",
    disabled: "",

    detailDepot_00499: "Solo se permite la carga a usuarios específicos",
    detailDepot_00500: "Estado de habilitación de la estación",
    detailDepot_00501: "Abierta al público",
    detailDepot_00502: "¿Admite cupones?",
    detailDepot_00503: "¿Admite colas?",
    detailDepot_00504: "¿Admite reservas?",
    detailDepot_00505: "¿Se permite la carga después del tiempo de espera de preparación del conector del cargador?",
    detailDepot_00506: "Modo de pago del usuario de WeChat",
    detailDepot_00507: "Modo de pago del usuario de la tarjeta de carga",
    detailDepot_00508: "Límite de SOC de la estación",
    detailDepot_00509: "Recordatorio del costo de electricidad restante en la estación",
    detailDepot_00510: "Controlar si la estación se muestra en WeChat Público",
    detailDepot_00511: "Información que afecta la presentación de la estación en el cliente",
    detailDepot_00512: "Permitir a los usuarios de la flota en la configuración de tarifas de la flota cargar después del inicio",
    detailDepot_00513: "En modo prepago, solo los usuarios recargables pueden recargar",
    detailDepot_00514: "En modo prepago, solo los usuarios de recarga pueden cargar",
    detailDepot_00515: "Solo para cargador de CC",
    detailDepot_00516: "Se enviará un recordatorio cuando la factura de electricidad restante sea < monto del recordatorio",
    detailDepot_00517: "Ingrese un número entero positivo menor que 100",
    detailDepot_00518: "Seleccione el tipo de usuario",
    detailDepot_00519: "Disponible",
    detailDepot_00520: "No disponible",
    detailDepot_00521: "Abierto",
    DetailDepot_00522: "No abierto",
    detailDepot_00523: 'Sí',
    detailDepot_00524: 'No',
    detailDepot_00525: "Abierto",
    detailDepot_00526: "No abierto",
    detailDepot_00527: "Compatible",
    detailDepot_00528: "No compatible",
    detailDepot_00529: "El parámetro está vacío",
    detailDepot_00530: "Solo las flotas compatibles pueden cargar",
    detailDepot_00531: "Tiempo de espera",
    detailDepot_00532: "minutos",
    detailDepot_00533: "Modo prepago",
    detailDepot_00534: "Modo pospago",
    detailDepot_00535: "Saldo mínimo",
    detailDepot_00536: "Usuario de terceros",
    detailDepot_00537: "Usuarios de WeChat",
    detailDepot_00538: "Cuando el SOC de la batería del vehículo alcanza",
    detailDepot_00539: "%, detiene la carga automáticamente",
    detailDepot_00540: "Precio por kWh",
    detailDepot_00541: "€",
    detailDepot_00542: "Importe del recordatorio",
    detailDepot_00543: "Cuota de electricidad restante",
    detailDepot_00544: "Por favor, introduzca el importe correcto (mayor que 0)",
    detailDepot_00545: "¡¡¡Guarde la operación anterior!!!",
    detailDepot_00546: "Políticas básicas",

    // 运营设置 ---标签管理
    detailDepot_00547: 'Gestión de etiquetas',
    detailDepot_00548: 'Agregar etiqueta personalizada',
    detailDepot_00549: 'Agregar etiqueta de estación en el número público de WeChat, puede encontrar la estación en el número público de WeChat y mostrarla en los detalles de la estación',
    detailDepot_00550: 'Nombre de etiqueta',
    detailDepot_00551: 'Mapa de etiquetas',
    detailDepot_00552: 'Tipo de etiqueta',
    detailDepot_00553: 'Orden de visualización',
    detailDepot_00554: "Mover hacia abajo",
    detailDepot_00555: "Mover hacia arriba",
    detailDepot_00556: "Etiquetas personalizadas",
    detailDepot_00557: "Información de etiqueta de estación",
    detailDepot_00558: "Nombre de etiqueta",
    detailDepot_00559: "Tipo de etiqueta",
    detailDepot_00560: "Etiquetas personalizadas",
    detailDepot_00561: "Mapa de etiquetas",
    detailDepot_00562: "Arrastre el archivo aquí o haga clic para cargar",
    detailDepot_00563: "Cuando hay una imagen de etiqueta, el frente muestra la imagen de la etiqueta. Cuando no hay un mapa de etiquetas, el estilo predeterminado del sistema frontal muestra el nombre de la etiqueta",
    detailDepot_00564: "Solo se admiten imágenes en formato PNG y JPG, tamaño {area}px, no más de {size}kb",
    detailDepot_00565: "¿Está seguro de eliminar?",
    detailDepot_00566: "Recordatorio",
    custom: "Custom",
    undefined: "Undefined",
    detailDepot_00567: "Operación exitosa",
    detailDepot_00568: "Operación fallida",
    detailDepot_00569: "Eliminación exitosa",
    detailDepot_00570: "Recuperación",
    detailDepot_00571: "Modificación exitosa",
    detailDepot_00572: "Ingrese contenido, se admiten hasta 5 caracteres",
    // Operational Setup - Charging Order Expansion
    detailDepot_00573: "Expansión de transacciones",
    detailDepot_00574: "Agregar información de visualización para pedidos de cobro en WeChat Public",
    detailDepot_00575: "Agregar información",
    detailDepot_00576: "Título",
    detailDepot_00577: "Mostrar enlace",
    detailDepot_00578: "Restricción de visualización",
    detailDepot_00579: 'Mostrar pedido',
    detailDepot_00580: "Información de visualización de la transacción",
    detailDepot_00581: "Mostrar contenido",
    detailDepot_00582: "Solo transacciones pagadas",
    detailDepot_00583: "Estado de transacción ilimitado",
    detailDepot_00584: "Descripción",
    detailDepot_00585: "Ingrese la dirección URL",
    detailDepot_00586: "Marque esta opción para mostrar esta información solo después de que se haya completado el pago",
    detailDepot_00587: "Complete el contenido",
    detailDepot_00588: "Ingrese el contenido",
    detailDepot_00589: "No más de {num} palabras",
    detailDepot_00590: "¡Ingrese un número mayor que 0!",
    detailDepot_00591: "¡Ingrese las tarifas por inactividad!",
    detailDepot_00592: "Ingrese el período de tiempo durante el cual ¡Se elimina la tarifa por inactividad!",
    detailDepot_00593: "Por favor, introduzca un número entero positivo",
    // Operational Settings --- Cloud-controlled Parking Lock Idle fee
    detailDepot_00594: "Tarifa por inactividad de bloqueo de espacio de estacionamiento controlado por la nube",
    detailDepot_00595: "Tasas de tarifa por inactividad ({type}/{unit})",
    detailDepot_00596: "Duración de tarifa por inactividad gratuita (minutos)",
    detailDepot_00597: "Durante el tiempo que el propietario de un vehículo tarda en conducir para prepararse para cargar o en salir de un espacio de estacionamiento sin que se le cobre una tarifa por inactividad",
    detailDepot_00598: "Tarjeta de identidad en línea",
    // Car park docking
    detailDepot_00600: "Atraque de aparcamientos",
    detailDepot_00601: "El atraque de aparcamientos tiene como finalidad ofrecer a los usuarios una reducción en la tarifa de aparcamiento o emitir cupones de aparcamiento",
    detailDepot_00602: "1. Infórmate si el sistema de aparcamientos donde se encuentra este recinto está atracado",
    detailDepot_00603: "2. Si ya has atracado, introduce el número de aparcamiento y asocia el aparcamiento al mismo. (Consulta con tu aparcamiento el sistema de aparcamientos y el número de aparcamiento)",
    detailDepot_00604: "3. Si no has atracado antes, puedes ponerte en contacto con la Empresa y solicitar un atraque.",
    detailDepot_00605: "Introducción a las marcas de sistemas de aparcamientos atracados",
    detailDepot_00606: "Asociaciones",
    detailDepot_00607: "Parking asociado",
    detailDepot_00608: "Identificador de aparcamiento",
    detailDepot_00609: "Cerrar asociación",
    detailDepot_00610: "Por favor, introduzca el ID del aparcamiento (solo se admiten números, letras y guiones bajos)",
    detailDepot_00611: "Filtrar pedidos con un tiempo de carga inferior a 10 minutos",
    detailDepot_00612: "¿Confirmación de cierre de asociación con {name}?",
    detailDepot_00613: "Reconsiderar",
    detailDepot_00614: "Confirmar",
    detailDepot_00615: "Estacionamiento asociado",
    detailDepot_00616: "Mensaje de aviso",
    detailDepot_00617: "No puede estar vacío",
    detailDepot_00618: "El ID del aparcamiento solo admite números, letras y guiones bajos.",
    // Open Flow + Government Matching
    detailDepot_00619: "Aquí solo se muestra el tráfico gubernamental al que el operador ya se ha conectado. Para acceder a más participantes del tráfico, comuníquese con la plataforma. Para obtener más información sobre el acceso, visite:",
    detailDepot_00620: "Plataforma gubernamental",
    detailDepot_00621: "Aquí solo se muestra el tráfico gratuito y el tráfico ya conectado al operador. Para acceder a más participantes del tráfico, comuníquese con la plataforma. Para obtener más información sobre el acceso, visite:",
    detailDepot_00622: "Centro de tráfico",
    detailDepot_00623: "Gratuito",
    detailDepot_00624: "Gobierno",
    detailDepot_00625: "Firma directa",
    detailDepot_00626: "Paquete total SmartCharge",
    detailDepot_00627: "Este flujo de datos ya es compatible con el código QR SmartCharge y debe actualizarse a un código QR fijo en los detalles del puesto de carga",
    detailDepot_00628: "Este flujo de datos no es compatible con el código QR SmartCharge, debe imprimir el código QR y pegarlo",
    detailDepot_00629: "Exportación por lotes de código QR",
    detailDepot_00630: "Configuración de tarifas",
    detailDepot_00631: "Descuento de estación",
    detailDepot_00632: "Configuración de inicio/parada",
    detailDepot_00633: "Rechazar apertura",
    detailDepot_00634: "Aceptar apertura",
    detailDepot_00635: "Abierto - sin inicio/parada",
    detailDepot_00636: "Abierto - se puede iniciar y detener",
    detailDepot_00637: "No abierto",
    detailDepot_00638: "Abierto",
    detailDepot_00639: "No hay puestos de carga habilitados en la estación, habilite al menos un puesto de carga",
    detailDepot_00640: "Información de la estación de carga interna",
    detailDepot_00641: "Código de país",
    detailDepot_00642: "Código de provincia y ciudad",
    detailDepot_00643: "Tarifas de estacionamiento",
    detailDepot_00644: "Tipo de estación",
    detailDepot_00645: "Estación del edificio",
    detailDepot_00646: "Número de espacios de estacionamiento",
    detailDepot_00647: "Número de teléfono de atención al cliente",
    detailDepot_00648: "Imagen en vivo",
    detailDepot_00649: "vacío",
    detailDepot_00650: 'Establezca "Abierto o no" en "Abierto" en [Detalles del sitio] - [Información del sitio] - [Información de estado].',
    detailDepot_00651: "Estación de carga",
    detailDepot_00652: "Carga inteligente",
    diversionDetail_00021: 'Operación',
    diversionDetail_00011: 'Operador',
    diversionDetail_00060: 'Plataforma de flujo de datos de terceros',
    diversionDetail_00012: 'Estado de la estación',
    diversionDetail_00013: 'Habilitado',
    diversionDetail_00014: 'No habilitado',
    diversionDetail_00068: 'Sí',
    diversionDetail_00069: 'No',
    diversionDetail_00072: 'Regresar',
    diversionDetail_00073: 'Modificado exitosamente',
    diversionDetail_00074: 'Por favor ingrese un número',
    diversionDetail_00075: 'Descuento de tarifa de servicio otorgado por el operador a SmartCharge (%)',
    diversionDetail_00076: 'Sin descuento en el costo total de una sesión de carga única (%)',
    diversionDetail_00077: 'Descuento en la tarifa del servicio de carga única (%)',
    diversionDetail_00078: '85 significa 15 % de descuento, 0 significa totalmente gratis',
    diversionDetail_00079: 'Carga única con descuento por kWh',
    diversionDetail_00080: 'Nombre para mostrar',
    diversionDetail_00081: 'Ingrese un nombre para mostrar, formato recomendado: nombre de marca (nombre del sitio de carga), la palabra clave "nombre de marca" debe eliminarse del nombre del sitio',
    diversionDetail_00082: 'Si se permiten acciones de inicio/parada de terceros',
    diversionDetail_00083: 'Permitir que solo ciertos usuarios carguen',
    diversionDetail_00084: 'Piénselo de nuevo',
    diversionDetail_00085: 'Lo entiendo',
    diversionDetail_00086: 'Ir a rellenar',
    diversionDetail_00087: 'El nombre no puede estar vacío',
    diversionDetail_00088: 'Apertura exitosa',
    diversionDetail_00089: 'Mensaje de aviso',
    diversionDetail_00090: 'Rellenar la información',
    diversionDetail_00091: 'Aceptar',
    diversionDetail_00092: 'Si se debe permitir que un tercero inicie o detenga el cargador',
    diversionDetail_00093: 'Configuración directa de la tarifa',
    diversionDetail_00094: 'Si se ha modificado la tarifa, haga clic en el botón "Aplicar tarifas" en Detalles del sitio - Tarifa para que la tarifa entre en vigor',
    diversionDetail_00095: 'Tarifas de CA',
    diversionDetail_00096: 'Seleccionar tarifa de CA',
    diversionDetail_00097: 'Tarifas de CC',
    diversionDetail_00098: 'Seleccionar tarifa de DC',
    diversionDetail_00099: 'Configuración de descuento del sitio',
    diversionDetail_00100: 'Agregar descuento en el sitio de carga',
    diversionDetail_00101: 'Hora de inicio',
    diversionDetail_00102: 'Ahora',
    diversionDetail_00103: 'Hora de finalización',
    diversionDetail_00104: 'ilimitado',
    diversionDetail_00105: 'Método preferido',
    diversionDetail_00106: '85 significa 15 % de descuento',
    diversionDetail_00107: 'Descuento por kWh ($)',
    diversionDetail_00108: 'Usar el método de descuento de tarifa de servicio',
    diversionDetail_00109: 'Usar el método de descuento por kWh',
    diversionDetail_00110: 'Estado',
    diversionDetail_00111: 'No iniciado',
    diversionDetail_00112: 'Iniciado',
    diversionDetail_00113: 'Validado',
    diversionDetail_00114: 'No válido',
    diversionDetail_00115: 'Historial de descuentos del sitio',
    diversionDetail_00116: '¡Guarde la operación anterior!',
    diversionDetail_00117: 'La hora de inicio no puede estar vacía',
    diversionDetail_00118: 'La hora de finalización no debe estar vacía',
    diversionDetail_00119: 'El método de preferencia no debe estar vacío',
    diversionDetail_00120: 'Ingrese un número entero positivo menor que 100',
    diversionDetail_00121: 'Guardado correctamente',
    diversionDetail_00122: 'Descuento de tarifa de servicio: {dis}%',
    diversionDetail_00123: 'Descuento por kWh: {dis}$',
    diversionDetail_00124: 'Rechazar la apertura de la información del sitio {name}, {name} no mostrará ninguna información del sitio.',
    diversionDetail_00125: '¿Confirmación de rechazar la apertura de la información del sitio {name}?',
    diversionDetail_10126: 'Enviar estado del dispositivo',
    diversionDetail_10127: 'Error al enviar el estado del dispositivo, ¡inténtelo de nuevo!',
    diversionDetail_10128: '¡Enviar el estado del dispositivo con éxito!',
    // Equipment Management - Equipment List
    diversionDetail_00126: 'Número de serie o nombre del cargador',
    diversionDetail_00127: 'Modelo',
    diversionDetail_00128: 'Estado',
    diversionDetail_00129: 'Disponible',
    diversionDetail_00130: 'Ver solo preparación',
    diversionDetail_00131: 'En uso',
    diversionDetail_00132: 'Ver solo acabado',
    diversionDetail_00133: 'Ver solo acabado',
    diversionDetail_00134: 'Agregar cargador',
    diversionDetail_00135: 'SN:',
    diversionDetail_00136: 'Fabricante - Modelo:',
    diversionDetail_00137: 'Detalles del cargador',
    diversionDetail_00138: 'Conector:',
    diversionDetail_00139: 'Desvío pendiente',
    diversionDetail_00140: 'Cita en curso',
    diversionDetail_00141: 'Conectado',
    diversionDetail_00142: 'Conectado',
    diversionDetail_00143: 'Transacciones',
    diversionDetail_00144: 'Cargador con activación automática',
    diversionDetail_00145: 'Después de seguir los pasos a continuación para activar automáticamente el cargador, puede agregar el cargador a la estación de carga.',
    diversionDetail_00146: 'Paso 1',
    diversionDetail_00147: 'Escanee el código QR a la izquierda con su WeChat móvil',
    diversionDetail_00148: "Consejos: Puede guardar la página en los favoritos de WeChat, para que pueda abrirla y usarla fácilmente en cualquier momento.",
    diversionDetail_00149: 'Paso 2',
    diversionDetail_00150: 'Ingrese el SN del cargador en el móvil, inicie la activación y confírmelo en el cargador.',
    diversionDetail_00151: 'Ingrese el número de serie completo',
    diversionDetail_00152: 'Buscar cargadores por ID de cargador',
    diversionDetail_00153: 'Este cargador se ha agregado, las adiciones posteriores se transferirán a la estación de carga actual y requerirán un reinicio antes de cargar',
    diversionDetail_00154: '(p. ej. AC #1)',
    diversionDetail_00155: 'Establezca el nombre del cargador en el campo',
    diversionDetail_00156: 'Cargador agregado exitosamente',
    diversionDetail_00157: 'Puede realizar las siguientes operaciones en Detalles de la estación - Gestión del cargador - Detalles del dispositivo:',
    diversionDetail_00158: '1. Modificar el nombre del dispositivo',
    diversionDetail_00159: '2. Cambiar el estado de activación',
    diversionDetail_00160: '3. Establezca si el bloqueo electromagnético y el radar están habilitados',
    diversionDetail_00161: 'Este cargador se ha agregado a la estación actual',
    diversionDetail_00162: 'Introduzca el número de serie completo del cargador',
    diversionDetail_00163: 'Complete la Inf. del cargador',
    diversionDetail_00164: 'Normal',
    diversionDetail_00165: 'Coche bloqueado',
    diversionDetail_00166: 'Inactivo',
    diversionDetail_00167: 'Desconocido',
    diversionDetail_00168: 'Insertar conector',
    diversionDetail_00169: 'Fin de carga',
    diversionDetail_00170: 'Ver solo los no devueltos',
    diversionDetail_00171: 'Devuelto',
    diversionDetail_00172: 'No en la posición de origen',
    // POS payment setup
    diversionDetail_00173: 'Configuración de pago POS',
    diversionDetail_00174: 'Aplicar configuración de pago POS',
    diversionDetail_00175: 'Solo los puestos de cobro con POS son compatibles con esta configuración',
    diversionDetail_00176: 'Monto preautorizado',
    diversionDetail_00177: 'Tipo de moneda de la estación según las tasas actuales',
    diversionDetail_00178: 'Aplicar confirmación',
    diversionDetail_00179: 'Tipo de moneda de la estación según las tasas actuales',
    diversionDetail_00180: 'Lista de puestos con POS instalados en esta estación',
    diversionDetail_00181: 'Estado de pago POS',
    diversionDetail_00182: 'Seleccionar todo',
    diversionDetail_00183: 'Número de serie del dispositivo',
    diversionDetail_00184: 'Fabricante-Modelo',
    diversionDetail_00185: 'Tipo de moneda del POS',
    diversionDetail_00186: 'Seleccione el dispositivo',
    diversionDetail_00187: 'Aplicación exitosa',
    diversionDetail_00188: 'Esperando respuesta',
    diversionDetail_00189: 'El tipo de moneda del POS del cargador no coincide con la configuración de la plataforma, verifique',
    diversionDetail_00190: "El tipo de moneda del POS en el cargador es {list}, hay un conflicto y se recomienda revisar el cargador incorrecto fuera de línea. Algunos cargadores con un tipo de moneda {default} diferente al de la plantilla de tarifas seleccionada de la estación no habilitarán los pagos POS. Por favor, verifique el tipo de moneda del cargador o cambie el tipo de moneda de las tarifas de la estación.",
    diversionDetail_00191: 'Usar moneda',
    diversionDetail_00192: 'Esta estación necesita seleccionar un tipo de tarifa de {type}, actualmente no hay tarifas de ese tipo, por favor, cree una nueva tarifa.',
    diversionDetail_00193: 'y',
    diversionDetail_00194: 'Por favor, ingrese el monto correcto a retener',
    diversionDetail_00195: 'Mensaje de aviso',
    diversionDetail_00196: 'Modificar tarifas',
    diversionDetail_00197: 'El tipo de moneda de liquidación de la estación de carga actual es diferente del tipo de moneda de las tarifas públicas',
    diversionDetail_00198: 'Por favor, vuelva a seleccionar las tarifas con el tipo de moneda {type}',
    diversionDetail_00199: 'Tipo de moneda de liquidación de la estación',
    diversionDetail_00200: 'El tipo de moneda de la estación actual no permite agregar tarifas y ofertas grupales en este momento.',
    diversionDetail_00201: 'Después de cambiar el tipo de moneda de la estación, cambie las tarifas públicas y de flota al mismo tipo de moneda y aplique las tarifas nuevamente',
    /* Public transport charging */
    detailDepot_00411: "Ingrese 0 para no tener restricciones",
    detailDepot_00412: "Configuración de información básica",
    detailDepot_00413: "Límite de potencia de carga total para toda la estación (KW)",
    detailDepot_00414: "Configuración de información básica",
    detailDepot_00415: "0 significa que no hay límite en la potencia de carga total",
    detailDepot_00416: "¿Está habilitada la carga ordenada?",
    detailDepot_00417: "Ingrese el valor correcto",
    detailDepot_00418: "Vehículos de autobús",
    detailDepot_00419: "Horario de línea",
    detailDepot_00420: "Potencia de limitación de grupo",
    detailDepot_00421: "Número de EV",
    detailDepot_00422: "MAC de EV",
    detailDepot_00423: "VIN de EV",
    detailDepot_00424: "Energía nominal total de la batería de potencia del vehículo (kW-h)",
    detailDepot_00425: "Grabando EV",
    detailDepot_00426: "Importar EV",
    detailDepot_00427: "Descargar plantilla de importación",
    detailDepot_00428: "Generar",
    detailDepot_00429: "No puede estar vacío",
    detailDepot_00430: "Solo se permiten dos decimales para la energía nominal total de la batería de potencia del vehículo completo",
    detailDepot_00431: "El parámetro MAC del vehículo es ilegal",
    detailDepot_00432: "Descargar formulario",
    detailDepot_00433: "¿Está seguro de que desea eliminar este EV (N.º de EV: {num})?",
    detailDepot_00434: "Opcional",
    detailDepot_00435: "Potencia total a limitar carga en grupo (KW)",
    detailDepot_00436: "¡Por favor, rellene todos los campos obligatorios!",
    detailDepot_00437: "Por favor, introduzca el valor correcto",
    detailDepot_00438: "Modificar grupo de distribución de potencia",
    detailDepot_00439: "Ver funcionamiento en directo",
    detailDepot_00440: "Importar",
    detailDepot_00441: "EV - Recomendación de espacio de carga",
    detailDepot_00442: "Descargar",
    detailDepot_00443: "Fecha de entrada en vigor",
    detailDepot_00444: "Actualmente en uso",
    detailDepot_00445: "Uso programado recientemente",
    detailDepot_00446: "Línea",
    detailDepot_00447: "Turnos",
    detailDepot_00448: "Hora de salida",
    detailDepot_00449: "Regreso hora",
    detailDepot_00450: "Código EV",
    detailDepot_00451: "SOC mínimo requerido en la salida (%)",
    detailDepot_00452: "Seleccione una fecha",
    detailDepot_00453: "Vigente de inmediato",
    detailDepot_00454: "Ajuste de frecuencia de vehículos",
    detailDepot_00455: "¿Está seguro de que desea eliminar el cronograma de ruta del vehículo?",
    detailDepot_00456: "Haga clic para cargar",
    detailDepot_00457: "Archivos importados",
    detailDepot_00458: "Importación de cronogramas de ruta del vehículo",
    detailDepot_00459: "",
    detailDepot_00460: "MAC2 EV (si el vehículo tiene una segunda MAC, introdúzcala aquí)",
    detailDepot_00461: "MAC2 EV",
    detailDepot_00462: "La dirección MAC no puede ser duplicada",
    detailDepot_00463: "Arranque automático del A/C",
    detailDepot_00464: "Configuración del arranque automático del aire acondicionado del vehículo",
    detailDepot_00465: "Tiempo de salida de la distancia de arranque automático del aire acondicionado (min)",
    detailDepot_00466: "¿Está habilitado el arranque automático del aire acondicionado del vehículo?",
    detailDepot_00467: "Después de modificar la configuración de Vehículos Bus, Horario de Línea, Tiempo de salida de la distancia de activación automática del aire acondicionado y ¿Está habilitado el encendido automático del aire acondicionado del vehículo?, es necesario presionar nuevamente la configuración.",
    detailDepot_00468: "Activar arranque automático del aire acondicionado.",
    detailDepot_00469: "¿Está seguro de presionar la configuración de arranque automático del aire acondicionado del vehículo?",
    detailDepot_00470: "Iniciar el aire acondicionado",
    detailDepot_00471: "Comando enviado",
    detailDepot_00472: "¡Los vehículos de autobús o el horario de la línea no pueden estar vacíos!",
    // Site Information
    detailDepot_10001: "Dirección de la estación",
    detailDepot_10002: "Ubicación en el mapa",
    detailDepot_10003: "Haz clic en el botón de la derecha para obtener la ubicación",
    detailDepot_10004: "Modificar ubicación",
    detailDepot_10005: "Código de provincia y ciudad",
    detailDepot_10006: "Código de país",
    detailDepot_10007: "Modelos compatibles",
    detailDepot_10008: "Tipo de estación",
    detailDepot_10009: "Teléfono de atención al cliente",
    detailDepot_10010: "Contacto de la estación",
    detailDepot_10011: "Número de móvil",
    detailDepot_10012: "Buzón de correo",
    detailDepot_10013: "Edificio de la estación",
    detailDepot_10014: "Ubicación de plazas de aparcamiento",
    detailDepot_10015: "Número de plazas de aparcamiento",
    detailDepot_10016: "Tarifas de aparcamiento",
    detailDepot_10017: "Imagen en directo",
    detailDepot_10018: "Haga clic para cargar la imagen",
    detailDepot_10019: "Solo se admiten imágenes PNG/JPG/GIF",
    detailDepot_10020: "No más de 1 M",
    detailDepot_10021: "Error al obtener la ubicación",
    detailDepot_10022: "Cerrar",
    detailDepot_10023: "Buscar",
    detailDepot_10024: "Resultados de la búsqueda",
    detailDepot_10025: "Arrastre el archivo aquí, o ",
    detailDepot_10026: "Haga clic para cargar",
    detailDepot_10027: "Introduzca el código de la ciudad",
    detailDepot_10028: "Formato de código de ciudad incorrecto (6 caracteres)",
    detailDepot_11026: "ID de estación",
    detailDepot_21026: "Verificar",
    // Equipment Management
    detailDepot_10029: "Lista de cargadores",
    detailDepot_10030: "Configuración del cargador",
    detailDepot_10031: "Configuración de pantalla",
    detailDepot_10032: "Lista blanca",
    detailDepot_10033: "Aplica la información de configuración después de cada modificación para garantizar que la pila de carga funcione correctamente",
    detailDepot_10034: "Aplicar configuración",
    detailDepot_10035: "Configuración de información local del cargador",
    detailDepot_10036: "Guardar",
    detailDepot_10037: "Editar",
    detailDepot_10038: "Configuración de la función del cargador",
    detailDepot_10039: "Configuración del factor de compensación",
    detailDepot_10040: "Configuración de la tarifa por inactividad",
    detailDepot_10041: "Restricciones",
    detailDepot_10042: "Sin restricciones",
    detailDepot_10043: "Éxito",
    detailDepot_10044: "Error",
    detailDepot_10045: "Aplicación en proceso",
    detailDepot_10046: "La configuración de pantalla incluye imágenes, texto y publicidad en la pantalla del cargador, según el modelo del cargador y el tamaño de la pantalla.",
    detailDepot_10047: "Configuración de la lista blanca",
    detailDepot_10048: "Si el cargador está fuera de línea, solo los usuarios de la lista blanca pueden comenzar a cargar.",
    detailDepot_10049: "Aplicar lista blanca",
    detailDepot_10050: "Borrar lista blanca",
    detailDepot_10051: "Ver lista blanca",
    // Tariff setting
    detailDepot_10052: "Tarifas públicas",
    detailDepot_10053: "Tarifas de flota",
    detailDepot_10054: "Aplique las tarifas establecidas al cargador para que este pueda facturar al usuario la carga según las nuevas tarifas. Nota: Es posible que los cargadores que no sean de Xcharge no admitan esta operación, lo que provocará un error en la aplicación",
    detailDepot_10055: "Aplicar tarifas",
    detailDepot_10056: "Primero cambie las tarifas predeterminadas",
    detailDepot_10057: "No hay cargadores en esta estación, no se puede aplicar",
    detailDepot_10058: "Error en la aplicación",
    detailDepot_10059: "Eliminado",
    detailDepot_10060: "Esta acción eliminará la flota de la lista. ¿Desea continuar?",
    detailDepot_10061: "Sugerencia",
    detailDepot_10062: "Lista de flotas",
    detailDepot_10063: "Añadir flota",
    detailDepot_10064: "Nuevas tarifas",
    detailDepot_10065: "Seleccionar flota",
    detailDepot_10066: "Por favor, introduzca el nombre de la flota",
    detailDepot_10067: "Número de usuarios",
    detailDepot_10068: "Tarifas",
    detailDepot_10069: "Por favor, seleccione tarifas",
    detailDepot_10070: "Selección de descuento",
    detailDepot_10071: "Sin descuento",
    detailDepot_10072: "Tarifa de servicio con descuento (%)",
    detailDepot_10073: "Ejemplo: Si establece una tarifa de servicio de carga única del 8% de descuento y el coste de carga es de 100 € (incluidos 70 € de electricidad y 30 € de servicio), el importe real que debe pagar esta vez es de 95,5 € (70+30*85%=95.5)",
    detailDepot_10074: "Ingrese 85 significa un descuento del 15% y 0 significa gratis",
    detailDepot_10075: "Descuento en tarifa de electricidad y tarifa de servicio (%)",
    detailDepot_10076: "Ejemplo: si establece el costo total de una carga única en un 20% de descuento y el costo total de esta carga es de €100, el monto real que debe pagar esta vez es €85 (100*85%=85)",
    detailDepot_10077: "Flota agregada exitosamente",
    detailDepot_10078: "¡Haga clic en el botón [Aplicar] para que las tarifas sean efectivas!",
    detailDepot_10079: "Las siguientes operaciones se pueden realizar en [Gestión de estaciones - Información de estaciones - TarifasEstablecer-Tarifas de flota]",
    detailDepot_10080: "1. Eliminar flota agregada",
    detailDepot_10081: "2. Agregar una nueva flota",
    detailDepot_10082: "Anterior",
    detailDepot_10083: "Siguiente",
    detailDepot_10084: "Seleccione una flota",
    detailDepot_10085: "Seleccione una tarifa",
    detailDepot_10086: "No se puede agregar una flota repetidamente",
    detailDepot_10087: "Establezca el parámetro de descuento",
    detailDepot_10088: "Ingrese un número entero positivo menor que 100",
    detailDepot_10089: "Seleccione tarifas y descuento",
    detailDepot_10090: "Sin descuento en el costo total de un único punto exitoso (%)",
    detailDepot_10092: "Períodos sin cargo por inactividad",
    detailDepot_10093: "Sin períodos sin cargo por inactividad",
    detailDepot_10094: "Hay un período de exención de cargo por inactividad",
    detailDepot_10095: "El intervalo de tiempo es el intervalo de tiempo local donde se encuentra la estación, se pueden agregar hasta 5 intervalos de tiempo",
    detailDepot_10096: "Aquí se pueden establecer períodos de inactividad sin cargo, según las necesidades del operador de la estación",
    detailDepot_10097: "¡Hay una superposición en el período de tiempo!",
    detailDepot_10098: "Complete el período de cargo por ocupación",
    detailDepot_10099: "La hora de finalización debe ser mayor que la hora de inicio",
    detailDepot_10100: "Agregar intervalo de tiempo",
    detailDepot_10101: "Error/Fuera de línea",
    detailDepot_10102: "De acuerdo con el valor promedio de 30 días, el saldo actual puede demorar {num} días",
    detailDepot_10103: "El tiempo personalizado es el mismo que la opción anterior, seleccione esta opción directamente o complete el tiempo personalizado nuevamente",
    //储能
    detailDepot_10104: "Selección del modo de funcionamiento de NZS",
    detailDepot_10105: "Modo automático",
    detailDepot_10106: "Modo ecológico",
    detailDepot_10107: "Modo máximo",
    detailDepot_10108: "Utilice dispositivos de almacenamiento de energía para llenar el vacío de potencia de carga y llenar rápidamente el vehículo",
    detailDepot_10109: "El almacenamiento de energía a precios de electricidad bajos y los servicios a precios de electricidad altos reducen los costos de electricidad",
    detailDepot_10110: "El sistema equilibra automáticamente los requisitos de energía y costo",
    detailDepot_10111: "Más información",
    detailDepot_10112: "Modo de funcionamiento del aire acondicionado NZS",
    detailDepot_10123: "Modelo económico",
    detailDepot_10124: "Modo eficiente",
    detailDepot_10125: "Tarifas de red",
    detailDepot_10126: "Nuevas tarifas de red",
    detailDepot_10127: "Tarifas de carga",

    detailDepot_10128: "Tarifas de red",
    detailDepot_10129: "Precio por tipo de período",
    detailDepot_10130: 'Tarifa de electricidad de red ({type}/{unit})',
    detailDepot_10131: 'anterior',
    detailDepot_10132: 'siguiente',
    detailDepot_10133: 'Seleccione el tipo de período',
    detailDepot_10134: 'Superpico',
    detailDepot_10135: 'Pico',
    detailDepot_10136: 'Fuera de pico',
    detailDepot_10137: 'Superfuera de pico',
    detailDepot_10138: 'Tipo de período',
    //配置检查
    detailDepot_10139: '1. Verificar el estado del hardware del dispositivo, lo que lleva decenas de segundos',
    detailDepot_10140: '2. Esta verificación requiere que el número de versión del firmware de la aplicación Android para el cargador sea superior a 3.0.0.',
    detailDepot_10141: '3. Esta comprobación no es compatible con dispositivos de terceros.',
    detailDepot_10142: "Iniciar inspección",
    detailDepot_10143: "Espere durante la autoinspección",
    detailDepot_10144: "Reinspección",
    detailDepot_10145: "Tiempo de inspección",
    detailDepot_10146: "Estado de la inspección",
    detailDepot_10147: "Vida útil del hardware",
    detailDepot_10148: "Elementos de autoinspección",
    detailDepot_10149: "Confirmar y recuperarse de una parada de emergencia",
    detailDepot_10150: "El cargador está en uso, la autoinspección se canceló, opere después de que esté inactivo",
    detailDepot_10151: "El cargador actual está fuera de línea",
    detailDepot_10152: "Se agotó el tiempo de autoinspección del cargador, inténtelo de nuevo",
    detailDepot_10153: "Más deficiente",
    detailDepot_10154: "Moderado",
    detailDepot_10155: "Mejor",
    detailDepot_10156: "Esta inspección requiere que el número de versión del firmware de la aplicación Android para el cargador sea 3.0.0 o superior",
    detailDepot_10157: "No se encontró el resultado de la última autoinspección",
    detailDepot_10158: "Recuperación inmediata",
    detailDepot_10159: "Primero verifique el cargador y luego considere la recuperación",
    detailDepot_10160: "Descargo de responsabilidad",
    detailDepot_10161: "Para garantizar el funcionamiento seguro del cargador, le recomendamos que realice una verificación de hardware antes de restaurar el botón de parada de emergencia. Si encuentra alguna falla de hardware o peligro de seguridad, deje de usar el cargador y comuníquese con nuestro equipo de soporte técnico. Le ofreceremos una solución lo antes posible para garantizar que su cargador pueda funcionar de forma segura y estable.",
    detailDepot_10162: "Tenga en cuenta que este aviso legal tiene como objetivo recordarle que elegir restaurar directamente el botón de parada de emergencia sin determinar primero el estado del cargador puede implicar ciertos riesgos y no seremos responsables de las consecuencias resultantes.",
    detailDepot_10163: "Confirmar recuperación directa",
    detailDepot_10164: "Las siguientes configuraciones de dispositivo son inconsistentes",
    detailDepot_10165: "Las siguientes configuraciones de dispositivo son consistentes",
    detailDepot_10166: "No se encontró ningún dispositivo que admita la inspección en el sitio",
    detailDepot_10167: "Se agotó el tiempo de respuesta, inténtelo de nuevo",
    detailDepot_10168: "Verificación de configuración",
    // add
    accountManage_00002: "Cuenta",
    accountManage_00003: "Propietario",
    accountManage_00004: "Móvil",
    accountManage_00005: "Rol",
    accountManage_00006: "Agregar cuenta",
    accountManage_00007: "Operación",
    accountManage_00008: "Ámbito",
    accountManage_00009: "Agregar cuenta",
    accountManage_000010: "Lista de cuentas",
    accountManage_000011: "Editar cuenta",
    accountManage_000012: "Cuenta para iniciar sesión en la plataforma de operaciones",
    accountManage_000013: "Contraseña",
    accountManage_000014: "Contraseña para iniciar sesión en la plataforma de operaciones",
    accountManage_000015: "Se puede ingresar un máximo de 20 caracteres pueden ser letras y números",
    accountManage_000016: "Propietario",
    accountManage_000017: "Use su nombre real para facilitar el seguimiento",
    accountManage_000018: "Celular",
    accountManage_000019: "Para que otros se pongan en contacto con el administrador",
    accountManage_000020: "Rol",
    accountManage_000021: "Administrador de CPO",
    accountManage_000022: "Seleccionar",
    accountManage_000023: "Seleccione un rol de cuenta",
    accountManage_000024: "Operación exitosa",
    accountManage_000025: "Ingrese el nombre de la estación de carga",
    accountManage_000026: "La estación de carga se agregó exitosamente. Procedimiento",
    accountManage_000027: "Se ha seleccionado la estación de carga",
    accountManage_000028: "Eliminación exitosa",
    accountManage_000029: "Superadministrador",
    accountManage_000030: "¿Está seguro de que desea eliminarla?",
    accountManage_000031: "Eliminación cancelada",
    accountManage_000032: "Hora de agregar",
    accountManage_000033: "Correo electrónico",
    detailDepot_11051: 'El número máximo de puertos de carga admitidos por la plataforma ha alcanzado el límite superior. Vaya a la página "Plataforma operativa - Servicio de apertura" para ampliar el número de puertos de carga admitidos por la plataforma',
    detailDepot_11052: "Estación de energía",
    detailDepot_11152: "¡Error al agregar! La suma de la potencia máxima de carga fuera de línea de todos los modelos de puertos de carga x la cantidad de puertos de carga en el grupo debe ser menor o igual al valor mínimo en la configuración de la potencia de carga total limitada en el grupo",
    detailDepot_11053: "Batería NZS",
    //百度翻译 小橘
    detailDepot_20001: "Tendencias de negocio",
    detailDepot_20002: "Análisis de ingresos",
    detailDepot_20003: "Análisis de capacidad de carga",
    detailDepot_20004: "Análisis de eficiencia",
    detailDepot_20005: "Análisis de usuarios",
    detailDepot_20006: "Análisis",
    detailDepot_20007: "Tendencias de ingresos totales",
    detailDepot_20008: "Cargo por servicio (yuan)",
    detailDepot_20009: "Cargo por electricidad (yuan)",
    detailDepot_20010: "Ingresos (yuan)",
    detailDepot_20011: "Proporción de ingresos de usuarios",
    detailDepot_20012: "Proporción de tráfico de terceros",
    detailDepot_20013: "Tendencias de ingresos de usuarios",
    detailDepot_20014: "Tendencias de ingresos por tráfico de terceros",
    detailDepot_20015: "Tendencia de la tasa de utilización de la electricidad",
    detailDepot_20016: "Tendencia de utilización a largo plazo",
    detailDepot_20017: "Tasa de utilización = carga diaria/carga diaria a plena carga<br/>carga diaria a plena carga = potencia nominal de la pila de carga * 24 horas",
    detailDepot_20018: "Tasa de utilización = tiempo de carga diario del conector de carga/24 horas",
    detailDepot_20019: "Tasa de utilización de la carga de equipos de CA (%)",
    detailDepot_20020: "Tasa de utilización de la carga de equipos de CC (%)",
    detailDepot_20021: "Capacidad de carga (grado)",
    detailDepot_20022: "Tendencia de la capacidad de carga",
    detailDepot_20023: "Proporción de carga de la fuente del usuario",
    detailDepot_20024: "Tendencia de la capacidad de carga de la fuente de usuario",
    detailDepot_20025: "Tendencia de la cantidad de carga de flujo de terceros",
    detailDepot_20026: "Tendencia de usuarios",
    detailDepot_20027: "Proporción de órdenes de carga de los usuarios",
    detailDepot_20028: "Número de usuarios que cargan",
    detailDepot_20029: "Número de usuarios que cargan por primera vez",
    detailDepot_20030: "Tendencia de órdenes de carga de los usuarios",
    detailDepot_20031: "Tendencia del número de órdenes de carga de flujo de terceros",
    detailDepot_20032: "Tendencia de ingresos por conector",
    detailDepot_20033: "Tarifa por inactividad ({0})",
    detailDepot_20034: "Tendencia de capacidad de carga promedio de 0 a 24 horas",
    detailDepot_20035: "Capacidad de carga por conector",
    detailDepot_20036: "Grado por conector",
    detailDepot_20037: "Capacidad de carga por conector",
    detailDepot_20038: "Cantidad única por conector",
    detailDepot_20039: "Número de pedidos por conector",
    detailDepot_20040: "Única",
    detailDepot_20041: "Número de órdenes de carga",
    detailDepot_20042: "Cargo por servicio por conector ({0})",
    detailDepot_20043: "Cargo por electricidad por conector ({0})",
    detailDepot_20044: "Tarifa por inactividad por conector ({0})",
    detailDepot_20045: "Tasa de utilización=carga diaria/carga diaria a plena carga<br/>carga diaria a plena carga=potencia nominal de la pila de carga * 24 horas",
    detailDepot_20046: "Tasa de utilización=tiempo de carga diaria del conector de carga/24 horas",
    detailDepot_20047: "Ingresos totales ({0})",
    detailDepot_20048: "Cargo por servicio total ({0})",
    detailDepot_20049: "Cargo por electricidad total ({0})",
    detailDepot_20050: "Tarifa por inactividad total ({0})",
    detailDepot_20051: "Ingresos totales de fuentes de desvío de terceros ({0})",
    detailDepot_20052: "Cargo total (kWh)",
    detailDepot_20053: "Capacidad total de carga de fuentes de autogestión y franquicia (grado)",
    detailDepot_20054: "Capacidad total de carga de fuentes de desvío de terceros (grado)",
    detailDepot_20055: "Número total de usuarios de carga (persona)",
    detailDepot_20056: "Número total de órdenes de carga (individuales)",
    detailDepot_20057: "Número total de primeras órdenes de carga (individuales)",
    detailDepot_20058: "Número total de órdenes de carga (órdenes) de autogestión y franquicia",
    detailDepot_20059: "Total de órdenes de carga de desvío de terceros (individuales)",
    // 能源微网设置
    detailDepot_30000: "Después de cada modificación, la información de configuración debe enviarse para garantizar el funcionamiento normal del sistema de hardware de la microred. Los parámetros configurados aquí se enviarán en lotes al equipo de la microred que puede ejecutar los parámetros en la estación.",
    detailDepot_30001: "Configuración de la batería de NZS",
    detailDepot_30002: "Límite mínimo de SOC de parada de descarga de la batería de NZS",
    detailDepot_30003: "Esto es para evitar la interrupción de la red eléctrica o la descarga excesiva de la batería",
    detailDepot_30004: "Límite máximo de SOC de parada de carga de la batería de NZS",
    detailDepot_30005: "Esto es para evitar que la batería se sobrecargue debido a la degradación de la batería",
    detailDepot_30006: "Si hay un equipo de carga de NZS en la estación, configure esta opción en 'Sí', puede ver los cambios de operación e ingresos generados por NZS a la estación en el 'Centro de energía'",
    detailDepot_30007: "",
    detailDepot_30010: "Aplicar configuración",
    detailDepot_30011: "Complete el formulario por completo",
    detailDepot_30012: "Plataforma dual",
    detailDepot_40000: "¿Admite carga inicial con VIN?",
    detailDepot_40001: "Si el dispositivo de la estación admite el VIN como la identidad para enchufar y cargar, después de esta configuración, la estación puede admitir el VIN como verificación de identidad cuando el vehículo comienza a cargarse. Después de la verificación, se puede iniciar la carga. Escenario de aplicación Ejemplo: Use el modo de tarjeta de enchufar y cargar para admitir la función de enchufar y cargar en la estación. Establezca este parámetro en Sí. ",
    detailDepot_40002: "Configuración de carga de la tarjeta de carga cuando el dispositivo está fuera de línea",
    detailDepot_40003: "Permitir todo",
    detailDepot_40004: "Lista blanca permitida",
    detailDepot_40005: "Cuando está fuera de línea, la tarjeta de carga inicia el modo de carga",
    detailDepot_40006: "Agregar dispositivos por lotes",
    detailDepot_40007: "Ingrese el número de serie completo del dispositivo. Admite varias entradas de SN de dispositivo, con un máximo de 50 entradas.",
    detailDepot_40008: "Un SN de dispositivo por línea, no ingrese símbolos, letras, espacios, etc. fuera del SN.",
    detailDepot_40009: "Ingrese el nombre del dispositivo",
    detailDepot_40010: "Verificación fallida",
    detailDepot_40011: "Verificación exitosa",
    detailDepot_40012: "Verifique si el SN del dispositivo es correcto",
    detailDepot_40013: "Se puede ingresar un máximo de 50",
    detailDepot_40014: "Otra estación agregó el SN del dispositivo",
    detailDepot_40015: "Se excedió el límite de boca",
    detailDepot_40016: "Ingrese el nombre del dispositivo",
    detailDepot_40017: "{0}",
    detailDepot_40018: "Un total de Se ingresaron {0} números de serie de dispositivos, {1} se verificó correctamente y {2} falló",
    detailDepot_40019: "Agregar dispositivos",
    detailDepot_40020: "¡Se agregaron {0} dispositivos correctamente!",
    detailDepot_51000: "Establezca el porcentaje",
    detailDepot_51001: "El monto de pago real no superará el monto preautorizado establecido aquí, incluida la tarifa de electricidad, la tarifa de servicio, la tarifa por inactividad, los impuestos, etc.",
    //设备列表自检
    eqDetails_30003: "Autoinspección HW",
    eqDetails_30004: "1. Verificar el estado del hardware del dispositivo, lo que lleva decenas de segundos",
    eqDetails_30005: "2. Esta verificación requiere que el número de versión de firmware de la aplicación Android para el cargador sea superior a 3.0.0",
    eqDetails_30006: "3. Esta verificación no es compatible con dispositivos de terceros",
    eqDetails_30007: "Resultado de la última inspección",
    eqDetails_30008: "Iniciar inspección",
    eqDetails_30009: "Espere durante la autoinspección",
    eqDetails_30010: "Reinspección",
    eqDetails_30011: "Tiempo de inspección",
    eqDetails_30012: "Inspección de estado",
    eqDetails_30013: "Vida útil del hardware",
    eqDetails_30014: "Elementos de autoinspección",
    eqDetails_30015: "Confirmar y recuperarse de una parada de emergencia",
    eqDetails_30016: "El cargador está en uso, la autoinspección se canceló, opere después de que esté inactivo",
    eqDetails_30017: "El cargador actual está fuera de línea",
    eqDetails_30018: "Se agotó el tiempo de autoinspección del cargador, intente nuevamente",
    eqDetails_30020: "Más deficiente",
    eqDetails_30021: "Moderado",
    eqDetails_30022: "Mejor",
    eqDetails_30023: "Esta inspección requiere que el número de versión de firmware de la aplicación Android para el cargador sea 3.0.0 o superior",
    eqDetails_30024: "No se encontró el resultado de la última autoinspección",
    eqDetails_30025: "Recuperación inmediata",
    eqDetails_30026: "Primero verifique el cargador, luego considere la recuperación",
    eqDetails_30027: "Descargo de responsabilidad",
    eqDetails_30028: "Para garantizar el funcionamiento seguro del cargador, le recomendamos que realice una verificación de hardware antes de restaurar el botón de parada de emergencia. Si encuentra alguna falla de hardware o peligro de seguridad, deje de usar el cargador y comuníquese con nuestro equipo de soporte técnico. Brindaremos una solución lo antes posible para garantizar que su cargador pueda funcionar de manera segura y estable.",
    eqDetails_30029: "Tenga en cuenta que este descargo de responsabilidad tiene como objetivo recordarle que elegir restaurar directamente el botón de parada de emergencia sin determinar primero el estado del cargador puede implicar ciertos riesgos y no seremos responsables de las consecuencias resultantes",
    eqDetails_30030: "Confirmar recuperación directa",
    eqDetails_30031: "Elementos de autocomprobación",
    eqDetails_30032: "Estado",
    eqDetails_30033: "{msg},{code},la autoinspección está cancelada, opere después del mantenimiento",
    eqDetails_30034: "Esta información se mostrará en la pantalla final de la pila cuando la pila de carga funcione mal",
    eqDetails_30035: "Número de teléfono de contacto de la estación",
    eqDetails_30036: "Puede modificar esta información en la sección 'Información de la estación' de la persona de contacto de la estación",
};