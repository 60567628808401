export default {
  errorLog_00001: 'Seleccionar estación',
  errorLog_00002: 'Seleccionar modelo',
  errorLog_00003: 'Hora de inicio',
  errorLog_00004: 'Hora de finalización',
  errorLog_00005: '-',
  errorLog_00006: 'Ingrese SN',
  errorLog_00007: 'Hora del informe',
  errorLog_00008: 'Clasificación de registros',
  errorLog_00009: 'Todos',
  errorLog_00010: "Error del cargador",
  errorLog_00011: "Cargador anormal",
  errorLog_00012: 'Sin conexión',
  errorLog_00013: 'Ingrese el código de error',
  errorLog_00014: 'Descripción',
  errorLog_00015: 'Código de error',
  errorLog_00016: 'Más información',
  errorLog_00017: 'Estación',
  errorLog_00018: 'CPO',
  errorLog_00019: 'Hora restaurada',
  errorLog_00020: 'Restaurado',
  errorLog_00021: 'No restaurado',
  errorLog_00022: 'Si se debe restaurar',
  errorLog_00023: ' No restaurado',
  errorLog_00024: 'Restaurado',
  errorLog_00025: "Error del cargador: no se puede usar normalmente. Cargador anormal: provoca la interrupción de la carga. El cargador puede seguir utilizándose",
  errorLog_00026: "Error de almacenamiento de energía"
}
