<template>
  <div class="login_Wrap">
    <div class="box">
      <div class="left">
        <span :class="['de','en','es'].includes(lang)?'nowrap':''">
          <span v-html="$t('login_00009')"></span>
        </span>
        <p :class="lang=='de'?'nowrap_p':''" v-if="product_area == 'cn'">{{operatorName}}{{$t('login_00010')}}<span>{{operatorName}}{{$t('login_00011')}}</span></p>
        <p :class="lang=='de'?'nowrap_p':''" v-else>{{$t('login_00010')}}<span>{{$t('login_00011')}}</span></p>
        <p v-if="product_area == 'eu'" class="statement"><a href="https://www.xcharge.com/statement" target="_blank">{{$t('common_45010')}}</a></p>
        <p v-if="product_area == 'cn' && operatorName == '智充'" class="statement cn"><a href="./saasAgreement.html" target="_blank">智充运营商平台隐私政策</a></p>
        <p class="ICP" v-if="product_area=='cn'">{{recordNum}}</p>
      </div>
      <div :class="['right',lang=='de'?'nowrap_form':'']">
        <div v-if="product_area == 'eu'" class="select-language">
          <el-select v-model="language" @change="selectLanguage" :placeholder="$t('select_language_tip')">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <p class="title">{{$t('login_00012')}}</p>
        <el-form class="form_box" label-position="top" label-width="80px">
          <el-form-item :label="$t('login_00013')">
            <el-input :placeholder="$t('login_00014')" v-model="username" class="inp"></el-input>
          </el-form-item>
          <el-form-item :label="$t('login_00015')">
            <el-input :placeholder="$t('login_00016')" v-model="password" :type="flag?'text':'password'" class="inp">
              <img slot="suffix" :src="flag?require('../../assets/img/login/eyes.png'):require('../../assets/img/login/eye.png')" @click="flag=!flag"></img>
            </el-input>
            <div class="reset">
              <a href="./resetPassword.html" target="_blank">{{$t('login_00033')}}</a>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn" @click="normal_login">{{$t('login_00004')}}</el-button>
          </el-form-item>
          <div class="tips">
            <p v-if="operatorName == '智充'">{{$t('login_00017')}}<span @click="toReg('reg')">{{$t('login_00018')}}</span></p>
            <p><span @click="toReg('add')">{{$t('login_00019')}}</span></p>
          </div>
        </el-form>
      </div>
    </div>
    <dilaog-component :flag_normal="flag_normal" :type="type" :product_area="product_area" @cancel="(v) => (flag_normal = v)" />
    <agreement v-if="agreementFLAG" :visible="agreementFLAG" @cancel="(v) => (agreementFLAG = v)" @handleCheck="getAgreementState"/>
    <PasswordSecurity :passwordSecurityFlag="passwordSecurityFlag" :lang="lang" :passwordSecurity="{ username, password }" @cancel="(v) => (passwordSecurityFlag = v)" @confirm="normal_login"/>
  </div>
</template>

<script>
import { requestUrl,operator,record} from "@/assets/utils/constants";
import { ajax } from "@/assets/utils/network";
import dilaogComponent from "./common/dialog.vue";
import agreement from "@/components/agreement";
import PasswordSecurity from "@/components/passwordSecurity";

export default {
  components: { dilaogComponent,agreement,PasswordSecurity },
  data() {
    return {
      operatorName: '',
      recordNum: '',
      agreementFLAG: false,
      lang: "",
      product_area: "",
      flag: false, // 密码
      username: "",
      password: "",
      language: "",
      options: [
        {
          value: "zh",
          label: "中文",
        },
        {
          value: "de",
          label: "Deutsch",
        },
        {
          value: "en",
          label: "English",
        },
        {
          value: "es",
          label: "Español"
        }
      ],
      type: "reg",
      flag_normal: false,
      passwordSecurityFlag: false,
    };
  },
  methods: {
    getAgreementState(val) { 
      if (val) {
        const user = {
          loginId : this.username,
          password: this.password,
          contractSigningTime : this.formatTime(new Date())
        }
        ajax({
          type: "POST",
          url: requestUrl + "/login",
          data: JSON.stringify(user),
          dataType: "json",
          success: (response)=> {
            const info = JSON.parse(response);
            if (info.error != null) {
              this.$message.error(
                info.error.msg == null ? info.error.code : info.error.msg
              );
              return false;
            }
            if (info.result != null) {
              if (info.result.isSupervisor) {
                document.cookie = `spvid=${info.result.resources[0].resource}`;
              }
              window.location.href = "./preview.html";
            }
          },
        });
      }
    },
    /*
     * 获取普通登录的开关状态
     */
    selectLanguage(value) {
      console.log(value);
      this.$i18n.locale = value;
      window.localStorage.lang = value;
      window.location.reload();
    },
    toReg(val) {
      if (this.product_area == "eu") {
        window.location.href = `./registerInternational.html?selectedLevel=${
          val == "add" ? 2 : 1
        }`;
        return;
      }
      this.type = val;
      this.flag_normal = true;
    },
    normal_login(val) {
      if (this.username == "" || this.password == "") {
        this.$message.error(this.$t("login_00005"));
        return false;
      }
      var user = new Object();
      user.loginId = this.username;
      user.password = this.password;
      // #10825
      if(val.type==='passwordSecurity'){
        user.password = val.password;
        user.newpassword = val.confirmPassword;
      }
      ajax({
        type: "POST",
        url: requestUrl + "/login",
        data: JSON.stringify(user),
        dataType: "json",
        success:  (response)=> {
          var info = JSON.parse(response);
          if (info.error != null) {
            if (info.error.code == "NEED_CONTRACT_SIGNING") {
              this.agreementFLAG = true;
              return false
            }
            if (info.error.code == "ERROR_CONTRACT_SIGNING") {
              this.$message.error(this.$t("login_00034"));
              return false
            }
            if (info.error.code == "ERROR_NEED_CONTRACT_SIGNING") {
              this.$message.error(this.$t("login_00035"));
              return false
            }
            if (info.error.code == "NEED_UPDATE_PASSWORD") {
              this.passwordSecurityFlag = true;
              return false
            }
            if (info.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            this.$message.error(
              info.error.msg == null ? info.error.code : info.error.msg
            );
            return false;
          }
          if (info.result != null) {
            // #10825
            if(val.type==='passwordSecurity'){
              this.passwordSecurityFlag = false;
              return false
            }
            if (info.result.isSupervisor) {
              document.cookie = `spvid=${info.result.resources[0].resource}`;
            }
            if (info.result.idType == "userGroup") {
              window.location.href = "./groupBaseInfo.html";
            } else {
              window.location.href = "./preview.html";
            }
          }
        },
        fail: function (response) {
          this.$message.error(response);
        },
      });
    },
    //获取当前时分秒
    formatTime(date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
      return year + month + day + hours + minutes + seconds;
    }
  },
  created: function () {
    window.document.title = this.$t("login_00008");
    this.product_area = product_area;
    this.operatorName = operator;
    this.recordNum = record;
    var lang = window.localStorage.lang;
    if (!lang) {
      var language = navigator.language.slice(0, 2);
      if (
        language === "ch" ||
        language === "en" ||
        language === "de" ||
        language === "es"
      ) {
        lang = language;
      } else {
        if (this.product_area == "eu") {
          lang = "en";
          window.localStorage.lang = 'en';
        } else {
          lang = "zh";
        }
      }
    }
    this.lang = window.localStorage.lang;
    this.language = lang;
  },
  mounted() {
    document.onkeydown = (e) => {
      var key = window.event.keyCode;
      if (key == 13) {
        this.normal_login();
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/reset.css";
@import "./style.scss";
@import "../../assets/font/iconfont.css";
</style>
<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 111111s;
  transition: color 11111s ease-out, background-color 111111s ease-out;
}
</style>