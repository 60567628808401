export default {
    eventlogsnzs_00001: "Lista de facturas",
    eventlogsnzs_00002: "Exportar pedidos",
    eventlogsnzs_00003: "Nº de factura",
    eventlogsnzs_00004: "Fecha de inicio",
    eventlogsnzs_00005: "Fecha de finalización",
    eventlogsnzs_00006: "restablecer",
    eventlogsnzs_00007: "buscar",
    eventlogsnzs_00008: "Estado de la factura",
    eventlogsnzs_00009: "todos",
    eventlogsnzs_00010: "Tipo de factura",
    eventlogsnzs_00011: "Cargando",
    eventlogsnzs_00012: "a pagar",
    eventlogsnzs_00013: "pagado",
    eventlogsnzs_00014: "Bloqueo directo al suelo del extremo del pilote",
    eventlogsnzs_00015: "Bloqueo directo al suelo de la plataforma",
    eventlogsnzs_00016: "Importe de la factura (€)",
    eventlogsnzs_00017: "Número de factura de ocupación",
    eventlogsnzs_00018: "Duración de la ocupación (min)",
    eventlogsnzs_00019: "Pilona de carga - puerto de carga",
    eventlogsnzs_00020: "Hora de inicio de la ocupación",
    eventlogsnzs_00021: "Hora de finalización de la ocupación",
    eventlogsnzs_00022: "Duración de la carga",
    eventlogsnzs_00023: "día",
    eventlogsnzs_00024: "hora",
    eventlogsnzs_00025: "minuto",
    eventlogsnzs_00026: "Usuarios de WeChat",
    eventlogsnzs_00027: "grupo",
    eventlogsnzs_00028: "Tarjeta de valor almacenado sin conexión",
    eventlogsnzs_00029: "Tarjeta de identificación en línea",
    eventlogsnzs_00030: "Importe actual de la factura",
    eventlogsnzs_00031: "Importe real del pago",
    eventlogsnzs_00032: "Detalles de la factura",
    eventlogsnzs_00033: "Propinas",
    eventlogsnzs_00034: "Esta operación descargará el archivo de Excel de la factura. ¿Desea continuar?",
    eventlogsnzs_00035: "confirmar",
    eventlogsnzs_00036: "cancelar",
    eventlogsnzs_00037: "Iniciar descarga",
    eventlogsnzs_00038: "Cancelar descarga",
    eventlogsnzs_00039: "Apodo de usuario, número de teléfono móvil y número de matrícula",
    eventlogsnzs_00040: "apodo",
    eventlogsnzs_00041: "teléfono",
    eventlogsnzs_00042: "número de matrícula",
    eventlogsnzs_00043: "Importe total de la factura",
    eventlogsnzs_00044: "Registro de transacciones de almacenamiento de energía",
    eventlogsnzs_00045: "",
    billDetail_00025: "Curva de carga",
    billDetail_00026: "Tensión de demanda del vehículo (línea discontinua)",
    billDetail_00027: "Corriente de demanda del vehículo (línea discontinua)",
    billDetail_00028: "Tensión medida del vehículo (línea discontinua)",
    billDetail_00029: "Corriente medida del vehículo (línea discontinua)",
    billDetail_00030: "Carga inicial de la batería",
    billDetail_00031: "Carga actual de la batería",
    billDetail_00032: "Potencia instantánea",
    billDetail_00033: "Potencia promedio",
    billDetail_00034: "Potencia máxima",
    billDetail_00035: "Modelo del vehículo",
    billDetail_00036: "Potencia",
    billDetail_00037: "Corriente",
    billDetail_00038: "Voltaje",
    billDetail_00039: "Energía acumulada",
    billDetail_00042: "kWh",
    bill_00059: "hoy",
    bill_00060: "hace 7 días",
    bill_00061: "hace 15 días",
    bill_00077: "ayer",
    bill_00078: "hace 30 días",
  }