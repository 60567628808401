export default {
  userCharge_00001: "Nombre del grupo",
  userCharge_00002: "Registro de recargas",
  userCharge_00003: "Información básica",
  userCharge_00004: "Fecha de recarga",
  userCharge_00005: "Monto total de la recarga({0})",
  userCharge_00006: "Monto principal({0})",
  userCharge_00007: "Monto de la recarga({0})",
  userCharge_00008: "Método de pago",
  userCharge_00009: "Usuario operativo",
  userCharge_00010: "Saldo({0})",
  userCharge_00011: "Historial de recargas",
  userCharge_00012: "Incluir bonus{promotionBalance}{unit}",
  userCharge_00013: "Hasta",
  userCharge_00014: "Fecha de inicio",
  userCharge_00015: "Fecha de finalización",
  userCharge_00016: "Plataforma",
  userCharge_00017: "Cuenta oficial de WeChat (WeChat Pay)",
  userCharge_00018: "Cuenta oficial de WeChat (Alipay)",
  userCharge_00019: "Otro",
  userCharge_00020: "",
  userCharge_00021: "",
  userCharge_00022: "",
  userCharge_00023: "",
  userCharge_00024: "",
  userCharge_00025: "",
  userCharge_00026: "",
  userCharge_00027: "",
  userCharge_00028: "",
  userCharge_00029: "",
  userCharge_00030: "",
  }