export default {
    contrastCurve_00001: 'Curvas de carga',
    contrastCurve_00002: 'Centro de pedidos',
    contrastCurve_00003: 'Pedido de carga completa',
    contrastCurve_00004: 'Detalles del pedido',
    contrastCurve_00005: 'Curvas de carga',
    contrastCurve_00006: 'Información del pedido',
    contrastCurve_00007: 'Información del pedido',
    contrastCurve_00008: 'Pedido actual',
    contrastCurve_00009: 'Apilar pedidos para el período',
    contrastCurve_00010: 'Kwh',
    contrastCurve_00011: 'Grado',
    contrastCurve_00012: 'Ver detalles del pedido',
    contrastCurve_00013: 'Potencia',
    contrastCurve_00014: 'Voltaje',
    contrastCurve_00015: 'Voltaje de demanda',
    contrastCurve_00016: 'Electricidad',
    contrastCurve_00017: 'Electricidad de demanda',
    contrastCurve_00018: 'día',
    contrastCurve_00019: 'h',
    contrastCurve_00020: 'min',
    contrastCurve_00021: 'Grupo',
    contrastCurve_00022: 'Usuario de la aplicación',
    contrastCurve_00023: "Administrador",
    contrastCurve_00024: 'Hora de inicio',
    contrastCurve_00025: 'Hora de finalización',
    contrastCurve_00026: 'Tiempo de carga',
    contrastCurve_00027: "Número de pedido",
    contrastCurve_00028: "Usuario de WeChat",
    contrastCurve_00029: "Iniciar/detener tarjeta",
    contrastCurve_00030: "Tarjeta de valor almacenado",
    contrastCurve_00031: "Tarjeta de identidad",
    contrastCurve_00032: "Tarjeta de configuración de carga inteligente",
    contrastCurve_00033: "Tarjeta de configuración de grupo",
    contrastCurve_00034: "Tarjeta de lectura de datos sin conexión",
    contrastCurve_00035: "Datos de respaldo",
    contrastCurve_00036: "Curva de carga",
    contrastCurve_00037: "Tensión de demanda en el lado del EV (línea discontinua)",
    contrastCurve_00038: "Corriente de demanda del lado del EV (línea discontinua)",
}