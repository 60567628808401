export default {
  orderlyCharging_00001: "Operación actual de carga ordenada de autobuses",
  orderlyCharging_00002: "Ayuda",
  orderlyCharging_00003: "Carga de EV",
  orderlyCharging_00004: "Conductor de EV",
  orderlyCharging_00005: "Limitar la potencia de carga total",
  orderlyCharging_00006: "Potencia de carga real",
  orderlyCharging_00007: "Código del vehículo",
  orderlyCharging_00008: "SOC mínimo requerido para la salida (%)",
  orderlyCharging_00009: "Hora de salida",
  orderlyCharging_00010: "Hora de regreso",
  orderlyCharging_00011: "Electricidad",
  orderlyCharging_00012: "1. Comience a cargar cuando el cargador esté fuera de línea. La plataforma no puede controlar la carga general, por lo que debe operar con precaución. <br/>2. La tarifa del sitio de carga está configurada como tarifa por inactividad y no se puede utilizar la función de carga ordenada de los autobuses. <br/>3. Cuando el vehículo eléctrico se esté cargando, desconecte el conector y la sesión de carga debe reiniciarse manualmente",
}