export default {
  login_00001: "Iniciar sesión en la plataforma del operador",
  login_00002: "Ingrese el nombre de usuario",
  login_00003: "Ingrese la contraseña",
  login_00004: "Iniciar sesión",
  login_00005: "El nombre de usuario y la contraseña no pueden estar en blanco",
  login_00006: "· Operación",
  login_00007: "Ya me registré, ahora",
  login_00008: "Iniciar sesión",
  login_00009: "Facilite la carga compartida.",
  login_00010: "De acuerdo con el diseño comercial de los diferentes operadores, XCHARGE tiene un conocimiento profundo de las necesidades de los clientes y creó su propio sistema de gestión de funciones completas para la operación de carga digital: la plataforma XCHARGE SaaS V3.0",
  login_00011: "",
  login_00012: "Bienvenido a la plataforma.",
  login_00013: "Cuenta",
  login_00014: "Por favor, introduzca la cuenta de inicio de sesión",
  login_00015: "contraseña",
  login_00016: "Por favor, introduzca la contraseña de inicio de sesión",
  login_00017: "¿Aún no se ha registrado?",
  login_00018: "Registrarse",
  login_00019: "Únase a la operación",
  login_00020: "Política de privacidad",
  login_00021: "Está listo para",
  login_00022: "Prepare la información para el registro",
  login_00023: "Consejos para unirse a la Charging Operation Alliance",
  login_00024: "Cuenta oficial de WeChat autoadministrada (se requiere una tarifa de servicio)",
  login_00025: "Abrir WeChat Pay",
  login_00026: "Se pueden compartir usuarios y dispositivos del operador de capa superior",
  login_00027: "Tener su propia plataforma de operador",
  login_00028: "* El código de invitación proporcionado por el operador superior debe estar disponible",
  login_00029: 'El propietario puede comenzar a cobrar escaneando el código de WeChat',
  login_00030: "El dinero de WeChat Pay del propietario se transfiere directamente a la cuenta de la plataforma comercial de WeChat Pay",
  login_00031: "Código de registro de la plataforma",
  login_00032: "Debe registrar el código en la plataforma de adquisición de ventas correspondiente",
  login_00033: "¿Olvidó su contraseña?",
  }