export default {
  userClassList_00001: "Gestión de categorías",
  userClassList_00002: "Lista de categorías",
  userClassList_00003: "Categorías de usuarios",
  userClassList_00004: "Nombre de la categoría",
  userClassList_00005: "Número de usuarios actuales",
  userClassList_00006: "Número de campañas de marketing",
  userClassList_00007: "Actividad de marketing",
  userClassList_00008: "Detalles de la categoría",
  userClassList_00009: "Operación",
  }