<template>
  <el-dialog :title="$t('common_73033')" :visible="passwordSecurityFlag" :close-on-click-modal="false"
    :lock-scroll="false" width="780px" @close="close">
    <div class="password_security">
      <div class="info">{{ $t('common_73034') }}</div>
      <ul :class="{'w160':lang!='zh'}">
        <li>
          <span class="label">{{ $t('common_73035') }}: </span>
          <span class="value">{{passwordSecurity.username}}</span>
        </li>
        <li>
          <span class="label">{{ $t('common_73036') }}: </span>
          <el-input v-model="password" type="password"></el-input>
        </li>
        <li>
          <span class="label">{{ $t('common_73037') }}: </span>
          <el-input v-model="newPassword" type="password"></el-input>
        </li>
        <li>
          <span class="label">{{ $t('common_73038') }}: </span>
          <el-input v-model="confirmPassword" type="password"></el-input>
        </li>
      </ul>
      <div class="tips" v-html="$t('common_73039')"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" plain @click="close">{{$t('common_00215')}}</el-button>
      <el-button type="primary" size="medium" @click="confirm">{{$t('common_00234')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { ajax, requestUrl } from "@/assets/utils/network";
export default {
  props: ["lang", "passwordSecurityFlag", "passwordSecurity"],
  data() {
    return {
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    close() {
      this.$emit("cancel", false);
    },
    confirm() {
      if (!this.password || !this.newPassword || !this.confirmPassword) {
        return this.$message.error(this.$t("common_73040"));
      }
      if (this.passwordSecurity.password !== this.password) {
        return this.$message.error(this.$t("common_73042"));
      }
      let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+]).{6,}$/;
      if (!regex.test(this.newPassword)) {
        return this.$message.error(this.$t("common_73043"));
      }
      if (this.newPassword !== this.confirmPassword) {
        return this.$message.error(this.$t("common_73041"));
      }
      this.$emit("confirm", {
        type: "passwordSecurity",
        password: this.password,
        confirmPassword: this.confirmPassword,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.password_security {
  width: 90%;
  margin: 0 auto;
  > .info {
    font-size: 20px;
    color: #1b2330;
  }
  > ul {
    margin: 20px 0;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #eff2f6;
    &.w160 {
      .label {
        width: 160px !important;
      }
    }
    > li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;
      font-size: 14px;
      color: #1e2633;
      font-weight: 600;
      &:last-child {
        margin-bottom: 0;
      }
      .el-input {
        width: 50%;
      }
      .label {
        width: 80px;
        font-weight: 400;
      }
    }
  }
  > .tips {
    font-size: 14px;
    color: #828aa5;
  }
}
</style>
<style>
.el-dialog__header {
  background: #eff2f6;
  font-weight: 600;
  padding: 0 0 0 20px !important;
  line-height: 60px;
}
.el-dialog__header span {
  color: #8087a0;
}
</style>