export default {
  occupancy_00001: "Lista de facturas",
  occupancy_00002: "Exportar pedidos",
  occupancy_00003: "N.º de factura",
  occupancy_00004: "Fecha de inicio",
  occupancy_00005: "Fecha de finalización",
  occupancy_00006: "restablecer",
  occupancy_00007: "buscar",
  occupancy_00008: "Estado de la factura",
  occupancy_00009: "todos",
  occupancy_00010: "Tipo de factura",
  occupancy_00011: "Cobro",
  occupancy_00012: "por pagar",
  occupancy_00013: "pagado",
  occupancy_00014: "Bloqueo directo al suelo del extremo del pilote",
  occupancy_00015: "Bloqueo directo al suelo de la plataforma",
  occupancy_00016: "Importe de la factura (€)",
  occupancy_00017: "Número de factura de ocupación",
  occupancy_00018: "Duración de la ocupación (min)",
  occupancy_00019: "Pilona de carga - puerto de carga",
  occupancy_00020: "Hora de inicio de la ocupación",
  occupancy_00021: "Hora de finalización de la ocupación",
  occupancy_00022: "Duración de la carga",
  occupancy_00023: "día",
  occupancy_00024: "hora",
  occupancy_00025: "minuto",
  occupancy_00026: "Usuarios de WeChat",
  occupancy_00027: "grupo",
  occupancy_00028: "Tarjeta de valor almacenado sin conexión",
  occupancy_00029: "Tarjeta de identificación en línea",
  occupancy_00030: "Monto de factura actual",
  occupancy_00031: "Monto de pago real",
  occupancy_00032: "Detalles de la factura",
  occupancy_00033: "Propinas",
  occupancy_00034: "Esta operación descargará el archivo de Excel de la factura. ¿Desea continuar?",
  occupancy_00035: "confirmar",
  occupancy_00036: "cancelar",
  occupancy_00037: "Iniciar descarga",
  occupancy_00038: "Cancelar descarga",
  occupancy_00039: "Apodo de usuario, número de teléfono móvil y número de matrícula",
  occupancy_00040: "apodo",
  occupancy_00041: "teléfono",
  occupancy_00042: "número de matrícula",
  occupancy_00043: "Importe total de la factura",
  bill_00059: "hoy",
  bill_00060: "hace 7 días",
  bill_00061: "hace 15 días",
  bill_00077: "ayer",
  bill_00078: "30 días atrás",
  }