
export default {
  marketCaptureTraffic_00001: "获取流量引导",
  marketCaptureTraffic_00002: "引导目录",
  marketCaptureTraffic_00003: "开放引入第三方流量",
  marketCaptureTraffic_00004: "优惠活动组合",
  marketCaptureTraffic_00005: "稳定的车队大客户",
  marketCaptureTraffic_00006: "裂变获客-邀请好友",
  marketCaptureTraffic_00007: "引导语",
  marketCaptureTraffic_00008: "通过接入引流第三方流量，可获取大量的流量。特别是以下第三方流量,建议您尽量全部接入并开放场站。",
  marketCaptureTraffic_00009: "地图流量",
  marketCaptureTraffic_00010: "查看更多流量",
  marketCaptureTraffic_00011: "网约车流量",
  marketCaptureTraffic_00012: "新能源车主聚合流量",
  marketCaptureTraffic_00013: "已开放站点：",
  marketCaptureTraffic_00014: "未开放站点：",
  marketCaptureTraffic_00015: "已接入",
  marketCaptureTraffic_00016: "未接入",
  marketCaptureTraffic_00017: "去开放",
  marketCaptureTraffic_00018: "如何接入",
  marketCaptureTraffic_00019: "通过领取优惠券活动和充电送返活动的营销组合，会给充电站带来很好的流量。",
  marketCaptureTraffic_00020: "优惠券",
  marketCaptureTraffic_00021: "1、一般情况下，对于位置不好的新站，为更好的获取流量效果，建议您更多使用低门槛的免单券或免服务费券。",
  marketCaptureTraffic_00022: '2、免单券使用"折扣券"中"新建折扣券“功能中“使用条件-总费用打折”设置，免服务券使用"折扣券"中"新建折扣券“功能中“使用条件-服务费打折”设置。',
  marketCaptureTraffic_00023: "3、各种类型的优惠券“有效期”，建议您设置相对时间。", 
  marketCaptureTraffic_00024: "去设置折扣券",
  marketCaptureTraffic_00025: "去设置抵用券",
  marketCaptureTraffic_00026: "领取优惠券活动",
  marketCaptureTraffic_00027: "1、活动建好后，建议您加大宣传推广，灵活运用“活动外发专用链接”。",
  marketCaptureTraffic_00028: "2、以下是可通过“活动外发专用链接”宣传推广案例，希望给您启发。",
  marketCaptureTraffic_00029: "可通过“活动外发专用链接”生成二维码，做成宣传单，在场站周围3公里小区、写字楼等粘贴地推推广;",
  marketCaptureTraffic_00030: "可通过“活动外发专用链接”生成二维码，做成活动单，粘贴在场站入口等明显位置，供车主领取;",
  marketCaptureTraffic_00031: "可通过“活动外发专用链接”嵌入微信公众号文章内、自动回复-被关注回复等线上宣传渠道;",
  marketCaptureTraffic_00032: "可将“活动外发专用链接”发到自己的新能源车主微信群中;等等",
  marketCaptureTraffic_00033: "去设置领取优惠券活动",
  marketCaptureTraffic_00034: "充电送返活动",
  marketCaptureTraffic_00035: '一般情况下，对于位置不好的新站，为更好的获取流量效果，建议您在”新建充电送返活动“功能中的“活动时间”的"生效时间"设置为全天。',
  marketCaptureTraffic_00036: '一般情况下，对于有一定流量的场站，建议您在”新建充电送返活动“功能中的“活动时间”的"生效时间"设置为每日流量不好的时间段。',
  marketCaptureTraffic_00037: "一般情况下，对于获取流量来说，建议您在”新建充电送返活动“功能中“活动档位”，可设置“赠送条件类型”为低门槛的充电量，并赠送多种类型多张优惠券。",
  marketCaptureTraffic_00038: "去设置充电送返活动",
  marketCaptureTraffic_00039: "场站需要获取更多稳定的车队大客户，如公交车队、物流车队、企业班车等，才能有稳定的流量。",
  marketCaptureTraffic_00040: "去设置车队大客户",
  marketCaptureTraffic_00041: "场站现有的车队大客户",
  marketCaptureTraffic_00042: "请输入选择充电站",
  marketCaptureTraffic_00043: "车队成员",
  marketCaptureTraffic_00044: "账户余额",
  marketCaptureTraffic_00045: "查看详情",
  marketCaptureTraffic_00046: "去设置邀请好友",
  marketCaptureTraffic_00047: "1、邀请好友功能可充分利用微信的熟人生态圈，快速裂变获取新的用户。",
  marketCaptureTraffic_00048: "2、“邀请好友奖励设置”建议您使用现金和优惠券组合的奖励设置，效果更好。",
  marketCaptureTraffic_00049: "3、请根据实际数据情况，及时调整奖励。如果有新站建立，建议您一段时间内更换为包含新站可用的优惠券奖励。",
  marketCaptureTraffic_00050: "政府",
  marketCaptureTraffic_00051: "免费",
  marketCaptureTraffic_00052: "直签",
  marketCaptureTraffic_00053: "智充总包",
  marketCaptureTraffic_00054: "接入第三方流量方式",
  marketCaptureTraffic_00055: "第一步  流量申请",
  marketCaptureTraffic_00056: "如您想接入流量方，请与平台联系。",
  marketCaptureTraffic_00057: "第二步  商务洽谈     ",
  marketCaptureTraffic_00058: "需与智充签署商务合作协议，约束双方权利与义务。",
  marketCaptureTraffic_00059: "包括不限于 系统对接费用、资金结算等。",
  marketCaptureTraffic_00060: "第三步  系统对接",
  marketCaptureTraffic_00061: "平台完成运营商与流量方系统对接。",
  marketCaptureTraffic_00062: "第四步  站点开放 ",
  marketCaptureTraffic_00063: "运营商可以在开放引流模块下看到可以开放的流量，",
  marketCaptureTraffic_00064: "并自行对运营站点进行开放和关闭开放操作。",
  marketCaptureTraffic_00065: "已知晓",
  marketCaptureTraffic_00066: "个人支付",
  marketCaptureTraffic_00067: "团体支付",
  marketCaptureTraffic_00068: "您的场站还没有车队大客户",









}