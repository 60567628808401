export default {
  userClassDetail_00001: 'Detalles de la categoría',
  userClassDetail_00002: 'Modificar nombre de la categoría',
  userClassDetail_00003: 'Actividades de marketing',
  userClassDetail_00004: 'Usuario de la categoría',
  userClassDetail_00005: 'Condiciones de la categoría',
  userClassDetail_00006: 'El sistema actualiza automáticamente la lista de usuarios de la categoría en tiempo real según las condiciones de la categoría',
  userClassDetail_00007: 'Nombre de usuario, teléfono móvil, número de matrícula',
  userClassDetail_00008: 'Lista de usuarios',
  userClassDetail_00009: 'Exportar usuarios',
  userClassDetail_00010: 'Área',
  userClassDetail_00011: "Último pedido pagado en el horario de hoy",
  userClassDetail_00012: 'Orden de cobro pagada hoy',
  userClassDetail_00013: 'Órdenes de cobro pagadas y no grupales de un solo usuario:',
  userClassDetail_00014: 'Número acumulado de pedidos',
  userClassDetail_00015: 'Hasta',
  userClassDetail_00016: 'Importe pagado acumulado (€)',
  userClassDetail_00017: 'Cargo acumulado (kWh)',
  userClassDetail_00018: 'Primer pedido pagado el día anterior',
  userClassDetail_00019: '¿Está seguro de que desea eliminar esta categoría de usuario?' ,
  userClassDetail_00020: 'La eliminación de esta categoría de usuario fue exitosa',
  userClassDetail_00021: 'La entrada no puede estar vacía',
  userClassDetail_00022: "Esta operación descargará el archivo Excel del usuario, ¿desea continuar?",
  userClassDetail_00023: 'Operación',
  userClassDetail_00024: 'Todos',
  userClassDetail_00025: "Promedio",
  userClassDetail_00026: "El número promedio de pedidos acumulados del usuario en promedio (incluido)",
  }