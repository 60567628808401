export default {
  groupDetail_00001: "Detalles del grupo de pago individual",
  groupDetail_00002: "Detalles del grupo de pago grupal",
  groupDetail_00003: "Asignar monto a los miembros",
  groupDetail_00004: "Configuración del sitio",
  groupDetail_00005: "Administración de miembros",
  groupDetail_00006: "Reconocer el EV distinto del usuario",
  groupDetail_00007: "Configuración de CPO",
  groupDetail_00008: "Administración de grupos",
  groupDetail_00009: "Exportar",
  groupDetail_00010: "Eliminar grupo",
  groupDetail_00011: "Irrecuperable después de eliminar un grupo",
  groupDetail_00012: "Transacciones",
  groupDetail_00013: "Esta operación descargará el archivo Excel del usuario del grupo, ¿desea ¿Continuar?",
  groupDetail_00014: "¿Está seguro de que desea eliminar el grupo {name}?",
  groupDetail_00015: "Operación cancelada",
  groupDetail_00016: "Configuración básica",
  groupDetail_00017: "Nombre del grupo",
  groupDetail_00018: "Método de pago",
  groupDetail_00019: "Configuración de privilegios",
  groupDetail_00020: "Importe máximo no pagado ({unit})",
  groupDetail_00021: "Número máximo de pedidos (transacciones) no pagados",
  groupDetail_00022: "Si se acepta el límite de tiempo de apertura de la pila de cobro",
  groupDetail_00023: "Pago personal",
  groupDetail_00024: "Pago grupal",
  groupDetail_00025: "{num}",
  groupDetail_00026: "Si se acepta el límite de tiempo de apertura de la pila de cobro, para permitir el uso de cupones",
  groupDetail_00027: "Si se debe admitir la recarga de terminal de autoservicio grupal",
  groupDetail_00028: "Asignar información de la cuenta",
  groupDetail_00029: "Saldo",
  groupDetail_00030: "Registro de recarga",
  groupDetail_00031: "Recarga de cuenta",
  groupDetail_00032: "Incluir cashback{promotionBalance}{unit}",
  groupDetail_00033: "¡Guarde la operación anterior!",
  groupDetail_00034: "Nombre del sitio de carga",
  groupDetail_00035: "Lista de sitios de carga",
  groupDetail_00036: "Agregar sitio de carga con descuento",
  groupDetail_00037: "Eliminación masiva",
  groupDetail_00038: "Plantilla de tarifa actual",
  groupDetail_00039: "Plantilla de tarifa actual Ofertas",
  groupDetail_00040: "Operar",
  groupDetail_00041: "DC",
  groupDetail_00042: "AC",
  groupDetail_00043: "El costo total de una sesión de carga única no tiene descuento",
  groupDetail_00044: "La tarifa de servicio de carga única no tiene descuento",
  groupDetail_00045: "La tarifa de servicio de carga única tiene un descuento de {des}",
  groupDetail_00046: "El costo total de una sesión de carga única tiene un descuento de {des}",
  groupDetail_00047: "El costo total de una sesión de carga única es gratis",
  groupDetail_00048: "Sin cargo por una sesión de carga única",
  groupDetail_00049: "Configuración de descuento de tarifa",
  groupDetail_00050: "Detalles",
  groupDetail_00051: "Eliminar",
  groupDetail_00052: "¿Confirmar la eliminación de la estación de carga del grupo?",
  groupDetail_00053: "No eliminado",
  groupDetail_00054: "Tarifa estándar",
  groupDetail_00055: "Si se modifica la tarifa estándar, haga clic en el botón 'Push tarifa' en los detalles del sitio-tarifa estándar, ¡y la tarifa entrará en vigencia!",
  groupDetail_00056: "Tarifa de CA",
  groupDetail_00057: "Tarifa de CC",
  groupDetail_00058: "Seleccionar tarifa de CA",
  groupDetail_00059: "Seleccionar tarifa de CC",
  groupDetail_00060: "El costo total de una sola carga no se descuenta (%)",
  groupDetail_00061: "Descuento de la tarifa de servicio de carga única (%)",
  groupDetail_00062: "85 significa 15% de descuento, 0 significa gratis",
  groupDetail_00063:"Ejemplo: Si establece un descuento del 15 % en la tarifa de servicio para una sesión de carga única, el cargo por esta vez es de 100 {unidad} (incluyendo 70 {unidad} por electricidad y 30 {unidad} por servicio), y el monto real a pagar esta vez es de 95,5 {unidad} (70) +30*85%=95,5).",
  groupDetail_00064: "Se descuenta el costo total de una sesión de carga única (%）",
  groupDetail_00065:"Por ejemplo: si establece un descuento del 15 % en el costo total de una sesión de carga única y el cargo total de este cargo es de 100 {unidad}, el monto real a pagar esta vez es de 85 {unidad} (100*85%=85).",
  groupDetail_00066: "Guardar configuración",
  groupDetail_00067: "Completar y enviar de inmediato",
  groupDetail_00068: "Si se debe asignar el monto al grupo miembros",
  groupDetail_00069: "Información del grupo",
  groupDetail_00070: "Nombre del grupo",
  groupDetail_00071: "saldo actual:{balance}{unit}",
  groupDetail_00072: "Incluir regalo{promotionBalance}{unit}",
  groupDetail_00073: "Ingresa el monto de recarga",
  groupDetail_00074: "Regalar",
  groupDetail_00075: "Contraseña de inicio de sesión",
  groupDetail_00076: "Ingresa la contraseña",
  groupDetail_00077: "Activar el reconocimiento del auto pero no el reconocimiento del usuario",
  groupDetail_00078: "Nombre de usuario, número de móvil",
  groupDetail_00079: "Agregar miembros al grupo",
  groupDetail_00080: "Nombre de usuario (número de móvil)",
  groupDetail_00081: "Configuración de la cuenta",
  groupDetail_00082: "Establecer como administrador",
  groupDetail_00083: "Cancelar un administrador",
  groupDetail_00084: "Eliminar",
  groupDetail_00085: "Detalles del usuario",
  groupDetail_00086: "¿Está seguro de eliminar a [{nickname}] del grupo?",
  groupDetail_00087: "El usuario también tiene un saldo de asignación de grupo de {num} euros, que el sistema recuperará automáticamente después de la eliminación.",
  groupDetail_00088: "Miembro usuario de WeChat",
  groupDetail_00089: "Miembros de la tarjeta de crédito",
  groupDetail_00090: "Número de tarjeta",
  groupDetail_00091: "Lista de tarjetas",
  groupDetail_00092: "Nombre de usuario",
  groupDetail_00093: "Ingrese su apodo, número de teléfono móvil, número de tarjeta y compatibilidad con múltiples condiciones de consulta separadas por coma (,), salto de línea, espacio y coma (,)",
  groupDetail_00094: "Resultado de búsqueda",
  groupDetail_00095: "Borrar",
  groupDetail_00096: "agregado",
  groupDetail_00097: "agregar",
  groupDetail_00098: "Se ha configurado",
  groupDetail_00099: "Establecer como administrador",
  groupDetail_00100: "lista de usuarios",
  groupDetail_00101: "Detalles de la tarjeta",
  groupDetail_00102: "1. Si configura 'Si se permite el uso de cupones' en 'Sí', los usuarios del grupo de usuarios pueden usar cupones al pagar pedidos.",
  groupDetail_00103: "1.1. Al pagar un pedido, dé prioridad al descuento de tarifa del sitio vinculado al grupo, use el monto de la factura después del descuento de tarifa del sitio vinculado al grupo y luego seleccione el cupón a usar de acuerdo con el cupón que cumple con las condiciones.",
  groupDetail_00104: "1.2、 Por ejemplo: <br/> los usuarios del grupo que cobran 100 {unidad} (incluyendo electricidad 70 {unidad} y cargo por servicio 30 {unidad}) esta vez pueden disfrutar del descuento de cargos del sitio vinculado al grupo (8.5% de descuento en el cargo por servicio para un solo cargo), y hay un cupón (el cargo por servicio para un solo cargo cumple 20 {unidad} 10% de descuento en el cargo por servicio), <br/> después de usar el descuento de cargos del sitio vinculado al grupo, el monto de la factura es 95.5 {unidad} (incluyendo electricidad 70 {unidad}), la tarifa por servicio es 25.5 {unidad} (30 * 85%). El cupón se satisface (la tarifa por servicio para un solo cargo es 20 {unidad} 10% de descuento en la tarifa por servicio). <br/> después de usar el cupón, el monto de la factura es 92,95 {unidad} (incluyendo 70 {unidad} + 22,95 {unidad} (25,5 * 90%). Finalmente, 92,95 {unidad} deben pagarse realmente esta vez.",
  groupDetail_00105: "2. Si 'permitir cupones' está configurado en 'no', los usuarios del grupo no pueden usar cupones al pagar pedidos.",
  groupDetail_00106: "Después de habilitar la configuración de reconocimiento de automóviles sin reconocimiento de personas, la carga en el sitio preferencial vinculado por el grupo por primera vez registrará el VIN correspondiente al vehículo del miembro del grupo. Un miembro del grupo solo puede corresponder a un vehículo. Para cargas posteriores en el sitio preferencial del grupo, solo los vehículos con VIN vinculados por miembros del grupo pueden disfrutar del descuento de tarifa del sitio establecido por el grupo.",
  groupDetail_00107: "• se recomienda que después de habilitar esta configuración, los miembros del grupo deban cargar una vez en el sitio de descuento del grupo lo antes posible para vincularse Vin.",
  groupDetail_00108: "• cuando esta configuración está habilitada, los miembros del grupo no podrán cargar usando la pila de CA.",
  groupDetail_00109: "parámetro no válido",
  groupDetail_00110: "Ingrese el apodo, número de teléfono móvil o número de tarjeta que desea consultar",
  groupDetail_00111: "La operación fue cancelada",
  groupDetail_00112: "Eliminación exitosa",
  groupDetail_00113: "Asistencia a la aplicación de factura en línea",
  groupDetail_00114: "Cuentas de grupo",
  groupDetail_00115: "Información de la cuenta de grupo",
  groupDetail_00116: "Límite de saldo mínimo ({unit})",
  groupDetail_00117: "En el modo de pago de cuenta de fondos, cuando el saldo de la cuenta es menor que el saldo mínimo, los usuarios del grupo no pueden comenzar a cargar (si está configurado en 0 {unit}, significa que no hay límite)",
  groupDetail_00118: "Saldo de recordatorio ({unidad})",
  groupDetail_00119: "En el modo de pago de cuenta de fondos, cuando el saldo de la cuenta es inferior al saldo de recordatorio, el administrador del grupo recibirá un SMS de recordatorio. (Si se establece en 0{unit} significa que no hay recordatorio)",
  groupDetail_00120: "Solo puede ingresar 0 o un número entero positivo",
  groupDetail_00121: "Usar moneda",
  // 20220124--添加优惠站点弹窗
  groupDetail_10000: "Agregar sitios de descuento",
  groupDetail_10001: "Ingrese un nombre de sitio o de transportista",
  groupDetail_10002: "Agregar por lotes",
  groupDetail_10003: "Nombre",
  groupDetail_10004: "Operador",
  groupDetail_10005: "Pila de carga",
  groupDetail_10006: "condición del sitio",
  groupDetail_10007: "operación",
  groupDetail_10008: "agregar",
  groupDetail_10009: "Se han añadido",
  groupDetail_10010: "Está habilitado",
  groupDetail_10011: "No está habilitado",
  groupDetail_10012: "ac",
  groupDetail_10013: "dc",
  groupDetail_10014: "siguiente",
  groupDetail_10015: "Seleccione la tarifa",
  }