export default {
  eventlogsgrid_00001: "Transaction List",
  eventlogsgrid_00002: "Export Data",
  eventlogsgrid_00003: "Transaction ID",
  eventlogsgrid_00004: "Start Date",
  eventlogsgrid_00005: "End Date",
  eventlogsgrid_00006: "Reset",
  eventlogsgrid_00007: "Query",
  eventlogsgrid_00008: "Status",
  eventlogsgrid_00009: "All",
  eventlogsgrid_00010: "Bill Type",
  eventlogsgrid_00011: "Billing",
  eventlogsgrid_00012: "Purchase Energy",
  eventlogsgrid_00013: "Sell Energy",
  eventlogsgrid_00014: "Energy Storage Device SN",
  eventlogsgrid_00015: "All Energy Storage Cabinets",
  eventlogsgrid_00016: "Purchased Energy (kWh)",
  eventlogsgrid_00017: "Purchase Time (minutes)",
  eventlogsgrid_00018: "Purchase Count (times)",
  eventlogsgrid_00019: "Sold Energy (kWh)",
  eventlogsgrid_00020: "Sell Time (minutes)",
  eventlogsgrid_00021: "Sell Count (times)",
  eventlogsgrid_00022: "Transaction Curve",
  eventlogsgrid_00023: "Day",
  eventlogsgrid_00024: "Hour",
  eventlogsgrid_00025: "Minute",
  eventlogsgrid_00026: "Device",
  eventlogsgrid_00027: "Recharge Duration",
  eventlogsgrid_00028: "Energy Sold",
  eventlogsgrid_00029: "Energy Purchased",
  eventlogsgrid_00030: "Purchasing Energy",
  eventlogsgrid_00031: "Purchase Completed",
  eventlogsgrid_00032: "Selling Energy",
  eventlogsgrid_00033: "Sale Completed",
  eventlogsgrid_00034: "This operation will download the transaction's Excel file, continue?",
  eventlogsgrid_00035: "Confirm",
  eventlogsgrid_00036: "Cancel",
  eventlogsgrid_00037: "Start Download",
  eventlogsgrid_00038: "Cancel Download",
  eventlogsgrid_00039: "Prompt",
  eventlogsgrid_00040: "Cumulative Energy",
  eventlogsgrid_00041: "Instantaneous Power",
  eventlogsgrid_00042: "Average Power",
  eventlogsgrid_00043: "Maximum Power",
  eventlogsgrid_00044: "Grid Transaction Log",
  eventlogsgrid_00045: "",

  billDetail_00025: "Charging Curve",
  billDetail_00026: "Vehicle Demand Voltage (Dashed Line)",
  billDetail_00027: "Vehicle Demand Current (Dashed Line)",
  billDetail_00028: "Vehicle Measured Voltage (Dashed Line)",
  billDetail_00029: "Vehicle Measured Current (Dashed Line)",
  billDetail_00030: "Battery Initial Charge",
  billDetail_00031: "Battery Current Charge",
  billDetail_00032: "Instantaneous Power",
  billDetail_00033: "Average Power",
  billDetail_00034: "Maximum Power",
  billDetail_00035: "Vehicle Model",
  billDetail_00036: "Power",
  billDetail_00037: "Current",
  billDetail_00038: "Voltage",
  billDetail_00039: "Cumulative Energy",
  billDetail_00042: "kWh",
  bill_00059: "Today",
  bill_00060: "7 Days Ago",
  bill_00061: "15 Days Ago",
  bill_00077: "Yesterday",
  bill_00078: "30 Days Ago"
}