export default {
  orderAnalysis_00001: 'Estadísticas de tickets',
  orderAnalysis_00002: 'Analizar',
  orderAnalysis_00003: "Estadísticas de tickets por estación",
  orderAnalysis_00004: "Estadísticas de tickets por roles",
  orderAnalysis_00005: "Estadísticas de tickets por categoría",
  orderAnalysis_00006: "Estadísticas de tickets por prioridad",
  orderAnalysis_00007: "Estadísticas de tickets por origen del problema",
  orderAnalysis_00008: "Estadísticas de tickets por marca y modelo del cargador",
  orderAnalysis_00009: 'Informe del personal',
  orderAnalysis_00010: 'Informe del cliente',
  orderAnalysis_00011: 'Informe del cargador',
  orderAnalysis_00012: 'Disparador automático',
  orderAnalysis_00013: 'Otro',
  orderAnalysis_00014: 'Normal',
  orderAnalysis_00015: 'Urgente',
  orderAnalysis_00016: 'Muy urgente',
  orderAnalysis_00017: 'Estadísticas de uso de accesorios',
  }