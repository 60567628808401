export default {
  securityRealTimeProtection_00001: "Número de protección de activación hoy",
  securityRealTimeProtection_00002: "Número de protección de activación este mes",
  securityRealTimeProtection_00003: "Número de paradas de carga hoy",
  securityRealTimeProtection_00004: "Número de paradas de carga este mes",
  securityRealTimeProtection_00005: "Número de cargas rechazadas hoy",
  securityRealTimeProtection_00006: "Número de cargas rechazadas este mes",
  securityRealTimeProtection_00007: "Alarma grave（{num}hoy）",
  securityRealTimeProtection_00008: "Alarma de advertencia（{num}hoy）",
  securityRealTimeProtection_00009: "Lista de protección en tiempo real",
  securityRealTimeProtection_00010: "Alarma grave Alarma",
  securityRealTimeProtection_00011: "Elementos",
  securityRealTimeProtection_00012: "Hora",
  securityRealTimeProtection_00013: "Modelo de vehículo",
  securityRealTimeProtection_00014: "Número de placa",
  securityRealTimeProtection_00015: "Estación",
  securityRealTimeProtection_00016: "Evento",
  securityRealTimeProtection_00018: "[Protección en tiempo real] Detener carga",
  securityRealTimeProtection_00019: "[Protección en tiempo real] Reducción de potencia",
  securityRealTimeProtection_00020: "[Alerta temprana] Inseguro",
  securityRealTimeProtection_00021: "[Alerta temprana] Subseguridad",
  securityRealTimeProtection_00022: "[Alerta temprana] La plataforma prohíbe la carga",
  securityRealTimeProtection_00023: "[Alerta temprana] Límite de carga de la plataforma",
  securityRealTimeProtection_00024: "Normal",
  securityRealTimeProtection_00025: "Alarma de advertencia",
  securityRealTimeProtection_00026: "Alarma sin procesar hoy",
  }