export default {
    cardSales_00001: 'Pedidos de tienda con descuento',
    cardSales_00002: 'N.º de pedido',
    cardSales_00003: 'Introduzca el nombre de usuario/número de móvil',
    cardSales_00004: 'Fecha de inicio',
    cardSales_00005: 'Fecha de finalización',
    cardSales_00006: 'Hasta',
    cardSales_00007: 'Precio (€)',
    cardSales_00008: 'Recuento de compras',
    cardSales_00009: 'Número de compras',
    cardSales_00010: 'Lista de pedidos',
    cardSales_00011: 'Exportar pedidos',
    cardSales_00012: 'Todos',
    cardSales_00013: 'Boleto de electricidad',
    cardSales_00014: 'Cupón',
    cardSales_00015: 'Monto pagado',
    cardSales_00016: 'Pago en WeChat',
    cardSales_00017: 'Cuentas oficiales de WeChat',
    cardSales_00018: '¿Descargar la lista de pedidos como archivo Excel?',
    cardSales_00019: 'Recordatorio',
    cardSales_00020: 'Iniciar descarga',
    cardSales_00021: 'Cancelar la descarga',
}