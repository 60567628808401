var triggerList = [
  {
    value: 'tx',
    label: '充电桩内部通信故障',
    children: [
      {
      value: '4',
      label: '充电桩离线',
      code:"offline",
      }, 
      {
        value: '8',
        label: '充电桩主机与DCB通讯失败[A0101]',
        code:"A0101"
      }, 
      {
        value: '9',
        label: '充电桩主机与分机DCB通讯失败[A0102]',
        code:"A0102",
      }, 
      {
        value: '10',
        label: '直流电表通讯485检测失败[A0103]',
        code:"A0103"
      },
  ]
  }, 
  {
    value: 'cgq',
    label: '传感器故障',
    children: [
      {
        value: '15',
        label: '充电主机门禁故障[A0401]',
        code:"A0401",
      },
      {
        value: '16',
        label: '液位报警[A0402]',
        code:"A0402",
      },
      {
        value: '17',
        label: '交流/直流防雷报警[A0404/A0405]',
        code: "A0404/A0405"
      },
      {
        value: '19',
        label: '充电主机烟感故障[A0407]',
        code: "A0407"
      },
      {
        value: '20',
        label: '机箱温度报警[A0408]',
        code:"A0408"
      },
      {
        value: '20',
        label: '机箱湿度报警[A0409]',
        code:"A0409"
      },
      {
        value: '20',
        label: '倾斜角报警/故障[A040A/A0419]',
        code:"A040A/A0419"
      },      
      {
        value: '21',
        label: '液冷枪头/枪尾温度故障[A040B/A040D/A040F/A0411/A0414/A0416]',
        code:"A040B/A040D/A040F/A0411/A0414/A0416",
      },      
      {
        value: '24',
        label: '急停电路故障[A0430]',
        code: "A0430",
      },
      {
        value: '24',
        label: 'HMI屏显示硬件故障，无法显示内容[A0432]',
        code: "A0432",
      },
    ]
  },
  {
    value: 'jl',
    label: '交流输入故障',
    children: [
      {
        value: '25',
        label: 'A/B/C相电压过高/过低[A0701/A0702/A0703/A0704/A0705/A0706]',
        code: "A0701/A0702/A0703/A0704/A0705/A0706"
      },
      {
        value: '26',
        label: '地线报警[A0707]',
        code: "A0707"
      },
      {
        value: '27',
        label: 'N零线未接[A0708]',
        code: 'A0708'
      },
      {
        value: '28',
        label: '交流输入断路器故障[A0709]',
        code: 'A0709'
      },
      {
        value: '29',
        label: '交流输入接触器拒动/粘连故障[A070A/A070B]',
        code:'A070A/A070B'
      },
      {
        value: '30',
        label: '系统掉电故障，无输入[A070C]',
        code:"A070C"
      },
      {
        value: '31',
        label: '交流输入缺相[A070E]',
        code:"A070E",
      },
      {
        value: '31',
        label: '交流输入过流[A070F]',
        code:"A070F",
      },
    ]
  },
  {
    value: 'zl',
    label: '直流输出故障',
    children: [
    {
      value: '32',
      label: '直流母线输出熔断器故障[A080A]',
      code: "A080A"
    },   
  ]
  },
  {
    value: 'cws',
    label: '车位锁故障',
    children: [{
      value: '40',
      label: '车位锁落锁失败，车位（及充电口）事实上无法使用[C0103]',
      code:"C0103"
    }]
  },
];

module.exports = triggerList;
