export default {
  work_00001: 'Iniciar ticket',
  work_00002: "Por favor, introduzca su apodo, número de teléfono móvil, número de tarjeta y varios criterios de consulta, que pueden estar separados por coma (,), salto de línea, espacio y señal de stop (,)",
  work_00003: 'Iniciar',
  work_00004: 'Estación de emisión',
  work_00005: 'Nombre de la estación',
  work_00006: 'Origen de la emisión',
  work_00007: 'Empleado informado',
  work_00008: 'Cliente informado',
  work_00009: 'Equipo informado',
  work_00010: 'Otro',
  work_00011: 'Título del ticket',
  work_00012: 'Cargador relacionado',
  work_00013: 'Transacción relacionada',
  work_00014: 'Usuario relacionado',
  work_00015: 'Descripción del problema',
  work_00016: 'Normal',
  work_00017: 'Urgente',
  work_00018: 'Muy urgente',
  work_00019: 'Descripción del problema',
  work_00020: 'Procesador',
  work_00021: 'Seguidor',
  work_00022: 'Agregar usuario relacionado al ticket',
  work_00023: 'Borrar',
  work_00024: 'Resultado de búsqueda',
  work_00025: 'Agregado',
  work_00026: 'Agregar',
  work_00027: 'Seleccione lo siguiente',
  work_00028: 'Seleccione la estación que tiene el problema',
  work_00029: 'Seleccione una prioridad',
  work_00030: 'Seleccione quién informó el problema',
  work_00031: 'Ingrese el título del ticket',
  work_00032: 'Ingrese una descripción del problema',
  work_00033: 'Seleccione el responsable del problema',
  work_00034: 'Fabricante del cargador',
  work_00035: 'Usuario relacionado',
  work_00036: 'Todos los CPO',
  work_00037: 'Seleccione CPO',
  work_00038: 'Todos',
  work_00039: 'El cargador está fuera de línea',
  work_00040: 'Falla del equipo',
  work_00041: 'La cantidad de carga es inferior a 1 kWh',
  work_00042: 'La duración de carga es demasiado larga',
  work_00043: 'Dispositivo avería',
  work_00044: 'Avería del vehículo',
  work_00045: 'Introduzca el número de serie del dispositivo',
  work_00046: 'Introduzca el número de pedido',
  
  work_10000: 'Mantenimiento',
  work_10001: 'Seleccione el cargador',
  work_10002: 'Seleccione un cargador con antelación. La información sobre problemas y errores se generará automáticamente. El contenido se reemplazará después de cambiar el cargador. Copie primero el contenido si desea conservarlo.',
  work_10003: 'Todos los modelos',
  work_10004: 'SN',
  work_10005: 'Estación',
  work_10006: 'CPO',
  work_10007: 'Otro cargador',
  work_10008: 'Agregar un problema',
  work_10009: 'Dirección de la estación',
  work_10010: 'Aviso de admisión',
  work_10011: 'Transacción relacionada',
  work_10012: 'Seleccionar transacción',
  work_10013: 'Información del error',
  work_10014: 'Código de error',
  work_10015: 'Estaciones',
  work_10016: 'ID de transacciones',
  work_10017: 'Agregue un problema',
  work_10018: 'Seleccione un cargador',
  work_10019: 'Complete el contenido',
  
  bill_00023: "Restablecer",
  bill_00024: "Buscar",
  bill_00025: "Ingresos ($)",
  bill_00026: "Monto del pedido ({0})",
  bill_00027: "Monto de la tarifa de electricidad ({0})",
  bill_00028: "Monto de la tarifa de servicio ({0})",
  bill_00029: "Monto de la tarifa por inactividad ({0})",
  bill_00030: "Electricidad vendida (kWh)",
  bill_00031: "Duración promedio de carga (min)",
  bill_00032: "Sesiones totales",
  bill_00033: "Cargador",
  bill_00034: "kWh",
  bill_00035: "Duración",
  bill_00036: "día",
  bill_00037: "h",
  bill_00038: "min",
  bill_00039: "Detalles",
  bill_00040: "Usuario de la aplicación",
  bill_00041: "Pagado",
  bill_00042: "Pagado",
  bill_00043: "Cobrando",
  bill_00044: "Facturación",
  bill_00045: "Por pagar",
  bill_00046: "en pausa",
  bill_00047: "Terminado pero aún conectado",
  bill_00048: "Tarifa por inactividad de facturación",
  bill_00049: "Cancelado",
  bill_00050: "Creado",
  bill_00051: "Anormal",
  bill_00052: "Hora de inicio",
  bill_00053: "Fin Hora",
  bill_00054: "Flota",
  bill_00055: "Monto de factura actual",
  bill_00056: "USD",
  bill_00057: "Fecha desde",
  bill_00058: "Fecha hasta",
  bill_00059: "Hoy",
  bill_00060: "Hace una semana",
  bill_00061: "Hace 15 días",
  bill_00062: "Recordatorio",
  bill_00063: "Esto descargará los archivos Excel de las facturas, ¿desea continuar?",
  bill_00064: "Confirmar",
  bill_00065: "Cancelar",
  bill_00066: "Los datos están en preparación, espere pacientemente. Por favor, no cierres ni actualices la página durante este período...",
  bill_00067: "Apodo del usuario",
  bill_00068: "Teléfono",
  bill_00069: "Número de tarjeta",
  bill_00070: "Número de matrícula",
  bill_00071: "El importe de la carga es inferior a 1 kWh",
  bill_00072: "El tiempo de carga es demasiado largo",
  bill_00073: "Mal funcionamiento del dispositivo",
  bill_00074: "Mal funcionamiento del coche",
  bill_00075: "Transacción de doble conector",
  bill_00076: "Tarifa de ocupación",
  bill_00077: "ayer",
  bill_00078: "Hace 30 días",
  bill_00079: "Tarjeta prepaga",
  bill_00080: "Tarjeta de inicio/parada",
  bill_00081: "ID Tarjeta",
  bill_00082: "Administrador",
  bill_00083: "Copia de seguridad de datos",
  bill_00084: "Tarjeta bancaria",
  bill_00085: "Unidad monetaria",
  bill_10000: "Todos los CPO",
  bill_00086: "Establecer los parámetros correctamente",
  bill_00101: "La cantidad de carga es superior a 1000 kWh",
  bill_00102: "Capacidad de carga anormal",

  detailDepot_00035: 'Imagen real',
  detailDepot_00036: 'Dirección, calle y nombre de la casa',
  detailDepot_00037: 'Buscar',
  detailDepot_00038: 'Nombre',
  detailDepot_00039: 'Dirección detallada',
  detailDepot_00040: 'Posición actual',
  detailDepot_00041: 'Cancelar',
  detailDepot_00042: 'Usar esta ubicación',
  detailDepot_00043: 'Seleccione las coordenadas de la estación de carga',
  detailDepot_00044: 'La posición seleccionada no coincide con la dirección',
  detailDepot_00045: "Aviso",
  detailDepot_00046: "Ya veo",
  detailDepot_00047: "No se puede encontrar la dirección",
  detailDepot_00048: "Se ha producido un error desconocido en el mapa de Google",
  }