export default {
  managementDepot_00001: 'Gestión de depósitos',
  managementDepot_00002: 'Centro de depósitos',
  managementDepot_00003: 'Conector',
  managementDepot_00004: 'Conector',
  managementDepot_00005: 'Disponible',
  managementDepot_00006: 'En uso',
  managementDepot_00007: 'Con fallas/Fuera de línea',
  managementDepot_00008: 'Todos',
  managementDepot_00009: 'Habilitado',
  managementDepot_00010: 'No habilitado',
  managementDepot_00011: 'Abierto',
  managementDepot_00012: 'No abierto',
  managementDepot_00013: 'Automóvil privado',
  managementDepot_00014: 'Vehículo comercial',
  managementDepot_00015: 'Furgoneta',
  managementDepot_00016: 'Autobús',
  managementDepot_00017: 'Bicicleta eléctrica',
  managementDepot_00018: 'Cargador',
  managementDepot_00019: 'Cargador',
  managementDepot_00020: 'Posición de la estación',
  managementDepot_00021: 'País:',
  managementDepot_00022: 'Provincia:',
  managementDepot_00023: 'Ciudad:',
  managementDepot_00024: 'Distrito:',
  managementDepot_00025: 'Seleccionar país',
  managementDepot_00026: 'Seleccionar provincia',
  managementDepot_00027: 'Seleccionar ciudad',
  managementDepot_00028: 'Seleccionar distrito',
  managementDepot_00029: 'Restablecer',
  managementDepot_00030: 'Buscar',
  managementDepot_00031: 'Tipo',
  managementDepot_00032: 'Todo',
  managementDepot_00033: 'Estación propietaria',
  managementDepot_00034: 'Estación franquiciada',
  managementDepot_00035: 'Ingrese el nombre del operador',
  managementDepot_00036: 'Tipo de EV',
  managementDepot_00037: 'Seleccionar todo',
  managementDepot_00038: 'Cancelar',
  managementDepot_00039: 'Operaciones por lotes',
  managementDepot_00040: 'Para configurar',
  managementDepot_00041: 'Nueva estación',
  managementDepot_00042: 'Información de la estación',
  managementDepot_00043: 'Cargadores',
  managementDepot_00044: 'Tarifas',
  managementDepot_00045: 'Políticas',
  managementDepot_00046: 'Transacciones',
  managementDepot_00047: 'Nombre de la estación',
  managementDepot_00048: 'Todos los tipos de vehículos',
  managementDepot_00049: 'Seleccione una estación',
  managementDepot_00050: 'Puntos de carga de CC',
  managementDepot_00051: 'Puntos de carga de CA',
  managementDepot_00052: 'Punto de carga disponible',
  managementDepot_00053: 'Punto de carga en uso',
  managementDepot_00054: 'Punto de carga anormal',
  managementDepot_00055: 'Buscar',
  managementDepot_00056: 'El tipo de moneda del sitio seleccionado entra en conflicto'
  }