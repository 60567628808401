export default {
  knowledgeDetail_00001: 'Detalles del banco de conocimientos',
  knowledgeDetail_00002: 'Banco de conocimientos',
  knowledgeDetail_00003: 'Hora de actualización',
  knowledgeDetail_00004: 'Eliminar',
  knowledgeDetail_00005: 'Editar',
  knowledgeDetail_00006: 'Categoría',
  knowledgeDetail_00007: "Marca y modelo del cargador relacionado",
  knowledgeDetail_00008: 'Ticket',
  knowledgeDetail_00009: 'Cargadores',
  knowledgeDetail_00010: 'Descripción del problema',
  knowledgeDetail_00011: 'Transacciones',
  knowledgeDetail_00012: 'Transacciones',
  knowledgeDetail_00013: "Registros de fallos del cargador",
  knowledgeDetail_00014: 'Código de falla del equipo',
  knowledgeDetail_00015: 'Descripción de la falla del equipo',
  knowledgeDetail_00016: 'Usuarios',
  knowledgeDetail_00017: 'Usuarios de WeChat',
  knowledgeDetail_00018: 'Usuarios de tarjetas',
  knowledgeDetail_00019: 'Solución',
  knowledgeDetail_00020: 'Método de procesamiento',
  knowledgeDetail_00021: 'Compartido con los CPO relevantes',
}