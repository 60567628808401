export default {
  workSchedule_00001: 'Nombre del cronograma',
  workSchedule_00002: 'Lista',
  workSchedule_00003: 'Nuevo horario',
  workSchedule_00004: 'Nombre del cronograma',
  workSchedule_00005: 'Este modo de trabajo no se aplica y se puede eliminar. ¿Está seguro de que desea eliminarlo?',
  workSchedule_00006: 'Reintentar',
  workSchedule_00007: 'Modificar cronograma',
  workSchedule_00008: 'Horario duplicado',
  workSchedule_00009: 'Detalles del horario duplicado',
  workSchedule_00010: '',
  workSchedule_00011: '',
}