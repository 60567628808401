export default {
    eur: "EUR",
    eur_name: "EUR",
    gbp: "GBP",
    gbp_name: "British Pound",
    sek: "SEK",
    sek_name: "Swedish Krona",
    hcf: "HCF",
    hcf_name: "Swiss Franc",
    cny: "CNY",
    cny_name: "CNY",
    usd: "USD",
    usd_name: "Dollar",
    rub: "RUB",
    rub_name: "Russian ruble",
    ils: "ILS",
    ils_name: "Israeli shekel",

    common_00001: "Cerrar sesión",
    common_00002: "YYYY/MM/DD",
    common_00003: "YYYY/MM/DD HH:mm:ss",
    common_00004: "Estaciones",
    common_00005: "Transacciones",
    common_00006: "Diagnóstico",
    common_00007: "Seguridad",
    common_00008: "Usuarios",
    common_00009: "Marketing",
    common_00010: "Datos",
    common_00011: "Finanzas",
    common_00012: "Desvío",
    common_00013: "Configuración",
    common_00014: "Descripción general de monitoreo",
    common_00015: "Inicio",
    common_00016: "Descripción general",
    common_00017: "Cargador monitor",
    common_00018: "Estadísticas anuales",
    common_00019: "Índice de mantenimiento",
    common_00020: "Transacciones anormales",
    common_00021: "Gestión de estaciones de carga",
    common_00022: "Crear estación de carga",
    common_00023: "Gestión de precios",
    common_00024: "Gestión de precios",
    common_00025: "Gestión de métodos de pago de carga",
    common_00026: "Prepago",
    common_00027: "Postpago",
    common_00028: "Gestión de cargadores",
    common_00029: "Gestión de métodos de pago de carga por inactividad",
    common_00030: "Bloqueo de estacionamiento controlado por la nube",
    common_00031: "Bloqueo de estacionamiento controlado por participación",
    common_00032: "Publicidad",
    common_00033: "Publicidad de cargadores",
    common_00034: "Publicidad pública",
    common_00035: "Etiqueta de visualización del sitio del cliente",
    common_00036: "Nivel de sistema etiqueta de marketing",
    common_00037: "Etiqueta de operación a nivel de sitio",
    common_00038: "Gestión del consumo de energía",
    common_00039: "Estadísticas de consumo de energía",
    common_00040: "Gestión de energía de la estación",
    common_00041: "Acoplamiento a plataforma gubernamental",
    common_00042: "Gestión de franquicias",
    common_00043: "Invitar franquicia",
    common_00044: "Transacciones de cobro",
    common_00045: "Transacciones individuales",
    common_00046: "Transacciones de flota",
    common_00047: "Transacciones de terceros",
    common_00048: "Transacciones anormales",
    common_00049: "Todas las transacciones",
    common_00050: "Transacciones",
    common_00051: "Factura de ocupación",
    common_00052: "Monitoreo de operaciones",
    common_00053: "Monitoreo en tiempo real",
    common_00054: "Transacciones anormales",
    common_00055: "Índice de mantenimiento",
    common_00056: "Registros",
    common_00057: "Registros de fallos",
    common_00058: "Registro de carga de vehículos eléctricos",
    common_00059: "Registros del sistema",
    common_00060: "Solución de problemas",
    common_00061: "Fallos actuales",
    common_00062: "Mantenimiento del módulo",
    common_00063: "Política de seguridad de carga",
    common_00064: "Restricciones de carga de EV en estaciones",
    common_00065: "Gestión de EV de alto riesgo",
    common_00066: "Protección activa en tiempo real",
    common_00067: "Lista negra",
    common_00068: "Usuarios",
    common_00069: "Gestión de usuarios",
    common_00070: "Clasificación de usuarios",
    common_00071: "Gestión de categorías",
    common_00072: "Flotas",
    common_00073: "Pago por flota",
    common_00074: "Pago por persona",
    common_00075: "Flotas",
    common_00076: "Tarjetas de carga",
    common_00077: "Grupos de tarjetas de carga",
    common_00078: "Tarjeta PnC",
    common_00079: "Tarjetas de carga",
    common_00080: "Tarjeta de inicio y parada sin conexión",
    common_00081: "Tarjeta de valor almacenado sin conexión",
    common_00082: "Automóvil eléctrico",
    common_00083: "Gestión de VE",
    common_00084: "Wechat",
    common_00085: "Atención al cliente",
    common_00086: "Miembro",
    common_00087: "Gestión de miembros",
    common_00088: "Ofertas y cupones",
    common_00089: "Cupones",
    common_00090: "Descuentos",
    common_00091: "Créditos",
    common_00092: "Bonos de electricidad",
    common_00093: "Bono de tarifa de servicio",
    common_00094: "Campañas de marketing",
    common_00095: "Reembolso por recarga",
    common_00096: "Bonos gratis",
    common_00097: "Actividad de registro y reembolso",
    common_00098: "Reembolso de cobro",
    common_00099: "Campaña de reembolso total",
    common_00100: "Actividad de cobro acumulada",
    common_00101: "Captación de clientes",
    common_00102: "Invitación de amigos",
    common_00103: "Venta de ofertas y cupones",
    common_00104: "Nuevas ofertas y cupones",
    common_00105: "Pedidos",
    common_00106: "Estadísticas de consumo de ventas",
    common_00107: "Distribución de ofertas y cupones",
    common_00108: "Canjear código",
    common_00109: "Registros",
    common_00110: "Estadísticas de consumo de ofertas canjeadas",
    common_00111: "Análisis de utilización",
    common_00112: "Utilización",
    common_00113: "Utilización por estación",
    common_00114: "Análisis a nivel de cargador",
    common_00115: "Horas pico",
    common_00116: "Análisis de potencia media",
    common_00117: "Utilización por franquiciado",
    common_00118: "Análisis de electricidad",
    common_00119: "Electricidad",
    common_00120: "Electricidad por estación",
    common_00121: "Electricidad por flota",
    common_00122: "Análisis a nivel de fuente de usuario",
    common_00123: "Electricidad por franquiciado",
    common_00124: "Análisis de ingresos",
    common_00125: "Ingresos",
    common_00126: "Ingresos por estación",
    common_00127: "Ingresos por flota",
    common_00128: "Análisis de interés bruto",
    common_00129: "Análisis a nivel de fuente de usuario",
    common_00130: "Ingresos por franquiciado",
    common_00131: "Análisis de importe de precio de cobro",
    common_00132: "Análisis de usuario",
    common_00133: "Usuario",
    common_00134: "Usuario por estación",
    common_00135: "Análisis a nivel de fuente de usuario",
    common_00136: "Análisis de la contribución del usuario",
    common_00137: "Modelado RFM del usuario",
    common_00138: "Usuario por franquiciado",
    common_00139: "Análisis de tarjetas y cupones",
    common_00140: "Análisis de tendencias de ventas",
    common_00141: "Análisis de distribución de ventas",
    common_00142: "Análisis de recargas",
    common_00143: "Análisis del importe de las recargas",
    common_00144: "Análisis del número de recargas",
    common_00145: "Análisis de reembolsos",
    common_00146: "Análisis del importe de los reembolsos",
    common_00147: "Análisis del número de reembolsos",
    common_00148: "Análisis de la cartera",
    common_00149: "Análisis de la tendencia del saldo de la cartera",
    common_00150: "Análisis de los patrones de consumo del saldo de la cartera",
    common_00151: "Funciones comunes",
    common_00152: "Factura individual",
    common_00153: "Factura grupal",
    common_00154: "Aceptación de reembolsos de la cartera",
    common_00155: "Consulta de pedidos",
    common_00156: "Estadísticas financieras",
    common_00157: "Monto de recarga",
    common_00158: "Monto de reembolso",
    common_00159: "Saldo de billetera del usuario",
    common_00160: "Monto de ventas de cupones de tarjeta",
    common_00161: "Configuración financiera",
    common_00162: "Configuración de recarga de usuario",
    common_00163: "Configuración de reembolso de usuario",
    common_00164: "Configuración de facturación de usuario",
    common_00165: "Gestión de reservas de terceros",
    common_00166: "Configuración de facturación final de autoservicio de grupo",
    common_00167: "Configuración de recarga de autoservicio de grupo",
    common_00168: "Pago de autoservicio",
    common_00169: "Facturación de la plataforma",
    common_00170: "Gestión de cuentas",
    common_00171: "Emitir una factura",
    common_00172: "Detalles de la factura",
    common_00173: "Facturación de la plataforma",
    common_00174: "Prospectos abiertos",
    common_00175: "Mapa Plataforma",
    common_00176: "Plataforma de vehículos operativos",
    common_00177: "Plataforma de integración",
    common_00178: "Plataforma gubernamental",
    common_00179: "Análisis del flujo de datos",
    common_00180: "Cargo por flujo de datos de terceros",
    common_00181: "Registro de operaciones",
    common_00182: "Gestión de cuentas",
    common_00183: "Gestión de límites de autoridad",
    common_00184: "Otras configuraciones",
    common_00185: "Vista de interfaz API",
    common_00186: "Operador",
    common_00187: "Configuración de información",
    common_00188: "Cuentas y permisos",
    common_00189: "Configuración de cliente",
    common_00190: "Wechat",
    common_00191: "Alipay",
    common_00192: "Plantilla de mensaje",
    common_00193: "Agregar vehículo",
    common_00194: "Editar información del vehículo",
    common_00195: "N.º de matrícula del vehículo",
    common_00196: "VIN del vehículo (orden de lectura)",
    common_00197: "teléfono",
    common_00198: "Modelo del vehículo|(opcional)",
    common_00199: "Ubicación|(opcional)",
    common_00200: "Provincia/estado, ciudad y distrito",
    common_00201: "Modificado correctamente",
    common_00202: "Información",
    common_00203: "Cargador",
    common_00204: "Hora de inicio",
    common_00205: "Hora de finalización",
    common_00206: "Tarifa eléctrica",
    common_00207: "Tarifa de serv",
    common_00208: "Tarifa inact",
    common_00209: "KWh",
    common_00210: "Minuto",
    common_00211: "Usuario de la aplicación",
    common_00212: "Agregar",
    common_00213: "Modificar",
    common_00214: "Eliminar",
    common_00215: "Cancelar",
    common_00216: "Eliminado correctamente",
    common_00217: "Guardar",
    common_00218: "Estación",
    common_00219: "Conector",
    common_00220: "Hora de creación",
    common_00221: "Hora de inicio de la carga",
    common_00222: "Hora de fin de carga",
    common_00223: "Duración de la carga",
    common_00224: "Elegir carga",
    common_00225: "Descuento",
    common_00226: "Tarjeta privada",
    common_00227: "Tarjeta de depósito",
    common_00228: "Tarjeta en línea",
    common_00229: "Detalles",
    common_00230: "Flota",
    common_00231: "Usuario de terceros",
    common_00232: "Pedido de venta con cupón",
    common_00233: "Notificar",
    common_00234: "Confirmar",
    common_00235: "Todos",
    common_00236: "Estación propia",
    common_00237: "Franquiciado",
    common_00238: "Por favor, introduzca el nombre del sitio",
    common_00239: "Buscar",
    common_00240: "Borrar",
    common_00241: "Añadido correctamente",
    common_00242: "Hora",
    common_00243: "Administrador",
    common_00244: "No",
    common_00245: "Todas las estaciones",
    common_00246: "Cambiar contraseña",
    common_00247: "Contraseña de inicio de sesión actual",
    common_00248: "Nueva contraseña de inicio de sesión",
    common_00249: "Por favor, utilice letras y números",
    common_00250: "Contraseña de inicio de sesión de la cuenta actual",
    common_00251: "Por favor, introduzca la contraseña actual",
    common_00252: "Por favor, introduzca la contraseña",
    common_00253: "Por favor, introduzca la contraseña de nuevo",
    common_00254: "La contraseña de confirmación no es la misma",
    common_00255: "Buscar",
    common_00256: "M",
    common_00257: "F",
    common_00258: "Perfil",
    common_00259: "Nombre",
    common_00260: "Género",
    common_00261: "Saldo de la cartera",
    common_00262: "Detalles del usuario",
    common_00263: "Ver orden de cobro",
    common_00264: "Cuenta oficial de WeChat",
    common_00265: "Recordatorio",
    common_00266: "Cancelar",
    common_00267: "Iniciar descarga",
    common_00268: "Ingrese nuevamente",
    common_00269: "Certificación EV del usuario",
    common_00270: "Lista negra de usuarios",
    common_00271: "Restablecer",
    common_00272: "Finalizar",
    common_00273: "Sí",
    common_00274: "No",
    common_00275: "El parámetro está vacío",
    common_00276: "Ingrese un número entero positivo menor que 100",
    common_00277: "Tarifa del operador",
    common_00278: "Recarga",
    common_00279: "Unidad",
    common_00280: "requerido",
    common_00281: "Configuración exitosa",
    common_00282: "Operación",
    common_00283: "Marca del vehículo",
    common_00284: "Vehículo modelo",
    common_00285: "Ingresar manualmente",
    common_00286: "Modelo de vehículo|marca",
    common_00287: "Guarde primero la operación anterior",
    common_00288: "Cancelar operación",
    common_00289: "CC",
    common_00290: "CA",
    common_00291: "Puerta de enlace",
    common_00292: "No disponible",
    common_00293: "Habilitado",
    common_00294: "Configuración",
    common_00295: "Enviar comentarios",
    common_00296: "{allDevice} equipos en total, {sucDevice} tuvo éxito, {faiDevice} falló.",
    common_00297: "Número de equipo",
    common_00298: "Nombre del equipo",
    common_00299: "Éxito",
    common_00300: "Fallo",
    common_00301: "Esperando respuesta",
    common_00302: "Fuera de línea",
    common_00303: "Cerrar",
    common_00304: "Gestión de estaciones",
    common_00305: "Tarifa push",
    common_00306: "Gestión de anuncios",
    common_00307: "Mantenimiento de cargadores",
    common_00308: "Funciones de terceros",
    common_00309: "Eliminar",
    common_00310: "Seleccionar",
    common_00311: "Volver",
    common_00312: "Fecha de inicio",
    common_00313: "Fecha de finalización",
    common_00314: "Seleccionar estaciones",
    common_00315: "Seleccionar franquiciados",
    common_00316: "Seleccionar modelo de equipo",
    common_00317: "Seleccionar flotas",
    common_00318: "Plataforma OEM",
    common_00319: "Inicio",
    common_00320: "Plataforma de operación de carga",
    common_00321: "Pantalla completa",
    common_00322: "Alejar",
    common_00323: "Exportar datos",
    common_00324: "Ubicación",
    common_00325: "MAC EV",
    common_00326: "Opcional",
    common_00327: "Seleccionar",
    common_00360: "Información básica",
    common_00361: "Sitio de oferta",
    common_00362: "Gestión de miembros del equipo",
    common_00363: "Consulta de pedidos",
    common_00364: "Estadísticas de carga",
    common_00365: "Editar",
    common_10000: 'Tipo de actividad',
    common_10001: 'Número de actividades',
    common_10002: 'Crear actividades',
    common_10003: 'Página de inicio de mantenimiento',
    common_10004: 'Centro de mantenimiento',
    common_10005: 'Análisis del mantenimiento del patio',
    common_10006: "Página de mantenimiento",
    common_10007: "Personalizada",
    common_10008: "Página de inicio financiera",
    common_10009: "¿Está seguro de acceder a la versión anterior de la plataforma operativa V2?<br/>Desde el 28 de febrero de 2022, la plataforma operativa Boss V2 no se mantendrá, ¡por favor, tenga en cuenta!",
    common_20008: 'Por favor, introduzca un nombre de estación',
    common_20009: "Resumen de la actividad",
    //能源中心
    common_20010: 'Monitor de energía',
    common_20011: 'Monitor de rendimiento',
    common_20012: 'Gestión de pilas Net Zero',
    common_20013: 'Estadísticas de energía',
    common_20014: 'Almacenamiento de energía',
    common_20015: 'Estadístico',
    common_20016: 'Energía',
    common_20017: 'Modo de potencia máxima',
    common_20018: 'Modo económico ecológico',
    common_20019: 'Modo automático',
    common_20020: 'Disponible',
    common_20021: 'Carga de batería',
    common_20022: 'Batería descargándose',
    common_20023: 'Minuto completo',
    common_20024: 'Modo de trabajo actual',
    common_20025: 'Modo',
    common_20026: 'NZS',
    common_20027: 'Batería en espera',
    common_20028: 'Fallo de la batería',
    common_20029: 'El modo Eco funciona de acuerdo con su tarifa de red durante las horas valle y horas pico. Pero debe elegir si el costo del período normal está más cerca del costo del período valle o del costo del período pico. ',
    common_20030: 'más cerca del período valle',
    common_20031: 'más cerca de las horas pico',
    common_20032: 'Se encontró que no ha establecido la tarifa de red para esta estación',
    common_20033: 'Seleccione la tarifa de red',
    common_20034: 'Nueva tarifa de red',
    common_20035: 'La tarifa de red debe establecerse con tres períodos de pico, plano y valle o cuatro períodos de etiqueta de pico, pico, plano y valle. ',
    common_20036: 'Seleccione qué período de tiempo está más cerca del costo de tiempo normal',
    common_20037: 'La tarifa de red debe establecerse en tres períodos de pico, plano y valle o cuatro períodos de etiqueta de pico, pico, plano y valle',
    common_20038: 'Modo económico',
    common_20039: 'Modo eficiente',
    common_20040: 'Modo de funcionamiento del aire acondicionado de almacenamiento y carga',
    common_20041: 'Modo de funcionamiento de almacenamiento y carga',
    common_20042: "La demanda de energía del vehículo es la primera prioridad y se repone según sea necesario. El módulo DCDC se utiliza primero para cargar el vehículo.",
    common_20043: "Para compensar la potencia de salida insuficiente de la estación, utilice el almacenamiento de energía para proporcionar energía que escasea.",
    common_20044: "El costo de CPO más bajo se puede lograr en función del estado de pico y de valle de los precios de la electricidad emitidos por la plataforma.",
    common_20045: "El afeitado de picos y el relleno de valles se utilizan para recargar el almacenamiento de energía cuando los precios de la electricidad no son pico, y el almacenamiento de energía se utiliza para cargar el automóvil durante los precios pico de la electricidad.",
    common_20046: "Controlar automáticamente la carga y descarga de acuerdo con la política de factura de electricidad emitida por la plataforma.",
    common_20047: "Ajustar automáticamente la demanda de energía y el costo del precio de la electricidad para satisfacer la potencia de carga del vehículo mientras aún se tiene suficiente energía para el almacenamiento y la reposición de energía.",
    common_20048: "Introducción a la estrategia",
    common_20049: "Escenario de aplicación",
    common_20050: "Introduzca el número de tarjeta",
    common_20051: "Introduzca la fuente",
    common_20052: "Error",
    common_20053: "Batería en espera",
    common_20054: "Hoy",
    common_20055: "Ayer",
    common_20056: "Ratio",
    common_20057: "Introducción al modo de trabajo",
    common_22000: "Lista blanca",
    common_22001: "Límite de SOC",

    common_10010: "entrada v2",
    // 工单
    common_31000: "Sistema de tickets",
    common_31001: "Gestión de tickets",
    common_31002: "Categoría de tickets",
    common_31003: "Estadísticas de tickets",
    common_31004: "Banco de conocimiento",
    common_31005: "Primero vincule la cuenta oficial",
    common_31006: "Iniciar ticket",
    common_31007: "Notificación automática",
    common_40000: 'Mant. del cargador',
    common_41000: 'Complete correctamente',
    common_41001: 'Activador auto de tickets',
    common_41002: 'Personalizar políticas',

    common_50004: "Gestión de CPO",
    common_50005: "Estaciones",
    common_50006: "Gestión de cargadores",
    common_51000: "Transacciones",
    common_51001: "Transacciones anormales",
    common_51002: "Transacciones anormales",
    common_62001: "Sugerencias de procesamiento:",
    common_62002: "Sugerencias para la solución de problemas:",

    // 资费推送结果补充
    common_45000: "Nombre del sitio",
    common_45001: "Número de dispositivos",
    common_45002: "Resultados de envío de dispositivos",
    common_45003: "DC",
    common_45004: "AC",
    common_45005: "Cero neto",
    common_45006: "Envío en curso",
    common_45007: "Envío fallido",
    common_45008: "Establecer grupo",
    // 到期倒计时
    common_11000: "El servicio de cobro se ha detenido",
    common_11001: "Vence en {0} días",
    common_11002: "La renovación automática falló y el servicio de cobro se detendrá después de {0} días",
    common_11003: "Error en la deducción de la factura de la plataforma, la pila involucrada en la factura {0} dejará de cobrar",
    common_11004: "La plataforma ha expirado",
    common_110001: "El servicio de cobro de la pila involucrada en la factura de este mes se ha detenido",
    common_11006: "Sin datos",
    common_45009: "Abrir el servicio",
    common_45010: "Política de privacidad",
    // 卡券
    common_00366: "Análisis del consumo de tarjetas",
    common_45011: "Los usuarios de los transportistas afiliados no tienen permiso para ver la información",
    // 充电口状态
    common_60000: "Ocupado",
    common_60002: "Batería anormal",
    common_60003: "Con falla/Fuera de línea",
    common_60004: "Reservado",
    common_60005: "Ver",
    common_60006: "Insertado",
    common_60007: "Cargando",
    common_60008: "Pendiente de movimiento de EV",
    common_60009: "Terminado",
    common_60010: "Visión general de la microrred",
    common_60011: "Configuración de la microrred",
    // 资费弹窗
    common_61000: "Nombre de los cargos",
    common_61001: "Por favor, introduzca el nombre de la tarifa",
    common_61002: "Este nombre de tarifa ya está ocupado",
    common_61003: "cargando",
    common_61004: "Tarifa de red",
    common_61005: "Usar moneda",
    common_61006: "Añadir hora",
    common_61007: "Se puede añadir un máximo de {0} periodos de tiempo",
    common_61008: "Hora de inicio",
    common_61009: "Hora de finalización",
    common_61010: "Electricidad de red ({type}/{unit})",
    common_61011: "Electricidad\n({type}/{unit})",
    common_61012: "Cargo por servicio\n({type}/{unit})",
    common_61013: "Inactivo tarifa\n({type}/min)",
    common_61014: "Período de tiempo pico/valle/llano",
    common_61015: "Precio correspondiente a pinggu pico",
    common_61016: "Sección normal de valle pico",
    common_61017: "Pico",
    common_61018: "Pico medio",
    common_61019: "Fuera de hora punta",
    common_61020: "Superfuera de hora punta",
    common_61021: "Ingrese la tarifa",
    common_61022: "Seleccione la etiqueta del período de tiempo",
    common_61023: "Configure el intervalo de tiempo de forma continua de 0:00 a 24:00",
    common_61024: "Ingrese la tarifa correcta",
    common_61025: "Operación exitosa",
    common_61026: "Operación fallida",
    common_61027: "Atrás",
    common_61028: "Siguiente",
    common_61029: "Sin definir",
    common_61030: "Primero cambie la tarifa predeterminada",
    common_61031: "Ámbito de aplicación",
    common_61032: "Tarifas públicas",
    common_61033: "Tarifas de flota",
    common_61034: "Aplicar tarifas",
    common_61035: "Cargadores de CA",
    common_61036: "Cargadores de CC",
    common_61037: " ({0}Estaciones)",
    common_61038: "El ámbito de aplicación de la tarifa es el siguiente. ¿Está seguro de continuar modificando?",
    common_61040: "Nuevos cargos",
    common_61041: "Editar cargos",
    common_61042: "0-Si 0-24 horas es un único tipo de período, seleccione la etiqueta de período de tiempo 'plana'",
    common_61043: "Si 0-24 horas tiene varios tipos de período de tiempo, seleccione más de dos etiquetas de período de tiempo",
    common_61044: "La etiqueta del período de tiempo correspondiente al precio de la electricidad es incorrecta, verifique, debe ser 'Pico > Media-pico > Fuera-pico > Súper-fuera-pico'",
    common_61045: "Modo de trabajo",
    common_61046: "Modo de trabajo de la estación",
    common_61047: "Obtenga más información",
    common_61048: "Modo de aire acondicionado de la estación",
    common_61049: "No se ha seleccionado ninguna tarifa de red para la estación actual. Seleccione primero la tarifa de red y luego cambie el modo de funcionamiento",
    common_61050: 'Solo hay una etiqueta de período de tiempo para la tarifa de red actual y no se pueden seleccionar los modos "Eco" y "Automático"',
    common_61051: 'La tarifa de red actual tiene solo dos etiquetas de período de tiempo y no se puede seleccionar el modo "Automático"',
    common_61052: "El período de tarifa de red actual y el modo de funcionamiento no coinciden. Modifique la tarifa de red o el modo de funcionamiento",
    common_61053: "Push exitoso",
    common_61054: "Dispositivo fuera de línea",
    common_61055: "Seleccionar tarifa",
    common_61056: "Este sitio necesita seleccionar un tipo de tarifa de {type}, actualmente no existe una plantilla de tarifa, cree una nueva tarifa",
    common_61057: "cargos",
    common_61058: "Aplicar alcance",
    common_61059: "Seleccione el sitio",
    common_61060: "DC",
    common_61061: "AC",
    common_61062: "Público",
    common_61063: "grupo",
    common_61064: "El tercero",
    common_61065: "Confirme si desea vincular la tarifa a",
    common_61066: "sitio",
    common_61067: "el",
    common_61068: "vinculación",
    common_61069: "reintentar",
    common_61070: "Primero vincule el sitio asignado",
    common_61071: "Seleccione un grupo",
    common_61072: "Seleccione el tráfico de terceros",
    common_61073: "Tiempo de trabajo SCH",
    common_61074: "Seleccione el horario del modo de trabajo personalizado",
    common_61075: "Usado",
    common_61076: "Sin usar",
    common_61077: "push",
    common_61078: "Tarifas de electricidad conectada a la red ({type}/{unit})",
    common_61080: "Permitir usar energía fotovoltaica",
    common_61081: "Cuando elige sí, significa que está permitido usar energía fotovoltaica para almacenar o cargar el vehículo. Cuando selecciona no, significa que no se permite el uso de energía fotovoltaica.",
    common_61082: "Potencia fotovoltaica máxima de la estación",
    common_61083: "Tiempo para recibir energía fotovoltaica",
    common_61084: "Configuración fotovoltaica",
    common_61085: "Gestión fotovoltaica",
    common_61086: "Suministro de energía",
    common_61087: "No suministro de energía",
    common_61088: "Energía total suministrada hoy",
    common_61163: "Límite de carga de bajo consumo a largo plazo",
    common_63010: "Introduzca el contenido",
    common_63013: "Añadir cargador",
    // 自定义模式
    common_70000: "Modo personalizado",
    common_70001: "Ver todos los horarios",
    common_70002: "CPO puede configurar libremente el modo de trabajo según su propia experiencia",
    common_70003: "El precio de la electricidad de la red eléctrica cambia de vez en cuando, y las condiciones de ocupación e inactividad de las estaciones cambian periódicamente, o la demanda de energía de las estaciones se ajusta periódicamente, como el despacho de energía personalizado",
    common_70004: "Nuevo",
    common_70005: "Horario de modo de trabajo personalizado (año)",
    common_70006: "Nombre del horario",
    common_70007: "Ingrese el nombre del horario",
    common_70008: "Agregar rango de fechas",
    common_70009: "Horario semanal",
    common_70010: "Mes",
    common_70011: "Día",
    common_70012: "Duplicado",
    common_70013: "Modo de uso de energía eléctrica",
    common_70014: "Editar programación de 0 a 24 horas",
    common_70015: "Agregar rango de fechas",
    common_70016: "Agregar programación semanal",
    common_70017: "NZS admite conexión de red a vehículo (GtoV, por sus siglas en inglés), conexión de batería a vehículo (BtoV, por sus siglas en inglés), conexión de red a batería (GtoB, por sus siglas en inglés), conexión de batería a red (B2G, por sus siglas en inglés) y conexión de PV a batería (P2B, por sus siglas en inglés). Seleccione el modo de uso de energía eléctrica dividiendo los períodos de tiempo según su experiencia.",
    common_70018: 'Por ejemplo, la disposición de prioridad de GtoV>BtoV>GtoB significa "recargar la batería cuando no haya un automóvil para cargar, utilizar primero la red eléctrica cuando haya un automóvil para cargar y luego utilizar la batería"; la disposición de prioridad de BtoV>GtoV>GtoB significa "recargar la batería cuando no haya un automóvil para cargar, utilizar primero la batería cuando haya un automóvil para cargar y luego utilizar la red eléctrica", y así sucesivamente',
    common_70019: "Agregar período de tiempo",
    common_70020: "Modo de uso de energía eléctrica",
    common_70021: "Ingrese el nombre",
    common_70022: "a",
    common_70023: "Lun",
    common_70024: "Mar",
    common_70025: "Miércoles",
    common_70026: "Jueves",
    common_70027: "Viernes",
    common_70028: "Sábado",
    common_70029: "Domingo",
    common_70030: "Copia realizada correctamente",
    common_70031: "Pegar",
    common_70032: "Copiado",
    common_70033: "De acuerdo con el rango de fechas y el cronograma semanal en el rango de fechas, se formula un cronograma para el modo de trabajo del equipo en un año. Después de ser enviado al extremo del cargador NZS, se llevará a cabo de manera circular todos los años de acuerdo con el cronograma. Si hay alguna modificación, es necesario enviarla al cargador de NZS nuevamente, después de que se cambie el cronograma.",
    common_70034: "Rellene la fecha y la hora de forma incorrecta",
    common_70035: "El modo de uso de energía eléctrica no puede estar vacío",
    common_70036: "El período debe comenzar desde 0 y finalizar a las 24",
    common_70037: "Complete el cronograma de 0 a 24 horas",
    common_63000: "Buscar",
    common_63001: "Por favor, introduzca el número de pedido",
    common_63002: "Por favor, introduzca el apodo/número de teléfono/número de matrícula/número de chasis",
    common_63003: "Por favor, introduzca el nombre del dispositivo/número de serie del dispositivo",
    common_63004: "Por favor, introduzca el nombre de la estación",
    common_63005: "Dispositivo",
    common_63006: "Por favor, introduzca un nombre de comunidad",
    common_63007: "Pedido",
    common_63008: "Estación de campo",
    common_63009: "Tarjeta enchufable y de carga",
    common_63011: "Ya existe",
    common_63012: "No válido",
    common_62000: "Apertura de miembros privilegiados",
    common_61134: "Aplicar tarifas",
    common_61147: "seleccionar todo",
    common_61148: "Aplicar todos los cargadores de CA",
    common_61149: "Aplicar todos los cargadores de CC",
    common_61150: "Confirmar para vincular las tarifas a todos los cargadores de CA",
    common_61151: "Confirmar para vincular las tarifas a todos los cargadores de CC",
    common_61152: "Todos los enlaces se realizaron correctamente",
    common_61153: "Algunos sitios no pudieron vincularse. Inténtelo de nuevo",
    common_61154: "No cierre la ventana emergente durante la vinculación",

    common_61160: "Complete la potencia correctamente",
    common_61161: "Complete la hora correcta",
    common_80000: "Contiene al menos 6 caracteres, incluido al menos 1 dígito, 1 letra y 1 carácter especial",
    common_61145: "Error de parámetro",

    common_71100: "Aviso de admisión",
    common_71101: "Problema",
    common_71102: "Sugerencia",
    common_71003: "Nombre del contacto",
    common_71004: "Agregue un problema",
    common_71005: "Descripción de la acción",
    //工单编辑器
    common_71006: "Subir archivo",
    common_71007: "Subir video",
    common_71008: "Agregar enlace de video",
    common_71009: "Ingrese el enlace del video",
    common_71010: "Arrastre el archivo aquí o",
    common_71011: "Haga clic para subir",
    common_71012: "Solo se pueden subir archivos MP4 y no deben superar {maxSize}M",
    common_71013: "Subir video local",
    common_71014: "La información asociada enviada al sistema XCHARGE ERP no se ha configurado. Comuníquese con el administrador o con su asesor de ventas para configurarlo",
    common_71015: "Este operador no ha configurado la información asociada enviada al sistema Kingdee ERP. Comuníquese con el administrador para configurarlo",
    common_71016: "Usar accesorios",
    common_71017: "Por favor ingrese el período válido",
    // menu
    common_71018: "Gestión de flotas",
    common_71019: "Gestión de tarjetas",
    common_71020: "Descripción financiera",
    common_71021: "Clasificación de billetes",
    common_71022: "Menú",
    common_80005: "Restante",
    common_80006: "Excedido",
    common_80007: "{type} actualmente no admite configuraciones de tarifas de múltiples tarifas. Modifique la tarifa a una tarifa de tarifa única.",
    common_80008: "Para modificar la tarifa",
    common_80009: "Confirme que esta tarifa no se haya aplicado a ninguna estación con equipos C6AM, C7NE, C7NA, ya que C6AM, C7NE, C7NA actualmente no admiten configuraciones de tarifas de múltiples tarifas.",
    // 腾讯地图
    common_80010: "Seleccione las coordenadas de la estación de carga",
    common_80011: "Dirección, calle y nombre de la casa",
    common_80012: "Buscar",
    common_80013: "Nombre",
    common_80014: "Dirección detallada",
    common_80015: "Posición actual",
    common_80016: "Cancelar",
    common_80017: "Usar esta ubicación",
    //急停恢复
    common_13001: "Recuperación de parada de emergencia",
    common_13002: "Esta operación requiere que la versión de firmware del dispositivo sea superior a 3.0.0",
    common_13003: "Problema de componente detectado. Repárelo pronto",
    common_13004: "SuspendedEV",
    // 添加站点弹窗
    common_72003: "",
    common_72004: "",
    common_72005: "Estado del sitio de carga",
    common_72006: "Habilitado",
    common_72007: "No activado",
    common_72008: "Abierto al público",
    common_72009: "Abierto",
    common_72010: "No abierto",
    common_72011: "Agregar un sitio de carga",
    common_72012: "",
    common_72013: "",
    common_72014: "",
    common_72015: "",
    common_72016: "",
    common_72017: "Ingrese el nombre del sitio de carga, el nombre del operador",
    common_72018: "Buscar",
    common_72019: "",
    common_72020: "",
    common_73033: "Mejora de la seguridad de la contraseña",
    common_73034: "Estimado usuario, hemos detectado que la contraseña que está utilizando actualmente es una contraseña débil, lo que plantea ciertos riesgos de seguridad. Para proteger mejor su información personal y los datos de su cuenta, cambie su contraseña.",
    common_73035: "Nombre de usuario",
    common_73036: "Contraseña actual",
    common_73037: "Nueva contraseña",
    common_73038: "Confirmar nueva contraseña",
    common_73039: "Requisitos de la nueva contraseña:<br/>· Debe tener al menos 6 caracteres.<br/>· Debe incluir letras, números y caracteres especiales (p. ej., !@#$%^&*()_+ etc.).",
    common_73040: "Complete todos los campos",
    common_73041: "Las contraseñas no coinciden, vuelva a ingresar",
    common_73042: "Contraseña incorrecta",
    common_73043: "Por favor ingrese una contraseña válida",
};