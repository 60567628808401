export default {
  manageUsers_00001: 'Gestión de miembros del grupo',
  manageUsers_00002: "Nombre del grupo",
  manageUsers_00003: "Miembros de Wechat",
  manageUsers_00004: "Miembro de la tarjeta de cobro",
  manageUsers_00005: "Agregar miembros del grupo",
  manageUsers_00006: "Exportar miembros de la comunidad",
  manageUsers_00007: "Apodo/número de móvil del usuario",
  manageUsers_00008: "Buscar",
  manageUsers_00009: "Transacciones",
  manageUsers_00010: "Eliminar",
  manageUsers_00011: "Retrato de rostro",
  manageUsers_00012: "Nombre de usuario",
  manageUsers_00013: "Género",
  manageUsers_00014: "Móvil número",
  manageUsers_00015: "número de placa",
  manageUsers_00016: "operación",
  manageUsers_00017: "Número de tarjeta",
  manageUsers_00018: "Nombre de usuario",
  manageUsers_00019: "La operación fue cancelada",
  manageUsers_00020: "¿Está seguro de que desea eliminar a [{nickname}] del grupo?",
  manageUsers_00021: "El usuario también tiene un saldo de asignación de grupo de {num} (€), que se recuperará automáticamente después de la eliminación.",
  manageUsers_00022: "¡No se pudo eliminar a [{nickname}] del grupo de usuarios! El usuario tiene pedidos sin terminar y no puede recuperar el saldo de asignación de grupo de usuarios de {num} (€).",
  manageUsers_00023: "Establecido correctamente",
  manageUsers_00024: "Esta operación descargará archivos Excel para los usuarios del grupo. ¿Desea continuar?",
  manageUsers_00025: "Recordatorio",
  manageUsers_00026: "Iniciar descarga",
  manageUsers_00027: "Cancelar descarga",
  manageUsers_00028: "Ingrese su apodo, número de teléfono móvil, número de tarjeta y múltiples criterios de consulta, que pueden estar separados por coma (,), salto de línea, espacio y señal de stop (,)",
  manageUsers_00029: "Eliminar",
  manageUsers_00030: "Resultados de la consulta",
  manageUsers_00031: "Agregado",
  manageUsers_00032: "Agregar",
  manageUsers_00033: "¡Sin permiso para ver!",
  manageUsers_00034: "Ingrese el nombre de usuario, número de teléfono móvil o número de tarjeta que desea consultar",
  }