export default {
    eventRelease_00001: "banner de la página de inicio",
    eventRelease_00002: "banner de la página de inicio habilitado",
    eventRelease_00003: "Vista previa",
    eventRelease_00004: "imagen del banner",
    eventRelease_00005: "saltar página",
    eventRelease_00006: "orden de visualización",
    eventRelease_00007: "seleccionar imagen predeterminada",
    eventRelease_00008: "amplificación",
    eventRelease_00009: "modificar",
    eventRelease_000010: "haga clic para cargar imágenes",
    eventRelease_000011: "ayuda de configuración",
    eventRelease_000012: "1. La imagen cargada solo admite imágenes en formato PNG y JPG, y el tamaño no es mayor a 5 m. El tamaño recomendado es 690 * 260px (8:3). Si el tamaño o la escala de la imagen cargada no es consistente, se estirará para mostrarla",
    eventRelease_000013: "",
    eventRelease_000014: "",
    eventRelease_000015: "seleccione el tipo de actividad",
    eventRelease_000016: "pegue el enlace del artículo de su cuenta oficial, plataforma pública de WeChat: https://mp.weixin.qq.com/ ",
    eventRelease_000017: "se pueden configurar hasta 8 banners",
    eventRelease_000018: "establezca la imagen del banner",
    eventRelease_000019: "establezca el ID de la actividad",
    eventRelease_000020: "Cargar",
    eventRelease_000021: "sin salto",
    eventRelease_000022: "página activa",
    eventRelease_000023: "enlace de cuenta oficial",
    eventRelease_000024: "devolución de valor almacenado",
    eventRelease_000025: "recogida gratuita",
    eventRelease_000026: "invitar amigos",
    eventRelease_000027: "comprar cupón de tarjeta",
    eventRelease_000028: "Establecer página de salto",
    eventRelease_000029: "Nombre de la actividad",
    eventRelease_000030: "Seleccionar actividad",
    eventRelease_000031: "Nueva actividad",
}