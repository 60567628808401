
export default {
  marketIncreaseCashFlow_00001: "短时间内增加现金流",
  marketIncreaseCashFlow_00002: "引导目录",
  marketIncreaseCashFlow_00003: "储值送返活动",
  marketIncreaseCashFlow_00004: "第三方渠道卡券售卖",
  marketIncreaseCashFlow_00005: "售卖特权会员",
  marketIncreaseCashFlow_00006: "去设置储值送返活动",
  marketIncreaseCashFlow_00007: "引导语",
  marketIncreaseCashFlow_00008: '1、在短时间内增加现金流的背景下，建议您在“储值送返活动"-”新建储值送返活动“功能中',
  marketIncreaseCashFlow_00009: "1.1、”活动档位“，多设置大额的储值金额和赠送对应的大额现金的储值档位。",
  marketIncreaseCashFlow_00010: '1.2、“储值金额退款”建议您设置”不支持退款“。',
  marketIncreaseCashFlow_00011: '1.3、“参与活动用户范围”建议您设置“不限制”。',
  marketIncreaseCashFlow_00012: '2、活动建好后，微信公众号等车主客户端活动充值要设置为开启。',
  marketIncreaseCashFlow_00013: '3、活动建好后，建议您加大宣传推广，灵活运用“活动外发专用链接”。',
  marketIncreaseCashFlow_00014: "以下是可通过“活动外发专用链接”宣传推广案例，希望给您启发。",
  marketIncreaseCashFlow_00015: "可通过“活动外发专用链接”生成二维码，做成活动单，粘贴在场站入口等明显位置，供车主方便充值;",
  marketIncreaseCashFlow_00016: "可通过“活动外发专用链接”嵌入微信公众号文章内、自动回复-被关注回复等线上宣传渠道;",
  marketIncreaseCashFlow_00017: "可将“活动外发专用链接”发到自己的新能源车主微信群中;等等",
  marketIncreaseCashFlow_00018: "通过第三方渠道和现有方式售卖卡券，可增加现金流。",
  marketIncreaseCashFlow_00019: "可售卖卡券",
  marketIncreaseCashFlow_00020: "1、可售卖卡券可使用电量包、代金券。建议您设置丰富的电量包、代金券产品。",
  marketIncreaseCashFlow_00021: "2、出于成本考虑，使用“新建电量包”功能时，“电量包类型”建议您设置“用电量抵用服务费”。",
  marketIncreaseCashFlow_00022: "3、处于成本考虑，使用“新建代金券”功能时，”代金券类型“建议您设置“用现金抵用服务费”。",
  marketIncreaseCashFlow_00023: "4、电量包和代金券，“有效期”建议您设置相对时间。“可用站点”范围建议您设置自营站点。",
  marketIncreaseCashFlow_00024: "去设置电量包",
  marketIncreaseCashFlow_00025: "去设置代金券",
  marketIncreaseCashFlow_00026: "第三方渠道售卖",
  marketIncreaseCashFlow_00027: "1、可在抖音、淘宝、京东、拼多多等第三方电子商务渠道售卖卡券。",
  marketIncreaseCashFlow_00028: "2、可在您有合作资源的第三方渠道售卖卡券，如新能源车4S店、车友会、线下商超等。",
  marketIncreaseCashFlow_00029: "3、线下渠道场景，您可以将卡券兑换码制作成实体卡，如礼品卡、电量卡等。",
  marketIncreaseCashFlow_00030: "4、可在“渠道兑换码”功能中依据渠道生成售卖卡券的兑换码。",
  marketIncreaseCashFlow_00031: "5、可在“渠道兑换记录”功能中查看渠道售卖卡券兑换码的兑换记录。",
  marketIncreaseCashFlow_00032: "去设置卡券渠道兑换码",
  marketIncreaseCashFlow_00033: "去查看渠道兑换记录",
  marketIncreaseCashFlow_00034: "现有方式售卖",
  marketIncreaseCashFlow_00035: "1、可通过“卡券上架”功能在微信公众号等车主客户端售卖。",
  marketIncreaseCashFlow_00036: "2、灵活运用“卡券销售链接”，多种宣传方式售卖。",
  marketIncreaseCashFlow_00037: "以下是可通过“卡券销售链接”售卖案例，希望给您启发。",
  marketIncreaseCashFlow_00038: "可通过“卡券销售链接”生成二维码，做成购买海报，粘贴在场站入口等明显位置，供车主购买;",
  marketIncreaseCashFlow_00039: "可通过“卡券销售链接”嵌入公众号自定义菜单、微信公众号文章内等线上购买渠道;",
  marketIncreaseCashFlow_00040: "可将“卡券销售链接‘发到自己的新能源车主微信群中;等等",
  marketIncreaseCashFlow_00041: "去设置卡券上架售卖",
  marketIncreaseCashFlow_00042: "去查看卡券销售订单",
  marketIncreaseCashFlow_00043: "通过第三方渠道和现有方式售卖特权会员，可增加现金流。",
  marketIncreaseCashFlow_00044: "可售卖特权会员",
  marketIncreaseCashFlow_00045: "1、建议您使用”开通特权会员“功能时，",
  marketIncreaseCashFlow_00046: "1.1、”特权会员时长档位“为多档位定价，如月度、半年等。",
  marketIncreaseCashFlow_00047: '1.2、出于成本考虑，会员权益"费用折扣”，建议您设置“服务费打折”。',
  marketIncreaseCashFlow_00048: "1.3、“可用站点”范围建议您设置自营站点。",
  marketIncreaseCashFlow_00049: "2、建议您设置丰富的特权会员产品。",
  marketIncreaseCashFlow_00050: "去设置特权会员",
  marketIncreaseCashFlow_00051: "第三方渠道售卖",
  marketIncreaseCashFlow_00052: "1、可在抖音、淘宝、京东、拼多多等第三方电子商务渠道售卖特权会员。",
  marketIncreaseCashFlow_00053: "2、可在您有合作资源的第三方渠道售特权会员，如新能源车4S店、车友会、线下商超等。",
  marketIncreaseCashFlow_00054: "3、线下渠道场景，您可以将特权会员兑换码制作成实体卡，如会员卡等。",
  marketIncreaseCashFlow_00055: "4、可在“渠道兑换码”功能中依据渠道生成售卖特权会员的兑换码。",
  marketIncreaseCashFlow_00056: "5、可在“渠道兑换记录”功能中查看渠道售卖特权会员兑换码的兑换记录。",
  marketIncreaseCashFlow_00057: "去设置特权会员渠道兑换码",
  marketIncreaseCashFlow_00058: "去查看特权会员兑换记录",
  marketIncreaseCashFlow_00059: "现有方式售卖",
  marketIncreaseCashFlow_00060: '1、可通过“开通特权会员”功能在微信公众号等车主客户端售卖。',
  marketIncreaseCashFlow_00061: "2、灵活运用“特权会员买入”链接，多种宣传方式售卖。",
  marketIncreaseCashFlow_00062: '以下是可通过"特权会员买入"链接售卖案例，希望给您启发。',
  marketIncreaseCashFlow_00063: '可通过"特权会员买入"链接生成二维码，做成购买海报，粘贴在场站入口等明显位置，供车主购买;',
  marketIncreaseCashFlow_00064: '可通过"特权会员买入"链接嵌入公众号自定义菜单、微信公众号文章内等线上宣传渠道;',
  marketIncreaseCashFlow_00065: '可将"特权会员买入"链接发到自己的新能源车主微信群中;等等',
  marketIncreaseCashFlow_00066: "去设置特权会员上架售卖",
  marketIncreaseCashFlow_00067: "去查看特权会员销售订单",








}