export default {
  userClass_00001: "The following classification criteria can be used to filter by the target user's individual or combined criteria. ",
  userClass_00002: 'View help',
  userClass_00003: 'Reference basis',
  userClass_00004: 'Area',
  userClass_00005: 'The latest charging order payment time is now',
  userClass_00006: 'Please select a time range',
  userClass_00007: "Charging order paid in today's date",
  userClass_00008: 'Single user non-group and paid charging order:',
  userClass_00009: 'Cumulative number of orders',
  userClass_00010: 'Please enter a natural number',
  userClass_00011: 'Cumulative paid amount (€)',
  userClass_00012: 'Accumulated charge (kWh)',
  userClass_00013: 'First order paid on the day before',
  userClass_00014: 'In',
  userClass_00015: 'To',
  userClass_00016: 'Generate results',
  userClass_00017: 'Province',
  userClass_00018: 'City',
  userClass_00019: 'Please fill in the complete charging order',
  userClass_00020: 'Please fill in the complete order payment time',
  userClass_00021: 'Category help',
  userClass_00022: 'New users can define the first order payment time condition by setting', 
  userClass_00023: 'RFM model',
  userClass_00024: 'The RFM model is an important tool and means of measuring the value of users and their ability to generate profit. It is widely used in many user relationship management (CRM) applications.' ,
  userClass_00025: 'This model describes the value of a single user in terms of R (time of most recent purchase), F (overall frequency of purchase over time), and M (overall amount of purchase over time)', 
  userClass_00026: 'The RFM model can be used to find target users through user classification. Corresponding model',
  userClass_00027: 'R (time of last charge order payment)',
  userClass_00028: 'F (cumulative number of charging orders paid for by a single user not in a group over a period of time)',
  userClass_00029: 'M (cumulative paid amount of charging orders paid by a single user, not a group, over time)',
  userClass_00030: 'High',
  userClass_00031: 'Low',
  userClass_00032: 'High value',
  userClass_00033: 'Need to initiate contact',
  userClass_00034: 'Need to focus on development',
  userClass_00035: 'Need to focus on retention',
  userClass_00036: 'Reference data',
  userClass_00037: 'Single user non-group and paid charging orders',
  userClass_00038: 'Cumulative paid amount',
  userClass_00039: 'Cumulative charge amount',
  userClass_00040: 'Category result',
  userClass_00041: 'Current number of eligible users',
  userClass_00042: 'View users',
  userClass_00043: 'Total number of users in the region',
  userClass_00044: 'Please enter category name',
  userClass_00045: 'Save as category',
  userClass_00046: 'After saving as a category, the system will automatically update the list of category users in real time according to the category conditions',
  userClass_00047: 'Please enter user category name',
  userClass_00048: 'Please select',
  userClass_00049: 'All',

  userClass_00050: 'User classification model tool',
  userClass_00051: 'Saved category management',
  userClass_00052: 'RFM model application in new energy vehicle charging industry:',
  userClass_00053: 'R and F are the important influencing parameters, followed by M',
  userClass_00054: 'Unlimited/High',
  userClass_00055: 'Key active',
  userClass_00056: 'Need to focus on in-depth cultivation',
  userClass_00057: 'You can try to recall',
  userClass_00058: 'Please select the value range',

  userClassList_00001: "Classification Management",
  userClassList_00002: "Classification List",
  userClassList_00003: "User Classification",
  userClassList_00004: "Classification Name",
  userClassList_00005: "Current number of users",
  userClassList_00006: "Number of marketing activities",
  userClassList_00007: "Marketing Activities",
  userClassList_00008: "Classification details",
  userClassList_00009: "Operation",
  userClassList_00010: 'Area',
  userClassList_00011: "Station",
  userClassList_00012: "New User",
  userClassList_00013: "Important active users",
  userClassList_00014: "Need to focus on cultivating users",
  userClassList_00015: "Need to contact the user actively",
  userClassList_00016: "You can try to recall the user",
  userClassList_00017: "Customized user classification",
  userClassList_00018: "Average",
  userClassList_00019: "The average number of users' cumulative orders on the average (inclusive)",
  userClassList_00020: "Relative value",
  userClassList_00021: "Absolute value",
}