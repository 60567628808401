export default {
  energyOperationMonitoring_00001: "能源运行监控",
  energyOperationMonitoring_00002: "数据透视",
  energyOperationMonitoring_00003: "充电口",
  energyOperationMonitoring_00004: "储能",
  energyOperationMonitoring_00005: "电网",
  energyOperationMonitoring_00006: "车充电用电",
  energyOperationMonitoring_00007: "最大用电功率",
  energyOperationMonitoring_00008: "从储能给车充电",
  energyOperationMonitoring_00009: "从电网给车充电",
  energyOperationMonitoring_00010: "储能放电量",
  energyOperationMonitoring_00011: "最大补电功率",
  energyOperationMonitoring_00012: "储能SOC",
  energyOperationMonitoring_00013: "从电网补电",
  energyOperationMonitoring_00014: "放电给充电口",
  energyOperationMonitoring_00015: "给储能补电",
  energyOperationMonitoring_00016: "给车充电",
  energyOperationMonitoring_00017: "储能补电使用",
  energyOperationMonitoring_00018: "储能放电使用",
  energyOperationMonitoring_00019: "最低",
  energyOperationMonitoring_00020: "平均",
  energyOperationMonitoring_00021: "最高",
  energyOperationMonitoring_00022: "导出数据",
  energyOperationMonitoring_00023: "最大用电量",
  energyOperationMonitoring_00024: "车充电用电量",
  energyOperationMonitoring_00025: "储能净放电量",
  energyOperationMonitoring_00026: "电网入电量",
  energyOperationMonitoring_00027: "日",
  energyOperationMonitoring_00028: "周",
  energyOperationMonitoring_00029: "月",
  energyOperationMonitoring_00030: "年",
  energyOperationMonitoring_00031: "所有时间",
  energyOperationMonitoring_00032: "车充电用电量从储能供给",
  energyOperationMonitoring_00033: "车充电用电量从电网供给",
  energyOperationMonitoring_00034: "电网入电量供给车充电",
  energyOperationMonitoring_00035: "电网入电量供给储能补电",
  energyOperationMonitoring_00036: "储能放电量供给车充电",
  energyOperationMonitoring_00037: "1月",
  energyOperationMonitoring_00038: "2月",
  energyOperationMonitoring_00039: "3月",
  energyOperationMonitoring_00040: "4月",
  energyOperationMonitoring_00041: "5月",
  energyOperationMonitoring_00042: "6月",
  energyOperationMonitoring_00043: "7月",
  energyOperationMonitoring_00044: "8月",
  energyOperationMonitoring_00045: "9月",
  energyOperationMonitoring_00046: "10月",
  energyOperationMonitoring_00047: "11月",
  energyOperationMonitoring_00048: "12月",

  energyOperationMonitoring_00049: "周一",
  energyOperationMonitoring_00050: "周二",
  energyOperationMonitoring_00051: "周三",
  energyOperationMonitoring_00052: "周四",
  energyOperationMonitoring_00053: "周五",
  energyOperationMonitoring_00054: "周六",
  energyOperationMonitoring_00055: "周日",
  energyOperationMonitoring_00056: "充电功率",
  energyOperationMonitoring_00057: "从储能给车充电",
  energyOperationMonitoring_00058: "从电网给车充电",
  energyOperationMonitoring_00059: "给储能补电",
  energyOperationMonitoring_00060: "给车充电",
  energyOperationMonitoring_00061: "电网净使用电量",
  energyOperationMonitoring_00062: "{0} 第 {1} 周",
  energyOperationMonitoring_00063: "光伏",
  energyOperationMonitoring_00064: "从光伏补电",
  energyOperationMonitoring_00065: "向电网售电",
  energyOperationMonitoring_00066: "从电网购电",
  energyOperationMonitoring_00067: "向电网出电量",
  energyOperationMonitoring_00068: "储能放电给电网",
  energyOperationMonitoring_00069: "储能放电给车充电",
  energyOperationMonitoring_00070: "光伏供电量",
  energyOperationMonitoring_00071: "储能补电功率",
  energyOperationMonitoring_00072: "储能放电功率",
  energyOperationMonitoring_00073: "光伏供电功率",
  energyOperationMonitoring_00074: "光伏供电给储能功率",
  energyOperationMonitoring_00075: "从电网购电",
  energyOperationMonitoring_00076: "向电网售电",
  energyOperationMonitoring_00077: "从光伏给车充电",
  energyOperationMonitoring_00078: "供给车充电",
  energyOperationMonitoring_00079: "车充电用电量从光伏供给",
  energyOperationMonitoring_00080: "光伏给车充电",
  energyOperationMonitoring_00081: "光伏发电供给效率",
  energyOperationMonitoring_00082: "并网卖电",
  energyOperationMonitoring_00083: "储能补电电量",
  energyOperationMonitoring_00084: "储能放电电量",
  energyOperationMonitoring_00085: "设备不存在",
  energyOperationMonitoring_00086: "查看订单",
  energyOperationMonitoring_00087: "查看日志",
  energyOperationMonitoring_00088: "请输入设备SN",

  
  



  


  

  






  



  



  

  
}