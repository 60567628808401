<template>
  <div class="container" @click="containerEvent">
    <div class="heade-bar">
      <el-tooltip effect="dark" popper-class="my-menu-tooltip" :manual="isCollapse" v-model="menuTooltipFlag" :content="$t('common_71022')"> 
      <span class="collapse-switcher" @click.prevent="operationMenu">
        <i class="iconfont icon-shouqi1" v-show="isCollapse || isShrink"></i>
        <i class="iconfont icon-zhankai1" v-show="!isCollapse && !isShrink"></i>
      </span>
      </el-tooltip>
      <div class="logo">
        <img :src="adminImg" v-if="adminImg" @click="toIndex">
        <span @click="toIndex">{{ sysName }}</span>
      </div>
      <div class="zc-select" v-if="isSupervisor">
          <el-select :placeholder="$t('common_00327')" filterable v-model="sourceInfo" @change="changeSelect()" value-key="id">
            <el-option v-for="(item,k) in selectList" :label="item.resourceTitle" :value="item.resource" :key="k"></el-option>
          </el-select>
        </div>
      <span class="full-screen">
        <!-- 全局搜索 -->
        <div v-if="product=='cn'" class="search_box" @click.stop="">
          <el-input class="search_inp" :placeholder="activeKey?searchPlace[activeKey]:$t('common_63000')" v-model.trim="keyword" @keyup.enter.native="searchEvent" @focus="focusEvent">
            <i v-if="!searchFlag" slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button class="search_btn" v-show="searchFlag" type="primary" icon="el-icon-search" @click="searchEvent">{{$t('common_63000')}}</el-button>
          <ul v-show="searchFlag" class="search_submenu">
            <li v-for="it in searchList" :key="it.key" :class="{'active':activeKey==it.key}" @click="activeKey=it.key,cacheActiveKey=it.key">{{it.label}}</li>
          </ul>
        </div>
        <!-- 到期时间 -->
        <a v-if="product=='cn'&&expirationTime.text" href="./selfHelpPayCost.html#accountManagement" target="_blank">
          <el-tooltip effect="dark" :popper-options="{boundariesElement:'.my-notify-icon'}" :content="expirationTime.text" placement="bottom"> 
          <span :class="['time_wrap',{'normal_time':expirationTime.type=='normal'},{'warning_time':expirationTime.type=='warning'}]">{{expirationTime.text}}</span>
          </el-tooltip>
        </a>
        <!-- <span v-if="isOlduser" @click="toV2">{{$t('common_10010')}}</span> -->
        <!-- <i class="iconfont icon-kanban"></i> -->
        <el-tooltip effect="dark" :popper-options="{boundariesElement:'.my-notify-icon'}" :content="$t('common_00233')" placement="bottom">
          <el-badge :value="notifyCount" :max="99" class="item">
            <i class="iconfont icon-xiaoxi1 my-notify-icon" @click="toNotify"></i>
          </el-badge>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('common_00321')" placement="bottom"> 
          <i class="iconfont icon-fangda my-fullscreen-icon" v-show="!isFullScreen&&isPc" @click.prevent="screenfull"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('common_00322')" placement="bottom">
          <i class="iconfont icon-suoxiao my-suoxiao-icon" v-show="isFullScreen&&isPc" @click.prevent="screenfull"></i>
        </el-tooltip>
      </span>
      <span class="tool-bar">
      
        <el-dropdown class="user-info-dropdown" trigger="hover">
          <span class="el-dropdown-link">
            <img :src="headImageUrl" v-if="headImageUrl">
            {{ userTitle }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="my-lang-select-dropdown">
            <el-dropdown-item @click.native="FLAG_CHANGE_PW = true">{{
              $t("common_00246")
            }}</el-dropdown-item>
            <el-dropdown-item @click.native="logout">{{
              $t("common_00001")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
    <div class="myMain">
      <div :class="['menu-con',isCollapse ? 'hided':'',{'w260':lang=='en'},{'w280':lang=='de'||lang=='es'},{'pc_index':isPc}]">
        <menuPc  :isActive="isactive" :isCollapse="isCollapse" :isShrink="isShrink"  v-if="isPc"/>
        <menuMobile @collapse="mobileCollapse()" :isActive="isactive" :isCollapse="isCollapse" :isShrink="isShrink"  v-else/>
      </div> 
      <div class="main-con">
        <slot></slot>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog
      :title="$t('common_00246')"
      :lock-scroll="false"
      :visible="FLAG_CHANGE_PW"
      @close="close"
      width="30%">
      <div class="dialog-changePw">
        <div class="model">
          <div class="title">{{ $t("common_00247") }}</div>
          <el-input
            size="medium"
            v-model="password"
            :placeholder="$t('common_00250')"
            type="password"
          />
        </div>
        <div class="model">
          <div class="title">{{ $t("common_00248") }}</div>
          <el-input
            size="medium"
            v-model="newPassword"
            :placeholder="$t('common_80000')"
            type="password"
          />
        </div>
        <div class="model">
          <div class="title">{{ $t("common_00268") }}</div>
          <el-input
            size="medium"
            v-model="newPassword1"
            :placeholder="$t('common_80000')"
            type="password"
          />
        </div>
        <div class="buttons">
          <el-button @click="close" size="medium">{{
            $t("common_00215")
          }}</el-button>
          <el-button type="primary" @click="uptPassword" size="medium">{{
            $t("common_00234")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 平台说明 临时-->
    <div :class="['platformIcon',{'mobile_icon':!isPc}]"
      @mousedown="handleMouseDown"
      @click="handleClick"
      v-if="FLAG_PLATFORM_ICON"
      :style="{ left: buttonLeft + 'px', top: buttonTop + 'px'}">
      <i v-if="!isPc" @click="FLAG_PLATFORM_ICON = false" class="el-icon-close close"></i>
    </div>
    <!-- 平台说明弹窗 -->
    <el-dialog
      :title="$t('请阅读《运营商平台调整说明》')"
      :lock-scroll="false"
      :visible="FLAG_PLATFORM"
      :show-close="false"
      width="1000px">
      <div class="platform_dialog">
        <div class="title">
          <h3>尊敬的运营商朋友, 您好:</h3>
          <p>感谢您使用智充运营商平台，您的支持是我们不断提升产品能力、优化服务体验的动力。在您的信任与支持下，我们不断打磨产品能力，为了持续提供优质服务、共建高质高效的充电运营服务，智充运营商平台将进行优化调整，具体如下:</p>
          <p>为了感谢运营商朋友长期以来的支持，为运营商朋友赋能，自2024年2月1日起，智充运营商平台将给予优惠，并支持线上缴纳平台使用费，简述如下：</p>
        </div>
        <div class="introduce">
          <h4>（1）运营商平台优惠后收费标准</h4>
          <div class="table">
            <p class="y">直流充电桩平台使用费-收费标准</p>
            <img src="../assets/img/menu/y-table.png" class="y-table">
          </div>
          <div class="table">
            <p class="g">交流充电桩平台使用费-收费标准</p>
            <img src="../assets/img/menu/g-table.png" class="g-table">
          </div>
          <h4>（2）运营商平台使用费缴纳流程图</h4>
          <div class="table">
            <p>甲方即：运营商平台使用方 乙方即：运营商平台提供方</p>
            <img src="../assets/img/menu/process.png" class="process">
          </div>
          <h4>（3）运营商平台功能调整</h4>
          <div class="table">
            <p class="indent">与此同时，自2024年2月1日起，智充运营商平台也将迎来全新升级，通过提供更丰富、更具性价比的增值服务，满足更专业、个性化的协同需求，具体如下:</p>
            <p class="indent">高级版平台赠送第三方直连流量（不包含总包流量），即：使用高级版平台包含第三方直连流量导流功能，您可以根据自身需求，在平台上选择对接平台上已开通的第三方直连流量，并与第三方流量方确认合作后进行开通。</p>
          </div>
          <h4>（4）运营商平台调整后功能</h4>
          <div class="table">
            <p class="y">基础版平台功能</p>
            <img src="../assets/img/menu/y1-table.png" class="y1-table">
          </div>
          <div class="table">
            <p class="g">高级版平台功能</p>
            <img src="../assets/img/menu/g1-table.png" class="g1-table">
          </div>
          <h4>（5）特别说明</h4>
          <div class="table ph">
            <p class="indent underline">我们将于2024年2月完成智充运营商平台的升级，届时对于您之前已使用智充运营商平台名下的所有充电桩，将按照您原使用的智充运营商平台版本类型进行升级，如升级过程中需要您配合的，我们会提前通知您。对于平台使用费免收期未到期的充电桩，本次升级后，将按照原有剩余时间继续提供升级后的智充运营商平台服务；对于平台使用费免收期已到期的充电桩，我们将为您提供2024年2月1日至2024年4月30日的智充运营商平台免费试用期，在免费试用期内按照升级后的智充运营商平台提供服务；免费试用期满请您按照本说明的优惠价格缴纳平台使用费。</p>
            <p class="indent underline">升级后的智充运营商平台将由系统智能化控制，每月1号会生成上月需要缴费的平台使用费账单，我们会在智充运营商平台上向您发送通知提醒，请您在每月11日12:00:00前完成上月平台使用费的缴纳，否则会影响您使用，为避免平台停止服务给您带来经济损失，请您按时缴费。</p>
            <p class="indent">如果您有任何问题，欢迎拨打010-53609116 咨询，咨询时间：工作日早10：00点至晚18：00点；另外您也可向您对接的智充业务同事进行咨询。</p>
            <p class="indent">感谢您一直以来对智充运营商平台的支持，期待与您共同会见更多新可能。</p>
            <p class="right">北京智充科技有限公司</p>
            <p class="right">2024年2月1日</p>
          </div>
        </div>
        <div class="btn">
          <el-button type="primary" @click="confirmRead">我已阅读</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import menuPc from "./menuPc.vue"
import menuMobile from "./menuMobile.vue"
import {
  requestUrl,
  ajax,
  checkLogin,
  getLoginInfo,
  checkIdType,
  getDateFromLongTime
} from "@/assets/utils/network";
import screenfull from "screenfull";
export default {
  components: {
    menuPc,
    menuMobile
  },
  data() {
    return {
      dragging: false,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
      buttonLeft: window.innerWidth - 210,
      buttonTop:  window.innerHeight - 170,
      clickPrevented: false,
      FLAG_PLATFORM: false,
      FLAG_PLATFORM_ICON: false,
      // 全局搜索
      keyword:'', 
      searchFlag: false,
      cacheActiveKey: "order",
      activeKey: "",
      searchPlace:{
        order:this.$parent.$t('common_63001'),
        wechat:this.$parent.$t('common_63002'),
        equipment:this.$parent.$t('common_63003'),
        site:this.$parent.$t('common_63004'),
        online:this.$parent.$t('common_20050'),
        plug:this.$parent.$t('common_20050'),
        group:this.$parent.$t('common_63006'),
      },
      searchList: [
        {
          label:this.$parent.$t('common_63007'),
          key:"order",
        },
        {
          label:this.$parent.$t('common_00211'),
          key:"wechat",
        },
        {
          label:this.$parent.$t('common_63005'),
          key:"equipment",
        },
        {
          label:this.$parent.$t('common_63008'),
          key:"site",
        },
        {
          label:this.$parent.$t('common_00228'),
          key:"online",
        },
        {
          label:this.$parent.$t('common_63009'),
          key:"plug",
        },
        {
          label:this.$parent.$t('common_00072'),
          key:"group",
        },
      ],
      // 账户过期时间
      expirationTime: {
        type:'', // normal:正常 ，即将过期：warning
        text:'',
      },
      catchActive:'',
      isClose: false, // 是否关闭二级菜单
      isPc: true,
      isOlduser:true, // 角色创建时间
      product:'cn',
      noPermissions:[],
      lang:'zh',
      sourceInfo:[],
      isSupervisor:false,
      creatFlag:false,
      aa:false,
      loginId: "",
      password: "",
      newPassword: "",
      newPassword1: "",
      FLAG_CHANGE_PW: false,
      notifyCount: 0, // 通知
      list: [],
      // 平台首页
      platformHome: {
        name: this.$parent.$t("common_00015"),
        icon: "iconfont icon-zhuye1",
        iconS: "iconfont icon-zhuye",
        selectFLAG: false,
        router: "preview.html",
        permissionKey: "siteInfo",
        iconFLAG: true,
        active: 2000,
        focus: 2,
        nopermission: true,
        child: [],
      },
      // 整体概览
      overview: {
        name: this.$parent.$t("common_00016"),
        icon: "iconfont icon-gailanbeifen",
        iconS: "iconfont icon-gailan",
        selectFLAG: false,
        router: "analyzeBI.html",
        permissionKey: "biInfo",
        iconFLAG: true,
        active: 2001,
        focus: 3,
        nopermission: true,
        child: [],
      },
      // 场站地图***
      depotMap:{
        name: this.$parent.$t("common_00324"),
        icon: "iconfont icon-gailanbeifen",
        iconS: "iconfont icon-gailan",
        selectFLAG: false,
        router: "depotMap.html",
        permissionKey: "siteInfo",
        iconFLAG: true,
        active: 2003,
        focus: 15,
      },
      //场站中心
      site: {
        name: this.$parent.$t("common_00004"),
        icon: "iconfont icon-a-changzhanbeifen3",
        iconS: "iconfont icon-a-changzhanbeifen4",
        selectFLAG: false,
        router: "managementDepot.html",
        permissionKey: "siteInfo",
        iconFLAG: true,
        active: 1,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00014"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00017"),
                router: "chargeMonitor.html",
                permission: "deviceMonitorChart",
                focus: 4,
              },
              /* {
                name: this.$parent.$t("common_00018"),
                router: "userLevel.html",
                permission: "userGradeInfo",
                isHide: true,
                focus: 5,
              }, */
              /* {
                name: this.$parent.$t("common_00019"),
                router: "chargingPackage.html",
                permission: "discountPackageInfo",
                isHide: true,
                focus: 6,
              }, */
              {
                name: this.$parent.$t("common_00048"),
                router: "bill.html#abnormal",
                permission: "chargeBillInfo",
                isHide: true,
                focus: 7,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00021"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00022"),
                router: "createDepot.html",
                permission: "addSite",
                focus: 9,
              },
              {
                name: this.$parent.$t("common_00304"),
                router: "managementDepot.html",
                permission: "siteInfo",
                focus: 8,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00023"),
            icon: "el-icon-document",
            children: [
              /* {
                name: this.$parent.$t("common_00305"),
                router: "rates.html",
                permission: "couponTemplateInfo",
                focus: 10,
              }, */
              {
                name: this.$parent.$t("common_00024"),
                router: "rates.html",
                permission: "dealerFeepolicyInfo",
                focus: 10,
              },
              {
                name: this.$parent.$t("common_61143"),
                router: "pushTask.html",
                permission: "feePolicyPushTaskInfo",
                focus: 2010,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00028"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_63013"),
                router: "addDevice.html",
                permission: "deviceInfo",
                focus: 2011,
              },
              {
                name: this.$parent.$t("common_00028"),
                router: "deviceMonitor.html#eq",
                permission: "deviceMonitorList",
                focus: 14,
              },
            ],
          },
          /* {
            name: this.$parent.$t("common_00025"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00026"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 12,
              },
              {
                name: this.$parent.$t("common_00027"),
                router: "activities.html",
                permission: "promotionInfo",
                focus: 13,
              },
            ],
          }, */
          /* {
            name: this.$parent.$t("common_00029"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00030"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 15,
              },
              {
                name: this.$parent.$t("common_00031"),
                router: "activities.html",
                permission: "promotionInfo",
                focus: 16,
              },
            ],
          }, */
          {
            name: this.$parent.$t("common_00032"),
            icon: "el-icon-document",
            children: [
              /* {
                name: this.$parent.$t("common_00306"),
                router: "activities.html",
                permission: "promotionInfo",
                focus: 159,
              },
              {
                name: this.$parent.$t("common_00033"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 19,
              },
              {
                name: this.$parent.$t("common_00034"),
                router: "activities.html",
                permission: "promotionInfo",
                focus: 20,
              } */
            ],
          },
          /* {
            name: this.$parent.$t("common_00035"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00036"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 21,
              },
              {
                name: this.$parent.$t("common_00037"),
                router: "activities.html",
                permission: "promotionInfo",
                focus: 22,
              },
            ],
          }, */
          /* {
            name: this.$parent.$t("common_00038"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00039"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 23,
              },
              {
                name: this.$parent.$t("common_00040"),
                router: "activities.html",
                permission: "promotionInfo",
                focus: 24,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00041"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00041"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 25,
              },
            ],
          }, */
          {
            name: this.$parent.$t("common_00042"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00043"),
                router: "joinOperator.html",
                permission: "inviteCodeInfo",
                focus: 26,
              },
            ],
          },
        ],
      },
      //订单中心
      order: {
        name: this.$parent.$t("common_00005"),
        icon: "iconfont icon-a-dingdanbeifen4",
        iconS: "iconfont icon-a-dingdanbeifen5",
        selectFLAG: false,
        router: "bill.html",
        permissionKey: "chargeBillInfo",
        iconFLAG: true,
        active: 2,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00044"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00045"),
                router: "bill.html#user",
                permission: "chargeBillInfo",
                focus: 28,
              },
              {
                name: this.$parent.$t("common_00046"),
                router: "bill.html#group",
                permission: "userGroupInfo",
                focus: 29,
              },
              {
                name: this.$parent.$t("common_00047"),
                router: "bill.html#outside",
                permission: "outsideOwnerInfo",
                focus: 30,
              },
              {
                name: this.$parent.$t("common_00048"),
                router: "bill.html#abnormal",
                permission: "chargeBillInfo",
                isHide: true,
                focus: 31,
              },
              {
                name: this.$parent.$t("common_00049"),
                router: "bill.html",
                permission: "chargeBillInfo",
                isHide: true,
                focus: 32,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00050"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00232"),
                router: "cardSales.html",
                permission: "discountPackageInfo",
                focus: 33,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00051"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00051"),
                router: "occupancyQuery.html",
                permission: "occupyBillInfo",
                focus: 34,
              },
            ],
          },
        ],
      },
      //维保中心
      maintenance: {
        name: this.$parent.$t("common_00006"),
        icon: "iconfont icon-bianzu",
        iconS: "iconfont icon-weibao",
        selectFLAG: false,
        router: "maintenanceHome.html",
        permissionKey: "DeviceErrorLogInfo",
        iconFLAG: true,
        active: 3,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00052"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_10006"),
                router: "maintenanceHome.html",
                permission: "DeviceErrorLogInfo",
                focus: 200,
              },
              {
                name: this.$parent.$t("common_00053"),
                router: "chargeMonitor.html",
                permission: "deviceMonitorChart",
                focus: 36,
              },
              {
                name: this.$parent.$t("common_00054"),
                router: "bill.html#abnormal",
                permission: "chargeBillInfo",
                focus: 37,
              },
              /* {
                name: this.$parent.$t("common_00055"),
                router: "inviteFriends.html",
                permission: "inviteFriendInfo",
                focus: 38,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_00056"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00057"),
                router: "errorLog.html",
                permission: "DeviceErrorLogInfo",
                focus: 39,
              },
              {
                name: this.$parent.$t("common_00058"),
                router: "vehicleChargingLog.html",
                permission: "VehicleChargeLogInfo",
                focus: 40,
              },
              {
                name: this.$parent.$t("common_00059"),
                router: "platformLog.html",
                permission: "DeviceCommLogInfo",
                focus: 41,
              },
              {
                name: this.$parent.$t("common_61079"),
                router: "chargingFail.html",
                permission: "chargeBillInfo",
                focus: 2100,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00060"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00061"),
                router: "deviceMonitor.html#maint",
                permission: "deviceMonitorList",
                focus: 42,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00307"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00062"),
                router: "powerMaint.html#power",
                permission: "DeviceLifeStatsInfo",
                focus: 43,
              },
              {
                name: this.$parent.$t("common_40000"),
                router: "powerMaint.html#eq",
                permission: "DeviceLifeStatsInfo",
                focus: 44,
              },
            ],
          },
          {
            name: this.$parent.$t("common_31007"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_31007"),
                router: "autoNotice.html",
                permission: "errorWarnInfo",
                focus: 1005,
              }
            ],
          },
          {
            name: this.$parent.$t("common_31000"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_31001"),
                router: "orderManagement.html",
                permission: "workFlowInfo",
                focus: 1001,
              },
              {
                name: this.$parent.$t("common_41001"),
                router: "orderTriggerList.html",
                permission: "workFlowInfo",
                focus: 1004,
              },
               {
                name: this.$parent.$t("common_31002"),
                router: "orderClassify.html",
                permission: "workFlowInfo",
                focus: 1003,
              },
              {
                name: this.$parent.$t("common_31003"),
                router: "orderAnalysis.html",
                permission: "workFlowInfo",
                focus: 1014,
              },
            ],
          },
          {
            name: this.$parent.$t("common_31004"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_31004"),
                router: "knowledge.html",
                permission: "workFlowInfo",
                focus: 1002,
              },
            ],
          },
        ],
      },
      //安全中心
      security: {
        name: this.$parent.$t("common_00007"),
        icon: "iconfont icon-a-bianzu14beifen3",
        iconS: "iconfont icon-anquan",
        selectFLAG: false,
        router: "securityRealTimeProtection.html",
        iconFLAG: true,
        active: 4,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00052"),
            icon: "el-icon-document",
            children: [
              // {
              //   name: this.$parent.$t("common_61104"),
              //   router: "safetyOverview.html",
              //   permission: "couponTemplateInfo",
              //   focus: 210,
              // },
              {
                name: this.$parent.$t("common_61100"),
                router: "securityRealTimeProtection.html",
                permission: "securityFenceInfo",
                focus: 2211,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00056"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_61101"),
                router: "safetyLog.html",
                permission: "securityFenceInfo",
                focus: 46,
              },
              {
                name: this.$parent.$t("common_00058"),
                router: "vehicleChargingLog.html",
                permission: "securityFenceInfo",
                focus: 40,  // 47
              },
              {
                name: this.$parent.$t("common_00059"),
                router: "platformLog.html",
                permission: "securityFenceInfo",
                focus: 41, // 48
              },
            ],
          },
          {
            name: this.$parent.$t("common_00063"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_61102"),
                router: "safetyReport.html",
                permission: "securityFenceInfo",
                focus: 49,
              },
              {
                name: this.$parent.$t("common_61103"),
                router: "limitedCharge.html",
                permission: "securityFenceInfo",
                focus: 50,
              },
              {
                name: this.$parent.$t("common_61162"),
                router: "securityLimited.html",
                permission: "securityFenceInfo",
                focus: 5001,
              },
            ],
          },
          {
            name: this.$parent.$t("common_80004"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_80003"),
                router: "safetyFence.html",
                permission: "securityFenceInfo",
                focus: 216,
              },
            ],
          },
        ],
      },
      //用户中心
      user: {
        name: this.$parent.$t("common_00008"),
        icon: "iconfont icon-a-bianzu15beifen5",
        iconS: "iconfont icon-yonghu",
        selectFLAG: false,
        router: "user.html",
        permissionKey: "wechatUserInfo",
        iconFLAG: true,
        active: 5,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00068"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00069"),
                router: "user.html",
                permission: "wechatUserInfo",
                focus: 51,
              },
              {
                name: this.$parent.$t("common_80018"),
                router: "userRanking.html",
                permission: "biUserRankInfo",
                focus: 2004,
              },
              {
                name: this.$parent.$t("common_00070"),
                router: "userClassification.html",
                permission: "userClassInfo",
                focus: 52,
              },
              // {
              //   name: this.$parent.$t("common_00071"),
              //   router: "userClassList.html",
              //   permission: "userClassInfo",
              //   focus: 53,
              // },
              /* {
                name: this.$parent.$t("common_00269"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 54,
              }, */
              {
                name: this.$parent.$t("common_00270"),
                router: "blackList.html",
                permission: "blacklistInfo",
                focus: 165,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00086"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00087"),
                router: "member.html",
                permission: "userGradeInfo",
                focus: 65,
              },
              {
                name: this.$parent.$t("common_62000"),
                router: "privilegedMember.html",
                permission: "userGradeInfo",
                focus: 230,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00072"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00073"),
                router: "group.html#group",
                permission: "userGroupInfo",
                focus: 54,
              },
              {
                name: this.$parent.$t("common_00074"),
                router: "group.html#user",
                permission: "userGroupInfo",
                focus: 55,
              },
              {
                name: this.$parent.$t("common_00075"),
                router: "group.html",
                permission: "userGroupInfo",
                focus: 56,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00076"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00077"),
                router: "chargeCard.html",
                permission: "nfcgroupInfo",
                focus: 57,
              },
              {
                name: this.$parent.$t("common_00078"),
                router: "managementCharger.html#U3S",
                permission: "nfcgroupInfo",
                focus: 58,
              },
              {
                name: this.$parent.$t("common_00079"),
                router: "managementCharger.html#U3",
                permission: "nfcgroupInfo",
                focus: 59,
              },
              {
                name: this.$parent.$t("common_00080"),
                router: "managementCharger.html#U1",
                permission: "nfcgroupInfo",
                focus: 60,
              },
              {
                name: this.$parent.$t("common_00081"),
                router: "managementCharger.html#U2",
                permission: "nfcgroupInfo",
                focus: 61,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00082"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00083"),
                router: "car.html",
                permission: "vehicleInfo",
                focus: 62,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00085"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00084"),
                router: "feedback.html",
                permission: "wechatFeedbackInfo",
                focus: 64,
                notify: 0
              },
            ],
          },
        ],
      },
      //营销中心
      activity: {
        name: this.$parent.$t("common_00009"),
        icon: "iconfont icon-a-bianzu17beifen3",
        iconS: "iconfont icon-yingxiao",
        selectFLAG: false,
        router: "activityOverview.html",
        permissionKey: "promotionInfo",
        iconFLAG: true,
        active: 6,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00088"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00089"),
                router: "coupon.html#dy",
                permission: "couponTemplateInfo",
                focus: 67,
              },
              {
                name: this.$parent.$t("common_00090"),
                router: "coupon.html#zk",
                permission: "couponTemplateInfo",
                focus: 68,
              },
              {
                name: this.$parent.$t("common_00091"),
                router: "cashCoupon.html#dj",
                permission: "discountPackageInfo",
                focus: 69,
              },
              {
                name: this.$parent.$t("common_00092"),
                router: "cashCoupon.html#dl",
                permission: "discountPackageInfo",
                focus: 70,
              },
              /* {
                name: this.$parent.$t("common_00093"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 71,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_00094"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_20009"),
                router: "activityOverview.html",
                permission: "promotionInfo",
                focus: 1171,
              },
              {
                name: this.$parent.$t("common_00095"),
                router: "storedValueActivity.html#value",
                permission: "promotionInfo",
                focus: 72,
              },
              {
                name: this.$parent.$t("common_00096"),
                router: "storedValueActivity.html#free",
                permission: "promotionInfo",
                focus: 73,
              },
              {
                name: this.$parent.$t("common_00098"),
                router: "storedValueActivity.html#charge",
                permission: "promotionInfo",
                focus: 222,
              },
              {
                name: this.$parent.$t("common_80001"),
                router: "teamPlaylistActivity.html",
                permission: "teamRankingInfo",
                focus: 8100,
              }
            ],
          },
          {
            name: this.$parent.$t("common_00101"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00102"),
                router: "inviteFriends.html",
                permission: "inviteFriendInfo",
                focus: 78,
              },
            ]
          },
          {
            name: this.$parent.$t("common_00103"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00104"),
                router: "chargingPackage.html",
                permission: "discountPackageInfo",
                focus: 79,
              },
              {
                name: this.$parent.$t("common_00105"),
                router: "cardSales.html",
                permission: "discountPackageInfo",
                focus: 80,
              },
              /* {
                name: this.$parent.$t("common_00106"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 81,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_00107"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00108"),
                router: "convertCode.html",
                permission: "discountPackageInfo",
                focus: 82,
              },
              {
                name: this.$parent.$t("common_00109"),
                router: "convertCodeRecord.html",
                permission: "discountPackageInfo",
                focus: 83,
              },
              /* {
                name: this.$parent.$t("common_00110"),
                router: "Coupon.html",
                permission: "discountPackageInfo",
                focus: 84,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_46000"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_46001"),
                router: "minProgramBanner.html",
                permission: "wechatAppBannerInfo",
                focus: 400,
              },
              {
                name: this.$parent.$t("common_46002"),
                router: "eventRelease.html",
                permission: "wechatAppPromotionInfo",
                focus: 401,
              },
              {
                name: this.$parent.$t("common_46003"),
                router: "followOfficialAccount.html",
                permission: "wechatAppFollowInfo",
                focus: 402,
              }, 
            ],
          },
        ],
      },
      //数据中心
      record: {
        name: this.$parent.$t("common_00010"),
        icon: "iconfont icon-a-bianzu18beifen5",
        iconS: "iconfont icon-shuju",
        selectFLAG: false,
        router: "analyzeXcharge.html#whole",
        permissionKey: "biInfo",
        iconFLAG: true,
        active: 7,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t('common_61146'),
            icon: "el-icon-document",
            isCard: true,
            children: [
              {
                name: this.$parent.$t("common_61155"),
                router: "report.html#week",
                permission: "biWeekDataAnalysis",
                focus: 201,
              },
              {
                name: this.$parent.$t("common_61156"),
                router: "report.html#month",
                permission: "biMonthDataAnalysis",
                focus: 202,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00111"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00112"),
                router: "analyzeXcharge.html#whole",
                permission: "biInfo",
                focus: 86,
              },
              {
                name: this.$parent.$t("common_00113"),
                router: "analyzeXcharge.html#site",
                permission: "biInfo",
                focus: 87,
              },
              {
                name: this.$parent.$t("common_00114"),
                router: "analyzeXcharge.html#device",
                permission: "biInfo",
                focus: 88,
              },
              {
                name: this.$parent.$t("common_00115"),
                router: "analyzeXcharge.html#date",
                permission: "biInfo",
                focus: 89,
              },
              {
                name: this.$parent.$t("common_00116"),
                router: "analyzeXcharge.html#average",
                permission: "biInfo",
                focus: 90,
              },
              {
                name: this.$parent.$t("common_00117"),
                router: "analyzeXcharge.html#operator",
                permission: "biInfo&&inviteCodeInfo",
                focus: 91,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00118"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00119"),
                router: "analyzeCharge.html#whole",
                permission: "biInfo",
                focus: 92,
              },
              {
                name: this.$parent.$t("common_00120"),
                router: "analyzeCharge.html#site",
                permission: "biInfo",
                focus: 93,
              },
              {
                name: this.$parent.$t("common_00121"),
                router: "analyzeCharge.html#group",
                permission: "biInfo&&userGroupInfo",
                focus: 94,
              },
              {
                name: this.$parent.$t("common_00122"),
                router: "analyzeCharge.html#user",
                permission: "biInfo",
                focus: 95,
              },
              {
                name: this.$parent.$t("common_00123"),
                router: "analyzeCharge.html#operator",
                permission: "biInfo&&inviteCodeInfo",
                focus: 96,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00124"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00125"),
                router: "analyzeIncome.html#whole",
                permission: "biInfo",
                focus: 97,
              },
              {
                name: this.$parent.$t("common_00126"),
                router: "analyzeIncome.html#site",
                permission: "biInfo",
                focus: 98,
              },
              {
                name: this.$parent.$t("common_00127"),
                router: "analyzeIncome.html#group",
                permission: "biInfo&&userGroupInfo",
                focus: 99,
              },
              /* {
                name: this.$parent.$t("common_00128"),
                router: "Coupon.html",
                permission: "biInfo",
                focus: 100,
              }, */
              {
                name: this.$parent.$t("common_00129"),
                router: "analyzeIncome.html#user",
                permission: "biInfo",
                focus: 101,
              },
              {
                name: this.$parent.$t("common_00130"),
                router: "analyzeIncome.html#operator",
                permission: "biInfo&&inviteCodeInfo",
                focus: 102,
              },
              /* {
                name: this.$parent.$t("common_00131"),
                router: "analyzeIncome.html#order",
                permission: "biInfo",
                focus: 103,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_00132"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00133"),
                router: "analyzeUser.html#whole",
                permission: "biInfo",
                focus: 104,
              },
              {
                name: this.$parent.$t("common_00134"),
                router: "analyzeUser.html#site",
                permission: "biInfo",
                focus: 105,
              },
              {
                name: this.$parent.$t("common_00135"),
                router: "analyzeUser.html#user",
                permission: "biInfo",
                focus: 106,
              },
              {
                name: this.$parent.$t("common_00136"),
                router: "analyzeUser.html#contribution",
                permission: "biInfo",
                focus: 107,
              },
              {
                name: this.$parent.$t("common_00137"),
                router: "analyzeUser.html#rfm",
                permission: "biInfo",
                focus: 108,
              },
              {
                name: this.$parent.$t("common_00138"),
                router: "analyzeUser.html#operator",
                permission: "biInfo&&inviteCodeInfo",
                focus: 109,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00139"),
            icon: "el-icon-document",
            isCard: true,
            children: [
              /* {
                name: this.$parent.$t("common_00140"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 110,
              },
              {
                name: this.$parent.$t("common_00141"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 111,
              }, */
              {
                name: this.$parent.$t("common_00366"),
                router: "cardAnalysis.html#card",
                permission: "couponTemplateInfo",
                focus: 1006,
              },
            ],
          },
          /* {
            name: this.$parent.$t("common_00142"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00143"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 112,
              },
              {
                name: this.$parent.$t("common_00144"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 113,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00145"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00146"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 114,
              },
              {
                name: this.$parent.$t("common_00147"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 115,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00148"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00149"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 116,
              },
              {
                name: this.$parent.$t("common_00150"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 117,
              },
            ],
          }, */
        ],
      },
      //财务中心
      finance: {
        name: this.$parent.$t("common_00011"),
        icon: "iconfont icon-caiwu",
        iconS: "iconfont icon-caiwu1",
        selectFLAG: false,
        router: "financialHome.html",
        permissionKey: "invoiceInfo",
        iconFLAG: true,
        active: 8,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00151"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_10008"),
                router: "financialHome.html",
                permission: "invoiceInfo",
                focus: 2002,
              },
              {
                name: this.$parent.$t("common_00152"),
                router: "personalInvoiceApplication.html",
                permission: "invoiceInfo",
                focus: 119,
                notify:0,
              },
              {
                name: this.$parent.$t("common_00153"),
                router: "groupInvoiceApplication.html",
                permission: "userGroupInfo",
                focus: 120,
                notify:0,
              },
              {
                name: this.$parent.$t("common_00154"),
                router: "walletRefundAccept.html",
                permission: "refundInfo",
                focus: 121,
                notify:0,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00155"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00045"),
                router: "bill.html#user",
                permission: "chargeBillInfo",
                focus: 122,
              },
              {
                name: this.$parent.$t("common_00046"),
                router: "bill.html#group",
                permission: "userGroupInfo",
                focus: 123,
              },
              {
                name: this.$parent.$t("common_00047"),
                router: "bill.html#outside",
                permission: "outsideOwnerInfo",
                focus: 124,
              },
              {
                name: this.$parent.$t("common_00049"),
                router: "bill.html",
                permission: "chargeBillInfo",
                focus: 125,
              },
              {
                name: this.$parent.$t("common_00051"),
                router: "occupancyQuery.html",
                permission: "occupyBillInfo",
                focus: 126,
              },
              {
                name: this.$parent.$t("common_00050"),
                router: "cardSales.html",
                permission: "discountPackageInfo",
                focus: 127,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00156"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00157"),
                router: "monthlyStatistics.html#recharge",
                permission: "settleInfo",
                focus: 128,
              },
              {
                name: this.$parent.$t("common_00158"),
                router: "monthlyStatistics.html#refund",
                permission: "refundInfo",
                focus: 129,
              },
              {
                name: this.$parent.$t("common_00159"),
                router: "monthlyStatistics.html#wallet",
                permission: "settleInfo",
                focus: 130,
              },
              {
                name: this.$parent.$t("common_00160"),
                router: "monthlyStatistics.html#shop",
                permission: "discountPackageInfo",
                focus: 131,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00161"),
            icon: "el-icon-document",
            children: [
              // 用户充值设置
              {
                name: this.$parent.$t("common_00162"),
                router: "financingSettings.html#userRechargeSettings",
                permission: "dealerInfo",
                focus: 132,
              },
              // 用户退款设置
              {
                name: this.$parent.$t("common_00163"),
                router: "financingSettings.html#userRefundSettings",
                permission: "dealerInfo",
                focus: 133,
              },
              // 用户开票设置
              {
                name: this.$parent.$t("common_00164"),
                router: "financingSettings.html#userBillingSettings",
                permission: "dealerInfo",
                focus: 134,
              },
              {
                name: this.$parent.$t("common_00165"),
                router: "financingSettings.html#reserveManagement",
                permission: "outsideOwnerInfo",
                focus: 135,
              },
              {
                name: this.$parent.$t("common_00166"),
                router: "financingSettings.html#selfInvoicingSetting",
                permission: "userGroupInfo",
                focus: 136,
              },
              {
                name: this.$parent.$t("common_00167"),
                router: "financingSettings.html#selfRechargeSetting",
                permission: "userGroupInfo",
                focus: 137,
              },
            ],
          },
          {
            name: this.$parent.$t("common_00168"),
            icon: "el-icon-document",
            children: [
              // 开通服务
              {
                name: this.$parent.$t("common_45009"),
                router: "selfHelpPayCost.html#service",
                permission: "dealerAccountInfo",
                focus: 170,
              },
              // 平台账单
              {
                name: this.$parent.$t("common_00169"),
                router: "selfHelpPayCost.html#platformBillings",
                permission: "dealerAccountInfo",
                focus: 138,
              },
              // 账户管理
              {
                name: this.$parent.$t("common_00170"),
                router: "selfHelpPayCost.html#accountManagement",
                permission: "dealerAccountInfo",
                focus: 139,
              },
              /* {
                name: this.$parent.$t("common_00171"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 140,
              },
              {
                name: this.$parent.$t("common_00172"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 141,
              }, */
            ],
          },
          /* {
            name: this.$parent.$t("common_00308"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00173"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 142,
              },
            ],
          }, */
        ],
      },
      //流量中心
      flow: {
        name: this.$parent.$t("common_00012"),
        icon: "iconfont icon-liuliang1",
        iconS: "iconfont icon-liuliang",
        selectFLAG: false,
        router: "diversionServices.html#4",
        permissionKey: "outsideOwnerInfo",
        iconFLAG: true,
        active: 9,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00174"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00175"),
                router: "diversionServices.html#4",
                permission: "outsideOwnerInfo",
                focus: 144,
              },
              {
                name: this.$parent.$t("common_00176"),
                router: "diversionServices.html#1",
                permission: "outsideOwnerInfo",
                focus: 145,
              },
              {
                name: this.$parent.$t("common_00177"),
                router: "diversionServices.html#5",
                permission: "outsideOwnerInfo",
                focus: 146,
              },
              {
                name: this.$parent.$t("common_00178"),
                router: "diversionServices.html#2",
                permission: "outsideOwnerInfo",
                focus: 147,
              },
              {
                name: this.$parent.$t("common_00318"),
                router: "diversionServices.html#6",
                permission: "outsideOwnerInfo",
                focus: 148,
              },
            ],
          },
          /* {
            name: this.$parent.$t("common_00179"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00124"),
                router: "analyzeIncome.html#whole",
                permission: "biInfo",
                focus: 146,
              },
              {
                name: this.$parent.$t("common_00118"),
                router: "analyzeCharge.html#whole",
                permission: "biInfo",
                focus: 147,
              },
              {
                name: this.$parent.$t("common_00132"),
                router: "analyzeUser.html#whole",
                permission: "biInfo",
                focus: 160,
              },
            ],
          }, */
          /* {
            name: this.$parent.$t("common_00180"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00181"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 148,
              },
              {
                name: this.$parent.$t("common_00182"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 149,
              },
              {
                name: this.$parent.$t("common_00183"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 150,
              },
              {
                name: this.$parent.$t("common_00184"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 151,
              },
              {
                name: this.$parent.$t("common_00185"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 152,
              },
            ],
          }, */
        ],
      },
      //能源中心
      energy: {
        name: this.$parent.$t("common_20016"),
        icon: "iconfont icon-nengyuan",
        iconS: "iconfont icon-nengyuan",
        selectFLAG: false,
        router: "energyHome.html",
        permissionKey: "energyStorageInfo",
        iconFLAG: true,
        active: 11,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00014"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_60010"),
                router: "energyHome.html",
                permission: "energyStorageInfo",
                focus: 2005,
              },
              {
                name: this.$parent.$t("common_20010"),
                router: "energyOperationMonitoring.html",
                permission: "energyStorageInfo",
                focus: 2000,
              },
              {
                name: this.$parent.$t("common_20011"),
                router: "revenueMonitoring.html",
                permission: "energyStorageInfo",
                focus: 2001,
              },
              {
                name: this.$parent.$t("common_61073"),
                router: "workSchedule.html",
                permission: "energyStorageInfo",
                focus: 2006,
              },
            ],
          },
          // {
          //   name: this.$parent.$t("common_20014"),
          //   icon: "el-icon-document",
          //   children: [
          //     {
          //       name: this.$parent.$t("common_20012"),
          //       router: "energyStoragePileManagement.html",
          //       permission: "energyStorageInfo",
          //       focus: 2003,
          //     }
          //   ],
          // },
          // {
          //   name: this.$parent.$t("common_20015"),
          //   icon: "el-icon-document",
          //   children: [
          //     {
          //       name: this.$parent.$t("common_20013"),
          //       router: "energyStatistics.html",
          //       permission: "energyStorageInfo",
          //       focus: 2002,
          //     }
          //   ],
          // }
        ],
      },
      //系统中心
      system: {
        name: this.$parent.$t("common_00013"),
        icon: "iconfont icon-a-bianzu29beifen5",
        iconS: "iconfont icon-xitong",
        selectFLAG: false,
        router: "systemInfoSetting.html",
        permissionKey: "dealerInfo",
        iconFLAG: true,
        active: 10,
        nopermission: true,
        isClose: false,
        child: [
          {
            name: this.$parent.$t("common_00186"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00187"),
                router: "systemInfoSetting.html",
                permission: "dealerInfo",
                focus: 154,
              },
              /* {
                name: this.$parent.$t("common_00181"),
                router: "Coupon.html",
                permission: "couponTemplateInfo",
                focus: 155,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_00188"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00182"),
                router: "accountManage.html",
                permission: "employeeInfo",
                focus: 156,
              },
              /* {
                name: this.$parent.$t("common_00183"),
                router: "accountManage.html",
                permission: "employeeInfo",
                focus: 157,
              }, */
            ],
          },
          {
            name: this.$parent.$t("common_00184"),
            icon: "el-icon-document",
            children: [
              {
                name: this.$parent.$t("common_00190"),
                router: "weChatApp.html",
                permission: "wechatoaInfo",
                focus: 158,
              },
              {
                name: this.$parent.$t("common_00191"),
                router: "alipayApp.html",
                permission: "alipayInfo",
                focus: 159,
              },
              {
                name: this.$parent.$t("common_00192"),
                router: "deviceMessageTemplate.html",
                permission: "dealerInfo",
                focus: 160,
              },
              {
                name: this.$parent.$t("common_00185"),
                router: "https://www.zhichongkeji.com/OpenAPI/",
                permission: "dealerInfo",
                focus: 161,
              },
            ],
          },
        ],
      },
      isCollapse: false,
      isFullScreen: false,
      sysName: "",
      userTitle: "",
      headImageUrl:"",
      adminImg:"",
      screenWidth: document.body.clientWidth,
      timer: false,
      widthMenu:'20px',
      flag: false,
      flagl : false,
      targetDate: null,
      timerInterval:null,
    };
  },
  props: ["isactive", "isfocus"],
  mounted() {
    // 是否绑定了微信公众号
    if(this.product=='cn'&&checkLogin('wechatOARequired')){
      this.$message({
        type: "error",
        offset: 70,
        message: this.$parent.$t("common_31005"),
      });
      setTimeout(()=>{
        window.location.href = './register.html?selectedLevel=1&stepActive=2';
      },1000)
    }
    // this.initMenuSet();
    var notifyCountUrl = requestUrl + "/message/unread/count";
    this.getNotifyCount(notifyCountUrl);
    window.onresize = () => {
      this.screenWidth = document.body.clientWidth;
    };
  },
  watch: {
    screenWidth(newValue) {
      if (!this.timer) {
        this.screenWidth = newValue;
        this.timer = true;
        setTimeout(() => {
          this.timer = false;
        }, 400);
      }
    },
    isShrink(val){
      if(!val){
        this.isCollapse = false;
      }
    }
  },
  computed: {
    isShrink() {
      let tag = this.screenWidth < 1370;
      if(tag){
        this.isCollapse = true
      }
      return tag;
    },
    menuTooltipFlag: {
      get() {
        return this.isCollapse;
      },
      set(val) {
        return val;
      }
    }
  },
  methods: {
    handleMouseDown(event) {
      this.dragging = false; // 先将拖拽状态设置为false
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.offsetX = this.buttonLeft;
      this.offsetY = this.buttonTop;
      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.handleMouseUp);
    },
    handleMouseMove(event) {
      const dx = event.clientX - this.startX;
      const dy = event.clientY - this.startY;
      if (!this.dragging && (Math.abs(dx) > 3 || Math.abs(dy) > 3)) {
        this.dragging = true; // 如果移动距离超过阈值，则将拖拽状态设置为true
        this.clickPrevented = true; // 如果发生了拖拽，则阻止点击事件的触发
      }
      if (this.dragging) {
        this.buttonLeft = this.offsetX + dx;
        this.buttonTop = this.offsetY + dy;
      }
    },
    handleMouseUp() {
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
    handleClick(event) {
      if (this.clickPrevented) {
        event.stopPropagation();
        this.clickPrevented = false; // 重置标志位，确保下次点击事件可以正常触发
      } else {
        this.FLAG_PLATFORM = true
      }
    },
    //是否显示阅读弹窗
    isShowAlert() {
      console.log(checkLogin())
      let myinfoAlert = checkLogin("contractAlert");
      let localAlert = localStorage.getItem('contractAlert');
      this.FLAG_PLATFORM_ICON = myinfoAlert;
      this.FLAG_PLATFORM = myinfoAlert && !localAlert;
      console.log(myinfoAlert)
      console.log(localAlert)
    },
    //确认阅读
    confirmRead() {
      this.FLAG_PLATFORM = false;
      localStorage.setItem('contractAlert', true);
    },
    // 搜索框
    focusEvent(){
      this.activeKey = this.cacheActiveKey  || 'order';
      this.searchFlag = true;
    },
    containerEvent(){
      this.searchFlag=false;
      this.keyword = "";
      this.cacheActiveKey = this.activeKey;
      this.activeKey = "";
    },
    // 搜索跳转
    searchEvent(){
      if(!this.activeKey) return;
      if(!this.keyword){
        this.$message.error(this.$t('common_63010'));
        return;
      }
      let url = '';
      switch (this.activeKey) {
        case 'order':
          url = `./bill.html?orderId=${this.keyword}`
          break;
        case 'wechat':
          url = `./user.html?keyword=${this.keyword}`
          break;
        case 'equipment':
          url = `./deviceMonitor.html?keyword=${this.keyword}#eq`
          break;
        case 'site':
          url = `./managementDepot.html?keyword=${this.keyword}`
          break;
        case 'online':
          url = `./managementCharger.html?keyword=${this.keyword}#U3`
          break;
        case 'plug':
          url = `./managementCharger.html?keyword=${this.keyword}#U3S`
          break;
        case 'group':
          url = `./group.html?nickname=${this.keyword}`
          break;
        default:
          break;
      }
      window.open(url,'_blank');
    },
    // 获取账户过期时间
    getExpirationTime() {
      ajax({
        type: "GET",
        url: `${requestUrl}/dealer/account`,
        dataType: "json",
        success: response => {
          const result = JSON.parse(response).result || [];
          if (!result.length) return false;
          let res = result[0];
          window.localStorage.removeItem('dealerLicenseId');
          if(!res.dealerLicenseId){
            window.localStorage.setItem("dealerLicenseId", true);
            for (let i = 0; i < this.list.length; i++) {
              if(this.list[i].active==8) {
                for (let j = 0; j < this.list[i].child.length; j++) {
                  for (let k = 0; k < this.list[i].child[j].children.length; k++) {
                    if([170].includes(this.list[i].child[j].children[k].focus)) {
                      this.list[i].child[j].children.splice(k,1)
                    }
                  }
                }
                break;
              }
            }
          }
          let automaticDays = res.automaticDays||0;
          let overdue = res.overdue; // 账户是否欠费
          let remainDays = res.remainDays; // 运营商的剩余天数
          let billingDueDay = res.billingDueDay; // 运营商的到期时间
          if (res.dealerLicense) {
            let {mode, packageId = []} = res.dealerLicense;
            if(packageId.length && (remainDays||remainDays==0)){
              if(mode==0||(mode==1&&!automaticDays)){
                // 先付费-非自动续费---免费版
                if(remainDays==0){
                  this.expirationTime.type='warning';
                  this.expirationTime.text=this.$t('common_11000');
                }else if(remainDays<=30){
                  this.expirationTime.type='warning';
                  this.expirationTime.text=this.$t('common_11001',[remainDays]);
                }else{
                  this.expirationTime.type='normal';
                  this.expirationTime.text=this.$t('common_11001',[remainDays]);
                }
              }else if(mode==1&&automaticDays){
                // 先付费-自动续费
                if(remainDays==0){
                  this.expirationTime.type='warning';
                  this.expirationTime.text=this.$t('common_11000');
                }else if(remainDays<=3){
                  this.expirationTime.type='warning';
                  this.expirationTime.text=this.$t('common_11002',[remainDays]);
                }else{
                  this.expirationTime.type='normal';
                  this.expirationTime.text=`${this.$t('common_11001',[remainDays])}(${this.$t('common_11005')})`;
                }

              }
            }
            if(mode==2&&overdue){
              // 后付费且已欠费
              if(!remainDays){
                this.expirationTime.type='warning';
                this.expirationTime.text=this.$t('common_110001');
              }else{
                this.expirationTime.type='warning';
                // this.expirationTime.text=this.$t('common_11003',[remainDays]);
                //倒计时
                this.targetDate = new Date(getDateFromLongTime(billingDueDay)).getTime();
                console.log(getDateFromLongTime(billingDueDay),'getDateFromLongTime(billingDueDay)')
                // 每秒更新倒计时显示
                this.timerInterval = setInterval(this.updateCountdown, 1000);
                
              }
            }
          }
        },
      });
    },


    updateCountdown() {
      const now = new Date().getTime();
      var timeRemaining = this.targetDate - now;
      if(timeRemaining<=0){
        clearInterval(this.timerInterval)
        timeRemaining = 0;
      }
      // 计算剩余的天、小时、分钟和秒
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
      // 更新倒计时显示
      let countdownElement = `${days}天${hours}小时${minutes}分钟${seconds}秒`;
      this.expirationTime.text= this.$t('common_11003',[countdownElement]);
    },
    // 获取储能桩站点列表
    getSites() {
      ajax({
        type: "GET",
        url:
          requestUrl +
          "/site?brief=true&orderColumn=title&order=asc&energySite=true",
        dataType: "json",
        success: (response) => {
          const result = JSON.parse(response).result;
          if(!result||(result&&!result.length)) {
            for (var i = 0; i < this.list.length; i++) {
              if(this.list[i].active==11) {
                this.list.splice(i,1);
              }
            }
          }
        },
      });
    },
    // 跳转老版平台
    toV2(){
      this.$alert(this.$t('common_10009'), this.$t('common_00265'), {
          dangerouslyUseHTMLString:true,
          showCancelButton: true,
          confirmButtonText: this.$t('common_00234'),
          cancelButtonText: this.$t('common_00215'),
          lockScroll: false,
          callback: action => {
              if (action == 'confirm') {
                let url = 'http://service.xcharger.net/v2/';
                if(this.product!='cn'){
                  url = 'https://eu-service.xcharger.net/v2';
                }
                window.open(url);
              } else if (action == 'cancel') {
                  
              }
          }
      })
    },
    mobileCollapse(v){
      this.isCollapse = true
    },
    // 判断当前设备
    isPCFn(){
      let sUserAgent = window.navigator.userAgent.toLowerCase();
      let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";//判断是否为iPad
      let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";//判断是否为iPhone用户
      let bIsMidp = sUserAgent.match(/midp/i) == "midp";
      let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      let bIsAndroid = sUserAgent.match(/android/i) == "android";
      let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  
      if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
          this.isPc = true; // 电脑打开
      }else{
          this.isPc = false; // 手机打开
      }
    },
    // 一级标题跳转页面
    tolink(val){
      let {router,permissionKey,active,child} = val
      if(!this.isPc) {
        let refValue=false
        let menuKey='menu'+val.active
        if(this.$refs[menuKey]){
          refValue=this.$refs[menuKey][0].$el.className.includes('is-opened')
        }
        active == this.catchActive&&refValue?val.isClose = !val.isClose:val.isClose=false;
        this.catchActive = active;
        if(!child||!child.length){
          let url = `./${router}`;
          let targetType = '_self';
          window.open(url,targetType);
        }
        return
      }
      if(router=='#') return;
      if(this.noPermissions.includes(permissionKey)) return;
      let url = `./${router}`;
      let targetType = '_self';
      if(router=='analyzeBI.html'){
        targetType = '_blank';
      }
      window.open(url,targetType);
    },
    // 未读信息数请求
    getRefundCount(url, callback) {
      var om = this;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: function(response) {
          callback(JSON.parse(response));
        }
      });
    },
    changeSelect(){
      document.cookie = 'spvid='+this.sourceInfo
      window.location.href = './preview.html'
    },
    menuOver(){
      window.setTimeout(this.menuOverReal, 300);
    },
    menuLeave(){
      window.setTimeout(this.menuLeaveReal, 1);
    },
    menuLeaveReal(){
      if(this.flagl)
        return
      this.flagl = true;
      let ops= document.querySelectorAll('.el-menu--vertical')
      let elwidth= document.getElementById('menu').offsetWidth;
      for (let p of ops) {
        let a = p.getAttribute("special_sign");
        if(a==888)
        {
          p.setAttribute("special_sign", 0);
        }
      }
      this.flagl = false
    },
    menuOverReal(){
      if(this.flag)
        return;
      this.flag = true;
      let ops= document.querySelectorAll('.el-menu--vertical')
      let elwidth= document.getElementById('menu').offsetWidth;
      console.log('menuOver', elwidth, this.isCollapse, this.isShrink)
      if(this.isCollapse || this.isShrink) {
        elwidth = 65
      } else{
        elwidth = 200
      }
      for (let p of ops) {
        if(p.classList.contains("el-zoom-in-left-enter-active") || p.classList.contains("el-zoom-in-left-enter-to"))
        {
          console.log("detected ele animation class");
          p.classList.remove("el-zoom-in-left-enter-active");
          p.classList.remove("el-zoom-in-left-enter-to");
          p.setAttribute("special_sign", 888);
          if(elwidth == 65 ){
            p.classList.remove("show2");
            p.classList.add("show");
          } else{
            p.classList.remove("show");
            p.classList.add("show2");
          }
        }
        else
        {
          if(elwidth == 65 ){
            p.classList.remove("show");
          } else{
            p.classList.remove("show2");
          }
        }
      }
      this.flag = false;
    },
    checkIdType(type) {
      return checkIdType(type);
    },
    //菜单隐藏
    initMenuSet(){
      var hideList = Array.prototype.slice.call(document.querySelectorAll('.el-menu-item-group ul'))
      hideList.forEach((e,index)=>{
        var childList = Array.prototype.slice.call(e.children);
        var temp = childList.filter((item) => {
            return item.style.cssText.search('display: none') < 0
        })
        if (temp.length === 0) {
          hideList[index].parentNode.parentNode.removeChild(hideList[index].parentNode);
        }
      })

      //隐藏模块
      var menuList = Array.prototype.slice.call(document.getElementsByClassName('el-submenu'))
      menuList.forEach((e,index) => {
        var childList = Array.prototype.slice.call(e.children)
        if(childList[1]){
          var itemMenu = Array.prototype.slice.call(childList[1].children[0].children)
          if (itemMenu.length === 0) {
              menuList[index].style.display = "none"
          }
        }
      })
    },
    //修改密码
    check() {
      if (!this.password) {
        this.$message.error(this.$t("common_00251"));
        return false;
      }
      if (!this.newPassword) {
        this.$message.error(this.$t("common_00252"));
        return false;
      }
      if (!this.newPassword1) {
        this.$message.error(this.$t("common_00253"));
        return false;
      }
      if (this.newPassword != this.newPassword1) {
        this.$message.error(this.$t("common_00254"));
        return false;
      }
      return true;
    },
    close() {
      this.FLAG_CHANGE_PW = false;
    },
    //修改密码
    uptPassword() {
      if (!this.check()) return;
      var param = {};
      param.loginId = this.loginId;
      param.password = this.password;
      param.newPassword = this.newPassword;
      var url = requestUrl + "/password";
      ajax({
        type: "POST",
        url: url,
        dataType: "json",
        async: false,
        data: JSON.stringify(param),
        success: (response) => {
          var info = JSON.parse(response);
          if (info.error) {
            this.$message.error(
              info.error.msg ? info.error.msg : info.error.code
            );
          } else {
            if (info.result) {
              this.$message({
                message: this.$t("common_00201"),
                type: "success",
              });
              this.FLAG_CHANGE_PW = false;
            }
          }
        },
      });
    },
    operationMenu() {
      this.isCollapse = !this.isCollapse;
      console.log('Menu Closed:',this.isCollapse)
    },

    screenfull() {
      this.isFullScreen = !this.isFullScreen;
      screenfull.toggle();
    },
    //退出登录
    logout() {
      let object = new Object();
      object.loginId = this.loginId;
      ajax({
        type: "POST",
        url: `${requestUrl}/logout`,
        data: JSON.stringify(object),
        dataType: "json",
        success: (data) => {
          if (this.roleId == "1013636002184695808") {
            window.location.href = "http://www.elecbay.cn/xjyy";
          } else if (this.roleId == "1013637748365725696") {
            window.location.href = "http://www.elecbay.cn/service/";
          } else {
            window.location.href = "./login.html";
          }
        },
      });
    },
    // 跳转通知页面
    toNotify() {
      window.open("./notify.html");
    },
    // 未读通知条数
    getNotifyCount(url) {
      var om = this;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: function (response) {
          var totalCount = JSON.parse(response).totalCount;
          om.notifyCount = totalCount;
        },
      });
    },
    toIndex(){
      window.location.href = "./preview.html";
    }
  },
  created() {
    this.isShowAlert();
    this.isPCFn();
    this.lang = window.localStorage.lang;
    this.product = window.product_area;
    // 正确运营商注册时间字段
    // var dealerCreateTime = checkLogin("dealerCreateTime");
    // if(dealerCreateTime){
    //   20220228000000>Number(dealerCreateTime)?this.isOlduser = true:this.isOlduser = false;
    // }
    // 后端暂未上线正确字段--暂替字段
    /* var roles = checkLogin("roles");
    if(roles.createTime){
      20220228000000>Number(roles.createTime)?this.isOlduser = true:this.isOlduser = false;
    }
    var permissions = checkLogin("permissionsV2");
    let noPermissions = [];
    var permissionsArr = [];
    for(var i in permissions){
      permissionsArr.push(i)
      if(permissions[i][0]==0||permissions[i][1]==0){
        noPermissions.push(i)
      }
    }
    this.noPermissions = noPermissions;
    if(
      checkLogin("permissionsV2").siteInfo[0] === 2 &&
      checkLogin("permissionsV2").siteInfo[1] === 1
    ){
      this.creatFlag = true
    }
    if (permissionsArr) {
      this.getSites();
      if(window.product_area=='eu'){
        //场站中心
          for (var i = 0; i < this.site.child.length; i++) {
            for(var j = 0; j < this.site.child[i].children.length;j++){
              if(this.site.child[i].children[j].focus == 2010) {
                this.site.child[i].children.splice(j,1)
              }
            }
          }
        // 订单中心
        for (var i = 0; i < this.order.child.length; i++) {
          for(var j = 0; j < this.order.child[i].children.length;j++){
            if([30,33,34].includes(this.order.child[i].children[j].focus)) {
              this.order.child[i].children.splice(j,1)
            }
          }
        }
        // 用户中心
        //uu
        this.user.router = 'chargeCard.html';
        this.user.permissionKey = 'nfcgroupInfo';
        for (var i = 0; i < this.user.child.length; i++) {
          for(var j = 0; j < this.user.child[i].children.length;j++){
            if([51,52,53,65,165,60,61,54,55,58,2004].includes(this.user.child[i].children[j].focus)) {
              this.user.child[i].children.splice(j,1);
              j--;
            } else if([64,230].includes(this.user.child[i].children[j].focus)) {
              this.user.child[i].children.splice(j,1)
            }
          }
        }
        // 电源模块保养
        this.maintenance.router = "deviceMonitor.html#maint";
        for (var i = 0; i < this.maintenance.child.length; i++) {
          for(var j = 0; j < this.maintenance.child[i].children.length;j++){
            if([40,1005,200,2100].includes(this.maintenance.child[i].children[j].focus)) {
              this.maintenance.child[i].children.splice(j,1)
            }
          }
        }
        //营销中心
        this.activity = {};
        //财务中心
        this.finance = {};
        // 流量中心
        this.flow = {};
        //安全中心
        this.security = {}
        // 数据中心
        this.record.router = "analyzeXcharge.html#whole";
        for (var i = 0; i < this.record.child.length; i++) {
          if(this.record.child[i].isCard){
             this.record.child.splice(i,1);
             i--;
             continue;
          }
          for(var j = 0; j < this.record.child[i].children.length;j++){
            if([88,90,95,101,106,107,108].includes(this.record.child[i].children[j].focus)) {
              this.record.child[i].children.splice(j,1);
              j--;
            }
          }
        }
        // 系统中心
        for (var i = 0; i < this.system.child.length; i++) {
          for(var j = 0; j < this.system.child[i].children.length;j++){
            if([158,159,160,161].includes(this.system.child[i].children[j].focus)) {
              this.system.child[i].children.splice(j,1)
              j--;
            }
          }
        }
      }else{
        //加盟商管理员权限
        if (checkIdType("dealerLevel2")) {
          //场站中心
          for (var i = 0; i < this.site.child.length; i++) {
            for(var j = 0; j < this.site.child[i].children.length;j++){
              if(this.site.child[i].children[j].focus == 26) {
                this.site.child[i].children.splice(j,1)
              }
            }
          }
          // 数据中心
          for (var i = 0; i < this.record.child.length; i++) {
            for(var j = 0; j < this.record.child[i].children.length;j++){
              if(
                this.record.child[i].children[j].focus == 91 ||
                this.record.child[i].children[j].focus == 96 ||
                this.record.child[i].children[j].focus == 102 ||
                this.record.child[i].children[j].focus == 109 
                ) {
                this.record.child[i].children.splice(j,1)
              }
            }
          }
          //订单中心
          for (var i = 0; i < this.order.child.length; i++) {
            for(var j = 0; j < this.order.child[i].children.length;j++){
              if(this.order.child[i].children[j].focus == 33) {
                this.order.child[i].children.splice(j,1)
              }
            }
          }
          //营销中心
          //隐藏  有问题可能
          this.activity.child[0].children[2] = {};
          this.activity.child[0].children[3] = {};
          this.activity.child[1].children[1] = {};
          this.activity.child[1].children[4] = {};
          this.activity.child[1].children[3] = {};
          this.activity.child[2].children[0] = {};
          this.activity.child[3].children[0] = {};
          this.activity.child[3].children[1] = {};
          this.activity.child[4].children[0] = {};
          this.activity.child[4].children[1] = {};
          this.activity.child[5].children[0] = {};
          this.activity.child[5].children[1] = {};
          this.activity.child[5].children[2] = {};
          
          
          //用户中心
          this.user.child[0].children= [];
          this.user.child[1].children[1] = {}; //隐藏特权会员  有问题可能
          for (var i = 0; i < this.user.child.length; i++) {
            for(var j = 0; j < this.user.child[i].children.length;j++){
              if(
                this.user.child[i].children[j].focus == 64 ||
                this.user.child[i].children[j].focus == 65
                ) {
                this.user.child[i].children.splice(j,1)
              }
            }
          }
          //财务中心
          this.finance = {};
        }
        //站点管理员权限
        if (checkIdType("site")){
          //场站中心
          for (var i = 0; i < this.site.child.length; i++) {
            for(var j = 0; j < this.site.child[i].children.length;j++){
              if(this.site.child[i].children[j].focus == 9) {
                this.site.child[i].children.splice(j,1)
              }
            }
          }
          //维保中心
          for (var i = 0; i < this.maintenance.child.length; i++) {
            for(var j = 0; j < this.maintenance.child[i].children.length;j++){
              if(this.maintenance.child[i].children[j].focus == 43) {
                this.maintenance.child[i].children.splice(j,1)
              }
            }
          }
          //营销中心
          this.activity.child[0].children[2] = {};
          this.activity.child[0].children[3] = {};
          this.activity.child[1].children[1] = {};
          this.activity.child[2].children[0] = {};
          this.activity.child[3].children[0] = {};
          this.activity.child[3].children[1] = {};
          this.activity.child[4].children[0] = {};
          this.activity.child[4].children[1] = {};
          this.activity.child[5].children[0] = {};
          this.activity.child[5].children[1] = {};
          this.activity.child[5].children[2] = {};
          //整体概览
          this.overview = {};
          //用户中心
          this.user ={}
        }
        //国内屏蔽场站地图
        for (var i = 0; i < this.site.child.length; i++) {
          for(var j = 0; j < this.site.child[i].children.length;j++){
            if([15].includes(this.site.child[i].children[j].focus)) {
              this.site.child[i].children.splice(j,1)
              break;
            }
          }
        }
        // energy能源中心 (非智充运营商隐藏能源中心)
        // 测试环境751340478032646144 正式环境784964326829000001  755405840063930368
        // if(!(["784964326829000001","755405840063930368","751340478032646144"].includes(getLoginInfo().id))){
        //   this.energy = {};
        //   this.security = {}
        // }
        //站点管理员屏蔽掉用户管理
        if(checkIdType("site") && this.user.child && this.user.child[0]){
          let userList = this.user.child[0].children || []
          let ifHas = userList.some((v,k)=>{
            return v.focus == 51
          })
          console.log(ifHas)
          ifHas && (this.user.child[0] = [])
        }
      }
      
      if(Object.keys(this.finance).length){
        //查询退款申请数量
        if(checkLogin("permissionsV2").refundInfo&&checkLogin("permissionsV2").refundInfo[1] != 0) {
          var url =
            requestUrl +
            "/wechatrefund?from=0&length=1&returnCount=true&status=unfinished";
          this.getRefundCount(url, res => {
            if(res.error) return;
            let count = res.totalCount;
            for (var i = 0; i < this.finance.child.length; i++) {
              for(var j = 0; j < this.finance.child[i].children.length;j++){
                if([121].includes(this.finance.child[i].children[j].focus)) {
                  this.finance.child[i].children[j].notify = count;
                  break;
                }
              }
            }
          });
        }
        //查询个人发票申请数量
        if(checkLogin("permissionsV2").invoiceInfo&&checkLogin("permissionsV2").invoiceInfo[1] != 0) {
          var url = requestUrl + "/wechat/invoice/stats?userType=wechat";
          this.getRefundCount(url, res => {
            if(res.error) return;
            let count = res.result;
            for (var i = 0; i < this.finance.child.length; i++) {
              for(var j = 0; j < this.finance.child[i].children.length;j++){
                if([119].includes(this.finance.child[i].children[j].focus)) {
                  this.finance.child[i].children[j].notify = count.processingNum;
                  break;
                }
              }
            }
          });
        //查询团体发票申请数量
          var url = requestUrl + "/wechat/invoice/stats?userType=userGroup";
          this.getRefundCount(url, res => {
            if(res.error) return;
            let count = res.result;
            for (var i = 0; i < this.finance.child.length; i++) {
              for(var j = 0; j < this.finance.child[i].children.length;j++){
                if([120].includes(this.finance.child[i].children[j].focus)) {
                  this.finance.child[i].children[j].notify = count.processingNum;
                  break;
                }
              }
            }
          });
        }
        // 自助缴费未读数
        if(checkLogin("permissionsV2").dealerAccountInfo&&checkLogin("permissionsV2").dealerAccountInfo[1] != 0) {
          var url = requestUrl + "/dealer/bill/stats";
          this.getRefundCount(url, res => {
            if(res.error) return;
            let count = res;
            for (var i = 0; i < this.finance.child.length; i++) {
              for(var j = 0; j < this.finance.child[i].children.length;j++){
                if([138].includes(this.finance.child[i].children[j].focus)) {
                  this.finance.child[i].children[j].notify = count.unpaidCount;
                  break;
                }
              }
            }
          });
        }
      }
      if(Object.keys(this.user).length){
        //统计微信公众号未读消息数
        if(checkLogin("permissionsV2").wechatFeedbackInfo&&checkLogin("permissionsV2").wechatFeedbackInfo[1] != 0) {
          var url =
            requestUrl +
            "/wechatOAMsg/unread/count";
          this.getRefundCount(url, res => {
            if(res.error) return;
            let count = res.totalCount;
            for (var i = 0; i < this.user.child.length; i++) {
              for(var j = 0; j < this.user.child[i].children.length;j++){
                if([64].includes(this.user.child[i].children[j].focus)) {
                  this.user.child[i].children[j].notify = count;
                  break;
                }
              }
            }
          });
        }
      }
    }
    this.list.push(
      this.platformHome,
      this.product=='cn'?this.overview:this.depotMap,
      this.site,
      this.order,
      this.maintenance,
      this.security,
      this.user,
      this.activity,
      this.record,
      this.finance,
      this.flow,
      this.energy,
      this.system,
    );
    this.$nextTick(()=>{
      if(this.product=='cn' && checkLogin("permissionsV2").biInfo[0] === 0 && checkLogin("permissionsV2").biInfo[1] === 0) {
        this.list.splice(1,1);
      }
    }) */
    let loginInfo = checkLogin("name");
    this.sysName = loginInfo.name;
    this.adminImg = checkLogin("imgUrl");
    let info = getLoginInfo();
    this.userTitle = info.username ? info.username : info.loginId;
    this.headImageUrl = info.headImageUrl;
    this.selectList = info.resources
    this.isSupervisor = info.isSupervisor
    this.loginId = info.loginId;
    this.product == 'cn' && this.getExpirationTime();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/font/iconfont.css";
.platform_dialog{
  height: calc(100vh - 200px);
  overflow-y: scroll;
  .title{
    h3{
      font-size: 20px;
      color: #1E2633;
      padding-bottom: 10px;
    }
    p{
      font-size: 14px;
      color: #1E2633;
      line-height: 22px;
      padding-bottom: 6px;
    }
  }
  .introduce{
    h4{
      margin-top: 30px;
      font-size: 16px;
      color: #1E2633;
      line-height: 22px;
    }
    p{
      font-size: 14px;
      color: #1E2633;
    }
    .ph{
      p{
        padding-top: 10px;
        line-height: 22px;
      }
      .underline{
        text-decoration: underline;
        font-weight: bold;
      }
      .right{
        display: flex;
        justify-content: right;
        padding-top: 5px;
      }
    }
    .table{
      margin-top: 18px;
      .indent{
        text-indent: 2em;
      }
      .y{
        font-size: 14px;
        font-weight: 600;
        color: #F7B500;
      }
      .g{
        font-size: 14px;
        font-weight: 600;
        color: #00A27B;
      }
      .y-table{
        width: 920px;
        height: 280px;
        padding-top: 8px;
      }
      .y1-table{
        width: 920px;
        height: 400px;
        padding-top: 8px;
      }
      .g-table{
        width: 411px;
        height: 122px;
        padding-top: 8px;
      }
      .g1-table{
        width: 920px;
        height: 604px;
        padding-top: 8px;
      }
      .process{
        width: 922px;
        height: 90px;
        padding-top: 8px;
      }
    }
  }
  .btn{
    margin: 20px 0;
    text-align: center;
  }
}
.container {
  background: #f7f7fa;
  .platformIcon{
    position: fixed;
    width: 160px;
    height: 110px;
    background: url(../assets/img/r-info.png);
    background-size:160px 110px;
    cursor: pointer;
    z-index: 9999;
    &.mobile_icon{
      width: 96px;
      height: 66px;
      background: url(../assets/img/r-info.png);
      background-size:96px 66px;
    }
    > .close {
      position: absolute;
      top: -8px;
      right: 2px;
      font-size: 22px;
      color: #8087A0;
    }
  }
  .heade-bar {
    position: fixed;
    top: 0;
    z-index:2000;
    width: 100%;
    display: flex;
    height: 60px;
    line-height: 60px;
    background: #fff;
    box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
    border-bottom: 1px solid #ececec;
    .collapse-switcher {
      width: 40px;
      text-align: center;
      cursor: pointer;
      color: #9097ac;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 40px;
        margin-left: 10px;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        &:hover {
          background: #eef2fd;
          transition: 0.4s;
        }
      }
    }
    .logo {
      flex: 1;
      height: 60px;
      line-height: 60px;
      margin-left: 10px;
      white-space: nowrap;
      img {
        margin: 12px 10px 0;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        cursor: pointer;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        color: #212943;
        vertical-align: top;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    .full-screen {
      display: flex;
      cursor: pointer;
      color: #8087a0;
      padding-right: 20px;
      align-items: center;
      .search_box{
        position: relative;
        .search_inp{
          width: 320px;
          margin-right: 30px;
        }
        .search_btn{
          position: absolute;
          right: 21px;
          top: 11px;
          height: 40px;
          border-radius: 0 4px 4px 0;
        }
        .search_submenu{
          display: flex;
          flex-wrap: wrap;
          position: absolute;
          top: 55px;
          left: 0;
          width: 340px;
          padding: 10px 0 4px 10px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #E6E7EC;
          li{
            width: 30%;
            margin-right: 10px;
            background: #EFF2F6;
            text-align: center;
            line-height: 24px;
            height: 24px;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1C2237;
            padding: 3px 0;
            margin-bottom: 6px;
            &.active{
              font-weight: 600;
              color: #FFFFFF;
              background: #5278E2;
            }
          }
        }
      }
      .time_wrap{
        margin-right: 30px;
        padding: 3px 14px;
        border-radius: 14px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 1.2;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        &.normal_time{
          background: #EDF1FC;
          color: #8087A0;
        }
        &.warning_time{
          background: #FEEEEF;
          color: #FA545E;
        }
      }
      .el-badge {
        height: 40px;
        line-height: 40px;
      }
      .iconfont {
        font-size: 40px;
        margin: 0 10px;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        &:hover {
          background: #eef2fd;
          transition: 0.4s;
        }
      }
    }
    .tool-bar {
      white-space: nowrap;
      .theme-picker {
        padding-right: 10px;
      }
      .lang-selector {
        padding-right: 10px;
        font-size: 15px;
        color: #fff;
        cursor: pointer;
      }
      .user-info-dropdown {
        font-size: 14px;
        padding-right: 20px;
        color: #2b3041;
        cursor: pointer;
        img {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          margin: 12px;
          float: left;
          border-radius: 35px;
        }
      }
    }
  }
  .myMain {
    display: flex;
    margin-top: 60px;
    .main-con {
      padding: 20px;
      // min-width: 1242px;
      min-width: 960px;
      flex: 1; 
      // margin-left: auto;
      box-sizing: border-box;
      // width:calc(100% - 200px); 
      // border: 1px solid red;
      // width:800px;
      // transition: all 0.3s ease;
      
      &.w100{
        // width:100px;
        // transform: translateX(0px);
        // transition: all 3s ease;
      }

    }
  }


  .m-title{
    padding-left: 40px;
    padding-right: 20px !important;
    &.p20{
      padding-left: 20px!important;
    }
  }


}

.dialog-changePw {
  color: #41526d;
  .model {
    margin-bottom: 10px;
    .title {
      margin-bottom: 6px;
    }
  }
  .buttons {
    text-align: right;
  }
}

.icon-a-changzhanbeifen4,
.icon-a-dingdanbeifen5,
.icon-weibao,
.icon-anquan,
.icon-yonghu,
.icon-yingxiao,
.icon-shuju,
.icon-caiwu1,
.icon-liuliang,
.icon-xitong {
  color: #5278e2 !important;
  transition: 0.3s;
}
/deep/.el-badge__content {
  min-width: 12px;
  box-sizing: content-box;
  height: 16px !important;
  line-height: 16px !important;
  padding: 0 2px !important;
  &.is-fixed {
    top: 10px !important;
    right: 30px !important;
  }
}
//新菜单样式
.menu-con{
  width:200px;
  transition: all 0.3s ease;
  z-index: 3999;
  &.pc_index{
    z-index: 1000;
  }
  &.w260{
    width:260px;
  }
  &.w280{
    width:280px;
  }
  &.hided{
    width:0px;
    transition: all 1s ease;
    
  }
}
</style>
<style lang="scss">
.my-menu-tooltip {
  top: 47px !important;
}
.my-lang-select-dropdown {
  right: 20px !important;
  left: inherit !important;
}
.el-dialog__header {
  background: #eff2f6;
  font-weight: 600;
  padding: 0 0 0 20px !important;
  line-height: 60px;
}
.el-dialog__header span {
  color: #8087a0;
}

.del-btn {
  color: #fa545e !important;
  border-color: #fa545e !important;

  &:hover {
    color: #fa545e !important;
    border-color: #fa545e !important;
    font-weight: 600 !important;
    background: white !important;
  }
}
</style>
