export default {
    groupDetail_00001: "个人支付团体详情",
    groupDetail_00002: "集团支付团体详情",
    groupDetail_00003: "分配金额给成员",
    groupDetail_00004: "优惠场站设置",
    groupDetail_00005: "成员管理",
    groupDetail_00006: "认车不认人设置",
    groupDetail_00007: "运营设置",
    groupDetail_00008: "团体管理",
    groupDetail_00009: "导出团体成员",
    groupDetail_00010: "删除团体",
    groupDetail_00011: "删除团体后不可恢复",
    groupDetail_00012: "查询订单",
    groupDetail_00013: "此操作将下载团体用户的Excel文件, 是否继续？",
    groupDetail_00014: "确定将团体{name}删除吗?",
    groupDetail_00015: "操作已取消",
    groupDetail_00016: "基本设置",
    groupDetail_00017: "团体名称",
    groupDetail_00018: "支付方式",
    groupDetail_00019: "特权设置",
    groupDetail_00020: "单个成员最多未支付金额({unit})",
    groupDetail_00021: "单个成员最多未支付单数（笔）",
    groupDetail_00022: "是否接受充电桩开放时间限制",
    groupDetail_00023: "个人支付",
    groupDetail_00024: "集团支付",
    groupDetail_00025: "{num}笔",
    groupDetail_00026: "是否允许使用优惠券",
    groupDetail_00027: "是否支持团体自助端充值",
    groupDetail_00028: "分配账户信息",
    groupDetail_00029: "余额",
    groupDetail_00030: "充值记录",
    groupDetail_00031: "账户充值",
    groupDetail_00032: "包含赠送{promotionBalance}{unit}",
    groupDetail_00033: "请保存上一步操作！",
    groupDetail_00034: "站点名称",
    groupDetail_00035: "站点列表",
    groupDetail_00036: "添加优惠站点",
    groupDetail_00037: "批量移除",
    groupDetail_00038: "当前资费模板",
    groupDetail_00039: "当前优惠",
    groupDetail_00040: "操作",
    groupDetail_00041: "直流",
    groupDetail_00042: "交流",
    groupDetail_00043: "单次充电总费用不打折",
    groupDetail_00044: "单次充电服务费不打折",
    groupDetail_00045: "单次充电服务费{des}折",
    groupDetail_00046: "单次充电总费用{des}折",
    groupDetail_00047: "单次充电总费用全免",
    groupDetail_00048: "单次充电服务费全免",
    groupDetail_00049: "资费优惠设置",
    groupDetail_00050: "站点详情",
    groupDetail_00051: "移除",
    groupDetail_00052: "您确认从团体中删除该充电站?",
    groupDetail_00053: "已取消删除",
    groupDetail_00054: "资费标准",
    groupDetail_00055: "如果资费标准有修改，在站点详情-资费标准中点击“推送资费”按钮，资费才可以生效！",
    groupDetail_00056: "交流资费",
    groupDetail_00057: "直流资费",
    groupDetail_00058: "选择交流资费标准",
    groupDetail_00059: "选择直流资费标准",
    groupDetail_00060: "单次充电总费用不打折（%）",
    groupDetail_00061: "单次充电服务费打折（%）",
    groupDetail_00062: "85表示8.5折，0表示全免",
    groupDetail_00063:"举例说明：如设置单次充电服务费8.5折，此次充电费用100{unit}(含电费70{unit}、服务费30{unit})，则此次需实际支付金额95.5{unit}(70+30*85%=95.5)。",
    groupDetail_00064: "单次充电总费用打折（%）",
    groupDetail_00065:"举例说明：如设置单次充电总费用8.5折，此次充电总费用100{unit}，则此次需实际支付金额85{unit}(100*85%=85)。",
    groupDetail_00066: "保存设置",
    groupDetail_00067: "完成并立即推送",
    groupDetail_00068: "是否分配金额给团体成员",
    groupDetail_00069: "团体信息",
    groupDetail_00070: "团体名称",
    groupDetail_00071: "当前余额：{balance}{unit}",
    groupDetail_00072: "包含赠送{promotionBalance}{unit}",
    groupDetail_00073: "填写充值金额",
    groupDetail_00074: "赠送",
    groupDetail_00075: "登录密码",
    groupDetail_00076: "请输入密码",
    groupDetail_00077: "是否开启认车不认人设置",
    groupDetail_00078: "用户昵称、手机号",
    groupDetail_00079: "添加团体成员",
    groupDetail_00080: "用户昵称(手机号)",
    groupDetail_00081: "账号设置",
    groupDetail_00082: "设为管理员",
    groupDetail_00083: "取消管理员",
    groupDetail_00084: "移除",
    groupDetail_00085: "用户详情",
    groupDetail_00086: "您确定将[{nickname}]从团体中删除？",
    groupDetail_00087: "该用户还有团体分配余额{num}元,删除后系统将自动收回。",
    groupDetail_00088: "微信用户成员",
    groupDetail_00089: "充电卡成员",
    groupDetail_00090: "卡号",
    groupDetail_00091: "卡列表",
    groupDetail_00092: "用户姓名",
    groupDetail_00093: "请输入昵称、手机号、卡号、多个查询条件支持以逗号（，）、换行、空格、顿号（、）分割",
    groupDetail_00094: "查询结果",
    groupDetail_00095: "清除",
    groupDetail_00096: "已添加",
    groupDetail_00097: "添加",
    groupDetail_00098: "已设置",
    groupDetail_00099: "设为管理员",
    groupDetail_00100: "用户列表",
    groupDetail_00101: "卡详情",
    groupDetail_00102: "1、如设置“是否允许使用优惠券”为“是”，则团体用户支付订单时，可以使用优惠券。",
    groupDetail_00103: "1.1、支付订单时优先使用团体绑定站点资费打折，使用团体绑定站点资费打折后的账单金额再根据优惠券满足条件选择使用的优惠券。",
    groupDetail_00104: "1.2、举例说明：<br/>团体用户此次充电费用100{unit}（含电费70{unit}、服务费30{unit}），可享受团体绑定站点资费打折（单次充电服务费8.5折），有1张优惠券（单次充电服务费满足20{unit}服务费9折），<br/>则使用团体绑定站点资费打折后，账单金额为95.5{unit}（含电费70{unit}，服务费25.5{unit}（30*85%）），满足优惠券（单次充电服务费满足20{unit}服务费9折）。<br/>则使用优惠券后，账单金额为92.95{unit}（含电费70{unit}+服务费22.95{unit}（25.5*90%）），最终此次需实际支付92.95{unit}。",
    groupDetail_00105: "2、如设置“是否允许使用优惠券”为“否”，团体用户支付订单时不能使用优惠券。",
    groupDetail_00106: "开启认车不认人设置后，第一次在团体绑定的优惠站点充电，将会记录团体成员车辆对应的VIN，一个团体成员只能对应一个车辆。后续在本团体的优惠站点充电，只有团体成员绑定VIN的车辆才能享受该团体设置的站点资费优惠。",
    groupDetail_00107: "• 建议开启该设置后，团体成员需要尽快在团体优惠站点充一次电进行VIN的绑定。",
    groupDetail_00108: "• 启用本设置后，团体成员将无法使用交流桩充电。",
    groupDetail_00109: "参数非法",
    groupDetail_00110: "请输入您要查询的昵称、手机号或卡号",
    groupDetail_00111: "操作已取消",
    groupDetail_00112: "移除成功",
    groupDetail_00113: "是否支持自助管理端在线申请发票",
    groupDetail_00114: "集团账户",
    groupDetail_00115: "集团账户信息",
    groupDetail_00116: "最低余额限制({unit})",
    groupDetail_00117: "资金账户支付模式时，账户余额低于最低余额时，团体内的用户无法启动充电,（设置为0{unit}则代表不限制）",
    groupDetail_00118: "提醒余额({unit})",
    groupDetail_00119: "资金账户支付模式时，账户余额低于提醒余额时，团体管理员会收到提醒短信。（设置为0{unit}则代表不提醒）",
    groupDetail_00120: "只能输入0或正整数",
    groupDetail_00121: "使用货币",
    groupDetail_00122: "",
    groupDetail_00123: "",
    groupDetail_00124: "",
    // 20220124--添加优惠站点弹窗
    groupDetail_10000: "添加优惠站点",
    groupDetail_10001: "请输入站点名称或运营商名称",
    groupDetail_10002: "批量添加",
    groupDetail_10003: "站点名称",
    groupDetail_10004: "运营商",
    groupDetail_10005: "充电桩",
    groupDetail_10006: "站点状态",
    groupDetail_10007: "操作",
    groupDetail_10008: "添加",
    groupDetail_10009: "已添加",
    groupDetail_10010: "已启用",
    groupDetail_10011: "未启用",
    groupDetail_10012: "交流",
    groupDetail_10013: "直流",
    groupDetail_10014: "下一步",
    groupDetail_10015: "请选择资费",
    groupDetail_10016: "是否允许使用代金券",
    groupDetail_10017: '1、如设置“是否允许使用代金券”为“是”，则团体用户支付订单时，可以使用代金券。',
    groupDetail_10018: "1.1、支付订单时优先使用团体绑定站点资费打折，使用团体绑定站点资费打折后的账单金额再使用代金券抵扣。",
    groupDetail_10019: '1.2、使用代金券抵扣时，如果车主同时拥有"现金抵用电费及服务费"类型和"用现金抵用服务费"类型的代金券，优先使用"现金抵用电费及服务费"类型的代金券，不使用“用现金抵用服务费”类型的代金券。',
    groupDetail_10020: '1.3、举例说明:<br/>团体用户此次充电费用100{unit}(含电费70{unit}、服务费30{unit})，可享受团体绑定站点资费打折(单次充电服务费8.5折)，有可用"现金抵用电费及服务费"类型的2张代金券的总可用余额为50{unit}。<br/>则使用团体绑定站点资费打折后，账单金额为95.5{unit}(含电费70{unit}，服务费25.5{unit}(30*85%))，则使用2张代金券抵扣后账单金额为45.5{unit}(95.5{unit}-50{unit})，最终此次需实际支付45.5{unit}。',
    groupDetail_10021: "2、如设置“是否允许使用代金券”为“否”，团体用户支付订单时不能使用代金券。",
    groupDetail_10022: "卡券优惠设置",
    groupDetail_10023: '如果设置"是否允许使用优惠券"和"是否允许使用代金券"都为"是"，支付订单时优先使用团体绑定站点资费打折。打折后的账单，如果同时满足车主拥有的优惠券和代金券的使用条件，优先使用代金券，不使用优惠券。',
}