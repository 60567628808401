export default {
  homePage_00001: 'Sesiones de carga',
  homePage_00002: 'Monto del pedido ({type})',
  homePage_00003: 'Ingresos por servicios ({type})',
  homePage_00004: 'Energía total ({type})',
  homePage_00005: 'CO₂ ahorrado (kg)',
  homePage_00006: 'Carga a usuarios',
  homePage_00007: 'Lista de tareas pendientes',
  homePage_00008: 'Estado de los cargadores',
  homePage_00009: 'Funciones comunes',
  homePage_00010: 'Asesoramiento empresarial',
  homePage_00011: 'Utilización',
  homePage_00012: 'Ranking de usuarios',
  homePage_00013: 'Ingresos',
  homePage_00014: 'Energía',
  homePage_00015: 'Duración',
  homePage_00016: 'Usuarios',
  homePage_00017: 'Hoy',
  homePage_00018: 'Ayer',
  homePage_00019: 'Ratio',
  homePage_00020: 'Solicitud de reembolso',
  homePage_00021: 'Solicitud de reembolso personal',
  homePage_00022: 'Solicitud de factura grupal',
  homePage_00023: 'Factura de pago',
  homePage_00024: 'Número de estaciones',
  homePage_00025: '',
  homePage_00026: 'Conectores de CC',
  homePage_00027: 'Conectores de CA',
  homePage_00028: 'Disponible',
  homePage_00029: 'En uso',
  homePage_00030: 'Con fallas/Fuera de línea',
  homePage_00031: 'Monitor de carga',
  homePage_00032: 'Gestión de usuarios',
  homePage_00033: 'Órdenes de carga',
  homePage_00034: 'Análisis de ingresos',
  homePage_00035: 'Actividades de recarga',
  homePage_00036: 'Órdenes anormales',
  homePage_00037: 'Grupo-usuario',
  homePage_00038: 'Desvío de gobierno',
  homePage_00039: '¿Cómo mejorar la tasa de utilización?',
  homePage_00040: 'Operación',
  homePage_00041: 'Membresía de usuario',
  homePage_00042: '¿Cómo acceder a nuevos clientes?',
  homePage_00043: '¿Cómo aumentar el ingreso por valor agregado?',
  homePage_00044: 'AC',
  homePage_00045: 'DC',
  homePage_00046: 'Configuración',
  homePage_00047: 'Cancelar',
  homePage_00048: 'Confirmar',
  homePage_00049: 'Tarifa',
  homePage_00050: 'Electricidad vendida',
  homePage_00051: 'La semana pasada',
  homePage_00052: 'El mes pasado',
  homePage_00053: 'Estación de carga de Kioto',
  homePage_00054: 'Toma de carga de DC',
  homePage_00055: 'Usuarios de carga',
  homePage_00056: 'Usuarios nuevos',
  homePage_00057: 'Electricidad fee({type})',
  homePage_00058: 'Monto del pedido({type})',
  homePage_00059: 'Tarifa del servicio({type})',
  homePage_00060: 'Duración de la carga',
  homePage_00061: 'Toma de carga de CA',
  homePage_00062: 'Servicios de guía',
  homePage_00063: 'Invitar amigos',
  homePage_00064: 'Servicio de publicidad',
  homePage_00065: 'Gestión empresarial',
  homePage_00066: 'Descripción general de la página de inicio',
  
  homePage_00067: 'Aún no está abierto',
  homePage_00068: 'Puesto de jóvenes',
  homePage_00069: 'El banco del sol',
  homePage_00070: 'Medio feliz',
  homePage_00071: 'La estación del este',
  
  homePage_00072: 'Caocao Travel',
  homePage_00073: 'Didi Travel',
  homePage_00074: 'DiDa Travel',
  homePage_00075: 'Kun Ling',
  homePage_00076: 'Familia Park',
  homePage_00077: 'Chow Yun-Fat',
  homePage_00078: 'Jay Chou',
  homePage_00079: 'Viejo pistolero de Pekín',
  
  homePage_00080: 'EUR',
  homePage_00081: '¡Bienvenido {name}!',
  homePage_00082: 'Niveles de miembros',
  homePage_00083: 'Servicio de redireccionamiento',
  homePage_00084: 'Invitar amigos',
  homePage_00085: 'Análisis de ingresos',
  homePage_00086: 'Análisis de usuarios',
  homePage_00087: 'Monitoreo de cobros en tiempo real',
  homePage_00088: 'Gestión de fallas',
  homePage_00089: 'Tarifa eléctrica ({type})',
  homePage_00090: 'Ver moneda',
  homePage_00097: 'La versión de su plataforma no contiene esta función, actualice a la versión avanzada o comuníquese con la empresa',
  homePage_00091: 'veces',
  homePage_00092: 'Ingresos',
  homePage_00093: 'Ingresos por servicios',
  homePage_00094: 'Electricidad vendida',
  homePage_00095: 'CO₂ ahorrado',
  homePage_00096: 'Tarifa eléctrica',
  }