export default {
    analyze_BI_00001:"visión general",
    analyze_BI_00002:"Pedidos totales (€)",
    analyze_BI_00003:"Electricidad total (kWh)",
    analyze_BI_00004:"Número total de usuarios",
    analyze_BI_00005:"Estaciones de carga",
    analyze_BI_00006:"Importe del pedido (€)",
    analyze_BI_00007:"Electricidad de carga (kWh)",
    analyze_BI_00008:"Usuarios",
    analyze_BI_00009:"Puntos de carga",
    analyze_BI_00010:"Usuarios actuales",
    analyze_BI_00011:"Top 5 por tiempo de carga",
    analyze_BI_00012:"Top 5 por potencia de carga",
    analyze_BI_00013:"Mapa de calor de 30 días X Electricidad de carga las 24 horas",
    analyze_BI_00014:"Electricidad de las 5 estaciones principales (kWh)",
    analyze_BI_00015:"Tendencia de la tasa de uso",
    analyze_BI_00016:"Tendencia de la electricidad",
    analyze_BI_00017:"Hasta",
    analyze_BI_00018:"Cantidad de carga (kwh)",
    analyze_BI_00019:"Tasa de utilización de la carga de equipos de CA (%)",
    analyze_BI_00020:"Tasa de utilización de la carga de equipos de CC (%)",
}