export default {
    diversionDetail_00001: 'Detalles de acceso',
    diversionDetail_00002: 'Centro de flujo de datos',
    diversionDetail_00003: 'Abrir flujo de datos',
    diversionDetail_00004: 'Este flujo de datos ya es compatible con el código QR SmartCharging, debe actualizar a un código QR fijo en los detalles del cargador',
    diversionDetail_00005: 'Este flujo de datos no es compatible con el código QR SmartCharging, debe imprimir el código QR y pegarlo',
    diversionDetail_00006: 'Ingrese el nombre del sitio de carga',
    diversionDetail_00007: 'Lista de sitios de carga',
    diversionDetail_00008: 'Gestión de reservas',
    diversionDetail_00009: 'Descuento general',
    diversionDetail_00010: 'Nombre del sitio de carga',
    diversionDetail_00011: 'CPO',
    diversionDetail_00012: 'Estado del sitio de carga',
    diversionDetail_00013: 'Habilitado',
    diversionDetail_00014: 'No habilitado',
    diversionDetail_00015: 'Cargador',
    diversionDetail_00016: 'AC',
    diversionDetail_00017: 'DC',
    diversionDetail_00018: 'Estado abierto para acceder al flujo de datos',
    diversionDetail_00019: 'Cerrar Abrir',
    diversionDetail_00020: 'Aceptar abrir',
    diversionDetail_00021: 'Operar',
    diversionDetail_00022: 'Exportar Código QR en bloque',
    diversionDetail_00023: 'Configuración de tarifas',
    diversionDetail_00024: 'Descuentos para sitios de carga',
    diversionDetail_00025: 'Configuración de inicio/parada',
    diversionDetail_00026: 'Plataforma de operación de vehículos eléctricos',
    diversionDetail_00027: 'Plataforma de agregación',
    diversionDetail_00028: 'Plataforma OEM',
    diversionDetail_00029: 'Plataforma gubernamental',
    diversionDetail_00030: 'Plataforma de mapas',
    diversionDetail_00031: 'Gobierno',
    diversionDetail_00032: 'Gratis',
    diversionDetail_00033: 'Registro directo',
    diversionDetail_00034: 'Contrato general SmartCharge',
    diversionDetail_00035: 'Detalles de acceso-',
    diversionDetail_00036: 'Automóviles de pasajeros',
    diversionDetail_00037: 'Vehículos logísticos',
    diversionDetail_00038: 'Autobús mediano',
    diversionDetail_00039: 'Autobús',
    diversionDetail_00040: 'Moto',
    diversionDetail_00041: 'Abierto - sin arranque/parada',
    diversionDetail_00042: 'Abierto - se puede arrancar y parar',
    diversionDetail_00043: 'No abierto',
    diversionDetail_00044: 'Abierto',
    diversionDetail_00045: 'No hay ningún cargador habilitado en la estación, habilite al menos un cargador.' ,
    diversionDetail_00046: 'Información de la estación en el interior',
    diversionDetail_00047: 'Código de país',
    diversionDetail_00048: 'Códigos provinciales y municipales',
    diversionDetail_00049: 'Tarifa de estacionamiento',
    diversionDetail_00050: 'Tipo de sitio de carga',
    diversionDetail_00051: 'Sitio en construcción',
    diversionDetail_00052: 'Número de plazas de aparcamiento',
    diversionDetail_00053: 'Número de atención al cliente',
    diversionDetail_00054: 'Imágenes de la escena real',
    diversionDetail_00055: 'Está vacío',
    diversionDetail_00056: 'Establezca "Abierto" en [Detalles del sitio] - [Información del sitio] - [Información de estado].' ,
    diversionDetail_00057: 'Estación de carga',
    diversionDetail_00058: 'Carga inteligente',
    diversionDetail_00059: 'Historial de recarga de reserva',
    diversionDetail_00060: 'Plataforma de tráfico de terceros',
    diversionDetail_00061: 'Si se reservan fondos',
    diversionDetail_00062: 'Saldo actual',
    diversionDetail_00063: 'Monto de reserva',
    diversionDetail_00064: 'Recarga de reserva',
    diversionDetail_00065: 'Se puede completar con un valor negativo para compensar la cuenta',
    diversionDetail_00066: 'Recordar saldo',
    diversionDetail_00067: 'Detener automáticamente la carga cuando el saldo sea menor a 0',
    diversionDetail_00068: 'Sí',
    diversionDetail_00069: 'No',
    diversionDetail_00070: 'Monto de recarga',
    diversionDetail_00071: 'Tiempo de pre recarga',
    diversionDetail_00072: 'Devolución',
    diversionDetail_00073: 'Modificado exitosamente',
    diversionDetail_00074: 'Por favor ingrese un número',
    diversionDetail_00075: 'Descuento en la tarifa de servicio (%) otorgado por el operador a SmartCharge',
    diversionDetail_00076: 'Sin descuento en el costo total de una sola carga (%)',
    diversionDetail_00077: 'Descuento en la tarifa de servicio de carga única (%)',
    diversionDetail_00078: '85 significa 15% de descuento, 0 significa totalmente gratis',
    diversionDetail_00079: 'Descuento por kWh para sesión de carga única',
    diversionDetail_00080: 'Nombre para mostrar',
    diversionDetail_00081: 'Ingrese el nombre para mostrar, formato recomendado: nombre de la marca del sitio de carga (nombre del sitio), la palabra clave "nombre de la marca" debe eliminarse del nombre del sitio',
    diversionDetail_00082: 'Si se permite que cargadores de terceros inicien o detengan',
    diversionDetail_00083: 'Solo se permite que ciertos usuarios carguen',
    diversionDetail_00084: 'Piénselo de nuevo',
    diversionDetail_00085: 'Lo entiendo',
    diversionDetail_00086: 'Complete',
    diversionDetail_00087: 'El nombre no puede estar vacío',
    diversionDetail_00088: 'Abrir correctamente',
    diversionDetail_00089: 'Mensaje de aviso',
    diversionDetail_00090: 'Complete la información',
    diversionDetail_00091: 'Aceptar',
    diversionDetail_00092: 'Permitir el inicio/parada de cargadores de terceros',
    diversionDetail_00093: 'Configuración directa de tarifas',
    diversionDetail_00094: 'Si se ha modificado la tarifa, haga clic en el botón "Enviar tarifa" en Detalles del sitio - Tarifa para que la tarifa entre en vigencia.' ,
    diversionDetail_00095: 'Tarifas de CA',
    diversionDetail_00096: 'Seleccionar tarifa de CA',
    diversionDetail_00097: 'Tarifa de CC',
    diversionDetail_00098: 'Seleccionar tarifa de CC',
    diversionDetail_00099: 'Configuración de descuento del sitio',
    diversionDetail_00100: 'Agregar descuento del sitio',
    diversionDetail_00101: 'Hora de inicio',
    diversionDetail_00102: 'Ahora',
    diversionDetail_00103: 'Hora de finalización',
    diversionDetail_00104: 'ilimitado',
    diversionDetail_00105: 'Método de oferta',
    diversionDetail_00106: '85 de descuento significa 15 % de descuento',
    diversionDetail_00107: 'Descuento por kWh (€)',
    diversionDetail_00108: 'Utilice el método de descuento del servicio de carga',
    diversionDetail_00109: 'Utilice el método de descuento por kwh',
    diversionDetail_00110: 'Estado',
    diversionDetail_00111: 'No iniciado',
    diversionDetail_00112: 'Iniciado',
    diversionDetail_00113: 'Valorado',
    diversionDetail_00114: 'Sin valor',
    diversionDetail_00115: 'Historial de descuentos del sitio',
    diversionDetail_00116: '¡Guarde la acción anterior!',
    diversionDetail_00117: 'La hora de inicio no puede estar vacía',
    diversionDetail_00118: 'La hora de finalización no puede estar vacía',
    diversionDetail_00119: 'El método preferido no puede dejarse en blanco',
    diversionDetail_00120: 'Por favor, introduzca un número entero positivo menor que 100',
    diversionDetail_00121: 'Guardado correctamente',
    diversionDetail_00122: 'Descuento en la tarifa de servicio: {dis}%',
    diversionDetail_00123: 'Descuento por kWh: {dis}%',
    diversionDetail_00124: 'Detener la apertura de la información del sitio {name}, {name} no mostrará ninguna información del sitio de carga.' ,
    diversionDetail_00125: '¿Confirmar detener la apertura de la información del sitio {name}?' ,
    diversionDetail_00126: 'Enviar estado del dispositivo',
    diversionDetail_00127: 'Error al enviar el estado del dispositivo, ¡inténtelo de nuevo!',
    diversionDetail_00128: '¡Enviar el estado del dispositivo correctamente!',
    diversionDetail_00129: 'Enviar potencia real',
    detailDepot_30011: "Complete el formulario por completo",
}