export default {
  coaDetail_00001: "策略详情",
  coaDetail_00002: "条件",
  coaDetail_00003: "动作",
  coaDetail_00004: "同时满足 (And)",
  coaDetail_00005: "生效周期",
  coaDetail_00006: "生效时间",
  coaDetail_00007: "生效设备",
  coaDetail_00008: "设备类型",
  coaDetail_00009: "包含告警码",
  coaDetail_00010: "设备上报故障/异常后{0}分钟",
  coaDetail_00011: "不限制",
  coaDetail_00012: "每{0}日，每台设备每种故障只报{1}次",
  coaDetail_00013: "每{0}日，每台设备所有故障只报{1}次",
  coaDetail_00014: "触发频次",
  coaDetail_00015: "有异常订单",
  coaDetail_00016: "无订单（呆滞桩）",
  coaDetail_00017: "充电桩整桩连续{0}天，无订单",
  coaDetail_00018: "充电口连续{0}天，每天都达到{1}个异常订单",
  coaDetail_00019: "整桩连续{0}天，每天都达到{1}个异常订单",
  coaDetail_00020: "长期（直到手动移除为止）",
  coaDetail_00021: "固定时间{0}天",
  coaDetail_00022: "挂失充电卡",
  coaDetail_00023: "限制充电车辆最大可充电SOC{0}%",
  coaDetail_00024: "满足条件的设备重启",
  coaDetail_00025: "处理人角色",
  coaDetail_00026: "工单分类",
  coaDetail_00027: "卡用户",
  coaDetail_00028: "",
  coaDetail_00029: "直流桩",
  coaDetail_00030: "交流桩",
  coaDetail_00031: "生效周期和时间",
  coaDetail_00032: "不限日期",
  coaDetail_00033: "每年 ",
  coaDetail_00034: "每周 ",
  coaDetail_00035: "每月 ",
  coaDetail_00036: "一",
  coaDetail_00037: "二",
  coaDetail_00038: "三",
  coaDetail_00039: "四",
  coaDetail_00040: "五",
  coaDetail_00041: "六",
  coaDetail_00042: "日",
  coaDetail_00043: "不限时间-全天",
  coaDetail_00044: "",
  coaDetail_00045: "",
  coaDetail_00046: "",
  coaDetail_00047: "",
  coaDetail_00048: "",
  coaDetail_00049: "",
  coaDetail_00050: "",
  coaDetail_00051: "",
  coaDetail_00052: "",
  coaDetail_00053: "",
  coaDetail_00054: "",
  coaDetail_00055: "",
  coaDetail_00056: "",
  coaDetail_00057: "",
  coaDetail_00058: "",
  coaDetail_00059: "",
  coaDetail_00060: "",
};