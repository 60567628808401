export default {
  vehicleChargingLogDetail_00001: "detalles del registro",
  vehicleChargingLogDetail_00002: "curva de carga",
  vehicleChargingLogDetail_00003: "",
  vehicleChargingLogDetail_00004: "Número de placa",
  vehicleChargingLogDetail_00005: "tipo de vehículo",
  vehicleChargingLogDetail_00006: "vehículo {tag}",
  vehicleChargingLogDetail_00007: "propietario",
  vehicleChargingLogDetail_00008: "número de móvil",
  vehicleChargingLogDetail_00009: "hora de inicio",
  vehicleChargingLogDetail_00010: "hora de finalización",
  vehicleChargingLogDetail_00011: "",
  vehicleChargingLogDetail_00012: "",
  vehicleChargingLogDetail_00013: "",
  vehicleChargingLogDetail_00014: "",
  vehicleChargingLogDetail_00015: "",
  billDetail_00025: "Curva de carga",
  billDetail_00026: "Tensión de demanda en el extremo EV (línea discontinua)",
  billDetail_00027: "Corriente de demanda en el extremo EV (línea discontinua)",
  billDetail_00028: "Tensión medida en el extremo EV (línea discontinua)",
  billDetail_00029: "Corriente medida en el extremo EV (línea discontinua)",
  billDetail_00030: "Carga inicial de la batería",
  billDetail_00031: "Carga actual de la batería",
  billDetail_00032: "Potencia instantánea",
  billDetail_00033: "Potencia media",
  billDetail_00034: "Potencia máxima",
  billDetail_00035: "Modelo EV",
  billDetail_00036: "Potencia",
  billDetail_00037: "Corriente",
  billDetail_00038: "Voltaje",
  billDetail_00039: "Capacidad de la batería",
  billDetail_00040: "Rango de potencia máxima, rango de potencia {powerBegin}-{powerEnd}Kw, total {minutes} minutos (del total {timePercent}), total {powerCharged} kWh, del total de potencia {chargePercent}",
  billDetail_00041: "Electricidad acumulada",
  billDetail_00042: "kwh",
  billDetail_00043: "Crear orden de carga",
  billDetail_00044: "Iniciar carga",
  billDetail_00045: "Iniciar facturación",
  billDetail_00046: "Detener carga",
  billDetail_00047: "Finalizar carga",
  billDetail_00048: "Iniciar carga por tiempo de posposición",
  billDetail_00049: "Finalizar carga",
  billDetail_00050: "Finalizar facturación",
  billDetail_00051: "Pago completado",
  billDetail_00091: "Rango de potencia máxima, rango de potencia {powerBegin}-{powerEnd}Kw, total {minutes} minutos (del total {timePercent}), total {powerCharged} kWh, del total de potencia {chargePercent}",
  
  }