export default {
  wra_10000:'No hay datos disponibles en este momento',
  
  wra_00000:'Copiar',
  wra_00001:'Centro financiero',
  wra_00002:'Funciones comunes',
  wra_00003:'Nombre de usuario/Número de móvil',
  wra_00004:'Fecha de inicio',
  wra_00005:'Fecha de finalización',
  wra_00006:'Estado de la solicitud',
  wra_00007:'Consulta',
  wra_00008:'Restablecer',
  
  wra_00009:'¡Copia realizada correctamente!',
  
  wra_00010:'Hoy',
  wra_00011:'Ayer',
  wra_00012:'Hace 7 días',
  wra_00013:'Hace 15 días',
  wra_00014:'Hace 30 días',
  
  wra_00015:'Todo',
  wra_00016:'Por confirmar',
  wra_00017:'Completado',
  wra_00018:'Cancelado',
  wra_00019:'Por completar',
  
  wra_00020:'',
  wra_00021:'AppID requerido para pago WeChat Merchant Enterprise',
  
  wra_00022:'Exportar datos reembolsados',
  wra_00023:'Esta operación descargará un archivo Excel de los pedidos reembolsados, ¿desea continuar?' ,
  wra_00024:'Solicitar',
  wra_00025: 'Aceptar',
  wra_00026: 'Cancelar',
  wra_00027: 'Iniciar descarga',
  wra_00028: "Cancelar descarga",
  wra_00029: "Descargando archivo de reembolso masivo",
  wra_00030: 'Descargar archivo de reembolso masivo (comerciantes especiales de WeChat Pay)',
  
  wra_00031:'Apodo',
  wra_00032:'Número de móvil',
  
  wra_00033:'Euro',
  
  wra_00034:'Monto a reembolsar',
  wra_00035:'Reembolsado',
  wra_00036:'No reembolsado',
  
  wra_00037:'Reembolso completado',
  wra_00038:'Aceptación confirmada',
  wra_00039: 'Cancelación aceptada',
  
  wra_00040:'El importe a reembolsar consta de',
  wra_00041:'Importe a reembolsar por pago de WeChat',
  wra_00042: 'Importe a reembolsar por Alipay',
  wra_00043: 'Importe de reembolso para pedidos sin recarga',
  
  wra_00044:'Resultado de aceptación de reembolso',
  wra_00045:'El sistema reembolsó automáticamente (pago de WeChat)',
  wra_00046:'Reembolso automático del sistema (Alipay)',
  wra_00047: 'Administrar un reembolso manual',
  
  wra_00048:'Motivo del reembolso incompleto',
  wra_00049:'Ninguno',
  
  wra_00050:'¿Ha realizado un reembolso en la plataforma comercial WeChat?' ,
  wra_00051:'Modificado',
  wra_00052:'Operación exitosa, esperando notificación de resultado de WeChat',
  wra_00053:'Esperando notificación de resultado de WeChat',
  wra_00054:'Aplicación exitosa, esperando notificación de resultado de WeChat',
  wra_00055:'No se pudo completar el reembolso automático debido a {causa}',
  wra_00056:'Razón del sistema',
  wra_00057:'Esperando notificación de resultado de WeChat',
  wra_00058:'El certificado ha expirado y no se puede reembolsar automáticamente',
  wra_00059:'Pedido vencido para reembolso automático',
  wra_00060:'Saldo de reembolso insuficiente disponible para el comerciante',
  wra_00061:'Se ha realizado un reembolso parcial y no se puede cancelado',
  wra_00062:'Modificado exitosamente',
  
  wra_00063:'Reembolso en proceso...',
  
  wra_00064:'el rango de tiempo excede los 31 días, seleccione uno nuevo'
  
  }