export default {
  energyStoragePileManagement_00001: 'Ocupado',
    energyStoragePileManagement_00002: 'El cargador no se ha habilitado todavía',
    energyStoragePileManagement_00003: 'El cargador está fuera de línea',
    energyStoragePileManagement_00005: 'Cambiar a disponible',
    energyStoragePileManagement_00006: 'Iniciar carga',
    energyStoragePileManagement_00008: 'Reservado',
    energyStoragePileManagement_00009: 'No hay código de error hasta el momento',
    energyStoragePileManagement_00010: "El cargador se reinició correctamente",
    energyStoragePileManagement_00011: "Inicio remoto",
    energyStoragePileManagement_00012: "Inicio remoto fallido",
    energyStoragePileManagement_00013: "Gestión de cargadores",
    energyStoragePileManagement_00014: "Errores actuales",
    energyStoragePileManagement_00015: "Solución de errores",
    energyStoragePileManagement_00016: "Ingrese el nombre del cargador o el número de serie",
    energyStoragePileManagement_00017: "Seleccione o ingrese el nombre del sitio de carga",
    energyStoragePileManagement_00018: "General",
    energyStoragePileManagement_00019: "Tipo de cargador:",
    energyStoragePileManagement_00020: "CC",
    energyStoragePileManagement_00021: "CA",
    energyStoragePileManagement_00022: "Puerta de enlace",
    energyStoragePileManagement_00023: "Defectuoso",
    energyStoragePileManagement_00024: "Fuera de línea",
    energyStoragePileManagement_00025: "Disponible",
    energyStoragePileManagement_00026: 'Solo comprobar si ya está conectado',
    energyStoragePileManagement_00027: "En uso",
    energyStoragePileManagement_00028: "Solo comprobar si está esperando 'Desconectar'",
    energyStoragePileManagement_00029: "Fuera de línea",
    energyStoragePileManagement_00030: "Deshabilitado",
    energyStoragePileManagement_00031: "SN:",
    energyStoragePileManagement_00032: "Tipo de proveedor:",
    energyStoragePileManagement_00033: "EVSE:",
    energyStoragePileManagement_00034: "Reiniciar el cargador",
    energyStoragePileManagement_00035: "Detalles del cargador",
    energyStoragePileManagement_00036: "Punto de carga:",
    energyStoragePileManagement_00037: "Fuera de línea",
    energyStoragePileManagement_00038: "Deshabilitado",
    energyStoragePileManagement_00039: "Defectuoso",
    energyStoragePileManagement_00040: "Ocupado",
    energyStoragePileManagement_00041: "Cargando",
    energyStoragePileManagement_00042: "Disponible",
    energyStoragePileManagement_00043: "Esperando a que se retire el EV",
    energyStoragePileManagement_00044: "Reservado",
    energyStoragePileManagement_00045: "Conectado",
    energyStoragePileManagement_00046: "Esperando a que se desconecte",
    energyStoragePileManagement_00047: "Ver pedidos",
    energyStoragePileManagement_00048: "Reiniciar punto de carga",
    energyStoragePileManagement_00049: "Se ha enviado el comando de reinicio y el proceso tardará unos 5 minutos. Permanezca en esta página, no la actualice",
    energyStoragePileManagement_00050: "Información del aviso",
    energyStoragePileManagement_00051: "Confirmar",
    energyStoragePileManagement_00052: "Gestión del cargador",
    energyStoragePileManagement_00053: "Fallo actual",
    energyStoragePileManagement_00054: "Enchufar",
    energyStoragePileManagement_00055: "Finalizar carga",
    energyStoragePileManagement_00056: "Deshabilitado",
    energyStoragePileManagement_00057: "Normal",
    energyStoragePileManagement_00058: "Defectuoso",
    energyStoragePileManagement_00059: "El EV está bloqueado",
    energyStoragePileManagement_00060: "En espera",
    energyStoragePileManagement_00061: "Desconocido",
    energyStoragePileManagement_00062: "Estado del cargador:",
    energyStoragePileManagement_00063: "Defectuoso",
    energyStoragePileManagement_00064: "Solo verifique que el cable no esté colocado nuevamente",
    energyStoragePileManagement_00065: "Colocado nuevamente",
    energyStoragePileManagement_00066: "No colocado nuevamente",
    energyStoragePileManagement_00067: "Anormal",
    energyStoragePileManagement_00068: "Ingrese el nombre del cargador o el número de serie",
    energyStoragePileManagement_00069: "Modo actual",
    energyStoragePileManagement_00070: "Disponible",
    energyStoragePileManagement_00071: "Conectado",
    energyStoragePileManagement_00072: "Cargando",
    energyStoragePileManagement_00073: "Esperando a que se desconecte",
    energyStoragePileManagement_00074: "Defectuoso",
    energyStoragePileManagement_00075: "Fuera de línea",
    energyStoragePileManagement_00076: "Deshabilitado",
    energyStoragePileManagement_00077: "Ocupado",
    energyStoragePileManagement_00078: "Batería cargándose",
    energyStoragePileManagement_00079: "Batería descargándose",
    energyStoragePileManagement_00080: "Batería disponible",
    energyStoragePileManagement_00081: "Los minutos terminan de cargarse",
    energyStoragePileManagement_00082: "modo",
}