export default {
    analyzeCharge_00001: "Electricidad",
    analyzeCharge_00002: "Electricidad por estación",
    analyzeCharge_00003: "Electricidad por flota",
    analyzeCharge_00004: "Análisis del volumen de carga de la fuente del usuario",
    analyzeCharge_00005: "Electricidad por franquicia",
    analyzeCharge_00006: "Tendencia de la electricidad",
    analyzeCharge_00007: "Análisis",
    analyzeCharge_00008: "Electricidad (kWh)",
    analyzeCharge_00009: "Distribución de la electricidad por modelo de cargador",
    analyzeCharge_00010: "Distribución de la electricidad por ciudad",
    analyzeCharge_00011: "Comparación de estaciones por electricidad",
    analyzeCharge_00012: "Tendencias de la electricidad de estaciones seleccionadas",
    analyzeCharge_00013: "Comparación de flotas por electricidad",
    analyzeCharge_00014: "Tendencias de electricidad de flotas seleccionadas",
    analyzeCharge_00015: "Comparación de franquiciados por electricidad",
    analyzeCharge_00016: "Tendencias de electricidad de franquiciados seleccionados",
    analyzeCharge_00017: "Seleccionar modelo de cargador",
    analyzeCharge_00018: "Comparación de utilización del sitio",
    analyzeCharge_00019: "Tendencia de utilización del sitio",
    analyzeCharge_00020: "Comparación de utilización del tipo de cargador",
    analyzeCharge_00021: "Tendencia de utilización del tipo de cargador",
    analyzeCharge_00022: "Utilización de la carga",
    analyzeCharge_00023: "Comparación de utilización del franquiciado",
    analyzeCharge_00024: "Tendencia de utilización del franquiciado",
    analyzeCharge_00025: "comparación de la potencia media del sitio",
    analyzeCharge_00026: "Análisis de la potencia media de los tipos de cargadores",
    analyzeCharge_00027: "Potencia media",
    analyzeCharge_00028: "Seleccione o introduzca un sitio de carga",
    analyzeCharge_00029: "Puertos en estado de carga",
    analyzeCharge_00030: "Tasa de ocupación del puerto de carga",
    analyzeCharge_00031: "Número de puertos en estado de carga",
    analyzeCharge_00032: "Tasa de ocupación de los puertos de carga %",
    analyzeCharge_00033: "Tasa de ocupación de los puertos de carga = Puertos en estado de carga / Todos los puertos de carga",
    analyzeCharge_00034: "Porcentaje de carga de origen del usuario",
    analyzeCharge_00035: "Porcentaje de cada tráfico en el tráfico de terceros",
    analyzeCharge_00036: "Volumen de carga de origen del usuario tendencia",
    analyzeCharge_00037: "Tendencia del volumen de carga de terceros",
    analyzeCharge_00038: "Tendencia de capacidad de carga promedio por 0-24 horas",
    analyzeCharge_00039: "Capacidad de carga por conector",
    analyzeCharge_00040: "Grado de carga por conector",

}