export default {
  energyOperationMonitoring_00001: "Energy monitor",
  energyOperationMonitoring_00002: "Pivot",
  energyOperationMonitoring_00003: "Charger",
  energyOperationMonitoring_00004: "NZS battery",
  energyOperationMonitoring_00005: "Grid",
  energyOperationMonitoring_00006: "Charger usage",
  energyOperationMonitoring_00007: "Max power usage",
  energyOperationMonitoring_00008: "From NZS battery",
  energyOperationMonitoring_00009: "From grid",
  energyOperationMonitoring_00010: "NZS battery discharge",
  energyOperationMonitoring_00011: "Max power charged",
  energyOperationMonitoring_00012: "NZS battery SOC",
  energyOperationMonitoring_00013: "From grid",
  energyOperationMonitoring_00014: "To charger",
  energyOperationMonitoring_00015: "To battery",
  energyOperationMonitoring_00016: "To charger",
  energyOperationMonitoring_00017: "NZS battery charge usage",
  energyOperationMonitoring_00018: "NZS battery discharge usage",
  energyOperationMonitoring_00019: "Low",
  energyOperationMonitoring_00020: "Avg",
  energyOperationMonitoring_00021: "High",
  energyOperationMonitoring_00022: "Export data",
  energyOperationMonitoring_00023: "Max energy usage",
  energyOperationMonitoring_00024: "Charger usage",
  energyOperationMonitoring_00025: "NZS battery discharge",
  energyOperationMonitoring_00026: "Net grid imported",
  energyOperationMonitoring_00027: "Day",
  energyOperationMonitoring_00028: "Week",
  energyOperationMonitoring_00029: "Month",
  energyOperationMonitoring_00030: "Year",
  energyOperationMonitoring_00031: "Lifetime",
  energyOperationMonitoring_00032: "Charger usage from NZS battery",
  energyOperationMonitoring_00033: "Charger usage from grid",
  energyOperationMonitoring_00034: "Grid import to charger",
  energyOperationMonitoring_00035: "Grid import to NZS battery",
  energyOperationMonitoring_00036: "NZS discharge to charger",

  energyOperationMonitoring_00037: "Jan",
  energyOperationMonitoring_00038: "Feb",
  energyOperationMonitoring_00039: "Mar",
  energyOperationMonitoring_00040: "Apr",
  energyOperationMonitoring_00041: "May",
  energyOperationMonitoring_00042: "Jun",
  energyOperationMonitoring_00043: "Jul",
  energyOperationMonitoring_00044: "Aug",
  energyOperationMonitoring_00045: "Sep",
  energyOperationMonitoring_00046: "Oct",
  energyOperationMonitoring_00047: "Nov",
  energyOperationMonitoring_00048: "Dec",

  energyOperationMonitoring_00049: "Mon",
  energyOperationMonitoring_00050: "Tue",
  energyOperationMonitoring_00051: "Wed",
  energyOperationMonitoring_00052: "Thu",
  energyOperationMonitoring_00053: "Fri",
  energyOperationMonitoring_00054: "Sat",
  energyOperationMonitoring_00055: "Sun",
  energyOperationMonitoring_00056: "Charging power",
  energyOperationMonitoring_00057: "From battery to vechile",
  energyOperationMonitoring_00058: "From grid to vechile",
  energyOperationMonitoring_00059: "To battery",
  energyOperationMonitoring_00060: "To vehicle",
  energyOperationMonitoring_00061: "Net grid use",
  energyOperationMonitoring_00062: "{0} week {1}",
  energyOperationMonitoring_00063: "PV", 
  energyOperationMonitoring_00064: "From PV", 
  energyOperationMonitoring_00065: "To grid power", 
  energyOperationMonitoring_00066: "To grid", 
  energyOperationMonitoring_00067: "Power exported to grid", 
  energyOperationMonitoring_00068: "NZS battery discharged to grid",
  energyOperationMonitoring_00069: "NZS battery discharged to charger",
  energyOperationMonitoring_00070: "PV power supplied",
  energyOperationMonitoring_00071: "NZS battery charged power",
  energyOperationMonitoring_00072: "NZS battery discharged power",
  energyOperationMonitoring_00073: "PV power supplied",
  energyOperationMonitoring_00074: "PV power to NZS battery",
  energyOperationMonitoring_00075: "Energy imported from grid",
  energyOperationMonitoring_00076: "To grid",
  energyOperationMonitoring_00077: "From PV",
  energyOperationMonitoring_00078: "To charger",
  energyOperationMonitoring_00079: "Charger use energy from PV",
  energyOperationMonitoring_00080: "PV energy to Charger",
  energyOperationMonitoring_00081: "PV power supply efficiency",
  energyOperationMonitoring_00082: "To Grid",
  energyOperationMonitoring_00083: "NZS Battery Charged",
  energyOperationMonitoring_00084: "NZS Battery Discharged",
  energyOperationMonitoring_00085: "Device does not exist",
  energyOperationMonitoring_00086: "View Sessions", 
  energyOperationMonitoring_00087: "View Transaction",
  energyOperationMonitoring_00088: "Please enter the device SN",
}