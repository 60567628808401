export default {
  eventlogsphotovoltaic_00001: "Transaction List",
  eventlogsphotovoltaic_00002: "Export Data",
  eventlogsphotovoltaic_00003: "Transaction ID",
  eventlogsphotovoltaic_00004: "Start Date",
  eventlogsphotovoltaic_00005: "End Date",
  eventlogsphotovoltaic_00006: "Reset",
  eventlogsphotovoltaic_00007: "Query",
  eventlogsphotovoltaic_00008: "Status",
  eventlogsphotovoltaic_00009: "All",
  eventlogsphotovoltaic_00010: "Bill Type",
  eventlogsphotovoltaic_00011: "Billing",
  eventlogsphotovoltaic_00012: "Power Supply",
  eventlogsphotovoltaic_00013: "Discharge",
  eventlogsphotovoltaic_00014: "Energy Storage Device SN",
  eventlogsphotovoltaic_00015: "All Energy Storage Cabinets",
  eventlogsphotovoltaic_00016: "Power Supply Energy (kWh)",
  eventlogsphotovoltaic_00017: "Power Supply Time (minutes)",
  eventlogsphotovoltaic_00018: "Power Supply Count (times)",
  eventlogsphotovoltaic_00019: "Discharge Energy (kWh)",
  eventlogsphotovoltaic_00020: "Discharge Time (minutes)",
  eventlogsphotovoltaic_00021: "Discharge Count (times)",
  eventlogsphotovoltaic_00022: "Transaction Curve",
  eventlogsphotovoltaic_00023: "Day",
  eventlogsphotovoltaic_00024: "Hour",
  eventlogsphotovoltaic_00025: "Minute",
  eventlogsphotovoltaic_00026: "Device",
  eventlogsphotovoltaic_00027: "Power Supply Duration",
  eventlogsphotovoltaic_00028: "Energy Supplied",
  eventlogsphotovoltaic_00029: "Energy Recharged",
  eventlogsphotovoltaic_00030: "Discharging",
  eventlogsphotovoltaic_00031: "Discharge Completed",
  eventlogsphotovoltaic_00032: "Power Supplying",
  eventlogsphotovoltaic_00033: "Power Supply Completed",
  eventlogsphotovoltaic_00034: "This operation will download the transaction's Excel file, continue?",
  eventlogsphotovoltaic_00035: "Confirm",
  eventlogsphotovoltaic_00036: "Cancel",
  eventlogsphotovoltaic_00037: "Start Download",
  eventlogsphotovoltaic_00038: "Cancel Download",
  eventlogsphotovoltaic_00039: "Prompt",
  eventlogsphotovoltaic_00040: "Cumulative Energy",
  eventlogsphotovoltaic_00041: "Instantaneous Power",
  eventlogsphotovoltaic_00042: "Average Power",
  eventlogsphotovoltaic_00043: "Maximum Power",
  eventlogsphotovoltaic_00044: "Photovoltaic Transaction Log",
  eventlogsphotovoltaic_00045: "",

  billDetail_00025: "Charging Curve",
  billDetail_00026: "Vehicle Demand Voltage (Dashed Line)",
  billDetail_00027: "Vehicle Demand Current (Dashed Line)",
  billDetail_00028: "Vehicle Measured Voltage (Dashed Line)",
  billDetail_00029: "Vehicle Measured Current (Dashed Line)",
  billDetail_00030: "Battery Initial Charge",
  billDetail_00031: "Battery Current Charge",
  billDetail_00032: "Instantaneous Power",
  billDetail_00033: "Average Power",
  billDetail_00034: "Maximum Power",
  billDetail_00035: "Vehicle Model",
  billDetail_00036: "Power",
  billDetail_00037: "Current",
  billDetail_00038: "Voltage",
  billDetail_00039: "Cumulative Energy",
  billDetail_00042: "kWh",
  bill_00059: "Today",
  bill_00060: "7 Days Ago",
  bill_00061: "15 Days Ago",
  bill_00077: "Yesterday",
  bill_00078: "30 Days Ago"
}