export default {
    cardRecord_00001: "Monto total de la recarga({unit})",
    cardRecord_00002: "Principal de la recarga({0})",
    cardRecord_00003: "Monto de la recarga({0})",
    cardRecord_00004: "Registro de recarga",
    cardRecord_00005: "Gestión de tarjetas de recarga del operador",
    cardRecord_00006: "Gestión de grupos de tarjetas de recarga",
    cardRecord_00007: "Recuento de recargas(veces)",
    cardRecord_00008: "Monto de la recarga({0})",
    cardRecord_00009: "Monto de dinero del regalo({unit})",
    cardRecord_00010: "Descargar registro de recarga",
    cardRecord_00011: "Fecha de recarga",
    cardRecord_00012: "Nombre",
    cardRecord_00013: "Método de recarga",
    cardRecord_00014: "CPO",
    cardRecord_00015: "Esta operación descargará el archivo Excel de los registros de recarga, ¿desea continuar?",
    cardRecord_00016: "Cuenta oficial de WeChat",
    cardRecord_00017: "（Alipay）",
    cardRecord_00018: "（WeChat Pay）",
    cardRecord_00019: "Plataforma",
    cardRecord_00020: "Grupo de tarjetas en línea",
    cardRecord_00021: "Grupo de tarjetas fuera de línea",
    cardRecord_00022: "Grupo de supertarjetas",
    cardRecord_00023: "hasta",
    cardRecord_00024: "Fecha de inicio",
    cardRecord_00025: "Fecha de cierre",
    cardRecord_00026: "Número de tarjeta",
}