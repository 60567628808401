export default {
  systemInfoSetting_00001: "Información básica",
  systemInfoSetting_00002: "Información de contacto",
  systemInfoSetting_00003: "Información de cuenta pública",
  systemInfoSetting_00004: "Texto de solicitud de ingreso de número de matrícula del lado del cliente",
  systemInfoSetting_00005: "Nombre de CPO",
  systemInfoSetting_00006: "ID de CPO",
  systemInfoSetting_00007: "Moneda admitida",
  systemInfoSetting_00008: "euro (yuan)",
  systemInfoSetting_00009: "Contacto",
  systemInfoSetting_000010: "Número de móvil",
  systemInfoSetting_000011: "Correo electrónico",
  systemInfoSetting_000012: "Obtener código de verificación",
  systemInfoSetting_000013: "Por favor, introduzca el código de verificación",
  systemInfoSetting_000014: "Cuenta",
  systemInfoSetting_000015: "Superadministrador",
  systemInfoSetting_000016: "Nombre de la cuenta",
  systemInfoSetting_000017: "Cuenta bancaria",
  systemInfoSetting_000018: "Apertura de cuenta bancaria",
  systemInfoSetting_000019: "Por favor, introduzca el contenido",
  systemInfoSetting_000020: "La información no puede estar vacía",
  systemInfoSetting_000021: "Modificado correctamente",
  systemInfoSetting_000022: "No habilitado",
  systemInfoSetting_000023: "El nombre no puede superar los {0} caracteres",
  systemInfoSetting_000024: "La cantidad de palabras introducidas no debe superar las 20",
  systemInfoSetting_000025: "Por favor, introduzca el buzón de correo correcto",
  systemInfoSetting_000026: "",
  //---------Información del usuario-------------------------------
  systemInfoSetting_000600: "agregar",
  systemInfoSetting_000601: "Seleccione",
  systemInfoSetting_000602: "Seleccione un tipo de moneda",
  systemInfoSetting_000603: "Nombre de la empresa",
  }