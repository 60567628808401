export default {
  login_00001: "Iniciar sesión en la plataforma CPO",
  login_00002: "Ingrese el nombre de usuario",
  login_00003: "Ingrese la contraseña",
  login_00004: "Acceso",
  login_00005: "El nombre de usuario y la contraseña no pueden estar en blanco",
  login_00006: "· Operación",
  login_00007: "Ya registrado",
  login_00008: "Acceso",
  registerEmail_00001: "Registrarse",
  registerEmail_00002: "CPO franquiciado",
  registerEmail_00003: "Autenticación de buzón",
  registerEmail_00004: "Ingrese la información de registro",
  registerEmail_00005: "Buscar operador de cobro",
  registerEmail_00006: "Buscar",
  registerEmail_00007: "Ingrese el código de invitación que le enviamos por el operador",
  registerEmail_00008: "Nombre del operador",
  registerEmail_00009: "Moneda de liquidación",
  registerEmail_00010: "Únase al operador",
  registerEmail_00011: "Únase a la Alianza de operadores de cobro",
  registerEmail_00012: "Complete el buzón",
  registerEmail_00013: "Le enviamos un correo electrónico a {email} que usted completó. Haga clic en el enlace de verificación en el correo electrónico para completar la verificación y completar la información de registro",
  registerEmail_00014: "Nombre de contacto | ¿Cómo debería llamarlo?",
  registerEmail_00015: "Ingrese su nombre",
  registerEmail_00016: "Nombre del operador | nombre visto por el usuario que realiza el cobro",
  registerEmail_00017: "No más de {0} palabras",
  registerEmail_00018: "Cuenta de inicio de sesión | como: XCharge",
  registerEmail_00019: "use letras y números",
  registerEmail_00020: "Contraseña de inicio de sesión | Contiene al menos 6 caracteres, incluyendo al menos 1 dígito, 1 letra y 1 carácter especial",
  registerEmail_00021: "Confirme que la contraseña sea coherente con la contraseña de inicio de sesión",
  registerEmail_00022: "Verificación del número de móvil",
  registerEmail_00023: "Vincular cuenta oficial de WeChat",
  registerEmail_00024: "Se recomienda el chino",
  registerEmail_00025: "Ingrese el nombre del operador",
  registerEmail_00026: "No se encontraron operadores calificados",
  registerEmail_00027: "Número de WeChat",
  registerEmail_00028: "Código de invitación utilizado",
  registerEmail_00029: "El código de invitación no existe",
  registerEmail_00030: "El buzón de correo no puede estar vacío",
  registerEmail_00031: "Ingrese su dirección de correo electrónico para que podamos comunicarnos con usted",
  registerEmail_00032: "Por favor, rellene la dirección de correo electrónico correcta",
  registerEmail_00033: "Error en la operación",
  registerEmail_00034: "Tiempo de espera de respuesta del servidor",
  registerEmail_00035: "Primero obtenga la cuenta oficial",
  registerEmail_10036: "Plataforma del operador",
  registerEmail_10037: "Seleccione un idioma",
  registerEmail_10038: "Me he registrado y ahora he iniciado sesión",
  registerEmail_10039: "Iniciar sesión",
  registerEmail_10040: "Iniciar sesión en la plataforma del operador",
  registerEmail_10041: "Iniciar sesión en la plataforma de gestión de grupos de usuarios",
  registerEmail_10042: "Establecido correctamente",
  registerEmail_10043: "Tipo de moneda",
  registerEmail_10044: "Por favor, introduzca la contraseña de nuevo",
  registerEmail_10045: "Las dos contraseñas son inconsistentes",
  registerEmail_10046: "Por favor ingrese el código de verificación",
  registerEmail_10047: "Por favor ingrese un valor numérico",
  registerEmail_10048: "Por favor ingrese 4 dígitos",
  registerEmail_10049: "Por favor ingrese el nombre de contacto correcto",
  registerEmail_10050: "El formato del número de cuenta es una combinación de números y letras",
  registerEmail_10051: "La contraseña es una combinación de letras o números",
  registerEmail_10052: "Por favor ingrese el nombre del contacto",
  registerEmail_10053: "Por favor ingrese el nombre del operador",
  registerEmail_10054: "El nombre no puede exceder {0} caracteres",
  registerEmail_10055: "Por favor ingrese el número de cuenta",
  registerEmail_10056: "Por favor ingrese una contraseña",
  registerEmail_10057: "La longitud es entre 6 y 20 caracteres",
  registerEmail_10058: "Lea atentamente la política de privacidad primero para confirmar si está de acuerdo",
  registerEmail_10059: "Ingrese su número de teléfono móvil",
  registerEmail_10060: "Ingrese el número de teléfono móvil correcto",
  // ******
  registerEmail_20053: "moneda",
  registerEmail_20054: "Moneda de transacción vista por el usuario que realiza el cobro",
  registerEmail_20055: "Seleccione",
  registerEmail_20056: "Ingrese la dirección de correo electrónico correcta y abra el enlace en el correo electrónico para verificación",
  registerEmail_20057: "Ingrese toda la información a la derecha para completar el registro",
  // ****2022/1/4
  registerEmail_30058: "Seleccione la moneda",
  registerEmail_30059: "Ingrese nuevamente",
  registerEmail_20056: "Ingrese la dirección de correo electrónico correcta y abra el enlace en el correo electrónico para verificación",
  registerEmail_20057: "Por favor, rellene toda la información a la derecha para completar el registro.",
  registerEmail_20051: "He leído y acepto",
  registerEmail_20052: "política de privacidad de la plataforma del operador de carga inteligente",
  // 注册码
  registerEmail_21000: "Código de registro | Si necesita un código de registro, contáctenos: 4008770227",
  registerEmail_21001: "Ingrese el código de registro",
  registerEmail_21002: "Error en el código de registro",
  registerEmail_21003: "Siguiente",
  registerEmail_21004: "Código de registro",
  registerEmail_21005: "Si necesita un código de registro, contáctenos",
  registerEmail_21006: "Establezca una contraseña",
}