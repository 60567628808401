export default {
  createCOA_00001: "新建策略",
  createCOA_00002: "修改策略",
  createCOA_00003: "场景策略名称不能超过15个字",
  createCOA_00004: "输入策略名称",
  createCOA_00005: "触发条件",
  createCOA_00006: "处理动作",
  createCOA_00007: "设备状态",
  createCOA_00008: "充电过程状态",
  createCOA_00009: "订单统计",
  createCOA_00010: "单设备订单异常",
  createCOA_00011: "充电处理",
  createCOA_00012: "用户处理",
  createCOA_00013: "通知处理",
  createCOA_00014: "设备处理",
  createCOA_00015: "工单处理",
  createCOA_00016: "生效周期和时间",
  createCOA_00017: "条件",
  createCOA_00018: "动作",
  createCOA_00019: "生效周期",
  createCOA_00020: "不限日期",
  createCOA_00021: "按日期区间设定",
  createCOA_00022: "按月度时间确定",
  createCOA_00023: "按每周时间设定",
  createCOA_00024: "生效时间",
  createCOA_00025: "不限时间-全天",
  createCOA_00026: "按时间区间设定",
  createCOA_00027: "生效设备",
  createCOA_00028: "设备类型",
  createCOA_00029: "直流桩",
  createCOA_00030: "交流桩",
  createCOA_00031: "同时满足 (And)",
  createCOA_00032: "包含告警码",
  createCOA_00033: "设备上报故障/异常后",
  createCOA_00034: "触发频次",
  createCOA_00035: "不限制",
  createCOA_00036: "注：最多可设置到7日",
  createCOA_00037: "每",
  createCOA_00038: "日",
  createCOA_00039: "每台设备每种故障只报",
  createCOA_00040: "每台设备所有故障只报",
  createCOA_00041: "次",
  createCOA_00042: "增加条件",
  createCOA_00043: ">大于",
  createCOA_00044: ">=大于等于",
  createCOA_00045: "==等于",
  createCOA_00046: "<小于",
  createCOA_00047: "<=小于等于",
  createCOA_00048: "有异常订单",
  createCOA_00049: "无订单（呆滞桩）",
  createCOA_00050: "整桩",
  createCOA_00051: "充电口",
  createCOA_00052: "充电桩整桩",
  createCOA_00053: "连续",
  createCOA_00054: "天",
  createCOA_00055: "每天都达到",
  createCOA_00056: "个异常订单",
  createCOA_00057: "无订单",
  createCOA_00058: "注：连续天数，最多支持填写31天",
  createCOA_00059: "卡用户",
  createCOA_00060: "注：会导致用户充电过程中断，需谨慎设置触发条件",
  createCOA_00061: "注：触发后需要到“用户中心-微信用户-用户黑名单”中解除",
  createCOA_00062: "固定时间",
  createCOA_00063: "长期",
  createCOA_00064: "（直到手动移除为止）",
  createCOA_00065: "注：触发后需要到“用户中心-充电卡-充电卡管理”中解除",
  createCOA_00066: "注：仅对能获取到VIN的车辆起效，触发后需要到“安全中心-安全策略-车辆限制充电”中解除",
  createCOA_00067: "注：仅对能获取到VIN的车辆起效，触发后需要到“安全中心-安全策略-车辆限制充电”中解除",
  createCOA_00068: "限制充电车辆最大可充电SOC",
  createCOA_00069: "工单分类",
  createCOA_00070: "处理人角色",
  createCOA_00071: "满足条件的设备重启",
  createCOA_00072: "注：会导致用户充电过程中断，需谨慎设置触发条件；触发时如果设备离线，则无法生效",
  createCOA_00073: "每年时间",
  createCOA_00074: "添加日期区间",
  createCOA_00075: "每月时间（多选）",
  createCOA_00076: "每周时间（多选）",
  createCOA_00077: "一",
  createCOA_00078: "二",
  createCOA_00079: "三",
  createCOA_00080: "四",
  createCOA_00081: "五",
  createCOA_00082: "六",
  createCOA_00083: "日",
  createCOA_00084: "每日时间",
  createCOA_00085: "添加时段",
  createCOA_00086: "请选择设备类型",
  createCOA_00087: "请正确填写告警码",
  createCOA_00088: "请正确填写故障时间",
  createCOA_00089: "请正确填写触发频次",
  createCOA_00090: "请正确填写",
  createCOA_00091: "已存在",
  createCOA_00092: "已存在当前用户",
  createCOA_00093: "请选择用户",
  createCOA_00094: "请填写名称",
  createCOA_00095: "请选择生效周期",
  createCOA_00096: "生效周期设置为全年时间，请选择不限日期",
  createCOA_00097: "生效周期设置为整月，请选择不限日期",
  createCOA_00098: "生效周期设置为整周，请选择不限日期",
  createCOA_00099: "生效时间设置为0-24小，请选择不限时间",
  createCOA_00100: "生效周期第{0}条数据错误：结束时间不能小于或等于开始时间",
  createCOA_00101: "生效周期第{0}条数据错误：开始时间不能小于或等于前一条数据的结束时间",
  createCOA_00102: "生效周期第{0}条数据错误：与前一条数据的时间间隔必须大于1天",
  createCOA_00103: "生效周期数据错误：时间段覆盖了整整一年",
  createCOA_00104: "生效周期第{0}条数据错误：请填写完整",
  createCOA_00105: "生效时间第{0}条数据错误：请填写完整",
  createCOA_00106: "生效时间第{0}条数据错误：结束时间不能小于或等于开始时间",
  createCOA_00107: "生效时间第{0}条数据错误：开始时间不能小于或等于前一条数据的结束时间",
  createCOA_00108: "生效时间数据错误：时间段覆盖了全天",
  createCOA_00109: "禁止和限制车辆充电动作只能同时存在一条",
  createCOA_00110: "C端用户在某些情况下，例如车辆黑名单、订单结束原因中，会看到策略名称，所以需要策略名称起名时注意可理解性。",
  createCOA_00111: "请选择处理动作",
  createCOA_00112: "请通过微信扫描左侧二维码关注“智充”公众号，以便接收定制化通知。",
  createCOA_00113: "1、在公众号底部菜单中，点击“充电维保”-“维保下单”，进入“智维保”小程序。",
  createCOA_00114: "2、使用您的平台账号登录“智维保”小程序。",
  createCOA_00115: "3、完成上述步骤后，您将通过“智充”公众号接收与您的账号相关的维保通知，所有通知均基于公众号模板消息功能发送。",
  createCOA_00116: "关注后，请按照以下步骤操作：",
};
