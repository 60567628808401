export default {
  safetyReportDetails_00001: 'Detalles del informe de seguridad',
  safetyReportDetails_00002: 'Informe de seguridad',
  safetyReportDetails_00003: 'Período del informe',
  safetyReportDetails_00004: 'Editar conclusiones y sugerencias',
  safetyReportDetails_00005: 'Exportar como PDF',
  safetyReportDetails_00006: 'Enviar al usuario',
  safetyReportDetails_00007: 'Limitar/Prohibir cobros',
  safetyReportDetails_00008: 'Número de placa',
  safetyReportDetails_00009: 'VIN',
  safetyReportDetails_00010: 'Marca-Modelo-Año',
  safetyReportDetails_00011: 'Ver usuario',
  safetyReportDetails_00012: 'Análisis del comportamiento de carga',
  safetyReportDetails_00013: 'Promedio de SOC durante la carga inicial',
  safetyReportDetails_00014: 'Promedio de SOC durante la carga final',
  safetyReportDetails_00015: 'Tiempos de carga rápidos',
  safetyReportDetails_00016: 'Tiempos de carga lentos',
  safetyReportDetails_00017: 'Tiempos de sobredescarga',
  safetyReportDetails_00018: 'Número de transacciones anormales',
  safetyReportDetails_00019: 'Ver transacciones anormales',
  safetyReportDetails_00020: 'Número total de transacciones',
  safetyReportDetails_00021: 'Ver registro de evaluación de seguridad',
  safetyReportDetails_00022: 'Proporción de transacciones anormales',
  safetyReportDetails_00023: 'Análisis de carga anormal',
  safetyReportDetails_00024: 'La relación de terminación anormal de la carga es alta',
  safetyReportDetails_00025: 'Análisis de la puntuación de seguridad',
  safetyReportDetails_00026: 'Observaciones del evento',
  safetyReportDetails_00027: 'Tendencia de cambio de la tasa de temperatura máxima',
  safetyReportDetails_00028: 'Tendencia de cambio de la temperatura máxima',
  safetyReportDetails_00029: 'Tendencia de cambio de la diferencia de temperatura máxima',
  safetyReportDetails_00030: 'Tendencia de cambio de la presión diferencial máxima',
  safetyReportDetails_00031: 'Tasa de retención de la capacidad de la batería',
  safetyReportDetails_00032: 'Puntuación',
  safetyReportDetails_00033: 'Velocidad de temperatura',
  safetyReportDetails_00034: 'Temperatura',
  safetyReportDetails_00035: 'Diferencia de temperatura',
  safetyReportDetails_00036: 'Diferencia de voltaje',
  safetyReportDetails_00037: 'Tasa de retención de capacidad de la batería',
  safetyReportDetails_00038: 'El valor promedio de la batería {name} {time} del automóvil es {caverage}, con el valor más alto de {max}, y el valor promedio del mismo año del modelo en su ciudad es {oaverage}. Se puede observar que el índice {evaluación} de la batería {nombre} del vehículo',
  safetyReportDetails_00039: 'No hay problema',
  safetyReportDetails_00040: 'Sub-seguridad, preste atención al mantenimiento del vehículo',
  safetyReportDetails_00041: 'Hay anomalías obvias. Por favor, repare el vehículo lo antes posible',
  safetyReportDetails_00042: 'Mismo modelo',
  safetyReportDetails_00043: 'Observaciones del evento',
  safetyReportDetails_00044: 'Por favor, rellene las observaciones',
  safetyReportDetails_00045: '¿Está seguro de eliminar esta nota?',
  safetyReportDetails_00046: 'Iniciar con menos del 30 % de carga de batería',
  safetyReportDetails_00047: 'Ver registros de seguridad',
  safetyReportDetails_00048: 'Detener la carga',
  safetyReportDetails_00049: 'Reducir la potencia',
  safetyReportDetails_00050: 'Alarma',
  safetyReportDetails_00051: 'Sin fallo',
  safetyReportDetails_00052: 'Evaluación individual',
  safetyReportDetails_00053: 'Seguro',
  safetyReportDetails_00054: 'Subseguro',
  safetyReportDetails_00055: 'Inseguro',
  safetyReportDetails_00056: 'Se generó una excepción de falla del vehículo',
  
  }