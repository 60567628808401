export default {
    groupDetail_00001: "Detalles del grupo de pago individual",
    groupDetail_00002: "Detalles de la flota de pago de flota",
    groupDetail_00003: "Asignar importe a los miembros",
    groupDetail_00004: "Configuración de la estación",
    groupDetail_00005: "Miembros",
    groupDetail_00006: "Reconocer el EV distinto del usuario",
    groupDetail_00007: "Configuración del CPO",
    groupDetail_00008: "Administración de grupos",
    groupDetail_00009: "Exportar",
    groupDetail_00010: "Eliminar grupo",
    groupDetail_00011: "No se puede recuperar después de eliminar un grupo",
    groupDetail_00012: "Transacciones",
    groupDetail_00013: "Esta operación descargará el archivo Excel del usuario del grupo, ¿desea continuar?",
    groupDetail_00014: "¿Está seguro de que desea eliminar la flota {name}?",
    groupDetail_00015: "Operación cancelada",
    groupDetail_00016: "configuración básica",
    groupDetail_00017: "Nombre de la flota",
    groupDetail_00018: "método de pago",
    groupDetail_00019: "Configuración de privilegios",
    groupDetail_00020: "Importe máximo no pagado por miembro ({unit})",
    groupDetail_00021: "Número máximo de transacciones no pagadas por miembro (transacciones)",
    groupDetail_00022: "Si se acepta el límite de tiempo de apertura de la pila de carga",
    groupDetail_00023: "Pagado por el individuo",
    groupDetail_00024: "Pagado por la flota",
    groupDetail_00025: "{num}",
    groupDetail_00026: "Si se permite el uso de cupones",
    groupDetail_00027: "Si se admite la recarga grupal de terminales de autoservicio",
    groupDetail_00028: "Asignar información de la cuenta",
    groupDetail_00029: "Saldo",
    groupDetail_00030: "Registro de recarga",
    groupDetail_00031: "Recarga de cuenta",
    groupDetail_00032: "Incluir cashback{promotionBalance}{unit}",
    groupDetail_00033: "¡Guarde la operación anterior!",
    groupDetail_00034: "Nombre del sitio de carga",
    groupDetail_00035: "Descuentos para estaciones",
    groupDetail_00036: "Agregar descuento",
    groupDetail_00037: "Eliminar descuentos",
    groupDetail_00038: "Tarifas",
    groupDetail_00039: "Oferta de descuento",
    groupDetail_00040: "Operar",
    groupDetail_00041: "CC",
    groupDetail_00042: "CA",
    groupDetail_00043: "El costo total de una sesión de carga única no tiene descuento",
    groupDetail_00044: "La tarifa de servicio de carga única no tiene descuento",
    groupDetail_00045: "La tarifa de servicio de carga única tiene un descuento de {des}",
    groupDetail_00046: "El costo total de una sesión de carga única tiene un descuento de {des}",
    groupDetail_00047: "El costo total de una sesión de carga única es gratuito",
    groupDetail_00048: "Sin cargo por una sesión de carga única",
    groupDetail_00049: "Configuración de descuento",
    groupDetail_00050: "Detalles",
    groupDetail_00051: "Eliminar",
    groupDetail_00052: "¿Confirmar que se eliminará la estación de carga de la flota?",
    groupDetail_00053: "No eliminado",
    groupDetail_00054: "Tarifas públicas",
    groupDetail_00055: "Si se modifican las tarifas, haga clic en el botón 'Aplicar tarifas' para que los cambios surtan efecto",
    groupDetail_00056: "Tarifas de CA",
    groupDetail_00057: "Tarifas de CC",
    groupDetail_00058: "Seleccionar tarifas de CA",
    groupDetail_00059: "Seleccionar tarifas de CC",
    groupDetail_00060: "El costo total de una sola carga no se descuenta (%)",
    groupDetail_00061: "Descuento de la tarifa de servicio de carga única (%)",
    groupDetail_00062: "85 significa 15% de descuento, 0 significa gratis",
    groupDetail_00063: "Ejemplo: si establece un descuento del 15 % en la tarifa de servicio para una sesión de carga única, el cargo por esta vez es 100{unit} (incluidos 70{unit} por electricidad y 30{unit} por servicio), y el monto real a pagar esta vez es 95,5{unit}(70) +30*85%=95,5).",
    groupDetail_00064: "Se aplica un descuento al costo total de una sesión de carga única (%）",
    groupDetail_00065:"Por ejemplo: si establece un descuento del 15 % en el costo total de una sesión de carga única y el cargo total de este cargo es 100{unit}, el monto real a pagar esta vez es 85{unit} (100*85%=85).",
    groupDetail_00066: "Guardar configuración",
    groupDetail_00067: "Completar y enviar inmediatamente",
    groupDetail_00068: "Si se debe asignar una cantidad a los miembros del grupo",
    groupDetail_00069: "Información de la flota",
    groupDetail_00070: "Nombre de la flota",
    groupDetail_00071: "saldo actual:{balance}{unit}",
    groupDetail_00072: "Incluir regalo{promotionBalance}{unit}",
    groupDetail_00073: "Ingresa el monto de la recarga",
    groupDetail_00074: "Regala",
    groupDetail_00075: "Contraseña de inicio de sesión",
    groupDetail_00076: "Ingresa la contraseña",
    groupDetail_00077: "Activa el reconocimiento del auto pero no el reconocimiento del usuario",
    groupDetail_00078: "Nombre de usuario, número de móvil",
    groupDetail_00079: "Agregar miembro",
    groupDetail_00080: "Nombre de usuario (número de móvil)",
    groupDetail_00081: "Configuración de la cuenta",
    groupDetail_00082: "Establecer como administrador",
    groupDetail_00083: "Cancelar un administrador",
    groupDetail_00084: "Eliminar",
    groupDetail_00085: "Detalles del usuario",
    groupDetail_00086: "¿Estás seguro de eliminar [{nickname}] del grupo?",
    groupDetail_00087: "El usuario también tiene un saldo de asignación de grupo de {num} euros, que será recuperado automáticamente por el sistema después de la eliminación.",
    groupDetail_00088: "Miembro usuario de WeChat",
    groupDetail_00089: "Buscar miembro",
    groupDetail_00090: "Número de tarjeta",
    groupDetail_00091: "Miembros de la flota",
    groupDetail_00092: "Nombre",
    groupDetail_00093: "Ingrese su apodo, número de teléfono móvil, número de tarjeta y admita múltiples condiciones de consulta separadas por coma (,), salto de línea, espacio y coma (,)",
    groupDetail_00094: "resultado de búsqueda",
    groupDetail_00095: "Borrar",
    groupDetail_00096: "añadido",
    groupDetail_00097: "añadir",
    groupDetail_00098: "Se ha establecido",
    groupDetail_00099: "Establecer como administrador",
    groupDetail_00100: "lista de usuarios",
    groupDetail_00101: "Detalles de la tarjeta",
    groupDetail_00102: "1. Si configura 'Permitir el uso de cupones' en 'Sí', los usuarios del grupo de usuarios podrán usar cupones al pagar pedidos.",
    groupDetail_00103: "1.1. Al pagar un pedido, dé prioridad al descuento de tarifa del sitio vinculado al grupo, utilice el monto de la factura después del descuento de tarifa del sitio vinculado al grupo y luego seleccione el cupón a utilizar de acuerdo con el cupón que cumpla con las condiciones.",
    groupDetail_00104: "1.2、 For example: <br/> group users who charge 100 {unit} (including electricity 70 {unit} and service charge 30 {unit}) this time can enjoy the discount of group binding site charges (8.5% off the service charge for a single charge), and there is a coupon (the service charge for a single charge meets 20 {unit} 10% off the service charge), <br/> after using the discount of group binding site charges, the bill amount is 95.5 {unit} (including electricity 70 {unit}) , the service fee is 25.5 {unit} (30 * 85%). The coupon is satisfied (the service fee for a single charge is 20 {unit} 10% off the service fee). <br/> after using the coupon, the bill amount is 92.95 {unit} (including 70 {unit} + 22.95 {unit} (25.5 * 90%). Finally, 92.95 {unit} needs to be actually paid this time.",
    groupDetail_00105: "2. Si 'permitir cupones' está configurado en 'no', los usuarios del grupo no pueden usar cupones al pagar pedidos.",
    groupDetail_00106: "Después de habilitar la configuración de reconocimiento de vehículos sin reconocimiento de personas, la primera carga en el sitio preferencial vinculado por el grupo registrará el VIN correspondiente al vehículo del miembro del grupo. Un miembro del grupo solo puede corresponder a un vehículo. Para las cargas posteriores en el sitio preferencial del grupo, solo los vehículos con VIN vinculados por los miembros del grupo pueden disfrutar del descuento de tarifa del sitio establecido por el grupo.",
    groupDetail_00107: "• Se recomienda que después de habilitar esta configuración, los miembros del grupo realicen el cargo una vez en el sitio de descuento grupal lo antes posible para vincular el Vin.",
    groupDetail_00108: "• Cuando se habilita esta configuración, los miembros del grupo no podrán realizar cargos utilizando la pila de CA.",
    groupDetail_00109: "Parámetro no válido",
    groupDetail_00110: "Por favor, introduzca el apodo, número de teléfono móvil o número de tarjeta que desea consultar",
    groupDetail_00111: "La operación se ha cancelado",
    groupDetail_00112: "Eliminación exitosa",
    groupDetail_00113: "Asistencia a la solicitud de facturas en línea",
    groupDetail_00114: "Cuenta de flota",
    groupDetail_00115: "Información de la cuenta de flota",
    groupDetail_00116: "Límite de saldo mínimo ({unit})",
    groupDetail_00117: "En el modo de pago de cuenta de fondos, cuando el saldo de la cuenta es inferior al saldo mínimo, los usuarios de la flota no pueden comenzar a cobrar (si está configurado en 0 {unit}, significa que no hay límite)",
    groupDetail_00118: "Saldo de recordatorio ({unit})",
    groupDetail_00119: "En el modo de pago de cuenta de fondos, cuando el saldo de la cuenta es inferior al saldo de recordatorio, el administrador de la flota recibirá un SMS recordatorio. (Si se establece en {unit} significa que no hay recordatorio)",
    groupDetail_00120: "Solo puede ingresar 0 o un número entero positivo",
    groupDetail_00121: "Usar moneda",
    // 20220124--添加优惠站点弹窗
    groupDetail_10000: "Añadir descuento",
    groupDetail_10001: "Ingresar nombre de estación",
    groupDetail_10002: "Añadir lote",
    groupDetail_10003: "Estación",
    groupDetail_10004: "Operador",
    groupDetail_10005: "Cargadores",
    groupDetail_10006: "Estado de la estación",
    groupDetail_10007: "Operación",
    groupDetail_10008: "Añadir",
    groupDetail_10009: "Se han añadido",
    groupDetail_10010: "Está habilitado",
    groupDetail_10011: "No está habilitado",
    groupDetail_10012: "AC",
    groupDetail_10013: "DC",
    groupDetail_10014: "Siguiente",
    groupDetail_10015: "Por favor seleccionar tarifa",
}