export default {
  revenueMonitoring_00001: "Batería NZS",
  revenueMonitoring_00002: "Red",
  revenueMonitoring_00003: "Potencia de uso del cargador (kWh)",
  revenueMonitoring_00004: "Valor de la energía ({unidad})",
  revenueMonitoring_00005: "Ingresos por uso del cargador",
  revenueMonitoring_00006: "Potencia de carga de la batería NZS (kWh)",
  revenueMonitoring_00007: "Analizar",
  revenueMonitoring_00008: "Costo del cargador desde la red",
  revenueMonitoring_00009: "Costo de la batería NZS desde la red",
  revenueMonitoring_00010: "Valor de la energía",
  revenueMonitoring_00011: "Uso del cargador",
  revenueMonitoring_00012: "Potencia (kWh)",
  revenueMonitoring_00013: "Ingresos por uso de energía ({unidad})",
  revenueMonitoring_00014: "Valor de la energía",
  revenueMonitoring_00015: "Hora de uso",
  revenueMonitoring_00016: "Hora de uso",
  revenueMonitoring_00017: "Pico (agudo y pico)",
  revenueMonitoring_00018: "Fuera de hora punta",
  revenueMonitoring_00019: "Súper fuera de hora punta",
  revenueMonitoring_00020: "Red",
  revenueMonitoring_00021: "Batería NZS",
  revenueMonitoring_00022: "Día",
  revenueMonitoring_00023: "Semana",
  revenueMonitoring_00024: "Mes",
  revenueMonitoring_00025: "Año",
  revenueMonitoring_00026: "Vida útil",
  revenueMonitoring_00027: "Ingresos exportados de la red",
  revenueMonitoring_00028: "Autoalimentado",
  revenueMonitoring_00029: "PV",
  revenueMonitoring_00030: "Interacción total con la red",
  revenueMonitoring_00031: "Energía suministrada por PV",
  revenueMonitoring_00032: "PV",
  revenueMonitoring_00033: "Tasa de utilización de la batería de NZS",
  revenueMonitoring_00034: "Tasa de utilización de la carga",
  revenueMonitoring_00035: "Tasa de utilización de la descarga",
  revenueMonitoring_00036: "Batería de NZS cargada",
  revenueMonitoring_00037: "Batería de NZS descargada",
  revenueMonitoring_00038: "Capacidad instalada de la batería de NZS",
  revenueMonitoring_00039: "Superpico",
  revenueMonitoring_00040: "Pico",
  revenueMonitoring_00041: "Fuera de picos",
  revenueMonitoring_00042: "Súper sin picos",
  revenueMonitoring_00043: "Costo de red",
  revenueMonitoring_00044: "Red neta importada",
  revenueMonitoring_00045: "A la red",
  revenueMonitoring_00046: "Otros",
  revenueMonitoring_00047: "Energía conectada a la red",
  revenueMonitoring_00048: "Energía cargada con batería",
  revenueMonitoring_00049: "Energía cargada con VE",
  
  energyOperationMonitoring_00037: "Ene",
  energyOperationMonitoring_00038: "Feb",
  energyOperationMonitoring_00039: "Mar",
  energyOperationMonitoring_00040: "Abr",
  energyOperationMonitoring_00041: "May",
  energyOperationMonitoring_00042: "Jun",
  energyOperationMonitoring_00043: "Jul",
  energyOperationMonitoring_00044: "Ago",
  energyOperationMonitoring_00045: "Sep",
  energyOperationMonitoring_00046: "Oct",
  energyOperationMonitoring_00047: "Nov",
  energyOperationMonitoring_00048: "Dic",
  
  energyOperationMonitoring_00049: "Lun",
  energyOperationMonitoring_00050: "Mar",
  energyOperationMonitoring_00051: "Miércoles",
  energyOperationMonitoring_00052: "jueves",
  energyOperationMonitoring_00053: "viernes",
  energyOperationMonitoring_00054: "sábado",
  energyOperationMonitoring_00055: "domingo",
  
  }