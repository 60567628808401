export default {
  rates_00001: 'Gestión de cargos',
  rates_00002: 'Centro de operaciones',
  rates_00003: 'Nombre de la tarifa',
  rates_00004: 'Si está en uso',
  rates_00005: 'Todo',
  rates_00006: 'Sí',
  rates_00007: 'No',
  rates_00008: 'Restablecer',
  rates_00009: 'Buscar',
  rates_00010: 'Nuevas tarifas',
  rates_00011: 'Operación',
  rates_00012: 'Usada',
  rates_00013: 'Sin usar',
  rates_00014: 'Modificar',
  rates_00015: 'Copiar',
  rates_00016: 'Detalles',
  rates_00017: 'Eliminar',
  rates_00018: 'Aplicar tarifa',
  rates_00019: 'Por favor, introduzca el nombre de la tarifa',
  rates_00020: 'Por favor, introduzca la tarifa',
  rates_00021: 'Por favor, introduzca la tarifa predeterminada para esta estación',
  rates_00022: 'Por favor, configure el intervalo de tiempo de forma continua de 0:00 a 24:00',
  rates_00023: 'Por favor, introduzca la tarifa correcta',
  rates_00024: 'El nombre de la tarifa está ocupado',
  rates_00025: 'Editar cargos',
  rates_00026: 'Copiar cargos',
  rates_00027: 'Añadir periodo de tiempo',
  rates_00028: 'Se puede añadir un máximo de {0} periodos de tiempo',
  rates_00029: 'Hora de inicio',
  rates_00030: 'Hora de finalización',
  rates_00031: 'Tarifa de electricidad ({type}/{unit})',
  rates_00032: 'Tarifa de servicio ({type}/{unit})',
  rates_00033: 'Tarifa por inactividad ({type}/{unit})',
  rates_00034: 'Tipo de período',
  rates_00035: 'Indefinido',
  rates_00036: 'Cancelar',
  rates_00037: 'Confirmar',
  rates_00038: 'Resultado de la inserción',
  rates_00039: 'Nombre del sitio',
  rates_00040: 'Número de equipo',
  rates_00041: 'Resultado de la inserción del dispositivo',
  rates_00042: 'CC',
  rates_00043: 'CA',
  rates_00044: 'No se puede restaurar la eliminación, ¿desea continuar?',
  rates_00045: 'Recordatorio',
  rates_00046: 'Eliminación exitosa',
  rates_00047: 'Nuevo éxito',
  rates_00048: 'Agudo',
  rates_00049: 'Pico',
  rates_00050: 'Plano',
  rates_00051: 'Valle',
  rates_00052: 'Éxito',
  rates_00053: 'Enviando',
  rates_00054: 'Error',
  rates_00055: 'Enviar fallido',
  rates_00056: 'Detalles',
  rates_00057: 'Exito de edición',
  rates_00058: 'Confirmar envío',
  rates_00059: 'Cerrar',
  rates_00060: 'Usar moneda',
  // ****
  rates_00061: 'Aplicar',
  rates_00062: 'Alcance',
  rates_00063: 'Tarifas públicas',
  rates_00064: 'Tarifas de flota',
  rates_00065: 'Tarifas de terceros',
  rates_00066: 'Tarifas',
  rates_00067: 'Cargos',
  rates_00068: 'Enviar objeto',
  rates_00069: 'Seleccione el sitio',
  rates_00070: 'Seleccione un grupo',
  rates_00071: 'Seleccione tráfico de terceros',
  rates_00072: 'Enviar a pila de CA',
  rates_00073: 'Enviar a pila de CC',
  rates_00074: 'vinculación',
  rates_00075: 'reintentar',
  rates_00076: 'Enlace primero al sitio asignado',
  //电网资费
  rates_00077: 'Tipo',
  rates_00078: 'Tarifas de red',
  rates_00079: 'Tarifas de servicio',
  rates_00080: 'Precio correspondiente a la hora punta de pinggu',
  rates_00081: 'Sección normal de la hora punta del valle',
  rates_00082: 'Electricidad de red ({type}/{unit})',
  rates_00083: 'anterior',
  rates_00084: 'siguiente',
  rates_00085: 'Seleccione el período de tarifa punta',
  rates_00086: 'CC',
  rates_00087: 'CA',
  rates_00088: 'Público',
  rates_00089: 'Grupo',
  rates_00090: 'Tercero',
  rates_00091: 'Confirme si desea vincular la tarifa a',
  rates_00092: 'sitio',
  rates_00093: 'el',
  rates_00094: 'Cero neto',
  }