export default {
    configInfo_00001: 'Sistema de carga inteligente',
    configInfo_00002: 'Información de configuración de la pantalla del cargador C1 (pantalla de 4,3 pulgadas)',
    configInfo_00003: 'Información de configuración de la pantalla del cargador C1 (pantalla de 7 pulgadas)',
    configInfo_00004: 'Cargando imagen',
    configInfo_00005: 'Arrastre el archivo aquí o haga clic para cargarlo',
    configInfo_00006: 'Solo se admiten imágenes en formato PNG, tamaño {area}px, no más de {size}kb。',
    configInfo_00007: 'Modificar imagen',
    configInfo_00008: 'La imagen que ha seleccionado es demasiado grande, vuelva a seleccionarla',
    configInfo_00009: 'Seleccione una imagen en formato png para cargar',
    configInfo_00010: 'Cargue una imagen que coincida con {0}px',
    configInfo_00011: 'Cargue una imagen',
    configInfo_00012: 'Modificación exitosa',
    configInfo_00013: 'Escanee el código de WeChat para comenzar a cargar',
    configInfo_00014: 'Escanee el código QR de WeChat a la izquierda, siga las instrucciones para el siguiente paso',
    configInfo_00015: 'No más de 24 caracteres',
    configInfo_00016: 'Anuncios después de activarse',
    configInfo_00017: 'Anuncios al deslizar',
    configInfo_00018: 'Anuncios después de conectar',
    configInfo_00019: 'Anuncios posteriores al barrido y desconectados',
    configInfo_00020: 'Anuncios cuando estén disponibles',
    configInfo_00021: 'Rotación de imágenes múltiples o método de video mp4, elija uno',
    configInfo_00022: 'Usar rotación de imágenes',
    configInfo_00023: 'Haga clic para cargar',
    configInfo_00024: 'Solo se admiten imágenes PNG y JPG, tamaño {area}px, no más de {size}kb',
    configInfo_00025: 'Usar video',
    configInfo_00026: '5 segundos',
    configInfo_00027: '10 segundos',
    configInfo_00028: '15 segundos',
    configInfo_00029: 'Hasta 6 cargas',
    configInfo_00030: 'Seleccione el formato png o jpg para cargar',
    configInfo_00031: 'Cargue hasta 1 video',
    configInfo_00032: 'El video que ha seleccionado es demasiado grande, selecciónelo nuevamente',
    configInfo_00033: 'Cargue una imagen o un video',
    configInfo_00034: 'Tiempo de reproducción',
    configInfo_00035: 'Solo se admiten videos en formato mp4, el tamaño no debe superar los {vsize} MB. (Resolución máxima recomendada: {varea} píxeles, velocidad de cuadros menor a 30 fps).',
    configInfo_00036: 'Subir imagen o vídeo',
    configInfo_00037: 'Usar imágenes',
    configInfo_00038: 'Solo se admiten imágenes en formato PNG, JPG y GIF, tamaño {area}px, no más de {size}kb',
    configInfo_00039: 'Usar vídeo',
    configInfo_00040: 'Seleccione mp4 para subir el vídeo',
    configInfo_00041: 'Suba un vídeo',
    configInfo_00042: 'Eliminar anuncios',
    configInfo_00043: '¿Está seguro de eliminar los anuncios de todos los postes de carga de este tipo en la estación de carga?',
    configInfo_00044: 'La eliminación no se restaurará, ¿desea continuar?',
    configInfo_00045: 'Sí',
    configInfo_00046: 'Cancelar',
    configInfo_00047: 'Seleccionar todo',
    configInfo_00048: 'Nombre del cargador',
    configInfo_00049: 'Número de dispositivo',
    configInfo_00050: 'X-CHARGE',
    configInfo_00051: 'Bienvenido a utilizar el sistema de carga de CC ingelligent',
    configInfo_00052: 'Utilice WeChat para cargar o utilice NFC para cargar con tarjeta magnética',
    configInfo_00053: 'Use el botón táctil para seleccionar el puerto de carga',
    configInfo_00054: 'Información de configuración de la pantalla de la estación de carga C401',
    configInfo_00055: 'Información de configuración de la pantalla de la estación de carga C6',
    configInfo_00056: 'Información de configuración de la pantalla de la estación de carga C6s',
    configInfo_00116: 'Información de configuración de la pantalla de la estación de carga C9Pro',
    configInfo_00057: 'El logotipo superior puede tener formato de texto o de imagen, cualquiera de los dos.',
    configInfo_00058: 'Usar texto',
    configInfo_00059: 'Por favor, introduzca el contenido',
    configInfo_00060: '{num} palabras o menos (incluyendo puntuación)',
    configInfo_00061: 'Por favor, rellene el texto de la pantalla superior o cargue una imagen',
    configInfo_00062: 'El texto de la pantalla superior es demasiado largo',
    configInfo_00063: "Por favor, seleccione 'Usar imagen' primero",
    configInfo_00064: "Por favor, guarde la acción anterior primero",
    configInfo_00065: "No más de {num} caracteres",
    configInfo_00066: "No más de 10 palabras",
    configInfo_00067: "Los parámetros están vacíos",
    configInfo_00068: "El texto ingresado para el parámetro no puede tener más de 7 dígitos vacíos",
    configInfo_00069: "El título de la pantalla de barrido no puede superar los 10 dígitos",
    configInfo_00070: "La longitud del parámetro es demasiado larga",
    configInfo_00071: "Escanee el código para cargar o use la tarjeta NFC para cargar",
    configInfo_00072: "Se enviaron {allDevice} dispositivos en total, {sucDevice} dispositivos tuvieron éxito, {faiDevice} dispositivos fallaron",
    configInfo_00073: "Nombre del cargador",
    configInfo_00074: "Resultado",
    configInfo_00075: "Detalles del sitio de carga",
    configInfo_00076: "Configuración de pantalla",
    configInfo_00077: "Información de configuración",
    configInfo_00078: "Logotipo de encendido",
    configInfo_00079: "Modificar",
    configInfo_00080: "Logotipo superior",
    configInfo_00081: "Texto de la interfaz",
    configInfo_00082: "Bienvenido al uso",
    configInfo_00083: "Escanee el código para cargar",
    configInfo_00084: "Bienvenido al uso",
    configInfo_00085: "Título de la interfaz del código de escaneo",
    configInfo_00086: "Guardar",
    configInfo_00087: "No más de 16 caracteres",
    configInfo_00088: "Configuración de la pantalla de la estación de carga C2 Información",
    configInfo_00089: "Texto de la pantalla de escaneo",
    configInfo_00090: "Texto superior",
    configInfo_00091: "Encabezado del texto de bienvenida",
    configInfo_00092: "Contenido del texto de bienvenida",
    configInfo_00093: "Texto del mensaje de bienvenida inferior",
    configInfo_00094: "Modificar el logotipo superior",
    configInfo_00095: "Eliminar",
    configInfo_00096: "Información del anuncio",
    configInfo_00097: "Lista de dispositivos",
    configInfo_00098: "Editar la información de configuración, verificar la pila de carga y enviarla a la pila de carga. Se recomienda que la información de configuración se envíe después de cada cambio para garantizar que la pila de carga funcione correctamente",
    configInfo_00099: "Enviar configuración",
    configInfo_00100: "Enviar todo",
    configInfo_00101: "Éxito",
    configInfo_00102: "Error",
    configInfo_00103: "Enviando",
    configInfo_00104: "Cerrar",
    configInfo_00114: "Pantalla del cargador C1 (pantalla de 7 pulgadas)",
    configInfo_00113: "Pantalla del cargador C2",
    configInfo_00112: "Pantalla del cargador C401",
    configInfo_00111: "Pantalla del cargador C6",
    configInfo_00110: "Pantalla del cargador C6s",
    configInfo_00117: "Pantalla del cargador C9Pro",
    configInfo_00115: "Pantalla del cargador C1 (pantalla de 4,3 pulgadas)",
    modify_success: "Modificación realizada correctamente",
}