export default {
  monthlyStatistics_00001:'Centro financiero',
  monthlyStatistics_00002:'Fecha de inicio',
  monthlyStatistics_00003:'Fecha de finalización',
  monthlyStatistics_00004:'consulta',
  monthlyStatistics_00005:'común',
  monthlyStatistics_00006:'Recarga',
  monthlyStatistics_00007:'Monto total',
  monthlyStatistics_00008:'Yuan',
  monthlyStatistics_00009:'Descargar formulario',
  monthlyStatistics_00010:'hoy',
  monthlyStatistics_00011:'ayer',
  monthlyStatistics_00012:'Hace 7 días',
  monthlyStatistics_00013:'Hace 15 días',
  monthlyStatistics_00014:'Hace 30 días',
  monthlyStatistics_00015:'Reembolso',
  
  monthlyStatistics_00016:'Objeto de recarga',
  monthlyStatistics_00017:'Número de transacciones de recarga',
  monthlyStatistics_00018:'Monto de recarga(Yuan)',
  monthlyStatistics_00019:'capital(Yuan)',
  monthlyStatistics_00020:'give(Yuan)',
  monthlyStatistics_00021:'operación',
  
  monthlyStatistics_00022:'Objeto de reembolso',
  monthlyStatistics_00023:'Número de reembolsos',
  monthlyStatistics_00024:'monto de reembolso(Yuan)',
  
  monthlyStatistics_00025:'Objeto estadístico',
  monthlyStatistics_00026:'Número de usuarios (persona)',
  monthlyStatistics_00027:'Saldo (Yuan)',
  monthlyStatistics_00028:'Capital (Yuan)',
  monthlyStatistics_00029:'Regalo (Yuan)',
  
  monthlyStatistics_00030:'Objetivo de ventas',
  monthlyStatistics_00031:'Número de compras',
  monthlyStatistics_00032:'Número de compradores',
  monthlyStatistics_00033:'Monto de ventas (Yuan)',
  
  monthlyStatistics_00034:'Todas las ventas con cupones',
  monthlyStatistics_00035:'Todos los saldos de billetera de los usuarios',
  
  monthlyStatistics_00036:'Año',
  monthlyStatistics_00037:'Estadísticas financieras'
  
  }