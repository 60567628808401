export default {
    deviceMonitor_00001: 'Ocupando',
    deviceMonitor_00002: 'El cargador no está disponible',
    deviceMonitor_00003: 'El cargador está fuera de línea',
    deviceMonitor_00005: 'Cambiar a disponible',
    deviceMonitor_00006: 'Iniciar carga',
    deviceMonitor_00008: 'Reservado',
    deviceMonitor_00009: 'Sin código de error',
    deviceMonitor_00010: "El cargador se reinició correctamente",
    deviceMonitor_00011: "Reinicio remoto",
    deviceMonitor_00012: "Error al reiniciar el cargador",
    deviceMonitor_00013: "Gestión del cargador",
    deviceMonitor_00014: "Errores actuales",
    deviceMonitor_00015: "Manejo de errores",
    deviceMonitor_00016: "Ingrese el nombre o el número de serie del cargador",
    deviceMonitor_00017: "Seleccione o ingrese el nombre de la estación",
    deviceMonitor_00018: "Todos",
    deviceMonitor_00019: "Modelo de cargador:",
    deviceMonitor_00020: "CC",
    deviceMonitor_00021: "CA",
    deviceMonitor_00022: "Puerta de enlace",
    deviceMonitor_00023: "Con fallas",
    deviceMonitor_00024: "Sin conexión",
    deviceMonitor_00025: "Disponible",
    deviceMonitor_00026: "Ver solo en preparación",
    deviceMonitor_00027: "Cargando",
    deviceMonitor_00028: "Ver solo en finalización",
    deviceMonitor_00029: "Sin conexión",
    deviceMonitor_00030: "No disponible",
    deviceMonitor_00031: "SN:",
    deviceMonitor_00032: "Modelo de fabricación:",
    deviceMonitor_00033: "Estación:",
    deviceMonitor_00034: "Reiniciar cargador",
    deviceMonitor_00035: "Detalles",
    deviceMonitor_00036: "Conector:",
    deviceMonitor_00037: "Fuera de línea",
    deviceMonitor_00038: "No disponible",
    deviceMonitor_00039: "Con falla",
    deviceMonitor_00040: "Ocupando",
    deviceMonitor_00041: "Cargando",
    deviceMonitor_00042: "Disponible",
    deviceMonitor_00043: "VE a mover",
    deviceMonitor_00044: "Reservado",
    deviceMonitor_00045: "Preparando",
    deviceMonitor_00046: "Terminando",
    deviceMonitor_00047: "Transacciones",
    deviceMonitor_00048: "Reiniciar conector",
    deviceMonitor_00049: "Se ha enviado el comando de reinicio del cargador, el proceso de reinicio tardará aproximadamente 5 minutos. Permanezca en esta página y no la actualice",
    deviceMonitor_00050: "Recordar información",
    deviceMonitor_00051: "Confirmar",
    deviceMonitor_00052: "Gestión del cargador",
    deviceMonitor_00053: "Fallo actual",
    deviceMonitor_00054: "Conectar",
    deviceMonitor_00055: "Fin de sesión de carga",
    deviceMonitor_00056: "No disponible",
    deviceMonitor_00057: "Normal",
    deviceMonitor_00058: "Con fallas",
    deviceMonitor_00059: "Bloqueado",
    deviceMonitor_00060: "En espera",
    deviceMonitor_00061: "Desconocido",
    deviceMonitor_00062: "Estado del cargador:",
    deviceMonitor_00063: "Con fallas",
    deviceMonitor_00064: "Ver solo los no devueltos",
    deviceMonitor_00065: "Devuelto",
    deviceMonitor_00066: "No se ha colocado en la posición inicial",
    deviceMonitor_00067: "Con fallas/fuera de línea",
    deviceMonitor_00068: "Batería NZS",
    deviceMonitor_00085: "Plataforma dual",
    deviceMonitor_00086: "Agregar cargador",
    eqDetails_30001: "Estado de parada de emergencia de recuperación del cargador",
    eqDetails_30002: "Si se debe realizar una autoinspección automática después de eso",
    eqDetails_30003: "Autoinspección HW",
    eqDetails_30004: "1. Verifique el estado del hardware del dispositivo, lo que demora decenas de segundos",
    eqDetails_30005: "2. Esta verificación requiere que el número de versión de firmware de la aplicación Android para el cargador sea superior a 3.0.0",
    eqDetails_30006: "3. Esta verificación no es compatible con dispositivos de terceros",
    eqDetails_30007: "Resultado de la última inspección",
    eqDetails_30008: "Iniciar inspección",
    eqDetails_30009: "Espere durante la autoinspección",
    eqDetails_30010: "Re-inspección",
    eqDetails_30011: "Tiempo de inspección",
    eqDetails_30012: "Inspección de estado",
    eqDetails_30013: "Vida útil del hardware",
    eqDetails_30014: "Elementos de autoinspección",
    eqDetails_30015: "Confirmar y recuperarse de una parada de emergencia",
    eqDetails_30016: "El cargador está en uso, la autoinspección se canceló, opere después de que esté inactivo",
    eqDetails_30017: "El cargador actual está fuera de línea",
    eqDetails_30018: "Se agotó el tiempo de autoinspección del cargador, intente nuevamente",
    eqDetails_30020: "Más deficiente",
    eqDetails_30021: "Moderado",
    eqDetails_30022: "Mejor",
    eqDetails_30023: "Esta inspección requiere que el número de versión del firmware de la aplicación Android para el cargador sea 3.0.0 o superior",
    eqDetails_30024: "No se encontró el resultado de la última autoinspección",
    eqDetails_30025: "Recuperación inmediata",
    eqDetails_30026: "Primero verifique el cargador, luego considere la recuperación",
    eqDetails_30027: "Descargo de responsabilidad",
    eqDetails_30028: "Para garantizar el funcionamiento seguro del cargador, le recomendamos que realice una verificación de hardware antes de restaurar el botón de parada de emergencia. Si encuentra alguna falla de hardware o peligro de seguridad, deje de usar el cargador y comuníquese con nuestro equipo de soporte técnico. Le brindaremos una solución lo antes posible para garantizar que su cargador pueda funcionar de manera segura y estable.",
    eqDetails_30029: "Tenga en cuenta que esta exención de responsabilidad tiene como objetivo recordarle que elegir restaurar directamente el botón de parada de emergencia sin determinar primero el estado del cargador puede implicar ciertos riesgos y no seremos responsables de las consecuencias resultantes.",
    eqDetails_30030: "Confirmar recuperación directa",
    eqDetails_30031: "Elementos de autocomprobación",
    eqDetails_30032: "Estado",
    eqDetails_30033: "{msg},{code},la autoinspección está cancelada, opere después del mantenimiento",
}