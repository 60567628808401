<template>
  <el-dialog
    :title="$t('marketCaptureTraffic_00054')"
    :visible.sync="visible"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :before-close="close"
    width="550px"
  >
    <div class="content">
      <p>{{ $t('marketCaptureTraffic_00055') }}</p>
      <p>{{ $t('marketCaptureTraffic_00056') }}</p>
      <p>{{ $t('marketCaptureTraffic_00057') }}</p>
      <p>{{ $t('marketCaptureTraffic_00058') }}</p>
      <p>{{ $t('marketCaptureTraffic_00059') }}</p>
      <p>{{ $t('marketCaptureTraffic_00060') }}</p>
      <p>{{ $t('marketCaptureTraffic_00061') }}</p>
      <p>{{ $t('marketCaptureTraffic_00062') }}</p>
      <p>{{ $t('marketCaptureTraffic_00063') }}</p>
      <p>{{ $t('marketCaptureTraffic_00064') }}</p>
    </div>
    <div class="dialog-btn">
      <el-button type="primary" size="medium" @click="close">{{$t('marketCaptureTraffic_00065')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      visible: true,
    };
  },
  created() {},

  methods:{
    close() {
      this.$emit("close");
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.m20 {
  margin: 0 20px;
}
.flex-container-left {
  margin-bottom: 20px;
  .tips {
    i {
      margin-right: 15px;
    }
    margin-bottom: 20px;
  }
}
.dialog-btn{
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
