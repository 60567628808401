export default {
    eqDetails_00001: '推送配置',
    eqDetails_00002: '设备详情',
    eqDetails_00003: '设备信息',
    eqDetails_00004: '设备配置',
    eqDetails_00005: '充电口管理',
    eqDetails_00006: '设备保养',
    eqDetails_00007: '重启设备',
    eqDetails_00008: '设备重启指令已发送，重启过程大概需要5分钟。请停留在此页面，不要刷新页面。',
    eqDetails_00009: '确定',
    eqDetails_00010: '启动充电',
    eqDetails_00011: '设备重启成功',
    eqDetails_00012: '远程重启',
    eqDetails_00013: '设备重启失败',
    eqDetails_00014: '设备名称',
    eqDetails_00015: '所在场站',
    eqDetails_00016: '查看',
    eqDetails_00017: '设备SN',
    eqDetails_00018: '制造商',
    eqDetails_00019: '设备型号',
    eqDetails_00020: '请输入设备名称',
    eqDetails_00021: '设备启用、电磁锁等配置在修改后会自动推送配置到设备，需要等待设备响应才会更新页面。',
    eqDetails_00022: '推送配置',
    eqDetails_00023: '推送到设备中',
    eqDetails_00024: '设备启用',
    eqDetails_00025: '电磁锁',
    eqDetails_00026: '电磁锁上锁失败可以充电',
    eqDetails_00027: '车位雷达',
    eqDetails_00028: '设备开放',
    eqDetails_00029: '开始时间',
    eqDetails_00030: '结束时间',
    eqDetails_00031: '最大充电电流(A)',
    eqDetails_00032: '最大充电功率(kW)',
    eqDetails_00033: '推送失败',
    eqDetails_00034: '设备离线',
    eqDetails_00035: '等待设备响应',
    eqDetails_00036: '推送成功',
    eqDetails_00037: '日期设置不正确',
    eqDetails_00038: '电源模块需保养计时提示：重置时间起累计充电时长',
    eqDetails_00039: '{hours}小时',
    eqDetails_00040: '重置时间：',
    eqDetails_00041: '正常',
    eqDetails_00042: '故障',
    eqDetails_00043: '您确定已对此充电桩{0}完成此次保养？点击确定后，{0}需要保养提示重新计时。',
    eqDetails_00044: '提示',
    eqDetails_00045: '充电口名称',
    eqDetails_00046: '充电二维码',
    eqDetails_00047: '动态二维码',
    eqDetails_00048: '固定二维码',
    eqDetails_00049: '升级为固定二维码',
    eqDetails_00050: '自定义二维码',
    eqDetails_00051: '远程控制',
    eqDetails_00052: '重启充电口',
    eqDetails_00053: '结束充电',
    eqDetails_00054: '置为空闲',
    eqDetails_00055: '充电口状态',
    eqDetails_00056: '车位管理设备',
    eqDetails_00057: '无',
    eqDetails_00058: '桩控车位锁',
    eqDetails_00059: '云控车位锁',
    eqDetails_00060: '车位管理设备信息',
    eqDetails_00061: '请输入设备编码',
    eqDetails_00062: '启用车位管理设备',
    eqDetails_00063: '远程控制车位管理设备',
    eqDetails_00064: '上升',
    eqDetails_00065: '下降',
    eqDetails_00066: '当前车位占用费计费用户',
    eqDetails_00067: '占用开始时间',
    eqDetails_00068: '用户详情',
    eqDetails_00069: '人工结束此次占用费计费',
    eqDetails_00070: '车位管理设备降下二维码',
    eqDetails_00071: '生成固定二维码',
    eqDetails_00072: '占用中',
    eqDetails_00073: '未启用',
    eqDetails_00074: '离线',
    eqDetails_00075: '故障',
    eqDetails_00076: '预约中',
    eqDetails_00077: '空闲',
    eqDetails_00078: '已插枪',
    eqDetails_00079: '充电中',
    eqDetails_00080: '待挪车',
    eqDetails_00081: '待拔枪',
    eqDetails_00082: '充电口',
    eqDetails_00083: '启动充电',
    eqDetails_00084: '请保存上一步操作！！',
    eqDetails_00085: '当前车位有正在车位占用费计费用户，请稍后修改!',
    eqDetails_00086: '参数为空',
    eqDetails_00087: '最长为20个字符',
    eqDetails_00088: '启动充电指令已发送',
    eqDetails_00089: '结束充电指令已发送',
    eqDetails_00090: '发送成功',
    eqDetails_00091: '升级为固定二维码',
    eqDetails_00092: '生成二维码成功',
    eqDetails_00093: '已启用',
    eqDetails_00094: '未启用',
    eqDetails_00095: '云控车位锁',
    eqDetails_00096: '桩控车位锁',
    eqDetails_00097: '请输入二维码内容，您配置的二维码会在桩上显示',
    eqDetails_00098: '注意: 只能生成固定二维码，且不可恢复成原始状态',
    eqDetails_00099: '自定义二维码不能为空！',
    eqDetails_00100: '自定义二维码成功',
    eqDetails_00101: '设置失败',
    eqDetails_00102: '长度不能超过{0}个字',
    eqDetails_00103: '当前收费时长（分钟）',
    eqDetails_00104: '当前预收占用费（{0}）',
    eqDetails_00105: '修改后占用费（{0}）',
    eqDetails_00106: '请输入金额',
    eqDetails_00107: '请输入修改后占用费',
    eqDetails_00108: '请输入正确的数字',
    eqDetails_00109: '人工结束此次占用费计费成功',
    eqDetails_00110: '固定二维码最多10万个，且不可回收，请谨慎操作，确定升级吗?',
    eqDetails_00111: '升级成功',
    eqDetails_00112: '完成',
    eqDetails_00113: '是否生成车位管理设备降下固定二维码？',
    eqDetails_00114: '暂无二维码',
    eqDetails_00115: '若设备离线，只支持白名单内的用户启动充电。',
    eqDetails_00116: '清除白名单',
    eqDetails_00117: '推送白名单',
    eqDetails_00118: '设备ID',
    eqDetails_00119: '',
    eqDetails_10000: '您确定已对所有{0}充电桩完成此次保养？点击确定后，所有{0}需要保养提示重新计时。',
    eqDetails_10001: '修改全部阀',
    eqDetails_10002: '小时',
    eqDetails_10003: '次',
    eqDetails_10004: '提示',
    eqDetails_10005: '应用到全部',
    eqDetails_10006: '全部重置',
    eqDetails_10007: '使用时间',
    eqDetails_10008: '已使用',
    eqDetails_10009: '保养计时提示阀值：',
    eqDetails_10010: '重置时间起累计使用时长大于等于',
    eqDetails_10011: '请填写完整',
    eqDetails_10012: '电源模块',
    eqDetails_10013: '储能电池',
    eqDetails_10014: '风扇',
    eqDetails_10015: '充电枪',
    eqDetails_10016: '枪头继电器(DC+)',
    eqDetails_10017: '拉线器',
    eqDetails_10018: '浪涌保护器',
    eqDetails_10019: 'LCD屏',
    eqDetails_10020: '泄放电阻',
    eqDetails_10021: '强制补电',
    eqDetails_10022: '强制放电',
    eqDetails_10023: '禁用',
    eqDetails_10024: '自动运行',
    eqDetails_10025: '设备信息',
    eqDetails_10026: '设备设置',
    eqDetails_10027: '设置说明',
    eqDetails_10028: '充电口设置',
    eqDetails_10029: '充电口启用',
    eqDetails_10030: '充电口名称',
    eqDetails_10031: '电池充电模式',
    eqDetails_10032: '空调运行模式',
    eqDetails_10033: '操作模式设置',
    eqDetails_10034: '储能电池柜设置',
    eqDetails_10035: '电池柜',
    eqDetails_10036: '电池柜名称',
    eqDetails_10037: '电池柜容量(kWh)',
    eqDetails_10038: '电池循环次数(次)',
    eqDetails_10039: '电池柜SOC(%)',
    eqDetails_10040: '电池柜SOH(%)',
    eqDetails_10041: '重启',
    eqDetails_10042: '推送白名单',
    eqDetails_10043: '设备异常日志',
    eqDetails_10044: '设置说明',
    eqDetails_10045: '设备开关、电磁锁和其他设置将在修改后自动将设置推送到设备，您需要在更新页面之前等待设备响应',
    eqDetails_10046: '如果设备离线，则仅支持白名单中的用户开始充电',
    eqDetails_10047: '更多',
    eqDetails_10048: '储能最高停止补电soc',
    eqDetails_10049: '储能最低停止放电soc',
    eqDetails_10050: '储能补电功率限制(kW)',
    eqDetails_10051: '储能放电功率限制(kW)',
    eqDetails_10052: '电网输入功率限制(kW)',
    eqDetails_10053: '您确定重启设备?',
    eqDetails_10054: '范围',
    eqDetails_10055: "故障",
    eqDetails_10056: "不限制{0}%",
    eqDetails_10057: "重启电池柜",
    eqDetails_10058: "限制{0}%",
    eqDetails_10059: "指令已发送成功，设备将在充电结束后执行，请稍后刷新页面查看执行结果",
    eqDetails_10060: "已充电",
    eqDetails_10061: "推送成功，等待设备响应中",
    eqDetails_10062: "电池柜工作状态切换成功",
    eqDetails_10063: "电池柜工作状态切换失败",
    eqDetails_10064: "电池柜工作状态切换超时",
    eqDetails_10065: "该枪在充电中，不需再次启动充电",
    eqDetails_10066: "请先禁用车位管理设备",
    eqDetails_10067: "置为空闲",
    eqDetails_10068: "此功能只有当平台和枪口信息不同步时，做应急处理使用。是否确认置为空闲？",
    eqDetails_10069: "不限制，额定最大充电功率为{0}kW",
    eqDetails_10070: "不限制，额定最大电流为{0}A",
    eqDetails_10071: "已充时长",
    eqDetails_10072: "预计充满",

    // 推送白名单
    detailDepot_00182: "站点支持白名单",
    detailDepot_00545: "请保存上一步操作！！",
    detailDepot_00112: "可以充电",
    detailDepot_00106: "不可以充电",
    // #7662新增
    detailDepot_20000: "所属运营商",
    detailDepot_20001: "操作系统版本",
    detailDepot_20002: "Android应用版本",
    detailDepot_20003: "DCB版本",
    detailDepot_20004: "更新固件",
    detailDepot_20005: "电磁锁上锁",
    detailDepot_20006: "电磁锁解锁",
    detailDepot_20007: "删除设备",
    detailDepot_20008: "确定删除此设备？",
    detailDepot_20009: "操作已取消",
    detailDepot_20010: "请在设备配置中将启用开关关闭，再删除设备",
    eqDetails_10073: "请输入正确的范围",
    eqDetails_10074: "此设备型号系统中未设置额定最大功率，暂时无法修改，请联系管理员",
    eqDetails_10075: "此设备型号系统中未设置额定最大电流，暂时无法修改，请联系管理员。",
    detailDepot_00107: "强制并网",
    detailDepot_00108: '并网功率限制(kW)',
    detailDepot_00109: '推送到设备中',
    detailDepot_00110: '设备重启指令已发送，重启过程大概需要5分钟',
    detailDepot_00111: '双平台',
    detailDepot_10112: '指ACDC模块最高工作功率，无论给NZS电池还是车充电，都不能超过这个设置值。',

    //维保类型
    eqDetails_21000: "直流电表",
    eqDetails_21001: "交流电表",
    eqDetails_21002: "切换继电器",
    eqDetails_21003: "门禁传感器",
    eqDetails_21004: "倾倒传感器",
    eqDetails_21005: "烟雾传感器",
    eqDetails_21006: "液位传感器",
    eqDetails_21007: "液冷泵",
    eqDetails_21008: "液冷泵风机",
    eqDetails_21009: "灯条",
    eqDetails_21010: "滤网",
    eqDetails_21011: "储能电池健康度",
    eqDetails_21012: "交流接触器",
    eqDetails_21013: "枪头继电器(DC-)",
    eqDetails_21014: "厂家",
    eqDetails_21015: "优优",
    eqDetails_21016: "英飞源",
    eqDetails_21017: "麦格米特",
    eqDetails_21018: "枪头继电器(DC+)",
    eqDetails_21019: "储能电池柜倾角矫正",
    eqDetails_21020: "倾角矫正",
    eqDetails_21021: "SOH低于",
    eqDetails_21022: "当前",

    //自检
    eqDetails_30001: "桩端恢复急停状态",
    eqDetails_30002: "之后是否自动自检",
    eqDetails_30003: "硬件自检",
    eqDetails_30004: "1、检查设备硬件状态，过程需要几十秒",
    eqDetails_30005: "2、此检查要求充电桩的Android应用的固件版本号在4.0.0以上。",
    eqDetails_30006: "3、第三方设备不支持此检查。",
    eqDetails_30007: "上次检查结果",
    eqDetails_30008: "开始检查",
    eqDetails_30009: "自检中，请稍候",
    eqDetails_30010: "重新检查",
    eqDetails_30011: "检查时间",
    eqDetails_30012: "状态检查",
    eqDetails_30013: "硬件寿命",
    eqDetails_30014: "自检项",
    eqDetails_30015: "确认并恢复急停",
    eqDetails_30016: "设备使用中，自检取消，请空闲后操作",
    eqDetails_30017: "当前设备离线",
    eqDetails_30018: "设备自检超时，请重试",
    eqDetails_30020: "差",
    eqDetails_30021: "中",
    eqDetails_30022: "良",
    eqDetails_30023: "此检查要求充电桩的Android应用的固件版本号在4.0.0及以上",
    eqDetails_30024: "未查询到上次自检结果",
    eqDetails_30025: "立即恢复",
    eqDetails_30026: "先检查设备，再考虑恢复",
    eqDetails_30027: "免责申明",
    eqDetails_30028: "为了确保充电桩的安全运行，我们建议您在恢复急停按钮前进行硬件检查，如发现任何硬件故障或安全隐患，请停止使用充电桩，并联系我们的技术支持团队。我们将尽快提供解决方案，以确保您的充电桩能够安全、稳定地运行。",
    eqDetails_30029: "请注意，此免责申明旨在提醒您，在不确定充电桩状态地前提下，选择直接恢复急停按钮，会存在一定风险，我们将不承担任何由此产生的责任。",
    eqDetails_30030: "确定直接恢复",
    eqDetails_30031: "自检项",
    eqDetails_30032: "状态",
    eqDetails_30033: "因{msg},{code}自检取消，请检修后操作",
    eqDetails_30034: "此固定二维码已被({device}){port}绑定，是否确认解绑之前的设备，绑到当前设备？",

    EqDetails_40034: "智充物联卡",
    EqDetails_40035: "(免费期至{time}）",
    EqDetails_40036: "非智充物联卡",
    EqDetails_40037: "网络",
    // 设备储能
    EqDetails_41000: "休眠",
    EqDetails_41001: "电池柜重启",

    // #10400
    eqDetails_50000: "最大电流限制",
    eqDetails_50001: "可以按时段限制设备最大充电电流，但不能超过设备额定最大电流{0}A。",
    eqDetails_50002: "限制最大充电电流(A)",
    eqDetails_50003: "请正确填写时间段",
    eqDetails_50004: "设置有空白或交叉时段，请检查后保存",
    eqDetails_50005: "限制最大充电电流不能超过额定最大电流值",
    eqDetails_50006: "",
    // 10826
    eqDetails_51000: "7 Day Rolling Charger/Backend Connectivity Meter",
    eqDetails_51007: "Ports",
    eqDetails_51008: "Batteries",
    eqDetails_51009: "Event Time",
    eqDetails_51010: "Event",
    eqDetails_51011: "Note",
    eqDetails_51012: "From: ",
    eqDetails_51013: "To: ",
    eqDetails_51014: "Duration: ",
    eqDetails_51015: "Available",
    eqDetails_51016: "Charger available",
    eqDetails_51017: "Error",
    eqDetails_51018: "Error",
    eqDetails_51019: "Faulted",
    eqDetails_51020: "Offline",
    eqDetails_51021: "Offline",
    eqDetails_51022: "Charger offline",
    eqDetails_51023: "Order",
    eqDetails_51024: "New Session",
    eqDetails_51025: "Session ID：",
    eqDetails_51026: "Discharing",
    eqDetails_51027: "Charing",

}