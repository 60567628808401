export default { //todo.
  userAllocateDetail_00001: "Nombre del grupo",
  userAllocateDetail_00002: "Registro de asignación",
  userAllocateDetail_00003: "Información básica",
  userAllocateDetail_00004: "Detalles de asignación",
  userAllocateDetail_00005: "Descargar detalles de asignación",
  userAllocateDetail_00006: "Avatar",
  userAllocateDetail_00007: "Usuario/Número de teléfono móvil/Número de tarjeta",
  userAllocateDetail_00008: "tipo",
  userAllocateDetail_00009: "Cantidad de asignación ({0})",
  userAllocateDetail_00010: "Usuario de WeChat",
  userAllocateDetail_00011: "Tarjeta de identificación en línea",
  userAllocateDetail_00012: "Lista de usuarios",
  userAllocateDetail_00013: "",
  userAllocateDetail_00014: "",
  userAllocateDetail_00015: "",
  userAllocateDetail_00016: "",
  userAllocateDetail_00017: "",
  userAllocateDetail_00018: "",
  userAllocateDetail_00019: "",
  userAllocateDetail_00020: "",
  }