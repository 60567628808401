export default {
    billDetail_00001: "Centro de pedidos",
    billDetail_00002: "Detalles de la transacción",
    billDetail_00003: "Todos los pedidos",
    billDetail_00004: "Notificar al aparcamiento",
    billDetail_00005: "Información de la transacción",
    billDetail_00006: "Vehículo y batería",
    billDetail_00007: "Curva de carga",
    billDetail_00008: "Tarifas",
    billDetail_00009: "Seguimiento de la transacción",
    billDetail_00010: "Detalles de facturación",
    billDetail_00011: "Código de identificación del vehículo eléctrico",
    billDetail_00012: "ID de la batería",
    billDetail_00013: "Fabricación de la batería",
    billDetail_00014: "Tipo de batería",
    billDetail_00015: "Carga máxima temperatura máxima permitida de la batería",
    billDetail_00016: "Tensión máxima permitida de la batería",
    billDetail_00017: "Tensión máxima permitida de la unidad de batería individual",
    billDetail_00018: "Tensión máxima permitida de carga total de la batería",
    billDetail_00019: "Corriente máxima permitida de la batería",
    billDetail_00020: "Tensión de la batería del VE",
    billDetail_00021: "Tensión nominal de la batería completa del VE",
    billDetail_00022: "Energía nominal de la batería completa del VE",
    billDetail_00023: "Capacidad nominal de la batería completa del VE",
    billDetail_00024: "Comparación de la curva de carga",
    billDetail_00025: "Curva de carga",
    billDetail_00026: "Tensión de demanda en el extremo del VE (línea discontinua)",
    billDetail_00027: "Demanda de corriente en el extremo del EV (línea discontinua)",
    billDetail_00028: "Voltaje medido en el extremo del EV (línea discontinua)",
    billDetail_00029: "Corriente medida en el extremo del EV (línea discontinua)",
    billDetail_00030: "Carga inicial de la batería",
    billDetail_00031: "Carga actual de la batería",
    billDetail_00032: "Potencia instantánea",
    billDetail_00033: "Potencia media",
    billDetail_00034: "Potencia máxima",
    billDetail_00035: "Modelo de EV",
    billDetail_00036: "Potencia",
    billDetail_00037: "Corriente",
    billDetail_00038: "Voltaje",
    billDetail_00039: "Capacidad de la batería",
    billDetail_00040: "Rango de potencia máxima, rango de potencia {powerBegin}-{powerEnd}Kw, total {minutes} minutos (del total {timePercent}), total {powerCharged} kWh, del total de potencia {chargePercent}",
    billDetail_00041: "Electricidad acumulada",
    billDetail_00042: "kwh",
    billDetail_00043: "Crear transacción de carga",
    billDetail_00044: "Iniciar carga",
    billDetail_00045: "Iniciar facturación",
    billDetail_00046: "Detener carga",
    billDetail_00047: "Finalizar carga",
    billDetail_00048: "Iniciar carga por tiempo de posposición",
    billDetail_00049: "Completar carga",
    billDetail_00050: "Finalizar facturación",
    billDetail_00051: "Pago completado",
    billDetail_00052: "(Botón de parada de emergencia presionado, carga detenida)",
    billDetail_00053: "(Falla del equipo, carga detenida)",
    billDetail_00054: "(carga completa o se ha alcanzado la condición final establecida)",
    billDetail_00055: "(Fallo del dispositivo, fin de la carga)",
    billDetail_00056: "(Fin de carga remota manual)",
    billDetail_00057: "(Finalizar la carga mediante operación en el puesto de carga, p. ej. deslizar la tarjeta, pulsar parada de emergencia, etc.)",
    billDetail_00058: "Tarifas aplicadas en la transacción",
    billDetail_00059: "Eliminado",
    billDetail_00060: "Electricidad cargada",
    billDetail_00061: "Cargo total ({0})",
    billDetail_00062: "Tarifa de electricidad ({0})",
    billDetail_00063: "Tarifa de servicio ({0})",
    billDetail_00064: "Información del usuario",
    billDetail_00065: "ID",
    billDetail_00066: "{pay}",
    billDetail_00067: "Método de inicio",
    billDetail_00068: "Método de pago",
    billDetail_00069: "Pago por WeChat",
    billDetail_00070: "Pago del saldo por WeChat",
    billDetail_00071: "Pago del saldo de la cuenta de fondos del grupo",
    billDetail_00072: "Pago mediante una aplicación de terceros",
    billDetail_00073: "Cuenta de dinero del usuario",
    billDetail_00074: "Pago con tarjeta de valor almacenado fuera de línea",
    billDetail_00075: "Pago con tarjeta de identificación en línea",
    billDetail_00076: "WeChat público (Alipay)",
    billDetail_00077: "Método de descuento",
    billDetail_00078: "Obtenga una recompensa",
    billDetail_00079: "No se utilizó ningún cupón para este pedido",
    billDetail_00080: "No hay recompensas adicionales para este pedido",
    billDetail_00081: "Notas",
    billDetail_00082: "Complete las notas del pedido",
    billDetail_00083: "¿Está seguro de eliminar este comentario del pedido?",
    billDetail_00084: "Confirmar eliminación",
    billDetail_00085: "Recordatorio",
    billDetail_00086: "Día(s)",
    billDetail_00087: "Hora(s)",
    billDetail_00088: "Minuto(s)",
    billDetail_00089: "Monto actual del pedido",
    billDetail_00090: "Monto real del pedido",
    billDetail_00091: "Rango de potencia máxima, rango de potencia {powerBegin}-{powerEnd}Kw, total {minutes} minutos (del total {timePercent}), total {powerCharged} kWh, del total de potencia {chargePercent}",
    billDetail_00092: "Cuenta oficial de WeChat (pago sin secreto de WeChat Pay)",
    billDetail_00093: "Cuenta oficial de WeChat (Alipay)",
    billDetail_00094: "Solo contabilidad",
    billDetail_00095: "Sin descuento en el costo total de un cargo único",
    billDetail_00096: "Sin descuento en el costo total de un cargo único",
    billDetail_00097: "Descuento de {des} por cargo de servicio único",
    billDetail_00098: "Descuento de {des} por costo total de un cargo único",
    billDetail_00099: "El costo total de una carga única es completamente gratuito",
    billDetail_00100: "Tarifa de servicio de carga única gratuita",
    billDetail_00101: "Privilegios para usuarios con rango",
    billDetail_00102: "Acredita {money} € cuando pagas el regalo",
    billDetail_00103: "Acredita {money} € por el monto de asignación grupal al momento del pago",
    billDetail_00104: "Motivo por el cual se debe pagar este pedido:",
    billDetail_00105: "No se ha notificado al sistema de terceros que se ha pagado el pedido de cobro.",
    billDetail_00106: "El monto del pedido es mayor que el saldo de la cuenta del usuario en ese momento debido al cargo por pago atrasado.",
    billDetail_00107: "Dentro de las reglas del grupo que permiten un máximo de impagos.",
    billDetail_00108: "Los pedidos de cobro no se cierran con precisión de acuerdo con las condiciones de cierre (dentro de la tolerancia de fallas), lo que da como resultado pedidos ligeramente mayores que el saldo de la cuenta del usuario en ese momento",
    billDetail_00109: "El puesto de cobro está fuera de línea en el momento del cobro, y el monto del pedido retroactivo es mayor que el saldo de la cuenta del usuario en ese momento cuando se conecta.",
    billDetail_00110: "El modelo de pago para el cobro del usuario en las estaciones de cobro es un modelo de pospago.",
    billDetail_00111: "El importe del pedido es mayor que el saldo de la cuenta del usuario en ese momento por razones desconocidas.",
    billDetail_00112: "Procesamiento de pedidos pendientes de pago.",
    billDetail_00113: "1. Después de recargar la cuenta y el saldo es mayor que el monto del pedido a pagar, el sistema liquidará automáticamente el pedido.<br/>2、Se le recordará al usuario que pague antes del próximo cargo, no se podrán cobrar los pedidos no pagados.<br/>3、Si el usuario no ha pagado durante mucho tiempo, puede modificar el monto por uno menor que el saldo de la cuenta y el sistema liquidará automáticamente el pedido.",
    billDetail_00114: "Nombre del cupón",
    billDetail_00115: "Tipo de cupón",
    billDetail_00116: "Condición de uso",
    billDetail_00117: "Gestión de cupones",
    billDetail_00118: "Eliminado",
    billDetail_00119: "Detalles del cupón",
    billDetail_00120: "Método de descuento",
    billDetail_00121: "Uso de electricidad para compensar los cargos de electricidad y servicio",
    billDetail_00122: "Servicio de compensación de consumo eléctrico",
    billDetail_00123: "Servicio de compensación de consumo eléctrico y de servicios",
    billDetail_00124: "Servicio de compensación de consumo",
    billDetail_00125: "Abona {power}kwh por {money}€ de descuento en tu recarga",
    billDetail_00126: "Descuenta{money}€",
    billDetail_00127: "Boleto de electricidad",
    billDetail_00128: "Créditos",
    billDetail_00129: "Tarjeta de inicio/parada",
    billDetail_00130: "Tarjeta con valor almacenado",
    billDetail_00131: "Tarjeta de identificación",
    billDetail_00132: "Tarjeta de configuración XCharge",
    billDetail_00133: "Tarjeta de configuración de grupo",
    billDetail_00134: "Tarjetas de lectura de datos fuera de línea",
    billDetail_00135: "Pagado",
    billDetail_00136: "Conectado",
    billDetail_00137: "Cobrando",
    billDetail_00138: "Por pagar",
    billDetail_00139: "Facturación para posponer",
    billDetail_00140: "Procesando pedidos estancados",
    billDetail_00141: "Cancelación temporal de pedidos bloqueados",
    billDetail_00142: "Cerrar y generar facturación",
    billDetail_00143: "Si el usuario no puede continuar con la carga o el puerto de carga no se puede restablecer a inactivo debido a que el pedido no se facturó a tiempo, elija una de las siguientes opciones:",
    billDetail_00144: "1. Cancelación temporal: cancele el pedido temporalmente y espere a que la estación de carga informe la factura más tarde. Si el puesto de carga no se ha facturado durante mucho tiempo o se ha reiniciado, es posible que el pedido no se facture nuevamente después de la cancelación temporal y se recomienda utilizar el segundo método: cerrar y generar una factura.",
    billDetail_00145: "2. Cierre y facturación: el sistema de la plataforma operativa cierra el pedido y se genera automáticamente una factura por el cargo actual y se intenta debitar para el pago. Tenga en cuenta: una vez que se haya generado la factura, la factura real informada por el puesto de carga se enviará a la dirección de correo electrónico indicada. ignorado.",
    billDetail_00146: "Número de matrícula del usuario",
    billDetail_00147: "Mantenimiento del mensaje de notificación",
    billDetail_00148: "El número de matrícula introducido no puede estar vacío",
    billDetail_00149: "Por favor, introduzca el número de matrícula del vehículo correcto",
    billDetail_00150: "Número de pedido de terceros",
    billDetail_00151: "Pedido push retroactivo",
    billDetail_00152: "Modificar precio",
    billDetail_00153: "Precio total del pedido",
    billDetail_00154: "Por favor, introduzca el importe del pago",
    billDetail_00155: "Importe modificado",
    billDetail_00156: "Importe descontado",
    billDetail_00157: "Descuento",
    billDetail_00158: "Pago de terceros",
    billDetail_00159: "Error de inserción",
    billDetail_00160: "Inserción exitosa",
    billDetail_00165: "El monto de efectivo modificado no tiene el formato correcto",
    billDetail_00166: "Transacción de doble conector",
    billDetail_00167: "Conectar y cargar",

    // Administrador del grupo
    billDetail_00200: "Nombre del grupo",
    billDetail_00201: "Buscar pedido",
    // POS
    billDetail_00202: 'N.° de flujo de máquina POS',
    billDetail_00203: 'Tarjeta bancaria',
    billDetail_00204: 'Método de inicio',
    billDetail_00205: 'Usuario POS',
    billDetail_00206: 'Sin cargo por inactividad',
    billDetail_00211: "Cancelar el pedido",
    billDetail_00212: "Antes de cancelar el pedido, si el usuario ya ha pagado el pedido, por favor reembolse las tarifas ya pagadas al usuario. ¿Está seguro de que desea cancelar este pedido? Después de la cancelación, los datos del pedido no se incluirán en las estadísticas.",
    billDetail_00213: "Contadores al iniciar carga",
    billDetail_00214: "Contadores al terminar carga",
    billDetail_00215: "Pedido cancelado",
    bill_00071: "El importe de la carga es inferior a 1 kWh",
    bill_00072: "La duración de la carga es demasiado larga",
    bill_00073: "Mal funcionamiento del dispositivo",
    bill_00074: "Mal funcionamiento del coche",
    bill_00101: "El importe de la carga es superior a 1000 kWh",
    bill_00102: "Capacidad de carga anormal",
    billDetail_10216: "{username} ha modificado el monto del pedido {oldFeeTotal}{currencyType} a {feeTotal}{currencyType}.",
    billDetail_10217: "{username} ha procesado el pedido estancado y el método de procesamiento es Cancelación temporal.",
    billDetail_10218: "{username} ha procesado el pedido estancado y el método de procesamiento es Cierre y facturación.",
    billDetail_00217: 'ID de transacción de OCPP',
    billDetail_10221: "${0} adeudados, reducidos por exceder la preautorización o los límites de la cuenta",
    billDetail_01001: 'AuxNumber',
    billDetail_01002: 'Curva de carga e información de la batería del vehículo eléctrico',
}