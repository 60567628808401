export default {
  minProgramBanner_00001: "banner de página de inicio",
  minProgramBanner_00002: "banner de inicio habilitado",
  minProgramBanner_00003: "Vista previa",
  minProgramBanner_00004: "imagen del banner",
  minProgramBanner_00005: "saltar página",
  minProgramBanner_00006: "orden de visualización",
  minProgramBanner_00007: "seleccionar imagen predeterminada",
  minProgramBanner_00008: "amplificación",
  minProgramBanner_00009: "modificar",
  minProgramBanner_000010: "haga clic para cargar imágenes",
  minProgramBanner_000011: "ayuda de configuración",
  minProgramBanner_000012: "1. El banner puede agregar hasta 8 páginas. El tiempo de visualización de cada imagen del banner es de 3 segundos y se se mostrarán por orden según el número de secuencia;",
  minProgramBanner_000013: "2. Si configura un enlace de salto, haga clic en él para saltar a la página que configure; si no configura un enlace de salto, solo se mostrarán las imágenes",
  minProgramBanner_000014: "3. La imagen cargada solo admite imágenes en formato PNG y JPG, y el tamaño no es mayor a 5 m. El tamaño recomendado es 690 * 260 px (8:3). Si el tamaño o la escala de la imagen cargada no es consistente, se estirará para mostrarla",
  minProgramBanner_000015: "seleccione el tipo de actividad",
  minProgramBanner_000016: "pegue el enlace del artículo de su cuenta oficial, plataforma pública de WeChat: https://mp.weixin.qq.com/",
  minProgramBanner_000017: "se pueden configurar hasta 8 banners",
  minProgramBanner_000018: "establezca la imagen del banner",
  minProgramBanner_000019: "establezca el ID de la actividad",
  minProgramBanner_000020: "Cargar",
  minProgramBanner_000021: "sin salto",
  minProgramBanner_000022: "página activa",
  minProgramBanner_000023: "enlace de la cuenta oficial",
  minProgramBanner_000024: "devolución de la información almacenada valor",
  minProgramBanner_000025: "recolección gratuita",
  minProgramBanner_000026: "invitar amigos",
  minProgramBanner_000027: "comprar cupón de tarjeta",
  minProgramBanner_000028: "Establecer página de salto",
  minProgramBanner_000029: "Nombre de la actividad",
  minProgramBanner_000030: "Seleccionar actividad",
  minProgramBanner_000031: "Nuevo banner",
  
  }