export default {
  userClass_00001: "Los siguientes criterios de clasificación se pueden utilizar para filtrar por criterios individuales o combinados del usuario objetivo. ",
  userClass_00002: 'Ver ayuda',
  userClass_00003: 'Base de referencia',
  userClass_00004: 'Zona',
  userClass_00005: 'La última hora de pago de la orden de cobro es ahora',
  userClass_00006: 'Seleccione un rango de tiempo',
  userClass_00007: "Orden de cobro pagada en la fecha de hoy",
  userClass_00008: 'Orden de cobro pagada y no grupal de un solo usuario:',
  userClass_00009: 'Número acumulado de órdenes',
  userClass_00010: 'Introduzca un número natural',
  userClass_00011: 'Importe acumulado pagado (€)',
  userClass_00012: 'Cargo acumulado (kWh)',
  userClass_00013: 'Primer pedido pagado el día anterior',
  userClass_00014: 'En',
  userClass_00015: 'Hasta',
  userClass_00016: 'Generar resultados',
  userClass_00017: 'Provincia',
  userClass_00018: 'Ciudad',
  userClass_00019: 'Por favor, rellene la orden de carga completa',
  userClass_00020: 'Por favor, rellene la hora de pago del pedido completo',
  userClass_00021: 'Ayuda de categoría',
  userClass_00022: 'Los nuevos usuarios pueden definir la condición de hora de pago del primer pedido mediante la configuración',
  userClass_00023: 'Modelo RFM',
  userClass_00024: 'El modelo RFM es una herramienta importante y un medio para medir el valor de los usuarios y su capacidad para generar ganancias. Se utiliza ampliamente en muchas aplicaciones de gestión de relaciones con el usuario (CRM)',
  userClass_00025: 'Este modelo describe el valor de un solo usuario en términos de R (momento de la compra más reciente), F (frecuencia general de compra a lo largo del tiempo) y M (monto general de compra a lo largo del tiempo)',
  userClass_00026: 'El modelo RFM se puede utilizar para encontrar usuarios objetivo a través de la clasificación de usuarios. Modelo correspondiente',
  userClass_00027: 'R (momento del último pago de la orden de cobro)',
  userClass_00028: 'F (número acumulado de órdenes de cobro pagadas por un solo usuario que no pertenece a un grupo durante un período de tiempo)',
  userClass_00029: 'M (monto acumulado pagado de órdenes de cobro pagadas por un solo usuario, no por un grupo, durante un período de tiempo)',
  userClass_00030: 'Alto',
  userClass_00031: 'Bajo',
  userClass_00032: 'Valor alto',
  userClass_00033: 'Es necesario iniciar el contacto',
  userClass_00034: 'Es necesario centrarse en el desarrollo',
  userClass_00035: 'Es necesario centrarse en la retención',
  userClass_00036: 'Datos de referencia',
  userClass_00037: 'Órdenes de cobro pagadas y no pertenecientes a un grupo de un solo usuario',
  userClass_00038: 'Importe acumulado pagado',
  userClass_00039: 'Importe acumulado de cobro',
  userClass_00040: 'Resultado de la categoría',
  userClass_00041: 'Número actual de usuarios elegibles',
  userClass_00042: 'Ver usuarios',
  userClass_00043: 'Número total de usuarios en la región',
  userClass_00044: 'Ingrese el nombre de la categoría',
  userClass_00045: 'Guardar como categoría',
  userClass_00046: 'Después de guardar como categoría, el sistema actualizará automáticamente la lista de usuarios de la categoría en tiempo real de acuerdo con las condiciones de la categoría',
  userClass_00047: 'Ingrese el nombre de la categoría del usuario',
  userClass_00048: 'Seleccione',
  userClass_00049: 'Todos',
  }