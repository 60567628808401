export default {
  eqDetails_00001: 'Push-Konfiguration',
  eqDetails_00002: 'Gerätseinzelheiten',
  eqDetails_00003: 'Gerätsinformation',
  eqDetails_00004: 'Gerätskonfiguration',
  eqDetails_00005: 'Ladepunktsmanagement',
  eqDetails_00006: 'Gerätswartung',
  eqDetails_00007: 'Neustart des Geräts',
  eqDetails_00008: 'Der Befehl zum Neustart des Geräts wurde gesendet, der Neustartvorgang wird etwa 5 Minuten dauern. Bitte bleiben Sie auf dieser Seite und aktualisieren Sie sie nicht.',
  eqDetails_00009: 'OK',
  eqDetails_00010: 'Ladevorgang starten',
  eqDetails_00011: 'Gerät erfolgreich neugestartet',
  eqDetails_00012: 'Ferngesteuerter Neustart',
  eqDetails_00013: 'Neustart fehlgeschlagen',
  eqDetails_00014: 'Gerätename',
  eqDetails_00015: 'zugehörende Ladestation',
  eqDetails_00016: 'anzeigen',
  eqDetails_00017: 'Gerätsseriennummer',
  eqDetails_00018: 'Gerätehersteller',
  eqDetails_00019: 'Gerätemodell',
  eqDetails_00020: 'Bitte geben Sie den Gerätenamen ein',
  eqDetails_00021: 'Nach Geräteaktivierung, elektromagnetische Verriegelung und so weiter übertragen die Konfigurationen automatisch an das Gerät; Die Seite werden aktualisiert nach der Antwort des Gerätes.',
  eqDetails_00022: 'Push-Konfiguration',
  eqDetails_00023: 'Zum Gerät pushen',
  eqDetails_00024: 'Gerät aktiviert',
  eqDetails_00025: 'Elektromagnetisches Schloss',
  eqDetails_00026: 'Laden ist möglich mit nicht verriegelte Magnetschloss',
  eqDetails_00027: 'Parken-Radar',
  eqDetails_00028: 'Ladestation verfügbar',
  eqDetails_00029: 'Startzeit',
  eqDetails_00030: 'Endzeit',
  eqDetails_00031: 'Maximaler Ladestrom (A)',
  eqDetails_00032: 'Max. Ladeleistung (kW)',
  eqDetails_00033: 'Push fehlgeschlagen',
  eqDetails_00034: 'Gerät ist offline',
  eqDetails_00035: 'Warten auf Geräteantwort',
  eqDetails_00036: 'Push war erfolgreich',
  eqDetails_00037: 'Datum falsch eingestellt',
  eqDetails_00038: 'Wartungsanforderung der Leistungsmodule Hinweise: kumulierte Ladestunden seit der Rückstellzeit',
  eqDetails_00039: '{hours}Stunde',
  eqDetails_00040: 'Uhrzeit zurücksetzen',
  eqDetails_00041: 'Normal',
  eqDetails_00042: 'Störung',
  eqDetails_00043: 'Bestätigen Sie, dass Sie diese Wartung für dieses Leistungsmodul abgeschlossen haben? Nachdem Sie auf OK geklickt haben, startet das neue Zeitrechnen der Wartungsanforderung.',
  eqDetails_00044: 'Hinweise',
  eqDetails_00045: 'Ladepunkt ID',
  eqDetails_00046: 'QR code ',
  eqDetails_00047: 'Dynamischer QR code',
  eqDetails_00048: 'Fester QR-Code',
  eqDetails_00049: 'Upgrade auf festen QR-Code',
  eqDetails_00050: 'Benutzerdefinierter QR-Code',
  eqDetails_00051: 'Fernsteuerung',
  eqDetails_00052: 'Neustart',
  eqDetails_00053: 'Ladevorgang beenden',
  eqDetails_00054: 'Auf verfügbar einstellen',
  eqDetails_00055: 'Status des Ladepunkts',
  eqDetails_00056: 'Parkplatzmanagementgerät',
  eqDetails_00057: 'Kein',
  eqDetails_00058: 'Ladesäulegesteuerte Parkplatzsperre',
  eqDetails_00059: 'Cloudgesteuerte Parkplatzsperre',
  eqDetails_00060: 'Informationen des Parkplatzmanagementgeräts',
  eqDetails_00061: 'Bitte Gerätecode eingeben',
  eqDetails_00062: 'Parkplatzmanagementgerät aktivieren',
  eqDetails_00063: 'Parkplatzmanagementgerät fernsteuern',
  eqDetails_00064: 'Aufsteigen',
  eqDetails_00065: 'Abwärt',
  eqDetails_00066: 'Aktueller Parkplatzbelegungsabrechnungsnutzer',
  eqDetails_00067: 'Belegungsbeginnszeit',
  eqDetails_00068: 'Benutzerdetails',
  eqDetails_00069: 'Manuelles Ende der Abrechnung dieser Belegungsgebühr',
  eqDetails_00070: 'Parkplatzmanagementgerät sendet QR-Code',
  eqDetails_00071: 'Festen QR-Code generieren',
  eqDetails_00072: 'In Belegung',
  eqDetails_00073: 'Nicht aktiviert',
  eqDetails_00074: 'Offline',
  eqDetails_00075: 'Störung',
  eqDetails_00076: 'In Reservierung',
  eqDetails_00077: 'verfügbar',
  eqDetails_00078: 'gesteckt',
  eqDetails_00079: 'Im Laden',
  eqDetails_00080: 'zu bewegende',
  eqDetails_00081: 'zu ziehende',
  eqDetails_00082: 'Ladepunkte',
  eqDetails_00083: 'Laden starten',
  eqDetails_00084: 'Bitte speichern Sie den vorherigen Vorgang',
  eqDetails_00085: 'Der aktuelle Platz hat Nutzer, denen die Platzbelegung in Rechnung gestellt wird, bitte später ändern!',
  eqDetails_00086: 'Der Parameter ist leer',
  eqDetails_00087: 'Maximal 20 Zeichen',
  eqDetails_00088: 'Der Befehl zum Starten des Ladevorgangs wurde gesendet',
  eqDetails_00089: 'Der Befehl zum Enden des Ladevorgangs wurde gesendet',
  eqDetails_00090: 'Erfolgreich gesendet',
  eqDetails_00091: 'Upgrade auf festen QR-Code',
  eqDetails_00092: 'QR-Code erfolgreich generiert',
  eqDetails_00093: 'Aktiviert',
  eqDetails_00094: 'Nicht aktiviert',
  eqDetails_00095: 'Cloud-gesteuerte Parkplatzsperre',
  eqDetails_00096: 'Ladesäulegesteuerte Parkplatzsperre',
  eqDetails_00097: 'Bitte geben Sie den Inhalt des QR-Codes ein, der von Ihnen konfigurierte QR-Code wird auf dem Stapel angezeigt',
  eqDetails_00098: 'Hinweis: Es können nur feste QR-Codes erzeugt werden, die nicht in ursprünglichen Zustand zurückversetzt werden können',
  eqDetails_00099: 'Der benutzerdefinierte QR-Code kann nicht leer sein',
  eqDetails_00100: 'Benutzerdefinierter QR-Code erfolgreich',
  eqDetails_00101: 'Einstellung fehlgeschlagen',
  eqDetails_00102: 'Die Länge kann {0} Zeichen nicht überschreiten',
  eqDetails_00103: 'Aktuelle Ladedauer (Minuten)',
  eqDetails_00104: 'Aktuelle vorbelastete Belegungsgebühr ({0})',
  eqDetails_00105: 'Geänderte Belegungsgebühr ({0})',
  eqDetails_00106: 'Bitte geben Sie den Betrag ein',
  eqDetails_00107: 'Bitte geben Sie die geänderte Belegungsgebühr ein',
  eqDetails_00108: 'Bitte geben Sie die richtige Zahl ein',
  eqDetails_00109: 'Manuelles Ende der Abrechnung für diese Belegungsgebühr war erfolgreich',
  eqDetails_00110: 'Die maximale Anzahl fester QR-Codes beträgt 100.000 und ist nicht abrufbar, bitte gehen Sie mit Vorsicht vor, sind Sie sicher, dass Sie aktualisieren wollen?',
  eqDetails_00111: 'Upgrade erfolgreich',
  eqDetails_00112: 'Erledigt',
  eqDetails_00113: 'Haben Sie einen festen QR-Code für die Übergabe der Parkplatzmanagementgerät erstellt?',
  eqDetails_00114: 'Zur Zeit kein QR-Code',
  eqDetails_00115: 'Wenn das Gerät offline ist, werden nur Benutzer in der Whitelist unterstützt, um den Ladevorgang zu starten',
  eqDetails_00116: 'Whitelist löschen',
  eqDetails_00117: 'Push-Whitelist',
  eqDetails_00118: 'Identifikation der geräte',
  eqDetails_10000: 'Sind Sie sicher, dass Sie diese Wartung für alle {0} Ladepunkte abgeschlossen haben? Nachdem Sie auf „OK“ geklickt haben, werden alle {0} Erinnerungen für erforderliche Dienste neu terminiert. ',
  eqDetails_10001: 'Alle Ventile ändern',
  eqDetails_10002: 'Stunde',
  eqDetails_10003: 'Zeit',
  eqDetails_10004: 'Tipps',
  eqDetails_10005: 'Auf alle anwenden',
  eqDetails_10006: 'Alle zurücksetzen',
  eqDetails_10007: 'Beleuchtungszeit',
  eqDetails_10008: 'Verwendet',
  eqDetails_10009: 'Aufforderungsschwelle für Wartungszeit:',
  eqDetails_10010: 'Die kumulierte Nutzungszeit seit dem Zurücksetzen ist größer oder gleich',
  eqDetails_10011: 'Bitte ausfüllen',
  eqDetails_10012: 'Leistungsmodul',
  eqDetails_10013: 'Speicherbatterie',
  eqDetails_10014: 'Lüfter',
  eqDetails_10015: 'Ladepistole',
  eqDetails_10016: 'Schütz',
  eqDetails_10017: 'Drahtzieher',
  eqDetails_10018: 'Überspannungsschutz',
  eqDetails_10019: 'LCD-Bildschirm',
  eqDetails_10020: 'Blutwiderstand',
  eqDetails_10021: 'Erzwungene Aufladung',
  eqDetails_10022: 'Erzwungene Entladung',
  eqDetails_10023: 'Verboten',
  eqDetails_10024: 'Zurücksetzen',
  eqDetails_10025: 'Informationen zum Ladegerät',
  eqDetails_10026: 'Ladegeräteinstellungen',
  eqDetails_10027: 'Einstellungsbeschreibung',
  eqDetails_10028: 'Ladesteckereinstellungen',
  eqDetails_10029: 'Anschlussschalter',
  eqDetails_10030: 'Verbindungsname',
  eqDetails_10031: 'Batterielademodus',
  eqDetails_10032: 'Betriebsmodus der Klimaanlage',
  eqDetails_10033: 'Einstellung des Betriebsmodus',
  eqDetails_10034: 'Einstellung Energiespeicherbatterieschrank',
  eqDetails_10035: 'Batterieschrank',
  eqDetails_10036: 'Name des Batterieschranks',
  eqDetails_10037: 'Batterie Kapazität',
  eqDetails_10038: 'durchlaufene Zyklen-Anzahl',
  eqDetails_10039: 'Batterieschrank SOC',
  eqDetails_10040: 'Batterieschrank SOH',
  eqDetails_10041: 'Neustart',
  eqDetails_10042: 'Push-Whitelist',
  eqDetails_10043: 'Fehlerprotokoll des Ladegeräts',
  eqDetails_10044: 'Einstellungsbeschreibung',
  eqDetails_10045: 'Ladeschalter, elektromagnetische Sperre und andere Einstellungen werden die Einstellung nach der Änderung automatisch an das Ladegerät übertragen, Sie müssen auf die Antwort des Ladegeräts warten, bevor Sie die Seite aktualisieren',
  eqDetails_10046: 'Wenn das Ladegerät offline ist, können nur Benutzer in der Whitelist mit dem Laden beginnen',
  eqDetails_10047: 'Mehr',
  eqDetails_10048: 'Batterie Aufladen Stoppunkt (SOC)',
  eqDetails_10049: 'Batterie Entladen Stoppunkt (SOC)',
  eqDetails_10050: 'Batterie Aufladen Leistungsgrenze(kW)',
  eqDetails_10051: 'Batterie Entladen Leistungsgrenze(kW)',
  eqDetails_10052: 'Grid import power limit (kW)',
  eqDetails_10053: 'Möchten Sie das Gerät wirklich neu starten?',
  eqDetails_10054: 'Geltungsbereich',
  eqDetails_10055: "Störung",
  eqDetails_10056: "Nicht begrenzen {0}%",
  eqDetails_10057: "Batterieschrank neu starten",
  eqDetails_10058: "Grenzwert {0}%",
  eqDetails_10059: "Der Befehl wurde erfolgreich gesendet, das Gerät wird nach dem Aufladen ausgeführt, bitte aktualisieren Sie die Seite später, um das Ausführungsergebnis anzuzeigen",
  eqDetails_10060: "Aufgeladen",
  eqDetails_10061: "Der Push war erfolgreich, ich warte auf die Antwort des Geräts",
  eqDetails_10062: "Der Betriebsstatus des Batterieschranks wurde erfolgreich umgeschaltet",
  eqDetails_10063: "Umschalten des Betriebszustands des Batterieschranks fehlgeschlagen",
  eqDetails_10064: "Batterieschrank Betriebszustand Umschaltung Überstunden",
  eqDetails_10065: "Die Waffe lädt, kein erneutes Aufladen erforderlich",
  eqDetails_10066: "Bitte deaktivieren Sie zuerst das Parkraumverwaltungsgerät",
  eqDetails_10067: "Auf Leerlauf setzen",
  eqDetails_10068: "Diese Funktion wird nur für die Notfallverarbeitung verwendet, wenn die Plattform- und Mündungsinformationen nicht synchron sind. Möchten Sie sie wirklich auf Leerlauf stellen?",

  eqDetails_10069: "Unbegrenzt, die Nenn-maximale Ladeleistung beträgt {0} kW",
  eqDetails_10070: "Keine Begrenzung, Nennstrom ist {0} A",
  eqDetails_10071: "Charged duration",
  eqDetails_10072: "Full expected",

  detailDepot_00182: "Site Support White List",
  detailDepot_00545: "Bitte speichern Sie den vorherigen Vorgang!",
  detailDepot_00112: "Kann aufgeladen werden",
  detailDepot_00106: "Laden ist nicht erlaubt",
  // #7662新增
  detailDepot_20000: "Dies ist ein gebot.",
  detailDepot_20001: "Teil des betriebssystems",
  detailDepot_20002: "Und jetzt gibt es die Android version",
  detailDepot_20003: "DCB entlassen",
  detailDepot_20004: "Firmware aktualisiert.",
  detailDepot_20005: "Das magnetschloss wird verriegelt",
  detailDepot_20006: "Das elektromagnetische schloss ist unverschlossen",
  detailDepot_20007: "Löschen",
  detailDepot_20008: "Das gerät wird sicher gelöscht?",
  detailDepot_20009: "Operation beseitigen",
  detailDepot_20010: "Bitte schalten sie den schalter in der einrichtung ein und löschen sie das gerät",
  detailDepot_00107: "Erzwungene Netzanbindung",
  detailDepot_00108: 'Grenzleistung des Netzanschlusses (kW)',
  detailDepot_00109: "Push to device",
  detailDepot_00110: 'Der Befehl zum Neustart des Geräts wurde gesendet, und der Neustart dauert ungefähr fünf Minuten',
  detailDepot_00111: 'Dual Platform',
  detailDepot_10112: "Refers to the maximum operating power of the ACDC module, which cannot be exceeded whether it is used to charge the NZS battery or the vehicle.",


  //维保类型（德语）
  eqDetails_21000: "DC-Meter",
  eqDetails_21001: "AC-Meter",
  eqDetails_21002: "Relais schalten",
  eqDetails_21003: "Zugangskontrollsensor",
  eqDetails_21004: "Tip-Sensor",
  eqDetails_21005: "Rauchsensor",
  eqDetails_21006: "Flüssigkeitsstandsensor",
  eqDetails_21007: "Flüssigkeitskühlpumpe",
  eqDetails_21008: "Lüfter der Flüssigkeitskühlungspumpe",
  eqDetails_21009: "Lichtleiste",
  eqDetails_21010: "Filter",
  eqDetails_21011: "Zustand der Energiespeicherbatterie",
  eqDetails_21012: "AC-Schütz",
  eqDetails_21013: "Lanzenspitzenrelais (DC-)",
  eqDetails_21014: "Hersteller",
  eqDetails_21015: "UUGreenPower",
  eqDetails_21016: "INFY Power",
  eqDetails_21017: "Megmeet",
  eqDetails_21018: "Lanzenspitzenrelais (DC+)",
  eqDetails_21019: "Batterieschrank hin",
  eqDetails_21020: "Das kippkorrigieren",
  eqDetails_21021: "SOH Is lower than",
  eqDetails_21022: "Current",

  //自检
  eqDetails_30001: "Charger recovery emergency stop state",
  eqDetails_30002: "Whether to perform automatic self-inspection after that",
  eqDetails_30003: "Hardware self-inspection",
  eqDetails_30004: "1. Check the status of the device hardware, which takes tens of seconds",
  eqDetails_30005: "2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.",
  eqDetails_30006: "3. This check is not supported by third-party devices.",
  eqDetails_30007: "Last inspection result",
  eqDetails_30008: "Start inspection",
  eqDetails_30009: "Please wait during self-inspection",
  eqDetails_30010: "Re-inspection",
  eqDetails_30011: "Inspect time",
  eqDetails_30012: "Status inspection",
  eqDetails_30013: "Hardware lifetime",
  eqDetails_30014: "Self-inspection items",
  eqDetails_30015: "Confirm and recover from emergency stop",
  eqDetails_30016: "The charger is in use, self-inspection is canceled, please operate after it is idle",
  eqDetails_30017: "The current charger is offline",
  eqDetails_30018: "Charger self-inspection timed out, please try again",
  eqDetails_30020: "Poorer",
  eqDetails_30021: "moderate",
  eqDetails_30022: "better",
  eqDetails_30023: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
  eqDetails_30024: "Last self-inspection result not found",
  eqDetails_30025: "Recovery immediately",
  eqDetails_30026: "Check the charger first, then consider recovery",
  eqDetails_30027: "Disclaimer",
  eqDetails_30028: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
  eqDetails_30029: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
  eqDetails_30030: "Confirm direct recovery",
  eqDetails_30031: "Self-check items",
  eqDetails_30032: "State",
  eqDetails_30033: "{msg},{code},self-inspection is canceled, Please operate after maintenance",
  // 设备储能
  EqDetails_41000: "Sleep",
  EqDetails_41001: "Battery cabinet reset",
  // #10400
  eqDetails_50000: "Maximum Current Limit",  
  eqDetails_50001: "The maximum charging current of the equipment can be limited by time periods, but cannot exceed the rated maximum current of {0}A.",  
  eqDetails_50002: "Limit Maximum Charging Current (A)",  
  eqDetails_50003: "Please fill in the time period correctly",  
  eqDetails_50004: "There are blank or overlapping time periods in the settings, please check and save after modification",  
  eqDetails_50005: "The limited maximum charging current cannot exceed the rated maximum current value",

  // 10826
  eqDetails_51000: "7 Day Rolling Charger/Backend Connectivity Meter",
  eqDetails_51007: "Ports",
  eqDetails_51008: "Batteries",
  eqDetails_51009: "Event Time",
  eqDetails_51010: "Event",
  eqDetails_51011: "Note",
  eqDetails_51012: "From: ",
  eqDetails_51013: "To: ",
  eqDetails_51014: "Duration: ",
  eqDetails_51015: "Verfügbar",
  eqDetails_51016: "Ladegerät verfügbar",
  eqDetails_51017: "Fehler",
  eqDetails_51018: "Fehler",
  eqDetails_51019: "Fehlerhaft",
  eqDetails_51020: "Offline",
  eqDetails_51021: "Offline",
  eqDetails_51022: "Ladegerät offline",
  eqDetails_51023: "Bestellung",
  eqDetails_51024: "Neue Sitzung",
  eqDetails_51025: "Sitzungs-ID:",
  eqDetails_51026: "Entladen",
  eqDetails_51027: "Laden",
}
