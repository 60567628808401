var triggerList = [
  {
    value: 'tx',
    label: '充电桩内部通信异常',
    children: [
      {
        value: '11',
        label: '电源模块CAN通信失败[A0104]',
        code:"A0104",
      },
      {
        value: '12',
        label: '充电口绝缘通信模块报警[A0105]',
        code: "A0105",
      },
      {
        value: '13',
        label: '自检超时[A0106]',
        code:"A0106"
      },
      {
        value: '5',
        label: 'DCB收到TCU异常[A010C]',
        code:"A010C",
      },
      {
        value: '6',
        label: '等待TCU VIN验证结果下发超时[A010E]',
        code: "A010E",
      }, 
      {
        value: '6',
        label: '车辆鉴权不通过（VIN验证）[A010F]',
        code: "A010F",
      }, 
  ]
  }, 
  {
    value: 'jycw',
    label: '绝缘异常',
    children: [{
      value: '44',
      label: '绝缘故障[A0202]',
      code:'A0202'
    }]
  },
  {
    value: 'dy',
    label: '电源模块异常',
    children: [
      {
      value: '14',
      label: '电源模块报警[A0301]',
      code: "A0301",
     },
     {
      value: '14',
      label: 'C6桩电源模块地址冲突/C2充电桩未初始化[A0302]',
      code: "A0302",
     },
  ]
  },
  {
    value: 'cgq',
    label: '传感器异常',
    children: [
      {
        value: '18',
        label: '充电口接触器故障[A0406]',
        code:"A0406"
      },
      {
        value: '22',
        label: '液冷故障[A0417]',
        code: "A0417"
      },
      {
        value: '23',
        label: '主机故障[A0418]',
        code:"A0418"
      },
    ]
  },
  {
    value: 'cdq',
    label: '充电枪异常',
    children: [{
      value: '45',
      label: '充电枪口温度报警[A0501]',
      code:'A0501'
    },
    {
      value: '45',
      label: '电磁锁上锁操作失败[A0502]',
      code:'A0502'
    },
    {
      value: '45',
      label: '电磁锁解锁操作失败[A0503]',
      code:'A0503'
    },
    {
      value:'46',
      label:'充电枪口发生异常，即充电过程中CC电压改变（非4v）[A0504]',
      code:'A0504'
      }
  ]
  },
  {
    value: 'zl',
    label: '直流输出异常',
    children: [
      {
        value: '33',
        label: '电压异常，过压/欠压/短路/过载[A0801/A0802/A0803/A0804]',
        code:"A0801/A0802/A0803/A0804"
      },
      {
        value: '34',
        label: '直流检测失败，空闲时枪头电压不为0/泄放结束后电压高于60V(GBT)[A0806]',
        code:"A0806"
      },
      {
        value: '35',
        label: '泄放检测失败[A0807]',
        code:"A0807"
      },
      {
        value: '36',
        label: '并联接触器拒动/粘连故障[A0808/A0809]',
        code: "A0808/A0809"
      },
      {
        value: '37',
        label: '充电主机预充电失败故障[A080B]',
        code:"A080B"
      },
      {
        value: '37',
        label: '充电主机响应充电失败故障[A080C]',
        code:"A080C"
      },
      {
        value: '38',
        label: '直流电表读数异常[A080F]',
        code:"A080F"
      },
      {
        value: '39',
        label: '枪头直流输出接触器拒动/粘连故障[A0810/A0811]',
        code:"A0810/A0811"
      },
      {
        value: '38',
        label: '预充超时[A0812]',
        code:"A0812"
      }, 
  ]
  },
  {
    value: 'fzdy',
    label: '辅助电源异常',
    children: [{
      value: '41',
      label: '辅助电源没有上电[A0901]',
      code:"A0901"
    }]
  },
  {
    value: 'txbw',
    label: 'BMS通信报文异常',
    children: [
     {
      value: '42',
      label: 'CRM/CRM00/CRMaa充电机辨识报文超时停止[A0A01/A0A02/A0A03]',
      code:"A0A01/A0A02/A0A03"
     },
     {
      value: '42',
      label: 'CRO充电机输出准备就绪状态报文超时停止[A0A04]',
      code:"A0A04"
     },
     {
      value: '42',
      label: 'CTS充电机发送时间同步信息报文超时停止[A0A05]',
      code:"A0A05"
     },
     {
      value: '42',
      label: 'CML充电机最大输出能力报文超时停止[A0A06]',
      code:"A0A06"
     },
     {
      value: '42',
      label: 'CCS充电机充电状态报文超时停止[A0A07]',
      code:"A0A07"
     },
     {
      value: '42',
      label: 'CST充电机中止充电报文超时停止[A0A08]',
      code:"A0A08"
     },
     {
      value: '42',
      label: 'CSD充电机统计数据报文超时停止[A0A09]',
      code:"A0A09"
     },
     {
      value: '42',
      label: '其它超时故障，BEM中定义[A0A0A]',
      code:"A0A0A"
     },
  ]
  },
  {
    value: 'fzdy',
    label: '充电状态异常',
    children: [{
      value: '43',
      label: '充电桩输出电流大于6A，直流电表/交流电表读数无变化，持续180s[A0B01/A0B02]',
      code: 'A0B01/A0B02'
    }]
  },
  {
    value: 'cdzj',
    label: '充电主机状态异常（分机上报）',
    children: [
      {
        value: '18',
        label: '充电主机急停[A0C01]',
        code:"A0C01"
      },
      {
        value: '22',
        label: '充电主机直流母线熔断器[A0C02]',
        code: "A0C02"
      },
      {
        value: '23',
        label: '充电主机门禁故障[A0C03]',
        code:"A0C03"
      },
      {
        value: '23',
        label: '充电主机烟感故障[A0C04]',
        code:"A0C04"
      },
      {
        value: '23',
        label: '充电主机交流输入断入器故障[A0C05]',
        code:"A0C05"
      },
      {
        value: '23',
        label: '充电主机交流输入接触器据动/误动/粘连故障[A0C06/A0C07]',
        code:"A0C06/A0C07"
      },
      {
        value: '23',
        label: '充电主机风扇故障[A0C08]',
        code:"A0C08"
      },
      {
        value: '23',
        label: '充电主机并联接触器据动/误动/粘连故障[A0C09/A0C0A]',
        code:"A0C09/A0C0A"
      },
      {
        value: '23',
        label: '充电主机液冷模块故障[A0C0B]',
        code:"A0C0B"
      },
      {
        value: '23',
        label: '充电主机液冷模块通信故障[A0C0C]',
        code:"A0C0C"
      },
      {
        value: '23',
        label: '充电主机避雷器故障[A0C0D]',
        code:"A0C0D"
      },
      {
        value: '23',
        label: '充电主机交流电度表通信故障[A0C0E]',
        code:"A0C0E"
      },
      {
        value: '23',
        label: '充电主机过温故障/告警[A0C0F/A0C10]',
        code:"A0C0F/A0C10"
      },
      {
        value: '23',
        label: '充电主机输入电压过压/欠压故障[A0C11/A0C12]',
        code:"A0C11/A0C12"
      },
      {
        value: '23',
        label: '充电主机水位监测异常故障[A0C13]',
        code:"A0C13"
      },
      {
        value: '23',
        label: '充电主机充电模块故障/通信故障（全部）[A0C14/A0C15]',
        code:"A0C14/A0C15"
      },
      {
        value: '23',
        label: '充电主机充电模块风扇故障[A0C16]',
        code:"A0C16"
      },
      {
        value: '23',
        label: '充电主机充电模块过温故障[A0C17]',
        code:"A0C17"
      },
      {
        value: '23',
        label: '充电主机充电模块交流输入故障[A0C18]',
        code:"A0C18"
      },
      {
        value: '23',
        label: '充电主机充电模块输出短路/过流/过压/欠压故障[A0C19/A0C1A/A0C1B/A0C1C]',
        code:"A0C19/A0C1A/A0C1B/A0C1C"
      },
      {
        value: '23',
        label: '充电主机充电模块输入过压/欠压/缺相故障[A0C1D/A0C1E/A0C1F]',
        code:"A0C1D/A0C1E/A0C1F"
      },
      {
        value: '23',
        label: '充电主机充电模块故障/通信故障（局部）[A0C20/A0C21]',
        code:"A0C20/A0C21"
      },
      {
        value: '23',
        label: '充电主机粘连检测应答超时[A0C22]',
        code:"A0C22"
      },
      {
        value: '23',
        label: '充电主机充电停止完成应答超时[A0C23]',
        code:"A0C23"
      },
      {
        value: '23',
        label: '充电主机绝缘检测帧接收/完成应答超时[A0C24/A0C25]',
        code:"A0C24/A0C25"
      },
      {
        value: '23',
        label: '充电主机预充电帧接收/完成应答超时[A0C26/A0C27]',
        code:"A0C26/A0C27"
      },
      {
        value: '23',
        label: '充电主机接收充电需求数据帧超时[A0C28]',
        code:"A0C28"
      },
      {
        value: '23',
        label: '充电主机接收远程升级固件文件数据帧超时[A0C29]',
        code:"A0C29"
      },
      {
        value: '23',
        label: '充电主机充电终端遥信帧接收超时[A0C2A]',
        code:"A0C2A"
      },
      {
        value: '23',
        label: '充电主机充电启动/充电停止/对时操作/版本校验应答超时[A0C60/A0C61/A0C62/A0C63]',
        code:"A0C60/A0C61/A0C62/A0C63"
      },
      {
        value: '23',
        label: '充电主机充电参数/预充电/绝缘检测应答超时[A0C64/A0C65/A0C66]',
        code:"A0C64/A0C65/A0C66"
      },
      {
        value: '23',
        label: '充电主机远程升级/调测数据/调控请求应答超时[A0C67/A0C68/A0C69]',
        code:"A0C67/A0C68/A0C69"
      },
      {
        value: '23',
        label: '充电主机远程升级固件文件传输完成帧/升级完成帧接收超时[A0C6A/A0C6B]',
        code:"A0C6A/A0C6B"
      },
      {
        value: '23',
        label: '充电主机充电主机遥信帧/遥测帧接收超时[A0C6C/A0C6D]',
        code:"A0C6C/A0C6D"
      },
      {
        value: '23',
        label: '充电主机粘连检测帧/绝缘检测完成帧接收超时[A0C6E/A0C6F]',
        code:"A0C6E/A0C6F"
      },
      {
        value: '23',
        label: '充电主机预充电完成帧/充电停止完成帧接收超时[A0C70/A0C71]',
        code:"A0C70/A0C71"
      },
      {
        value: '23',
        label: '充电主机和分通信初始化未完成[A0C72]',
        code:"A0C72"
      },
      {
        value: '23',
        label: '充电主机心跳接收状态异常[A0C73]',
        code:"A0C73"
      },
    ]
  },
];

module.exports = triggerList;
