export default {
charging_package45:"Utilice efectivo para compensar los cargos de electricidad y servicio",
charging_package46:"Créditos de electricidad para los cargos de electricidad y servicio",
charging_package47:"Compensación en efectivo de los cargos por servicio",
charging_package48:"Compensación del monto de la electricidad por el cargo por servicio",
charging_package52:" {día} a partir de la fecha de compra/canje del paquete por parte del usuario",

cash_coupon0001: 'Centro de marketing',
cash_coupon0002: 'Herramientas preferenciales',
cash_coupon0003: 'Nombre',
cash_coupon0004: 'Por favor ingrese',
cash_coupon0005: 'Restablecer',
cash_coupon0006: 'Buscar',
cash_coupon0007: 'Lista',
cash_coupon0008: 'Crear',
cash_coupon0009: 'Tipo',
cash_coupon0010: 'Importe',
cash_coupon0011: 'kwh',
cash_coupon0012: 'euro',
cash_coupon0013: 'Período de validez',
cash_coupon0014: 'Operar',
cash_coupon0015: 'Detalle',
cash_coupon0016: 'Billetes de luz',
cash_coupon0017: 'Créditos',
}