export default {
  whiteList_00001: 'Lista blanca',
  whiteList_00002: 'Gestión de estaciones',
  whiteList_00003: 'Gestión de cargadores',
  whiteList_00004: 'Configuración de lista blanca',
  whiteList_00005: 'Número de tarjeta/Nombre/Número de móvil',
  whiteList_00006: 'Todos',
  whiteList_00007: 'Tarjeta de identificación en línea',
  whiteList_00008: 'Tarjeta enchufable y de carga',
  whiteList_00009: 'Restablecer',
  whiteList_00010: 'Buscar',
  whiteList_00011: 'Nombre',
  whiteList_00012: 'Número de móvil',
  whiteList_00013: 'Número',
  whiteList_00014: 'Número de tarjeta',
  whiteList_00015: 'Tipo de tarjeta',
  whiteList_00016: 'Origen del permiso',
  whiteList_00017: 'Operación',
  whiteList_00018: 'Ver detalles',
  whiteList_00019: 'Inicio/detención de la tarjeta sin conexión',
  whiteList_00020: 'Tarjeta de valor almacenado sin conexión',
  whiteList_00021: 'Conectar y cargar',
  }