export default {
    auto_00001: "Escanee el código QR de la izquierda con WeChat para seguir la cuenta oficial de 'xcharger' y recibir notificaciones personalizadas.",
    auto_00002: "1. En el menú inferior de la cuenta oficial, haga clic en 'Mantenimiento de carga' - 'Orden de mantenimiento' para ingresar al miniprograma 'Mantenimiento inteligente'.",
    auto_00003: "2. Inicie sesión en el miniprograma 'Mantenimiento inteligente' con su cuenta de la plataforma.",
    auto_00004: "3. Después de completar los pasos anteriores, recibirás notificaciones de mantenimiento a través de la cuenta oficial 'xcharger'. Todas las notificaciones se envían mediante la función de mensaje de plantilla de la cuenta oficial.",
    auto_00005: "Cargador defectuoso (este error provocará la desactivación de la función de carga)",
    auto_00006: "Equipo anormal (este error no provocará la desactivación de la función de carga)",
    auto_00007: "Cargador fuera de línea",
    auto_00008: "Por ejemplo: botón de parada de emergencia presionado, alarma de temperatura del chasis, etc.",
    auto_00009: "Por ejemplo: falla de bloqueo de la cerradura electromagnética, alarma del módulo de potencia, etc.",
    auto_00010: "Categoría de notificación",
    auto_00011: "Grupo de destino de la notificación",
    auto_00012: "Operación",
    auto_00013: "Editar",
    auto_00014: "Todos",
    auto_00015: "Notificar automáticamente",
    auto_00016: "Eliminar",
    auto_00017: "Rol",
    auto_00018: "Cuenta",
    auto_00019: "Operación",
    auto_00020: "Establecer frecuencia",
    auto_00021: "Cada",
    auto_00022: "Día",
    auto_00023: "Cada cargador notificará solo cada falla",
    auto_00024: "Cada cargador notificará solo todas las fallas",
    auto_00025: "veces",
    auto_00026: "Predeterminado",
    auto_00027: "Cada cargador recibirá notificaciones solo de fallas fuera de línea",
    auto_00031: "Después de seguir los pasos, siga los pasos a continuación:",
}