export default {
  vehicleChargingLog_00001: "por favor ingrese el número de placa",
  vehicleChargingLog_00002: "fecha de inicio",
  vehicleChargingLog_00003: "fecha de finalización",
  vehicleChargingLog_00004: "Número de placa",
  vehicleChargingLog_00005: "tipo de vehículo",
  vehicleChargingLog_00006: "vehículo {tag}",
  vehicleChargingLog_00007: "propietario",
  vehicleChargingLog_00008: "móvil",
  vehicleChargingLog_00009: "hora de inicio",
  vehicleChargingLog_00010: "hora de finalización",
  vehicleChargingLog_00011: "número de pedido",
  vehicleChargingLog_00012: "estación",
  vehicleChargingLog_00013: "puerto",
  vehicleChargingLog_00014: "detalles del registro",
  vehicleChargingLog_00015: "detalles",
  vehicleChargingLog_00016: "el rango de fechas no puede superar los 90 días, ¡seleccione nuevamente!",
  vehicleChargingLog_00018: "¡seleccione una fecha!",
  vehicleChargingLog_00019: "Falla del vehículo",
  vehicleChargingLog_00020: "ingrese el VIN del vehículo",
  vehicleChargingLog_00021: "Marca|Modelos",
  }