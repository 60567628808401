export default {
    analyzeUser_00001: "Usuario",
    analyzeUser_00002: "Usuario por estación",
    analyzeUser_00003: "Análisis de utilización del modelo Charger",
    analyzeUser_00004: "Análisis de contribución del usuario",
    analyzeUser_00005: "Modelo RFM del usuario",
    analyzeUser_00006: "Tendencias de número de usuarios",
    analyzeUser_00007: "Análisis",
    analyzeUser_00008: "Análisis por cantidad de electricidad",
    analyzeUser_00009: "Análisis por duración",
    analyzeUser_00010: "Usuarios repetidos",
    analyzeUser_00011: "Nuevos usuarios",
    analyzeUser_00012: "Comparación de estaciones por usuario",
    analyzeUser_00013: "Tendencias de usuarios de estaciones seleccionadas",
    analyzeUser_00014: "Contribución media de ingresos por sitio",
    analyzeUser_00015: "Importe medio de cobro por sitio",
    analyzeUser_00016: "Duración media de cobro por sitio",
    analyzeUser_00017: "Tiempo medio de cobro por sitio",
    analyzeUser_00018: "Precio de cobro acumulado (€）",
    analyzeUser_00019: "Nombre",
    analyzeUser_00020: "Tipo de usuario",
    analyzeUser_00021: "N.º de tarjeta",
    analyzeUser_00022: "Hora del último cobro",
    analyzeUser_00023: "Precio de cobro acumulado (€)",
    analyzeUser_00024: "Recuento de cobros(tiempo)",
    analyzeUser_00025: "Recuentos de cobros(tiempo)",
    analyzeUser_00026: "Usuarios cuya última recarga se realizó en un plazo de 30 días",
    analyzeUser_00027: "Usuarios cuya última recarga fue en un plazo de 30-90 días",
    analyzeUser_00028: "Usuarios cuya última recarga fue en un plazo de 90-180 días",
    analyzeUser_00029: "Comparación de Franquiciados por usuario",
    analyzeUser_00030: "Usuario por Franquiciado",
    analyzeUser_00031: "Tendencias de usuarios de Franquiciados seleccionados",
    analyzeUser_00032: "Analizar el número de órdenes de cobro de fuentes de usuario",
    analyzeUser_00033: "Órdenes de cobro",
    analyzeUser_00034: "Porcentaje de órdenes de cobro de fuentes de usuario",
    analyzeUser_00035: "Participación de cada uno de los flujos de terceros",
    analyzeUser_00036: "Tendencias en el número de órdenes de cobro de fuentes de usuario",
    analyzeUser_00037: "Tendencias en el número de órdenes de cobro de flujo de terceros",
    analyzeUser_00038: 'Transacción',
    analyzeUser_00039: 'Número de transacciones por conector',
}