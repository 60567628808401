export default {
  settingDepot_00001: 'Conjunto de volumen',
  settingDepot_00002: 'Centro de depósito',
  settingDepot_00003: 'Gestión de depósito',
  settingDepot_00004: 'Depósito de carga seleccionado',
  settingDepot_00005: 'Recurso común',
  settingDepot_00006: 'Tarifas de grupo',
  settingDepot_00007: 'Información básica',
  settingDepot_00008: 'Ejecutar ajustes',
  settingDepot_00009: 'Envíe el estándar de carga establecido para jugar a la pila de carga, y la pila de carga puede liquidar los cargos de carga para los usuarios de acuerdo con el nuevo estándar de carga',
  settingDepot_00010: 'Envíe tarifa',
  settingDepot_00011: 'Estándar de tarifa de CA',
  settingDepot_00012: 'Uso de tarifas:',
  settingDepot_00013: 'Elegir tarifas',
  settingDepot_00014: 'Nuevas tarifas de CC',
  settingDepot_00015: 'Hora de inicio',
  settingDepot_00016: 'Hora de finalización',
  settingDepot_00017: 'Cargo de electricidad ({tipo}/{unidad})',
  settingDepot_00018: 'Tarifa de servicio ({tipo}/{unidad})',
  settingDepot_00019: 'Tarifa por inactividad ({tipo}/{unidad})',
  settingDepot_00020: 'Período de tiempo',
  settingDepot_00021: 'Sin definir',
  settingDepot_00022: 'Máximo',
  settingDepot_00023: 'Pico',
  settingDepot_00024: 'Plano',
  settingDepot_00025: 'Valle',
  settingDepot_00026: 'Estándar de tarifa de CC',
  settingDepot_00027: 'Nuevas tarifas de CA',
  settingDepot_00028: 'Nuevas tarifas',
  settingDepot_00029: 'Nombre de la tarifa',
  settingDepot_00030: 'Ingrese el nombre de la tarifa',
  settingDepot_00031: 'El nombre de la tarifa está ocupado',
  settingDepot_00032: 'Agregar tiempo',
  settingDepot_00033: 'Operación',
  settingDepot_00034: 'Eliminar',
  settingDepot_00035: 'Cancelar',
  settingDepot_00036: 'Confirmar',
  settingDepot_00037: 'Seleccione o cree un nuevo estándar de tarifa',
  settingDepot_00038: 'Se puede agregar un máximo de {0} períodos de tiempo',
  settingDepot_00039: 'Por favor, rellene la tarifa estándar',
  settingDepot_00040: 'Por favor, rellene la tarifa correcta',
  settingDepot_00041: 'Creación exitosa',
  settingDepot_00042: 'Seleccione',
  settingDepot_00043: 'Por favor, complete la información de la tarifa',
  settingDepot_00044: 'El período de la tarifa comienza de 0 a 24',
  settingDepot_00045: "El costo total de la sesión de carga única no tiene descuento",
  settingDepot_00046: "El costo total de la sesión de carga única no tiene descuento",
  settingDepot_00047: "La tarifa del servicio de carga única tiene un descuento de {des}",
  settingDepot_00048: "El costo total de la sesión de carga única tiene un descuento de {des}",
  settingDepot_00049: "El costo total de la sesión de carga única es gratuito",
  settingDepot_00050: "Sin cargo por un solo cargo",
  settingDepot_00051: 'Por favor ingrese el monto correcto (mayor que 0)',
  settingDepot_00052: 'Éxito',
  settingDepot_00053: 'Envío',
  settingDepot_00054: 'Error',
  settingDepot_00055: 'Error al enviar',
  settingDepot_00056: 'Nombre del depósito',
  settingDepot_00057: 'Número de equipo',
  settingDepot_00058: 'Resultado del envío del dispositivo',
  settingDepot_00059: 'CC',
  settingDepot_00060: 'CA',
  settingDepot_00061: 'Agregar grupo',
  settingDepot_00062: 'Nombre del grupo',
  settingDepot_00063: 'Tarifas actuales',
  settingDepot_00064: 'Descuentos actuales',
  settingDepot_00065: 'Prioridad (de mayor a menor)',
  settingDepot_00066: 'Turno hacia arriba',
  settingDepot_00067: 'Turno hacia abajo',
  settingDepot_00068: 'Establecer',
  settingDepot_00069: 'Push',
  settingDepot_00070: 'Eliminar',
  settingDepot_00071: 'Contacto de depósito',
  settingDepot_00072: 'Cuando se active la alarma de falla del equipo, se notificará a la persona de contacto por SMS',
  settingDepot_00073: 'Nombre',
  settingDepot_00074: 'Por favor, ingrese su nombre',
  settingDepot_00075: 'Celular',
  settingDepot_00076: 'Por favor, ingrese su número de móvil',
  settingDepot_00077: 'Correo electrónico',
  settingDepot_00078: 'Ingrese la dirección de correo electrónico',
  settingDepot_00079: 'Editar',
  settingDepot_00080: 'Guardar',
  settingDepot_00081: 'Estado habilitado del depósito',
  settingDepot_00082: 'Controlar si el sitio se muestra en la cuenta pública de WeChat',
  settingDepot_00083: 'Comenzar a usar',
  settingDepot_00084: 'No habilitado',
  settingDepot_00085: 'Abriendo',
  settingDepot_00086: 'Abierto',
  settingDepot_00087: 'Desactivado',
  settingDepot_00088: 'Solo los grupos de soporte pueden cobrar',
  settingDepot_00089: 'Después de habilitar esta función, se puede cobrar a los usuarios del grupo en la configuración de tarifas grupales',
  settingDepot_00090: 'Sí',
  settingDepot_00091: 'No',
  settingDepot_00092: 'Cupones admitidos',
  settingDepot_00093: 'Colas admitidas',
  settingDepot_00094: 'Reserva admitida',
  settingDepot_00095: 'Tiempo de reserva',
  settingDepot_00096: '15 minutos',
  settingDepot_00097: '30 minutos',
  settingDepot_00098: 'Modo de pago de usuario de WeChat',
  settingDepot_00099: 'Prepago',
  settingDepot_00100: 'Pospago',
  settingDepot_00101: 'El límite de saldo mínimo es',
  settingDepot_00102: 'Yuan',
  settingDepot_00103: 'Modelo de pago de usuario de tarjeta de identificación en línea',
  settingDepot_00104: 'La información del sitio que se muestra en el cliente se ve afectada',
  settingDepot_00105: 'Horario de apertura',
  settingDepot_00106: 'En el modelo prepago, solo el usuario prepago puede cargar la batería',
  
  settingDepot_00107: 'Éxito',
  settingDepot_00108: 'Envío',
  settingDepot_00109: 'Error',
  settingDepot_00110: 'Error al enviar',
  settingDepot_00111: 'Modifique la tarifa predeterminada o agregue un grupo primero',
  settingDepot_00112: 'Error al enviar. No hay ningún dispositivo en el sitio',
  settingDepot_00113: 'Esta operación eliminará el grupo de la lista. ¿Desea continuar?',
  settingDepot_00114: 'Recordatorio',
  settingDepot_00115: 'Confirmar',
  settingDepot_00116: 'Cancelar',
  settingDepot_00117: 'Eliminación cancelada',
  settingDepot_00118: 'Confirme para guardar otras operaciones primero',
  settingDepot_00119: 'Seleccione un sitio',
  settingDepot_00120: 'Sin modificación de información, operación cancelada',
  settingDepot_00121: 'Ingrese el nombre de contacto correcto',
  settingDepot_00122: 'Ingrese el número de teléfono correcto',
  settingDepot_00123: 'Ingrese el formato de correo electrónico correcto',
  settingDepot_00124: 'Configuración de fecha incorrecta',
  settingDepot_00125: 'Seleccione grupo',
  settingDepot_00126: 'Ingrese el nombre de la comunidad',
  settingDepot_00127: 'Número de usuarios',
  settingDepot_00128: 'Estándar de tarifa',
  settingDepot_00129: 'Si se modifica el estándar de tarifa, haga clic en el botón "Enviar tarifa" en los detalles del sitio - estándar de tarifa para que la tarifa entre en vigencia',
  settingDepot_00130: 'Tarifa de cambio',
  settingDepot_00131: 'Seleccione la tarifa',
  settingDepot_00132: 'Tarifa de CC',
  settingDepot_00133: 'Selección de descuento',
  settingDepot_00134: 'El costo total de un solo punto de éxito no se descuenta (%)',
  settingDepot_00135: 'Descuento de la tarifa de servicio de carga única (%)',
  settingDepot_00136: 'Por ejemplo: si el cargo por servicio de una carga única se establece en un 8,5 % de descuento y el cargo por esta carga es del 100 % yuanes (incluidos 70 yuanes por electricidad y 30 yuanes por servicio), el monto real a pagar esta vez es 95,5 yuanes (70 + 30 * 85% = 95,5)',
  settingDepot_00137: '85 significa 8,5% de descuento y 0 significa gratis',
  settingDepot_00138: 'Descuento en el costo total de una carga única (%) ',
  settingDepot_00139: 'Por ejemplo: si el cargo total por una carga única se establece en 8,5% de descuento y el cargo total por esta carga es 100 yuanes, el monto real del pago es 85 yuanes (100 * 85% = 85)',
  settingDepot_00140: '85 significa 8,5% de descuento y 0 significa gratis',
  settingDepot_00141: 'Grupo agregado exitosamente',
  settingDepot_00142: 'Haga clic en ¡El botón [push charge] en la tarifa estándar hace que la tarifa sea efectiva!',
  settingDepot_00143: 'Las siguientes operaciones se pueden realizar en [administración de estaciones de carga - detalles del sitio - información del sitio]:',
  settingDepot_00144: '1. Eliminar comunidad agregada',
  settingDepot_00145: '2. Añadir un nuevo grupo',
  settingDepot_00146: 'Paso anterior',
  settingDepot_00147: 'Próximo paso',
  settingDepot_00148: 'Confirmar',
  settingDepot_00149: 'Seleccione un grupo',
  settingDepot_00150: 'Seleccione el estándar de tarifa',
  settingDepot_00151: 'No se pueden agregar grupos de usuarios repetidamente',
  settingDepot_00152: 'Establezca parámetros de descuento',
  settingDepot_00153: 'Ingrese un número entero positivo menor que 100',
  settingDepot_00154: 'Seleccione tarifa y descuento',
  settingDepot_00155: 'Completado',
  settingDepot_00156: 'Resultados de modificación por lotes',
  settingDepot_00157: 'Esperando respuesta',
  settingDepot_00158: 'motivo',
  settingDepot_00159: 'cerrar',
  settingDepot_00160: 'Saldo mínimo',
  settingDepot_00161: 'Usar moneda',
  settingDepot_00162: '1. Un grupo añadido',
  settingDepot_00163: '',
  settingDepot_00164: '',
  settingDepot_00165: '',
  settingDepot_00166: '',
  settingDepot_00167: '',
  settingDepot_00168: '',
  settingDepot_00169: '',
  settingDepot_00170: '',
  }