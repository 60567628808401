export default {
    createDepot_00001: 'Crear estación de carga',
    createDepot_00002: 'Centro de carga',
    createDepot_00003: 'Último paso',
    createDepot_00004: 'Modelos de soporte',
    createDepot_00005: 'Automóvil de pasajeros',
    createDepot_00006: 'Furgoneta de carga',
    createDepot_00007: 'Midibús',
    createDepot_00008: 'Autobús',
    createDepot_00009: 'Nombre de la estación',
    createDepot_00010: 'Se recomienda utilizar un punto de referencia como nombre',
    createDepot_00011: 'Dirección de la estación',
    createDepot_00012: 'Input.GetPosition',
    createDepot_00013: 'Provincia',
    createDepot_00014: 'Ciudad',
    createDepot_00015: 'Distrito',
    createDepot_00016: 'Calle',
    createDepot_00017: 'Puedes modificar la descripción de una calle específica',
    createDepot_00018: 'Siguiente paso',
    createDepot_00019: 'Información básica',
    createDepot_00020: 'Tarifas',
    createDepot_00021: 'Finalizar creación',
    createDepot_00022: 'Patinete',
    createDepot_00023: 'Seleccionar tarifas',
    createDepot_00024: 'Nuevas tarifas',
    createDepot_00025: 'Hora de inicio',
    createDepot_00026: 'Hora de finalización',
    createDepot_00027: 'Tarifa de electricidad ({type}/{uint})',
    createDepot_00028: 'Tarifa de servicio ({type}/{uint})',
    createDepot_00029: 'Tarifa de inactividad ({type}/{uint})',
    createDepot_00030: 'Tipo de período',
    createDepot_00031: 'Indefinido',
    createDepot_00032: 'Cúspide',
    createDepot_00033: 'Pico',
    createDepot_00034: 'Plano',
    createDepot_00035: 'Valle',
    createDepot_00036: 'Puede',
    createDepot_00037: 'Ver los detalles de la estación;',
    createDepot_00038: 'Buscar la transacción de carga de la estación;',
    createDepot_00039: 'Gestionar el cargador de la estación;',
    createDepot_00040: 'Establecer las tarifas de la estación;',
    createDepot_00041: 'Establecer las políticas de la estación, etc.',
    createDepot_00042: 'Acceder a la gestión de la estación',
    createDepot_00043: 'Se puede añadir un máximo de {0} periodos de tiempo',
    createDepot_00044: 'Por favor, rellene la información básica de la estación, que es obligatoria.',
    createDepot_00045: 'Puede crear una nueva tarifa o seleccionar una existente en el cuadro desplegable.',
    createDepot_00046: '¡Su estación ha sido creada, pruébela ahora!',
    createDepot_00047: '¡Estación creada!',
    createDepot_00048: 'en la gestión de la estación',
    createDepot_00049: 'realizar las siguientes operaciones:',
    createDepot_00050: 'Tarifas de CC',
    createDepot_00051: 'Nuevas tarifas de CA',
    createDepot_00052: 'Tarifas de CA',
    createDepot_00053: 'Nuevas tarifas de CC',
    createDepot_00054: 'Nombre de los cargos',
    createDepot_00055: 'Seleccionar',
    createDepot_00056: 'Agregar hora',
    createDepot_00057: 'Por favor, rellene el estándar de tarifa',
    createDepot_00058: 'Eliminar',
    createDepot_00059: 'Cancelar',
    createDepot_00060: 'Confirmar',
    createDepot_00061: 'Por favor, verifique la información de la estación',
    createDepot_00062: 'El período de tarifa comienza de 0 a 24',
    createDepot_00063: 'Por favor, rellene la tarifa correcta',
    createDepot_00064: 'Nuevo éxito',
    createDepot_00065: 'Seleccionar las coordenadas de ubicación de la estación de carga',
    createDepot_00066: 'Nombre',
    createDepot_00067: 'Dirección detallada',
    createDepot_00068: 'Usando esta ubicación',
    createDepot_00069: 'Dirección, Calle, Nombre de la casa',
    createDepot_00070: 'Buscar',
    createDepot_00071: 'Ubicación actual',
    createDepot_00072: '¡Error al obtener la ubicación!',
    createDepot_00073: 'El nombre de la tarifa está ocupado',
    createDepot_00074: 'Seleccione o cree un nuevo estándar de tarifa',
    createDepot_00075: 'Uso actual:',
    createDepot_00076: 'Por favor, introduzca el nombre de las tarifas',
    createDepot_00077: 'Usar moneda',
    createDepot_00078: 'Esta estación necesita seleccionar un tipo de tarifa de {type}, actualmente no existe una plantilla de tarifa de este tipo, por favor, cree una nueva tarifa.',
    createDepot_00079: 'Se debe seleccionar el mismo tipo de moneda para las tarifas de cargadores de CC y CA.',
    createDepot_00080: 'Moneda',
    createDepot_00081: '¿No se requiere ningún tipo de moneda?',
    createDepot_00082: 'Haga clic para agregar',
    createDepot_00083: 'Agregar',
    createDepot_00084: 'Cancelar',
    createDepot_00085: 'Error al agregar',
    createDepot_00086: 'Zona horaria',
    createDepot_00087: 'Agregar o administrar estación cargador',
    createDepot_00088: 'Error en la adquisición de la estación',
}