export default {
  eventlogsphotovoltaic_00001: "事务列表",
  eventlogsphotovoltaic_00002: "导出数据",
  eventlogsphotovoltaic_00003: "事务编号",
  eventlogsphotovoltaic_00004: "开始日期",
  eventlogsphotovoltaic_00005: "结束日期",
  eventlogsphotovoltaic_00006: "重置",
  eventlogsphotovoltaic_00007: "查询",
  eventlogsphotovoltaic_00008: "事务状态",
  eventlogsphotovoltaic_00009: "全部",
  eventlogsphotovoltaic_00010: "账单类型",
  eventlogsphotovoltaic_00011: "计费中",
  eventlogsphotovoltaic_00012: "供电",
  eventlogsphotovoltaic_00013: "放电",
  eventlogsphotovoltaic_00014: "储能设备SN",
  eventlogsphotovoltaic_00015: "全部储能柜",
  eventlogsphotovoltaic_00016: "供电电量(度)",
  eventlogsphotovoltaic_00017: "供电时间(分钟)",
  eventlogsphotovoltaic_00018: "供电次数(次)",
  eventlogsphotovoltaic_00019: "放电电量(度)",
  eventlogsphotovoltaic_00020: "放电时间(分钟)",
  eventlogsphotovoltaic_00021: "放电次数(次)",
  eventlogsphotovoltaic_00022: "事务曲线",
  eventlogsphotovoltaic_00023: "天",
  eventlogsphotovoltaic_00024: "小时",
  eventlogsphotovoltaic_00025: "分钟",
  eventlogsphotovoltaic_00026: "设备",
  eventlogsphotovoltaic_00027: "供电时长",
  eventlogsphotovoltaic_00028: "已供电量",
  eventlogsphotovoltaic_00029: "已补电量",
  eventlogsphotovoltaic_00030: "放电中",
  eventlogsphotovoltaic_00031: "已放完",
  eventlogsphotovoltaic_00032: "供电中",
  eventlogsphotovoltaic_00033: "已供完",
  eventlogsphotovoltaic_00034: "此操作将下载事务的Excel文件，是否继续?",
  eventlogsphotovoltaic_00035: "确定",
  eventlogsphotovoltaic_00036: "取消",
  eventlogsphotovoltaic_00037: "开始下载",
  eventlogsphotovoltaic_00038: "取消下载",
  eventlogsphotovoltaic_00039: "提示",
  eventlogsphotovoltaic_00040: "累计电量",
  eventlogsphotovoltaic_00041: "瞬时功率",
  eventlogsphotovoltaic_00042: "平均功率",
  eventlogsphotovoltaic_00043: "最高功率",
  eventlogsphotovoltaic_00044: "光伏事务日志",
  eventlogsphotovoltaic_00045: "",
  billDetail_00025: "充电曲线",
  billDetail_00026: "车端需求电压(虚线)",
  billDetail_00027: "车端需求电流(虚线)",
  billDetail_00028: "车端测量电压(虚线)",
  billDetail_00029: "车端测量电流(虚线)",
  billDetail_00030: "电池初始电量",
  billDetail_00031: "电池当前电量",
  billDetail_00032: "瞬时功率",
  billDetail_00033: "平均功率",
  billDetail_00034: "最高功率",
  billDetail_00035: "车型",
  billDetail_00036: "功率",
  billDetail_00037: "电流",
  billDetail_00038: "电压",
  billDetail_00039: "累计电量",
  billDetail_00042: "度",
  bill_00059: "今天",
  bill_00060: "7天前",
  bill_00061: "15天前",
  bill_00077: "昨天",
  bill_00078: "30天前",
}