export default {
  notify_00001: 'Notificación',
  notify_00002: 'T. notificaciones',
  notify_00003: 'Año',
  notify_00004: 'Mes',
  notify_00005: 'Facturado',
  notify_00006: 'Rechazado',
  notify_00007: 'Revisar y reenviar solicitud',
  notify_00008: 'Verifique su número o correo',
  notify_00009: `Billing Notification: Your platform usage fee <span style="color:#09B59D">deduction successful</span>, the bill has been paid. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
  notify_00010: `Billing Notification: Your IoT card network fee <span style="color:#09B59D">deduction successful</span>, the bill has been paid. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
  notify_00011: `Billing Notification: Your platform usage fee <span style="color:#FA545E">deduction failed</span>, the bill is <span style="color:#FF9F0A">pending payment</span>, with <span style="color:#FA545E">3</span> days remaining. Please pay in time to avoid service interruption. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
  notify_00012: `Billing Notification: Your platform usage fee <span style="color:#FA545E">deduction failed</span>, the bill is <span style="color:#FA545E">overdue</span>. Please pay within <span style="color:#FA545E">{day}</span> days to avoid service interruption. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
  notify_00013: `Billing Notification: Your IoT card network fee <span style="color:#FA545E">deduction failed</span>, the bill is <span style="color:#FA545E">overdue</span>. Please pay in time. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
  notify_00014: `Invoice Processing Notification: Your invoice application {status}, bill amount {amount} yuan, {invoiceMsg}. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./invoiceDetails.html?id={id}' ,'{messageId}')">View</a>`,
  notify_00015: `Invoice Processing Notification: Your invoice application {status}, bill amount {amount} yuan, logistics information: {logisticsInfo}. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./invoiceDetails.html?id={id}' ,'{messageId}')">View</a>`,
  notify_00016: `{userName} ha preparado los datos del pedido exportados desde la función {exportType} el {exportTime}. Descárguelo en un plazo de 7 días. Si venció, vuelva a exportar el pedido si es necesario. <a href="javascript:void(0)" onclick="vm.readMessageAnddownload('{downloadId}-{downloadType}-{createTime}' ,'{messageId}')">Descargar</a>`,
  notify_00017: 'Espere, descarga en curso',
  notify_00018: `{userName} no pudo exportar el pedido desde la función {exportType} el {exportTime}. ¡Por favor, <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./bill.html#{type}' ,'{messageId}')">inténtelo de nuevo</a> más tarde!`,
  notify_00019: 'El archivo ha expirado',
  notify_00020: `Charging Stop Notification: Your platform usage fee is <span style="color:#FA545E">overdue</span> and not paid in time. Charging service has been stopped. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">View</a>`,
  notify_00021: `Company-to-company transfer to recharge platform account successful! Recharge amount {amount} yuan. For details, please check in <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?&payCostType=accountBalanceDetail' ,'{messageId}')">Balance Details</a>.`,
  notify_00022: `Expiration Reminder: The free IoT card network fee for {simCardNum} chargers <span style="color:#FA545E">has expired</span>. A fee bill will be generated on the 1st of next month. Please ensure sufficient balance in the platform account.`,
  notify_00023: `Non-XCharge charger model: {factory}-{sourceId} application successful. You can now add this model device on the platform. <a href="javascript:void(0)" onclick="vm.readMessageAndJump('./addDevice.html' ,'{messageId}')">Add Device</a>`,
  notify_00024: `Non-XCharge charger model: {factory}-{sourceId} application failed. Reason: {comment}.`,
  notify_00025: 'Notificación del dispositivo',
  notify_00026: 'Notificación financiera',
  notify_00027: 'Notificación de seguridad',
  notify_00028: 'Tarea asincrónica',
  notify_00029: 'Actualización del sistema',
  notify_00030: 'Otras notificaciones',
};