
export default {
  marketCaptureTraffic_00001: "获取流量引导",
  marketCaptureTraffic_00002: "引导目录",
  marketCaptureTraffic_00003: "开放引入第三方流量",
  marketCaptureTraffic_00004: "优惠活动组合",
  marketCaptureTraffic_00005: "稳定的车队大客户",
  marketCaptureTraffic_00006: "裂变获客-邀请好友",
  marketCaptureTraffic_00007: "引导语",
  marketCaptureTraffic_00008: "通过接入引流第三方流量，可获取大量的流量",
  marketCaptureTraffic_00009: "地图流量",
  marketCaptureTraffic_00010: "查看更多流量",
  marketCaptureTraffic_00011: "网约车流量",
  marketCaptureTraffic_00012: "新能源车主聚合流量",
  marketCaptureTraffic_00013: "已开放站点：",
  marketCaptureTraffic_00014: "未开放站点：",
  marketCaptureTraffic_00015: "已接入",
  marketCaptureTraffic_00016: "未接入",
  marketCaptureTraffic_00017: "去开放",
  marketCaptureTraffic_00018: "如何接入",
  marketCaptureTraffic_00019: "通过领取优惠券活动和充电送返活动的营销组合，会给充电站带来很好的流量。",
  marketCaptureTraffic_00020: "优惠券",
  marketCaptureTraffic_00021: "1、一般情况下，对于位置不好的新站，为更好的获取流量效果，建议您更多使用低门槛的免单券或免服务费券。",
  marketCaptureTraffic_00022: '2、免单券使用"折扣券"中"新建折扣券“功能中“使用条件-总费用打折”设置，免服务券使用"折扣券"中"新建折扣券“功能中“使用条件-服务费打折”设置。',
  marketCaptureTraffic_00023: "3、各种类型的优惠券“有效期”，建议您设置相对时间。", 
  marketCaptureTraffic_00024: "去设置折扣券",
  marketCaptureTraffic_00025: "去设置抵用券",
  marketCaptureTraffic_00026: "领取优惠券活动",
  marketCaptureTraffic_00027: "1、活动建好后，建议您加大宣传推广，灵活运用“活动外发专用链接”。",
  marketCaptureTraffic_00028: "2、以下是可通过“活动外发专用链接”宣传推广案例，希望给您启发。",
  marketCaptureTraffic_00029: "可通过“活动外发专用链接”生成二维码，做成宣传单，在场站周围3公里小区、写字楼等粘贴地推推广;",
  marketCaptureTraffic_00030: "可通过“活动外发专用链接”生成二维码，做成活动单，粘贴在场站入口等明显位置，供车主领取;",
  marketCaptureTraffic_00031: "可通过“活动外发专用链接”嵌入微信公众号文章内、自动回复-被关注回复等线上宣传渠道;",
  marketCaptureTraffic_00032: "可将“活动外发专用链接”发到自己的新能源车主微信群中;等等",
  marketCaptureTraffic_00033: "去设置领取优惠券活动",





}