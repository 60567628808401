export default {
  parkRecommend_00001: "Descargar",
  parkRecommend_00002: "Simulación de carga",
  parkRecommend_00003: "Recomendación de espacio de carga para el vehículo",
  parkRecommend_00004: "Espacio de estacionamiento para carga",
  parkRecommend_00005: "Cargadores",
  parkRecommend_00006: "Código del vehículo",
  parkRecommend_00007: "Parámetros de generación de simulación de carga",
  parkRecommend_00008: "Consumo de energía del vehículo eléctrico por kilómetro (kW·h/km)",
  parkRecommend_00009: "Velocidad del vehículo eléctrico (km/h)",
  parkRecommend_00010: "Rango de error del tiempo de retorno del vehículo (+-)",
  parkRecommend_00011: "10 minutos",
  parkRecommend_00012: "30 minutos",
  parkRecommend_00013: "60 minutos",
  parkRecommend_00014: "¡Por favor, introduzca un número mayor que 0!",
  parkRecommend_00015: "Descargar formulario",
  }