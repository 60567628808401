export default {
  coaLog_00001: "触发日志",
  coaLog_00002: "",
  coaLog_00003: "触发时间",
  coaLog_00004: "触发条件",
  coaLog_00005: "处理动作",
  coaLog_00006: "触发次数",
  coaLog_00007: "结束充电",
  coaLog_00008: "拉黑微信用户",
  coaLog_00009: "{0}天",
  coaLog_00010: "长期",
  coaLog_00011: "挂失充电卡",
  coaLog_00012: "最大可充电SOC{0}%，",
  coaLog_00013: "己发送微信模板消息给{employee}",
  coaLog_00014: "短信",
  coaLog_00015: "邮件",
  coaLog_00016: "微信",
  coaLog_00017: "已发送设备重启命令",
  coaLog_00018: "已发送{workFlowClass}到处理人{handleRole}，",
  coaLog_00019: "在{date}生效周期和{time}生效时间内",
  coaLog_00020: "全部直流桩",
  coaLog_00021: "全部交流桩",
  coaLog_00022: "告警码：",
  coaLog_00023: "满足以下条件：",
  coaLog_00024: "连续{cycleLength}天，每天都达到{cycleTarget}个异常订单",
  coaLog_00025: "连续{cycleLength}天，无订单",
  coaLog_00026: "禁止充电车辆",
  coaLog_00027: "限制充电车辆",
  coaLog_00028: "订单：",
  coaLog_00029: "SN：",
  coaLog_00030: "工单编号：",
  coaLog_00031: "",
  coaLog_00032: "",
  coaLog_00033: "",
  coaLog_00034: "",
  coaLog_00035: "",
  coaLog_00036: "",
  coaLog_00037: "",
  coaLog_00038: "",
  coaLog_00039: "",
  coaLog_00040: "",
  coaDetail_00032: "不限日期",
  coaDetail_00033: "每年 ",
  coaDetail_00034: "每周 ",
  coaDetail_00035: "每月 ",
  coaDetail_00036: "一",
  coaDetail_00037: "二",
  coaDetail_00038: "三",
  coaDetail_00039: "四",
  coaDetail_00040: "五",
  coaDetail_00041: "六",
  coaDetail_00042: "日",
  coaDetail_00043: "不限时间-全天",
};