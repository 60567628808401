export default {
    groupRecord_00001: "Fecha de recarga",
    groupRecord_00002: "Importe total de la recarga ({unit})",
    groupRecord_00003: "Principal de la recarga ({0})",
    groupRecord_00004: "Importe de reembolso de la recarga ({unit})",
    groupRecord_00005: "Saldo de la cuenta después de la recarga ({0})",
    groupRecord_00006: "Modo de recarga",
    groupRecord_00007: "CPO",
    groupRecord_00008: "Incluyendo cashback{promotionBalance}{unit}",
    groupRecord_00009: "Importe del cashback ({unit})",
    groupRecord_00010: "Registro de recarga de la cuenta de grupo",
    
  }