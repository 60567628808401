export default {
 userRanking_00001: 'Actualmente viendo',
 userRanking_00002: 'Exportar usuario',
 userRanking_00003: 'Ver usuario',
 userRanking_00004: 'Gestión de miembros',
 userRanking_00005: 'Ver usuario',
 userRanking_00006: 'avatar',
 userRanking_00007: 'apodo',
 userRanking_00008: 'Género',
 userRanking_00009: 'Número de teléfono móvil',
 userRanking_00010: 'Número de matrícula',
 userRanking_00011: 'Saldo',
 userRanking_00012: 'Orden de consulta',
 userRanking_00013: 'Detalles',
 userRanking_00014: 'Hombre',
 userRanking_00015: 'Mujer',
 userRanking_00016: '',
 userRanking_00017: '',
 userRanking_00018: '',
}