export default {
  workSchedule_00001: 'Nombre del plan de tiempo del modo de trabajo personalizado',
  workSchedule_00002: 'Lista',
  workSchedule_00003: 'Nuevo horario',
  workSchedule_00004: 'Nombre del plan de tiempo',
  workSchedule_00005: 'Este modo de trabajo no se aplica y se puede eliminar. ¿Estás seguro de eliminar? ',
  workSchedule_00006: 'Reintentar',
  workSchedule_00007: '',
  workSchedule_00008: '',
  workSchedule_00009: '',
  workSchedule_00010: '',
  workSchedule_00011: '',
 }