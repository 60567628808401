export default {
    energyHome_00001: "Cargador que no es NZS",
    energyHome_00002: "Batería NZS",
    energyHome_00003: "Estado de carga actual de la batería NZS",
    energyHome_00004: "Hoy mismo me autoabastezco",
    energyHome_00005: "Disponible",
    energyHome_00006: "modo",
    energyHome_00007: "Modo de trabajo actual",
    energyHome_00008: "Gestión de cargadores de microrredes",
    energyHome_00009: "Capacidad instalada de la batería NZS",
    energyHome_00010: "Poder",
    energyHome_00011: "Voltaje",
    energyHome_00012: "Actual",
    energyHome_00013: "Tiempo de modo de trabajo SCH",
    energyHome_00014: "Cantidad de carga de vehículos eléctricos de hoy",
    energyHome_00015: "Ingresos actuales por carga de vehículos eléctricos",
    energyHome_00016: "Establecer el costo de uso de la red",
    energyHome_00017: "Capacidad de la estación de carga",
    energyHome_00018: "Importando desde la red",
    energyHome_00019: "Exportando a la red",
    energyHome_00020: "Energía fotovoltaica exportadora",
    energyHome_00021: "Potencia de carga del vehículo",
    energyHome_00022: "Cargando",
    energyHome_00023: "Descargando",
    energyHome_00024: "Baterías NZS",
}