export default {
    accountManage_00001: "Por favor ingrese el nombre de la cuenta, el propietario y el número de teléfono móvil",
    accountManage_00002: "Cuenta (ID de inicio de sesión)",
    accountManage_00003: "Propietario",
    accountManage_00004: "Número de teléfono móvil",
    accountManage_00005: "Rol",
    accountManage_00006: "Nueva cuenta",
    accountManage_00007: "Operación",
    accountManage_00008: "Estaciones",
    accountManage_00009: "Crear una cuenta",
    accountManage_000010: "Lista de cuentas",
    accountManage_000011: "Editar",
    accountManage_000012: "Iniciar sesión en la plataforma",
    accountManage_000013: "Contraseña",
    accountManage_000014: "Iniciar sesión en la plataforma plataforma",
    accountManage_000015: "Contiene al menos 6 caracteres, incluyendo al menos 1 dígito, 1 letra y 1 carácter especial",
    accountManage_000016: "Propietario",
    accountManage_000017: "Su nombre real",
    accountManage_000018: "Número de móvil",
    accountManage_000019: "Es fácil ponerse en contacto con usted",
    accountManage_000020: "Rol",
    accountManage_000021: "Administrador del operador",
    accountManage_000022: "Seleccionar",
    accountManage_000023: "Seleccione el rol de la cuenta",
    accountManage_000024: "Operación exitosa",
    accountManage_000025: "Nombre de la estación de carga",
    accountManage_000026: "Estaciones de carga agregadas exitosamente",
    accountManage_000027: "Estaciones de carga seleccionadas",
    accountManage_000028: "Eliminado correctamente",
    accountManage_000029: "Superadministrador",
    accountManage_000030: "¿Está seguro de que desea eliminar?",
    accountManage_000031: "Eliminación cancelada",
    accountManage_000032: "Hora de creación",
    accountManage_000033: "Correo electrónico",
    accountManage_000034: "vinculación",
    accountManage_000035: "Eliminar la vinculación",
    accountManage_000036: "Letras y números,<=20",
}