export default {
  weChatRecord_00001: "Registro de recarga",
  weChatRecord_00002: "Fecha de recarga",
  weChatRecord_00003: "Monto de recarga({0})",
  weChatRecord_00004: "Capital({0})",
  weChatRecord_00005: "Regalo({0})",
  weChatRecord_00006: "Método de recarga",
  weChatRecord_00007: "Operador",
  weChatRecord_00008: "Observación",
  weChatRecord_00009: "(Sin reembolsos) Actividad de recarga",
  }