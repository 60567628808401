export default {
  from_get_coupon_on:"{validityDays} días a partir de la fecha de recepción del cupón",
  rewardSetting_0001: 'Configuración de la recompensa',
  rewardSetting_0002: 'Cantidad',
  rewardSetting_0003: 'Agregar cupón',
  rewardSetting_0004: 'Ingrese el nombre de la tarjeta',
  rewardSetting_0005: 'Tipo de cupón',
  rewardSetting_0006: 'Nombre del cupón',
  rewardSetting_0007: 'Tipo',
  rewardSetting_0008: 'Fecha de vencimiento',
  rewardSetting_0009: 'Misión de mejora: {nombre}',
  rewardSetting_0010: 'Misión de descenso: {nombre}',
  rewardSetting_00011: 'Si agrega un cupón con un período de validez absoluto, tenga en cuenta que el cupón se eliminará de la recompensa antes que el cupón caduca. ',
  rewardSetting_00012: 'Ingrese un número, el número no puede exceder 100',
  rewardSetting_00013: 'Nivel de membresía del usuario',
  rewardSetting_00014: "Dentro de {validityDays} días de la adquisición",
  }