export default {
    eur: "EUR",
    eur_name: "EUR",
    gbp: "GBP",
    gbp_name: "British Pound",
    sek: "SEK",
    sek_name: "Swedish Krona",
    hcf: "HCF",
    hcf_name: "Swiss Franc",
    cny: "CNY",
    cny_name: "CNY",
    usd: "USD",
    usd_name: "Dollar",
    rub: "RUB",
    rub_name: "Russischer Rubel",
    ils: "ILS",
    ils_name: "Israelische Schekel",

    common_00001: "Logout",
    common_00002: "YYYY/MM/DD",
    common_00003: "YYYY/MM/DD HH:mm:ss",
    common_00004: "Stations",
    common_00005: "Vorgänge",
    common_00006: "Maintenance",
    common_00007: "Security",
    common_00008: "User",
    common_00009: "Marketing",
    common_00010: "Data",
    common_00011: "Finance",
    common_00012: "Diversion",
    common_00013: "System",
    common_00014: "Übersicht der Überwachung",
    common_00015: "Startseite",
    common_00016: "Gesamtübersicht",
    common_00017: "Echtzeit-Überwachung",
    common_00018: "年度统计",
    common_00019: "维修指标",
    common_00020: "Abnormale Vorgänge",
    common_00021: "Ladestationen Management",
    common_00022: "Ladestation erstellen",
    common_00023: "Tarif management",
    common_00024: "Tarif Vorlage",
    common_00025: "充电费付费方式管理",
    common_00026: "预付费",
    common_00027: "后付费",
    common_00028: "Geräte management",
    common_00029: "占用费收取方式管理",
    common_00030: "云控车位锁",
    common_00031: "桩控车位锁",
    common_00032: "广告投放",
    common_00033: "设备广告投放",
    common_00034: "公众号广告投放",
    common_00035: "客户端场站展示标签",
    common_00036: "系统级营销类标签",
    common_00037: "场站级经营类标签",
    common_00038: "耗能管理",
    common_00039: "耗能统计",
    common_00040: "场站耗能管理",
    common_00041: "Regierungsplatform andocken",
    common_00042: "Franchise Management",
    common_00043: "Franchise einladen",
    common_00044: "Ladevorgänge",
    common_00045: "Einzelne Ladevorgänge",
    common_00046: "Gruppeladevorgänge",
    common_00047: "Ladevorgang von Dritten ",
    common_00048: "abnormaler Vorgang",
    common_00049: "Alle Ladevorgänge",
    common_00050: "Gutscheinbestellung",
    common_00051: "Belegungsrechnung",
    common_00052: "Operationsüberwachung",
    common_00053: "Echtzeit-Überwachung",
    common_00054: "Abnormale Vorgänge",
    common_00055: "维护指标",
    common_00056: "Log suchen",
    common_00057: "device exception log",
    common_00058: "vehicle charging log",
    common_00059: "platform business log",
    common_00060: "Fehlerbehebung",
    common_00061: "Aktuelle Fehler",
    common_00062: "Leistungsmodulswartung",
    common_00063: "充电安全策略",
    common_00064: "站点车辆充电限制",
    common_00065: "高危车型管理",
    common_00066: "主动实时防护",
    common_00067: "Schwarze",
    common_00068: "Benutzer",
    common_00069: "Benutzerverwaltung",
    common_00070: "Nutzerklassifikation",
    common_00071: "Klassifikationsmanagement",
    common_00072: "Gruppe",
    common_00073: "Gruppenzahlung",
    common_00074: "Individuelle Zahlung",
    common_00075: "Verwaltung aller Gruppen",
    common_00076: "Karten aufladen",
    common_00077: "Verwaltung der Karte",
    common_00078: "PnC-Karte",
    common_00079: "Online Ausweiskarte ",
    common_00080: "Offline Start-Stopp-Karte",
    common_00081: "Offline-Speicherwertkarte",
    common_00082: "EV Fzg",
    common_00083: "Fahrzeug",
    common_00084: "Wechat",
    common_00085: "Kundendienst",
    common_00086: "Mitglied",
    common_00087: "Mitgliedermanagement",
    common_00088: "Gutschein Instrument",
    common_00089: "Vouchar",
    common_00090: "Coupon",
    common_00091: "Coupon",
    common_00092: "Elektrizitätpaket",
    common_00093: "Servicegebührcoupon",
    common_00094: "Activity",
    common_00095: "Stored value return",
    common_00096: "Free collection",
    common_00097: "注册送返活动",
    common_00098: "充电送返活动",
    common_00099: "累计送返活动",
    common_00100: "累计充电活动",
    common_00101: "Servicegebührcoupon",
    common_00102: "Freunde einladen",
    common_00103: "Gutscheinverkauf",
    common_00104: "Gutschein in Regal",
    common_00105: "Gutscheinbestellung",
    common_00106: "Verkaufsartikel-Verbrauchsstatistik",
    common_00107: "Gutscheinverteilung",
    common_00108: "Gutscheinverteilung",
    common_00109: "Kanaleinlösenrekord",
    common_00110: "已兑换优惠消耗统计",
    common_00111: "Effizienzanalyse",
    common_00112: "Gesamtebene",
    common_00113: "Standortebene",
    common_00114: "Gerätsebene",
    common_00115: "Hauptverkehrszeiten",
    common_00116: "DurchschnittsLeistung",
    common_00117: "Franchisenehmer-Ebene",
    common_00118: "Analyse des Ladungsvolumens",
    common_00119: "Gesamtebene",
    common_00120: "Standortebene",
    common_00121: "Gruppenebene",
    common_00122: "Benutzerquellen",
    common_00123: "Franchisenehmer-Ebene",
    common_00124: "Analyse der Einkommen",
    common_00125: "Gesamtebene",
    common_00126: "Standortebene",
    common_00127: "Gruppenebene",
    common_00128: "毛利率分析",
    common_00129: "Benutzerquellen",
    common_00130: "Franchisenehmer-Ebene",
    common_00131: "充电订单金额分析",
    common_00132: "Analyse der Nutzer",
    common_00133: "Gesamtebene",
    common_00134: "Standortebene",
    common_00135: "Benutzerquellen",
    common_00136: "Nutzerbeiträge",
    common_00137: "RFM-Modellanalyse",
    common_00138: "Franchisenehmer-Ebene",
    common_00139: "卡券分析",
    common_00140: "销售趋势分析",
    common_00141: "销售分布分析",
    common_00142: "充值分析",
    common_00143: "充值金额分析",
    common_00144: "充值用户数分析",
    common_00145: "退款分析",
    common_00146: "退款金额分析",
    common_00147: "退款用户数分析",
    common_00148: "钱包分析",
    common_00149: "钱包余额趋势分析",
    common_00150: "钱包余额消耗方式分析",
    common_00151: "Häufig verwendete Funktionen",
    common_00152: "Individuelle Rechnung",
    common_00153: "Gruppenrechnung",
    common_00154: "Erstattungen",
    common_00155: "Vorgänge suchen",
    common_00156: "Finanzstatistiken",
    common_00157: "Aufladebetrag",
    common_00158: "Erstattungsbetrag",
    common_00159: "Saldo der Brieftasche",
    common_00160: "Betrag der Karte",
    common_00161: "Finanzeinstellung",
    common_00162: "Benutzererstattung",
    common_00163: "Erstattung",
    common_00164: "Benutzerfakturierung",
    common_00165: "Reserven durch Dritten",
    common_00166: "Rechnungsstellung",
    common_00167: "Aufladung",
    common_00168: "Selbstbedingungs-bezahlen",
    common_00169: "Plattformrechnung",
    common_00170: "Konto mangement",
    common_00171: "申请发票",
    common_00172: "发票明细",
    common_00173: "平台代开发票",
    common_00174: "Offene Leads",
    common_00175: "Map Plattform",
    common_00176: "Fahrzeugberieb",
    common_00177: "Aggregationsplattformen",
    common_00178: "Plattformen der Regierung",
    common_00179: "流量分析",
    common_00180: "Drittenfluss",
    common_00181: "Betriebsprotokoll",
    common_00182: "Konto mangement",
    common_00183: "Betriebsprotokoll",
    common_00184: "andere Einstellung",
    common_00185: "API",
    common_00186: "CPO",
    common_00187: "Informationseinstellung",
    common_00188: "Konto und Zugang",
    common_00189: "客户端设置",
    common_00190: "Wechat",
    common_00191: "Alipay",
    common_00192: "Begrüßungsnachricht",
    common_00193: "Fahrzeug zufügen",
    common_00194: "Fahrzeug bearbeiten",
    common_00195: "Kennzeichen",
    common_00196: "Fahrzeug-VIN-Code",
    common_00197: "Tel-Nr.",
    common_00198: "Modell | (optional)",
    common_00199: "Standort | (optional)",
    common_00200: "Bundesland/Stadt",
    common_00201: "erfolgreiche Änderung",
    common_00202: "Informationen",
    common_00203: "Ladegerät",
    common_00204: "Startzeit",
    common_00205: "Endzeit",
    common_00206: "Strompreis",
    common_00207: "Servicegebühr",
    common_00208: "Verzugsgebühr",
    common_00209: "kWh",
    common_00210: "Minute",
    common_00211: "App-Nutzer",
    common_00212: "Zufügen",
    common_00213: "ändern",
    common_00214: "Löschen",
    common_00215: "Abbrechen",
    common_00216: "erfolgreiche Löschen",
    common_00217: "Speichern",
    common_00218: "Standort",
    common_00219: "Ladepunkt",
    common_00220: "Betriebsprotokoll",
    common_00221: "Laden gestartet",
    common_00222: "Laden beendet",
    common_00223: "Ladezeit",
    common_00224: "Volumen",
    common_00225: "Rabatt",
    common_00226: "Start/Stopp-Karte",
    common_00227: "Prepaidkarte",
    common_00228: "Online-ID-Karte",
    common_00229: "Einzelheit",
    common_00230: "Gruppenutzer",
    common_00231: "Drittanbieter",
    common_00232: "Gutscheinbestellung",
    common_00233: "Benachrichtigen",
    common_00234: "Bestätigen",
    common_00235: "Alle Standorte",
    common_00236: "Selbst betriebene Website",
    common_00237: "Franchisee",
    common_00238: "Standortname eingeben",
    common_00239: "Suchen",
    common_00240: "leeren",
    common_00241: "Erfolgreich hinzugefügt",
    common_00242: "h",
    common_00243: "Administrator",
    common_00244: "Nein",
    common_00245: "Alle Standorte",
    common_00246: "Passwörte ändern",
    common_00247: "Login mit alten Passwort",
    common_00248: "Login mit neuen Passwort",
    common_00249: "Buchstaben und Zahlen verwenden",
    common_00250: "Aktuelles Login-Passwort",
    common_00251: "Bitte altes Passwort eingeben",
    common_00252: "Bitte Passwort eingeben",
    common_00253: "Bitte Passwort erneut eingeben",
    common_00254: "Passwörter stimmen nicht überein",
    common_00255: "suchen",
    common_00256: "m",
    common_00257: "w",
    common_00258: "Profilbild",
    common_00259: "Nutzernahme",
    common_00260: "Geschlecht",
    common_00261: "钱包余额",
    common_00262: "Nutzerdetails",
    common_00263: "Ladevorgänge anzeigen",
    common_00264: "Keine Öffentlichkeit",
    common_00265: "Hinweis",
    common_00266: "Download abgebrochen",
    common_00267: "Download gestartet",
    common_00268: "Erneut eingeben",
    common_00269: "用户车辆认证",
    common_00270: "Schwarze",
    common_00271: "reset",
    common_00272: "Abschließen",
    common_00273: "Ja",
    common_00274: "Nein",
    common_00275: "Parameter sind leer",
    common_00276: "Bitte geben Sie eine positive Ganzzahl unter 100 ein",
    common_00277: "Betreibertarif",
    common_00278: "Aufladen",
    common_00279: "Einheit",
    common_00280: "erforderlich",
    common_00281: "Einstellung erfolgreich",
    common_00282: "Operation",
    common_00283: "Marke auswählen",
    common_00284: "Modell auswählen",
    common_00285: "Manuelle Eingabe",
    common_00286: "Fahrzeugmarke | Modell",
    common_00287: "Bitte vorherigen Schritt speichern",
    common_00288: "Aktion abbrechen",
    common_00289: "AC",
    common_00290: "DC",
    common_00291: "Gateway",
    common_00292: "deaktiviert",
    common_00293: "Aktiviert",
    common_00294: "einstellen",
    common_00295: "Ergebnis übertragen",
    common_00296: "Änderung wurde auf {allDevice} Geräte übertragen, {sucDevice} waren erfolgreich, {faiDevice} sind fehlgeschlagen",
    common_00297: "Gerätenummer",
    common_00298: "Gerätename",
    common_00299: "Erfolg",
    common_00300: "Fehler",
    common_00301: "Warten auf Antwort",
    common_00302: "off-line",
    common_00303: "Schließen",
    common_00304: "aktuelle Ladestation",
    common_00305: "Änderung übernehmen",
    common_00306: "Werbungmanagement",
    common_00307: "Wartung der Ausrüstung",
    common_00308: "Funktionen von Dritten",
    common_00309: "Entfernen",
    common_00310: "Wahl",
    common_00311: "zurück",
    common_00312: "Startdatum",
    common_00313: "Enddatum",
    common_00314: "Ladestation auswählen",
    common_00315: "Partner auswählen",
    common_00316: "Gerätstypen auswählen",
    common_00317: "Gruppe auswählen",
    common_00318: "OEM Plattform",
    common_00319: "Start",
    common_00320: "Betreiberplattform",
    common_00321: "vergrößern",
    common_00322: "schmal",
    common_00323: "Daten exportieren",
    common_00324: "Karte der Station",
    common_00325: "Fzg-MAC",
    common_00326: "Optional",
    common_00327: "Bitte auswählen",
    common_00360: "Basisinformationen",
    common_00361: "Ladestation mit Angebote",
    common_00362: "Gruppemitgliedermanagement",
    common_00363: "Ladevorgänge",
    common_00364: "Ladestatistik",
    common_00365: "Edit",
    common_10000: 'Typ of aktivität',
    common_10001: 'Anzahl der tätigkeiten',
    common_10002: 'Starte eine party',
    common_10003: 'Wave ist das titelblatt',
    common_10004: 'Zentrale.',
    common_10005: 'Wells, lass die kämpfe',
    common_10006: "Wave ist das titelblatt",
    common_10007: "kundenspezifisch",
    common_10008: "Titelseite vom schatzblatt",
    common_20008: 'Bitte geben sie einen namen ein',
    common_20009: "Kurzer vortrag.",
    common_10009: "Sind sie sicher, dass sie auf die alte v-geschwindigkeit zugreifen können?<br/>Ab dem 28. Februar 2022 ist der besitzer - typ v-2 nicht mehr auf kurs. Wissen sie nun bescheid!",
    //能源中心
    common_20010: 'Befehl ausführen',
    common_20011: 'Ein gewinn Bewachung',
    common_20012: 'Ein bolzen management',
    common_20013: 'Die energiestatistiken',
    common_20014: 'Speicher',
    common_20015: 'Statistik',
    common_20016: 'Zentrum für energie',
    common_20017: 'Max-Power mode',
    common_20018: 'Ökoökonomisches mode',
    common_20019: 'Auto mode',

    common_10010: "v2 entry",
    common_20020: 'verfügbar',
    common_20021: 'Energiespeicherung und Nachschub',
    common_20022: 'Energiespeicher entlädt sich',
    common_20023: 'Minute voll',
    common_20024: 'Aktueller Arbeitsmodus',
    common_20025: 'Modus',
    common_20026: 'Speichern und Laden',
    common_20027: 'Energiespeicher im Leerlauf',
    common_20028: 'Energiespeicherfehler',
    common_20029: 'Der Eco-Modus arbeitet gemäß Ihrem Energiespeichertarif während der Tal- und Spitzenzeiten. Sie müssen jedoch wählen, ob die normalen Periodenkosten näher an den Talperiodenkosten oder den Spitzenperiodenkosten liegen. ',
    common_20030: 'näher an der Talphase',
    common_20031: 'näher an den Stoßzeiten',
    common_20032: 'Es wurde festgestellt, dass Sie den Energiespeichertarif für diese Station nicht eingestellt haben',
    common_20033: 'Energiespeichertarif auswählen',
    common_20034: 'Neuer Stromspeichertarif',
    common_20035: 'Der Energiespeichertarif muss mit drei Spitzen-, Flach- und Talperioden oder vier Labelperioden mit Spitzen-, Spitzen-, Flach- und Talperioden festgelegt werden. ',
    common_20036: 'Bitte wählen Sie aus, welcher Zeitraum näher an den normalen Zeitkosten liegt',
    common_20037: 'Der Energiespeichertarif muss auf drei Peak-, Flat- und Valley-Zeiträume oder vier Label-Peak-, Peak-, Flat- und Valley-Zeiträume eingestellt werden',
    common_20038: 'Sparmodus',
    common_20039: 'Effizienter Modus',
    common_20040: 'Klimatisierung-Arbeitsmodus',
    common_20041: 'NZS Arbeitsmodus',
    common_20042: "The power demand of the vehicle is the first priority, and it is replenished as needed. The DCDC module is used first to charge the vehicle.",
    common_20043: "To make up for the insufficient output power of the station, use energy storage to provide power that is in short supply.",
    common_20044: "The lowest CPO cost can be achieved based on the peak and off-peak status of electricity prices issued by the platform.",
    common_20045: "Peak shaving and valley filling are used to recharge the energy storage when electricity prices are off-peak, and energy storage is used to charge the car during peak electricity prices.",
    common_20046: "Automatically control charging and discharging according to the electricity bill policy issued by the platform.",
    common_20047: "Automatically adjust the power demand and electricity price cost to meet the vehicle charging power while still having enough energy for energy storage and replenishment.",
    common_20048: "Strategieeinführung",
    common_20049: "Anwendungsszenario",
    common_20050: "Bitte geben Sie die Kartennummer ein",
    common_20051: "Bitte geben Sie die Quelle ein",
    common_20052: "Fehler",
    common_20053: "Standby",
    common_20054: "Heute",
    common_20055: "Gestern",
    common_20056: "Vergleich mit Vortag",
    common_20057: "Working Mode Introduction",
    common_22000: "WhiteList",
    common_22001: "SOC limit",


    // 工单
    common_31000: "Arbeitsauftragssystem",
    common_31001: "Arbeitsauftragsverwaltung",
    common_31002: "Arbeitsauftragsklassifikation",
    common_31003: "Arbeitsauftragsstatistik",
    common_31004: "Wissensdatenbank",
    common_31005: "Bitte binden Sie zuerst das offizielle Konto",
    common_31006: "Arbeitsauftrag einleiten",
    common_31007: "Automatische Benachrichtigung",
    common_40000: 'Wartung der Gerätelebensdauer',
    common_41000: 'Bitte richtig ausfüllen',
    common_41001: 'Automatischer Ticketauslöser',
    common_41002: 'Customize scenario policies',
    // 资费推送结果补充
    common_45000: "Ladestationsname",
    common_45001: "Gerätsanzahl",
    common_45002: "Push-ergebnisse",
    common_45003: "DC",
    common_45004: "AC",
    common_45005: "Net Zero",
    common_45006: "In Push",
    common_45007: "Push fehlgeschlagen",
    common_45008: "Die ganze gruppe",
    // 到期倒计时
    common_11000: "Es gibt keinen ladedienst mehr",
    common_11001: "Mit ablauf Von {0} tagen.",
    common_11002: "Automatische aufrechnung fehlfunktioniert und das laderaum wird nach {0} tagen abgesetzt",
    common_11003: "Das plattformen war fehlgeschlagen und der laderaum wurde {0} tage später abgesetzt",
    common_11004: "Die frist für die nutzung der plattform ist abgelaufen",
    common_11006: "Keine Daten",
    common_45009: "Ich höre auf.",
    common_45010: "Datenschutzerklärung",
    // 卡券
    common_00366: "卡券消耗分析",
    common_45011: "Können sie das überprüfen, bevor sie jemanden mit einer einladung anriefen",
    // 充电口状态
    common_60000: "Belegt",
    common_60002: "Abnormal",
    common_60003: "故障/异常/离线",
    common_60004: "Reservierung",
    common_60005: "Ich kontrolliere sie.",
    common_60006: "Eingefügt",
    common_60007: "Aufladung",
    common_60008: "Fahrzeug zu bewegen",
    common_60009: "Abgeschlossen",
    common_60010: "Microgrid Overview",
    common_60011: "Microgrid Settings",
    // 资费弹窗
    common_61000: "Tarifname",
    common_61001: "Bitte geben Sie einen Tarifnamen ein",
    common_61002: "Dieser Tarifname ist bereits belegt",
    common_61003: "Ladung",
    common_61004: "Speicher",
    common_61005: "Währung verwenden",
    common_61006: "Zeiten zufügen",
    common_61007: "Max.{0} Zeiten können hinzugefügt werden",
    common_61008: "Startzeit",
    common_61009: "Endzeit",
    common_61010: "Strom für das stromnetz({type}/{unit})",
    common_61011: "Elektrizität\n({type}/{unit})",
    common_61012: "Servicegebühr\n({type}/{unit})",
    common_61013: "Verzögerungsgebühr\n({type}/min)",
    common_61014: "Haupt-/Nebentarif Zeiten",
    common_61015: "Der gipfel des tal entspricht dem preis",
    common_61016: "Das nennt man gewöhnlich die spitze",
    common_61017: "Tip",
    common_61018: "Peak",
    common_61019: "Flat",
    common_61020: "Offpeak",
    common_61021: "Bitte Tarifstandard ausfüllen",
    common_61022: "Please select the time-period label",
    common_61023: "Tarifperiode muss von 0 bis 24 . beginnen",
    common_61024: "Bitte geben Sie den korrekten Tarifstandard ein",
    common_61025: "Aktion Erfolg",
    common_61026: "Aktion fehlgeschlagen",
    common_61027: "Schritt für schritt",
    common_61028: "Ich meine als nächstes",
    common_61029: "Undefiniert",
    common_61030: "Bitte ändern Sie zuerst den Standardtarif.",
    common_61031: "Öffentliche parkplätze",
    common_61032: "Gemeiner vorschuss",
    common_61033: "Gruppe",
    common_61034: "Paragraph",
    common_61035: "Kommunizieren",
    common_61036: "Bälle in strömen",
    common_61037: " ({0}Stations)",
    common_61038: "The application scope of the tariff is as follows. Are you sure to continue to modify?",
    common_61040: "Neu gebauter Tarif",
    common_61041: "Tarif ändern",
    common_61042: "0-If 0-24 hours is one single period type, please select the 'flat' time-period label",
    common_61043: "If 0-24 hours has multiple time period type, please select more than two time-period labels",
    common_61044: "The label of the time-period corresponding to the electricity price is incorrect, please check, it should be 'Peak > Mid-Peak > Off-Peak > Super Off-Peak'",
    common_61045: "Arbeitsmode",
    common_61046: "Charging Station Working Mode",
    common_61047: "Lern mehr",
    common_61048: "Charging Station Air Condition Mode",
    common_61049: "There is no grid tariff selected for the current station. Please select the grid tariff first and then switch the working mode",
    common_61050: 'There is only one time period lable for current grid tariff, and "Eco" and "Auto" modes cannot be selected',
    common_61051: 'The current grid tariff has only two time period labels, and the "Auto" mode cannot be selected',
    common_61052: "The current grid tariff period and working mode do not match. Please modify the grid tariff or working mode",
    common_61053: "Push erfolgreich",
    common_61054: "Gerät offline",
    common_61055: "Tarif auswählen",
    common_61056: "Die Ladestation muss eine Tarifart von {type} auswählen, eine solche Tarifvorlage existiert derzeit nicht, bitte erstellen Sie einen neuen Tarif.",
    common_61057: "finanzierung",
    common_61058: "Abschieben",
    common_61059: "Seite auswählen",
    common_61060: "Niedrige gebühren",
    common_61061: "Von austauschgebühren",
    common_61062: "Für öffentliche gelder",
    common_61063: "Gruppe",
    common_61064: "Eine dritte partei",
    common_61065: "Gilt die bestätigung der teilnahmegebühr",
    common_61066: "Seiten",
    common_61067: ".",
    common_61068: "Binde",
    common_61069: "Jetzt vielleicht",
    common_61070: "Suchen sie zuerst ein profil mit einem pfahl heraus",
    common_61071: "Wählt die gruppe aus",
    common_61072: "Bitte wählen sie eine dritte option",
    common_61073: "Work Mode Time SCH",
    common_61074: "Please select the time schedule of custom work mode",
    common_61075: "Angewandt",
    common_61076: "Nicht angewandt",
    common_61077: "bringen",
    common_61078: "Stromgebühren für den Netzanschluss({type}/{unit})",
    common_61080: "Photoren-effekte sind erlaubt",
    common_61081: "Wenn sie auswählen, bedeutet das, dass es erlaubt ist, vorhandene, nicht vorhandene materialien zu speichern Oder zu laden. Wenn sie jetzt nichts mehr sagen, wird strom wegen co2 nicht mehr zugelassen.",
    common_61082: "Gene gene für photovoltaik",
    common_61083: "Zeit für den einspeisenden photovoltaik-strom",
    common_61084: "PV settings",
    common_61085: "PV management",
    common_61086: "supplying energy",
    common_61087: "not supplying energy",
    common_61088: "Total energy supplied today",
    common_61163: "Langfristige Ladegrenze bei niedrigem Stromverbrauch",
    common_63013: "Ladegerät hinzufügen",

    // 自定义模式
    common_70000: "Custom",
    common_70001: "View all time schedules",
    common_70002: "CPO can freely configure the work mode according to your own experience",
    common_70003: "The electricity price of the power grid changes from time to time, and the busy and idle conditions of the stations change periodically, or the energy demand of the stations is adjusted periodically, such as customized energy dispatching.",
    common_70004: "New",
    common_70005: "Customized working mode time schedule (year)",
    common_70006: "Time Schedule Name",
    common_70007: "Please enter time schedule name",
    common_70008: "Add date range",
    common_70009: "Weekly schedule",
    common_70010: "Month",
    common_70011: "Day",
    common_70012: "Duplicate",
    common_70013: "Electric energy usage mode",
    common_70014: "Edit 0-24 hour schedule",
    common_70015: "Add date range",
    common_70016: "Add weekly schedule",
    common_70017: "NZS unterstützt Grid to Vehicle (kurz GtoV), Battery to Vehicle (kurz BtoV), Grid to Battery (kurz GtoB), Battery to Grid (kurz B2G), PV to Battery (kurz P2B). Bitte wählen Sie den Nutzungsmodus der elektrischen Energie durch Aufteilung der Zeiträume nach Erfahrung.",
    common_70018: 'Zum Beispiel bedeutet die Prioritätsanordnung von GtoV>BtoV>GtoB "die Batterie aufladen, wenn kein Auto geladen werden kann, das Stromnetz zuerst verwenden, wenn ein Auto geladen werden soll, und dann die Batterie verwenden"; Die Prioritätsanordnung von BtoV>GtoV>GtoB bedeutet "die Batterie aufladen, wenn kein Auto geladen werden kann, die Batterie zuerst verwenden, wenn ein Auto geladen wird, und dann das Stromnetz verwenden", und so weiter',
    common_70019: "Add time period",
    common_70020: "Electric energy usage mode",
    common_70021: "Please enter the name",
    common_70022: "to",
    common_70023: "Mon",
    common_70024: "Tue",
    common_70025: "Wed",
    common_70026: "Thu",
    common_70027: "Fri",
    common_70028: "Sat",
    common_70029: "Sun",
    common_70030: "Copy Succeeded",
    common_70031: "Paste",
    common_70032: "Copied",
    common_70033: "According to the date range and the weekly schedule in the date range, to formulate a schedule for the work mode of equipment in a year. After being pushed to NZS charger end, it will be carried out circularly every year according to the schedule. If there is any modification, it needs to be pushed to NZS charger end again, after the schedule is changed.",
    common_70034: "Incorrect fill the time and date",
    common_70035: "Electric energy usage mode cannot be empty",
    common_70036: "The period should start from 0 to end at 24",
    common_70037: "Please fill in the 0-24 hour schedule",
    common_63000: "Search ",
    common_63001: "Please enter the order number ",
    common_63002: "Please enter nickname/phone number/license plate number/frame number ",
    common_63003: "Please enter device name/device serial number ",
    common_63004: "Please enter the station name ",
    common_63005: "Device ",
    common_63006: "Please enter a community name ",
    common_63007: "Order ",
    common_63008: "Field Station ",
    common_63009: "Plug and Charge card ",
    common_63010: "Please enter content ",
    common_63011: "Already exists ",
    common_63012: "Invalid ",
    common_63013: "Add Device ",
    
    common_62000: "Eröffnung privilegierter Mitglieder",
    common_62001: "Verarbeitungsvorschläge:",
    common_62002: "Vorschläge zur Fehlerbehebung:",
    common_61134: "Los, druck",
    common_61147: "Auswahl für alle",
    common_61148: "Wir verstärken den austausch",
    common_61149: "Beide gleich auf den pfahl drücken",
    common_61150: "Überprüfen sie, ob die elektrische ladung an alle ladenzapfsäulen angebracht ist",
    common_61151: "Können wir die veränderung der elektrischen ladung an all den dc-außenposten übertragen",
    common_61152: "Totale zerstörung aktiviert",
    common_61153: "Einige seiten sind eventuell nicht erreichbar. Komm, noch mal",
    common_61154: "Schließ das fenster nicht, wenn du es kitzelt",

    common_61160: "Bitte füllen sie die batterie richtig auf",
    common_61161: "Bitte füllen sie die richtige zeit aus",
    common_80000: "Contains at least 6 characters, including at least 1 digit, 1 letter, and 1 special character",
    common_61145: "Fehlerhafte parameter",

    common_71100: "Admission Notice",
    common_71101: "Issue",
    common_71102: "Suggestion",
    common_71003: "Contact Name",
    common_71004: "Please add a issue",
    common_71005: "Description of actio",
    common_71014: "The associated information sent to the XCHARGE ERP system has not been set. Please contact the administrator or your sales consultant to set it up.",
    common_71015: "This operator has not set the associated information sent to the Kingdee ERP system. Please contact the administrator to set it up.",
    common_71016: "Use Accessories",
    common_71017: "Please enter the valid period",
    // menu
    common_71018: "Fleet management",
    common_71019: "Card management",
    common_71020: "Financial Overview",
    common_71021: "Ticket classification",
    common_71022: "menu",
    common_50005: "Stehen",
    common_80005: "Remaining",
    common_80006: "Exceeded",
    common_80007: "{type} currently does not support multi-rate tariff settings. Please modify the tariff to a single-rate tariff.",
    common_80008: "To modify the tariff",
    common_80009: "Please confirm that this tariff has not been applied to any stations with C6AM,C7NE,C7NA equipment, as C6AM,C7NE,C7NA currently does not support multi-rate tariff settings.",

    // 腾讯地图
    common_80010: "Ladestationskoordinate auswählen",
    common_80011: "Adresse, Straße, Hausname",
    common_80012: "suchen",
    common_80013: "Name",
    common_80014: "Adressangaben",
    common_80015: "aktuelle Standort",
    common_80016: "abbrechen",
    common_80017: "diese Standort benutzen",

    //急停恢复
    common_13001: "Emergency stop recovery",
    common_13002: "This operation requires the device firmware version to be above 3.0.0",
    common_13003: "Component issue detected. Please repair soon.",
    common_13004: "SuspendedEV",
    common_73033: "Password Security Enhancement",
    common_73034: "Dear user, we have detected that the password you are currently using is a weak password, which poses certain security risks. To better protect your personal information and account data, please change your password.",
    common_73035: "Username",
    common_73036: "Current Password",
    common_73037: "New Password",
    common_73038: "Confirm New Password",
    common_73039: "New password requirements:<br/>· Must be at least 6 characters long.<br/>· Must include letters, numbers, and special characters (e.g., !@#$%^&*()_+ etc.).",
    common_73040: "Please complete all fields",
    common_73041: "Passwords do not match, please re-enter",
    common_73042: "Incorrect password",
    common_73043: "Please enter a valid password",
}
