
export default {
    cardGroup_00001: "¿Confirmar la eliminación del grupo de tarjetas de crédito {title}?",
    cardGroup_00002: "Se enviaron {allDevice} dispositivos en total, {sucDevice} tuvo éxito y {faiDevice} falló.",
    cardGroup_00003: "Modo de operación|(elija uno)",
    cardGroup_00004: "{id}(Perdido)",
    cardGroup_00005: "¡No se puede leer la tarjeta! Consejo: asegúrese de que el lector de tarjetas esté conectado y coloque la tarjeta NFC en el lector de tarjetas.",
    cardGroup_00006: "La tarjeta no está vacía",
    cardGroup_00007: "La tarjeta está vacía",
    cardGroup_00008: "Error al escribir en la tarjeta: {msg}",
    cardGroup_00009: "Detalles del grupo de tarjetas de cobro",
    cardGroup_00010: "Gestión de tarjetas de cobro del operador",
    cardGroup_00011: "Grupo de tarjetas de cobro",
    cardGroup_00012: "Información del grupo",
    cardGroup_00013: "Estaciones",
    cardGroup_00014: "Enviar lista blanca",
    cardGroup_00015: "Verificar registros de recarga",
    cardGroup_00016: "Transacciones",
    cardGroup_00017: "Eliminar grupo de tarjetas",
    cardGroup_00018: "Operación cancelada",
    cardGroup_00019: "Detalles del grupo de tarjetas de cobro - Grupo de tarjetas en línea",
    cardGroup_00020: "Detalles del grupo de tarjetas de carga - Grupo de tarjetas sin conexión",
    cardGroup_00021: "Detalles del grupo de tarjetas de carga - Grupo de tarjetas super",
    cardGroup_00022: "Si el dispositivo está fuera de línea, después de aplicar la lista blanca, se pueden admitir tarjetas de carga de este grupo de tarjetas de carga para iniciar la carga. Después de crear y perder una tarjeta, se debe aplicar nuevamente la lista blanca.",
    cardGroup_00023: "Reintentar",
    cardGroup_00024: "N.º de dispositivo",
    cardGroup_00025: "Nombre del dispositivo",
    cardGroup_00026: "Resultados de la inserción",
    cardGroup_00027: "Éxito",
    cardGroup_00028: "Falso",
    cardGroup_00029: "Esperando respuesta",
    cardGroup_00030: "Cerrar",
    cardGroup_00031: "Error de inserción, no hay sitio de carga relacionado",
    cardGroup_00032: "Error de inserción",
    cardGroup_00033: "Enviar lista blanca",
    cardGroup_00034: "Grupo de tarjetas en línea",
    cardGroup_00035: "Grupo de tarjetas fuera de línea",
    cardGroup_00036: "Grupo de tarjetas super",
    cardGroup_00037: "Nombre del grupo de tarjetas de carga",
    cardGroup_00038: "Modificar",
    cardGroup_00039: "Guardar",
    cardGroup_00040: "Cancelar",
    cardGroup_00041: "Iniciar sesión en la cuenta",
    cardGroup_00042: "Contraseña de inicio de sesión",
    cardGroup_00043: "Descargar NFC (APP)",
    cardGroup_00044: "Nueva tarjeta de identificación",
    cardGroup_00045: "Se ingresan las tarjetas de identificación de los usuarios. El programa lector de tarjetas NFC debe reconocer el número de la tarjeta. La tarjeta de identificación se puede recargar. No es necesario configurar el cargador para pasar la tarjeta para cargarla.",
    cardGroup_00046: "Nueva tarjeta plug-and-charge",
    cardGroup_00047: "Se han introducido tarjetas plug-and-charge para los usuarios. El número de tarjeta debe ser el MAC del vehículo. La tarjeta plug-and-charge se puede recargar. Es necesario configurar el cargador para habilitar la carga plug-and-play.",
    cardGroup_00048: "Introduzca el número de su tarjeta, nombre de usuario, número de móvil y número de referencia",
    cardGroup_00049: "Tipo de tarjeta",
    cardGroup_00050: "Número de tarjeta",
    cardGroup_00051: "Número auxiliar",
    cardGroup_00052: "Nombre de usuario (número de móvil)",
    cardGroup_00053: "Número de matrícula del vehículo",
    cardGroup_00054: "Tipo de tarjeta",
    cardGroup_00055: "Tarjeta de inicio/parada sin conexión",
    cardGroup_00056: "Tarjeta de valor almacenado sin conexión",
    cardGroup_00057: "Tarjeta de identidad en línea",
    cardGroup_00058: "Tarjeta PnC",
    cardGroup_00059: "Tarjeta de configuración",
    cardGroup_00060: "Saldo",
    cardGroup_00061: "Operar",
    cardGroup_00062: "Detalle",
    cardGroup_00063: "Guarde la operación anterior",
    cardGroup_00064: "El parámetro está vacío",
    cardGroup_00065: "Nueva tarjeta de identificación",
    cardGroup_00066: "Origen",
    cardGroup_00067: "Nombre del contacto",
    cardGroup_00068: "Celular",
    cardGroup_00069: "Clave",
    cardGroup_00070: "Monto de la recarga",
    cardGroup_00071: "Contraseña de inicio de sesión",
    cardGroup_00072: "Tarjeta de recarga realizada correctamente",
    cardGroup_00073: "Permitido",
    cardGroup_00074: "「Gestión de tarjetas de cobro」",
    cardGroup_00075: "Grupo de tarjetas de cobro",
    cardGroup_00076: "「Detalle」",
    cardGroup_00077: "Realice las siguientes operaciones en:",
    cardGroup_00078: "1、Recargar para la tarjeta de cobro de tipo memoria;",
    cardGroup_00079: "2、Verificar registro de recarga;",
    cardGroup_00080: "3、Modificar detalles de contacto de la tarjeta de cobro;",
    cardGroup_00081: "Confirmar",
    cardGroup_00082: "Siguiente paso",
    cardGroup_00083: "Ingrese el Nro. de identidad",
    cardGroup_00084: "Ingrese el Nro. correcto",
    cardGroup_00085: "El idioma chino no debe estar incluido en el Nro. de tarjeta. ",
    cardGroup_00086: "Por favor, introduzca la fuente de la tarjeta",
    cardGroup_00087: 'Fuente no autorizada para rellenar "PnC"',
    cardGroup_00088: "Por favor, introduzca el número de matrícula correcto",
    cardGroup_00089: "Por favor, introduzca el número de móvil correcto",
    cardGroup_00090: "Formato incorrecto del importe de recarga",
    cardGroup_00091: "Por favor, introduzca la contraseña",
    cardGroup_00092: "Introducción de la tarjeta de identificación en línea con éxito",
    cardGroup_00093: "Nueva tarjeta plug-and-charge",
    cardGroup_00094: "Verifique el VIN del vehículo que coincide con el usuario",
    cardGroup_00095: "Complete la información de la tarjeta",
    cardGroup_00096: "Complete la creación",
    cardGroup_00097: "Este número de móvil coincide con varios EV ({type}) en 2 semanas, ¡identifique el EV manualmente ({type})!",
    cardGroup_00098: "Nombre de usuario|Número de móvil",
    cardGroup_00099: "VIN del EV",
    cardGroup_00100: "Último|Hora de finalización de la carga",
    cardGroup_00101: "Último|Sitio de carga",
    cardGroup_00102: "Confirmar",
    cardGroup_00103: "Anterior",
    cardGroup_00104: "Siguiente",
    cardGroup_00105: "PnC",
    cardGroup_00106: "Vincular estaciones restringidas",
    cardGroup_00107: "Añadir sitio de carga",
    cardGroup_00108: "Estación",
    cardGroup_00109: "CPO",
    cardGroup_00110: "Detalles del sitio",
    cardGroup_00111: "Eliminar",
    cardGroup_00112: "¿Confirmar la eliminación de este sitio?",
    cardGroup_00113: "Recordatorio",
    cardGroup_00114: "Confirmar",
    cardGroup_00115: "Ingrese el nombre del sitio, el nombre del operador",
    cardGroup_00116: "Estado del sitio de carga",
    cardGroup_00117: "Abierto al público",
    cardGroup_00118: "Cargador",
    cardGroup_00119: "AC",
    cardGroup_00120: "DC",
    cardGroup_00121: "Agregar",
    cardGroup_00122: "Agregado",
    cardGroup_00123: "Habilitado",
    cardGroup_00124: "No disponible",
    cardGroup_00125: "Disponible",
    cardGroup_00126: "No abierto",
    cardGroup_00127: "MAC del EV",
    cardGroup_00128: "Consultar la MAC del EV que coincidió con el usuario",
    cardGroup_10000: "Nuevas tarjetas de recarga",
    cardGroup_10001: "Límite de SOC para recarga de tarjetas en grupo",
    cardGroup_10002: "Ingrese un número entero de 1 a 100",
    cardGroup_10003: "Descargar plantilla",
    cardGroup_10004: "Importar tarjetas por lotes",
    cardGroup_10005: "Importación por lotes de tarjetas de recarga automática",
    cardGroup_10006: "1. Descargue primero la plantilla de importación por lotes y complete la información relacionada con la tarjeta de carga en la plantilla.",
    cardGroup_10007: "Si importa tarjetas Plug-and-Charge en bloque, ingrese el número de tarjeta en la plantilla según la MAC del vehículo utilizada en la carga del vehículo.",
    cardGroup_10008: "Si importa tarjetas de identificación en bloque, ingrese el número de tarjeta reconocido por el lector de tarjetas NFC en la plantilla.",
    cardGroup_10009: "2. Seleccione el botón correspondiente para importar la tarjeta de carga según el tipo de tarjeta de carga.",
    cardGroup_10010: "¡Use la plantilla para la importación por lotes!",
    cardGroup_10011: "En el archivo de plantilla, ingrese correctamente el número de licencia del vehículo y del móvil para las siguientes tarjetas.",
    cardGroup_10012: "En el archivo de plantilla, las siguientes tarjetas ya existen en la plataforma.",
    cardGroup_10013: "{totalCount} tarjetas en total, se importaron con éxito {successCount} tarjetas.",
    cardGroup_10014: "{totalCount} tarjetas en total, se importaron con éxito {successCount} tarjetas, no se pudieron importar {failCount} tarjetas.",
    cardGroup_10015: "En el archivo de plantilla, las siguientes tarjetas ya existen en otros grupos de tarjetas en la plataforma.",
    cardGroup_10016: "grupos de tarjetas: {0}",
    cardGroup_10017: "tarjetas:",



    support_u2_card: "Compatible con tarjetas de valor almacenado sin conexión",
    support_u1_card: "Compatible con tarjetas de inicio/detención sin conexión",
    working_mode_title: "Mensaje de bienvenida| (mensaje en la pantalla del cargador)",
    config_card_welcome: "Bienvenido a la estación de carga XCharge",
    config_card_tip_1: "Corriente nominal| (la corriente real se basa en el equipo del puesto de carga)",
    addTimeInterval: "Agregar franja horaria",
    addTimeIntervalPrompt: "Máx. 12 franjas horarias",
    beginTime: "Hora de inicio",
    endTime: "Hora de cierre",
    electrical_fee: "Tarifa de electricidad",
    service_fee: "Tarifa de servicio",
    operation: "Operación",
    electric_unit: "kwh",
    config_card_wifi_ssid: "SSID de Wi-Fi",
    config_card_wifi_pass: "Contraseña de Wi-Fi",
    select_input: "Opcional",
    elec_lock: "Cerradura electromagnética",
    elec_lock_status: "Estado de la cerradura electromagnética",
    unavailable: "No disponible",
    available: "Disponible",
    unlock_before_pay: "Desbloquear cuando esté completamente cargado",
    unlock_after_pay: "Desbloquear después de la liquidación",
    radar: "Radar",
    radar_status: "Estado del radar",
    config_card_make_success: "Tarjeta de configuración creada con éxito",
    add_charge_card_remind_3: "1、Recargar una tarjeta de crédito de tipo memoria",
    add_charge_card_remind_4: "2、Buscar registro de recarga",
    add_charge_card_remind_5: "3、Cambiar los datos de contacto de la tarjeta de crédito",
    finish: "Finalizar",
    nextStep: "Siguiente",
    preStep: "Anterior",
    ac: "CA",
    dc: "CC",
    user_control_add: "Agregar",
    user_control_added: "Agregado",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
    open: "Disponible",
    unopen: "No disponible",
    charge_card_operation_remind_1: "¡No se puede leer la tarjeta! Recordatorio: asegúrese de que el lector de tarjetas esté conectado y coloque la tarjeta NFC en el lector de tarjetas。",
    charge_card_operation_remind_2: "La tarjeta no está vacía",
    charge_card_operation_remind_3: "La tarjeta está vacía",
    charge_card_operation_remind_4: "La tarjeta no forma parte de una cuenta corriente de grupo",
}