export default  {
  orderTriggerOpt_00001: 'Nuevo disparador',
  orderTriggerOpt_00002: 'Nombre del activador',
  orderTriggerOpt_00003: 'Si el disparador está habilitado',
  orderTriggerOpt_00004: 'Todos',
  orderTriggerOpt_00005: 'Habilitar',
  orderTriggerOpt_00006: 'Activador de estadísticas de operación',
  orderTriggerOpt_00007: 'Activador del informe del cargador',
  orderTriggerOpt_00008: 'Categoría de activación de ticket',
  orderTriggerOpt_00009: 'Condición de activación',
  orderTriggerOpt_00010: 'Procesador de tickets',
  orderTriggerOpt_00011: 'Seguidor de ticket',
  orderTriggerOpt_00012: 'Hora de notificación del ticket',
 orderTriggerOpt_00013: 'Editar disparador',
 orderTriggerOpt_00014: 'Cuando el equipo informa una falla o una anomalía del equipo, si el disparador no vuelve a la normalidad después del intervalo de tiempo establecido por el disparador, iniciará automáticamente un Ticket de resolución de problemas. (Nota: Algunas fallas del equipo se recuperarán automáticamente. Si el intervalo de tiempo se establece demasiado corto, es fácil generar falsas alarmas)',
 orderTriggerOpt_00015: 'Todos',
 orderTriggerOpt_00016: 'Categorías de tickets',
 orderTriggerOpt_00017: 'Número de serie del equipo', 
 orderTriggerOpt_00018: 'Todos los equipos por fabricante',
 orderTriggerOpt_00019: 'Todos los modelos',
 orderTriggerOpt_00020: 'Ticket pendiente',
 orderTriggerOpt_00021: 'Procesando ticket',
 orderTriggerOpt_00022: 'El ticket ha sido resuelto',
 orderTriggerOpt_00023: 'El ticket ha sido cerrado', 
 orderTriggerOpt_00024: 'Lista de tickets',
 orderTriggerOpt_00025: 'Boleto de Exportación',
  orderTriggerOpt_00026: 'Número de billete',
  orderTriggerOpt_00027: 'Título del billete',
  orderTriggerOpt_00028: 'Categorías',
  orderTriggerOpt_00029: 'Prioridad',
  orderTriggerOpt_00030: 'Estado',
  orderTriggerOpt_00031: 'Estación problemática',
  orderTriggerOpt_00032: 'Procesador',
  orderTriggerOpt_00033: 'Originador',
  orderTriggerOpt_00034: 'Hora de inicio del ticket',
  orderTriggerOpt_00035: 'Hora de actualización',
  orderTriggerOpt_00036: 'Fuente del problema',
  orderTriggerOpt_00037: 'Operación',
  orderTriggerOpt_00038: 'Volver al ticket',
  orderTriggerOpt_00039: 'Procesado',
  orderTriggerOpt_00040: 'Orden',
  orderTriggerOpt_00041: 'Boleto de transferencia',
  orderTriggerOpt_00042: 'Cerrar el ticket',
  orderTriggerOpt_00043: 'Agregar a la base de conocimientos',
  orderTriggerOpt_00044: 'Detalles',
  orderTriggerOpt_00045: 'Muy urgente',
  orderTriggerOpt_00046: 'Urgente',
  orderTriggerOpt_00047: 'General',
  orderTriggerOpt_00048: 'Pendiente',
  orderTriggerOpt_00049: 'Procesando',
  ordenTriggerOpt_00050: 'Procesado',
  orderTriggerOpt_00051: 'cerrado',
  orderTriggerOpt_00052: 'Observaciones',
  orderTriggerOpt_00053: 'Por favor ingrese el contenido',
  orderTriggerOpt_00054: 'Solución',
  orderTriggerOpt_00055: 'Manejador de transferencias',
  orderTriggerOpt_00056: 'Aceptar la solicitud',
  orderTriggerOpt_00057: 'Si se debe confirmar el boleto',
  orderTriggerOpt_00058: 'Confirmar recepción del billete',
  orderTriggerOpt_00059: 'Boleto de transferencia',
  orderTriggerOpt_00060: 'Confirmar transferencia',
  orderTriggerOpt_00061: 'Motivo de la transferencia',
  orderTriggerOpt_00062: 'Cerrar el ticket',
 orderTriggerOpt_00063: '¿Está seguro de cerrar el Ticket?',
 orderTriggerOpt_00064: 'Confirmar cierre',
 orderTriggerOpt_00065: 'Motivo de cierre',
 orderTriggerOpt_00066: 'Devolver al Ticket',
 orderTriggerOpt_00067: '¿Está seguro de devolver el Ticket?',
 orderTriggerOpt_00068: 'Confirmar return',
 orderTriggerOpt_00069: 'Motivo de la devolución',
 orderTriggerOpt_00070: 'El ticket ha sido resuelto',
 orderTriggerOpt_00071: '¿Confirma que el ticket ha sido resuelto?',
 orderTriggerOpt_00072: 'Confirmación de resolución',
 orderTriggerOpt_00073: 'Por favor, rellene todos los campos', orderTriggerOpt_00074: 'Operación exitoso',
  orderTriggerOpt_00075: 'Llegó',
  orderTriggerOpt_00076: 'Guardar correctamente',
  orderTriggerOpt_00077: 'Nombre de la estación',
  orderTriggerOpt_00078: 'Estado de la estación',
  orderTriggerOpt_00079: 'Agregar estación',
  orderTriggerOpt_00080: 'Condición de activación de la estación',
  orderTriggerOpt_00081: 'Continuo',
  orderTriggerOpt_00082: 'día(s)',
  orderTriggerOpt_00083: 'Fin',
  orderTriggerOpt_00084: 'los conectores no tienen transacción',
 orderTriggerOpt_00085: 'Seleccione un sitio',
 orderTriggerOpt_00086: 'Ingrese la condición de activación del sitio',
 orderTriggerOpt_00087: 'Eliminación múltiple',
 orderTriggerOpt_00088: 'Por favor complete el formulario',
 orderTriggerOpt_00089: 'Transacciones anormales',
 orderTriggerOpt_00090: 'Después de que el equipo informe una falla/anormal',
 orderTriggerOpt_00091: 'Descripción del problema',
 orderTriggerOpt_00092: 'Puerto de carga de estadísticas de pedidos anormales',
 orderTriggerOpt_00093: 'Estadísticas de pedidos anormales-usuario',
 orderTriggerOpt_00094: 'Sin cargador',
 orderTriggerOpt_00095: 'Disparador de equipo',
 orderTriggerOpt_00096: 'Seleccionado',
 orderTriggerOpt_00097: 'Ingrese un número entero ≥ 0',
 orderTriggerOpt_00098: 'El disparador contiene códigos de error',
 orderTriggerOpt_00099: "Frecuencia de activación",
 orderTriggerOpt_000100: "Todos",
 orderTriggerOpt_000101: "día",
 orderTriggerOpt_000102: "Cada cargador informará sólo de cada falla",
 orderTriggerOpt_000103: "Cada cargador informará sólo de todas las fallas",
 orderTriggerOpt_000104: "veces",
 orderTriggerOpt_000105: "Predeterminado",
 orderTriggerList_00001: "Cargador fuera de línea",
 orderTriggerList_00002:"Botón de parada de emergencia presionado",
 orderTriggerList_00003:"Módulo de alimentación en alarma",
 orderTriggerList_00004:"Error en la operación de activación/desbloqueo del bloqueo electromagnético",
 orderTriggerList_00005:"Fabricante del cargador",
 orderTriggerList_00006:"Configuración del activador",
 orderTriggerList_00007:"Plantilla de activación",
}