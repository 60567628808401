export default {
  coaStrategy_00001: "策略名称",
  coaStrategy_00002: "策略列表",
  coaStrategy_00003: "从模板中新建策略",
  coaStrategy_00004: "新建策略",
  coaStrategy_00005: "本月触发次数",
  coaStrategy_00006: "更新时间",
  coaStrategy_00007: "创建人",
  coaStrategy_00008: "策略开关",
  coaStrategy_00009: "场站应用范围",
  coaStrategy_00010: "",
  coaStrategy_00011: "场站应用范围",
  coaStrategy_00012: "选择模版",
  coaStrategy_00013: "根据桩不可用故障码触发工单",
  coaStrategy_00014: "实时监控充电桩状态，一旦检测到不可用故障码，立即自动生成维修工单，确保快速响应与修复。",
  coaStrategy_00015: "长时低功充电限制",
  coaStrategy_00016: "为避免车锅长时间处于功率充电状态，系統白动停充限制或通知管理员处理，提升充电效率与用户体验。",
  coaStrategy_00017: "限制充电桩最大允许充电SOC",
  coaStrategy_00018: "根据电池健康管理及安全策略，设定充电桩最大充电状态 (SOC）限制，保护电池寿命，预防过充风险。",
  coaStrategy_00019: "查找呆滞桩并触发工单",
  coaStrategy_00020: "定期扫描并分析充电订单数据，识别长时间未使用的呆滞桩，自动触发维保工单，优化资源配管。",
  coaStrategy_00021: "查找订单异常桩并触发工单",
  coaStrategy_00022: "定期扫描并分析充电订单数据，识别异常订单较多的充电桩，自动触发维保工单。",
  coaStrategy_00023: "请选择站点",
};
