export default {
  orderlyAnalogCharging_00001: "Operación de simulación de carga ordenada de bus",
  orderlyAnalogCharging_00002: "Ayuda",
  orderlyAnalogCharging_00003: "Carga del vehículo",
  orderlyAnalogCharging_00004: "Conducción del vehículo",
  orderlyAnalogCharging_00005: "Limitar la potencia de carga total",
  orderlyAnalogCharging_00006: "Potencia de carga real",
  orderlyAnalogCharging_00007: "Código del vehículo",
  orderlyAnalogCharging_00008: "SOC mínimo requerido para la salida (%)",
  orderlyAnalogCharging_00009: "Hora de salida",
  orderlyAnalogCharging_00010: "Hora de regreso",
  orderlyAnalogCharging_00011: "Electricidad",
  orderlyAnalogCharging_00012: "1. Cuando la pila de carga está fuera de línea, comience a cargar. La plataforma no puede controlar la carga general. Tenga cuidado< Br /> 2. La tarifa de inactividad se establece para la tarifa de la estación y la función de carga ordenada del autobús no se puede utilizar< Br /> 3. El conector se desconecta cuando el vehículo se está cargando y la carga debe iniciarse manualmente nuevamente",
  }