export default {
    eqDetails_00001: 'Aplicar config',
    eqDetails_00002: 'Detalle del cargador',
    eqDetails_00003: 'Inf. del cargador',
    eqDetails_00004: 'Configuración del cargador',
    eqDetails_00005: 'Gestión del punto de carga',
    eqDetails_00006: 'Mant. del cargador',
    eqDetails_00007: 'Reiniciar',
    eqDetails_00008: 'Se ha enviado el comando de reinicio del cargador, el proceso de reinicio tardará aproximadamente 5 minutos. Por favor, permanezca en esta página y no la actualice.',
    eqDetails_00009: 'Confirmar',
    eqDetails_00010: 'Iniciar carga',
    eqDetails_00011: 'El cargador se reinició correctamente',
    eqDetails_00012: 'Reinicio remoto',
    eqDetails_00013: 'Error al reiniciar el cargador',
    eqDetails_00014: 'Nombre del cargador',
    eqDetails_00015: 'Estación de carga a la que pertenece',
    eqDetails_00016: 'Verificar',
    eqDetails_00017: 'Número de serie',
    eqDetails_00018: 'Fabricante',
    eqDetails_00019: 'Modelo del cargador',
    eqDetails_00020: 'Por favor, Introduzca el nombre del cargador',
    eqDetails_00021: 'Cargador disponible, bloqueo electromagnético y otras configuraciones aplicarán automáticamente la configuración al cargador después de la modificación, debe esperar la respuesta del cargador antes de actualizar la página',
    eqDetails_00022: 'Aplicar config',
    eqDetails_00023: 'Aplicar al cargador',
    eqDetails_00024: 'Cargador disponible',
    eqDetails_00025: 'Cerradura electromagnética',
    eqDetails_00026: 'No se pudo bloquear, se puede cargar',
    eqDetails_00027: 'Radar de zona de aparcamiento',
    eqDetails_00028: 'Cargador disponible',
    eqDetails_00029: 'Hora de inicio',
    eqDetails_00030: 'Hora de finalización',
    eqDetails_00031: 'Corriente de carga máx. (A)',
    eqDetails_00032: 'Corriente de carga máx. potencia de carga (kW)',
    eqDetails_00033: 'Error en la aplicación',
    eqDetails_00034: 'El cargador está fuera de línea',
    eqDetails_00035: 'Esperando la respuesta del cargador',
    eqDetails_00036: 'Aplicación exitosa',
    eqDetails_00037: 'Fecha configurada incorrectamente',
    eqDetails_00038: 'El módulo de potencia requiere mantenimiento. Aviso de tiempo: horas de carga acumuladas desde el tiempo de reinicio',
    eqDetails_00039: '{hours}horas',
    eqDetails_00040: 'Tiempo de reinicio:',
    eqDetails_00041: 'Normal',
    eqDetails_00042: 'Error',
    eqDetails_00043: '¿Está seguro de haber completado este mantenimiento para este módulo de alimentación del puesto de carga? Después de hacer clic en Aceptar, el módulo de alimentación requiere un mensaje de mantenimiento para volver a programar el tiempo.',
    eqDetails_00044: 'Recordatorio',
    eqDetails_00045: 'Nombre del conector',
    eqDetails_00046: 'Código QR de carga',
    eqDetails_00047: 'Código QR dinámico',
    eqDetails_00048: 'Código QR fijo',
    eqDetails_00049: 'Actualizar a código QR fijo',
    eqDetails_00050: 'Código QR personalizado',
    eqDetails_00051: 'Control remoto',
    eqDetails_00052: 'Reiniciar conector',
    eqDetails_00053: 'Finalizar carga',
    eqDetails_00054: 'Restablecer a disponible',
    eqDetails_00055: 'Estado del conector',
    eqDetails_00056: 'Dispositivo de gestión de plazas de aparcamiento',
    eqDetails_00057: 'N/A',
    eqDetails_00058: 'Bloqueo de plaza de aparcamiento controlado por estaca',
    eqDetails_00059: 'Bloqueo de plaza de aparcamiento controlado por la nube',
    eqDetails_00060: 'Información del dispositivo de gestión de plazas de aparcamiento',
    eqDetails_00061: 'Por favor, introduzca el código del dispositivo',
    eqDetails_00062: 'Habilitar dispositivo de gestión de plazas de aparcamiento',
    eqDetails_00063: 'Control remoto del dispositivo de gestión de plazas de aparcamiento',
    eqDetails_00064: 'Subir',
    eqDetails_00065: 'Descender',
    eqDetails_00066: 'Usuario de facturación de ocupación de plaza de aparcamiento actual',
    eqDetails_00067: 'Hora de inicio de ocupación',
    eqDetails_00068: 'Detalles del usuario',
    eqDetails_00069: 'Finalización manual de la facturación de este cargo por ocupación',
    eqDetails_00070: 'Descenso del código QR del dispositivo de gestión de plazas de aparcamiento',
    eqDetails_00071: 'Generar código QR fijo',
    eqDetails_00072: 'Ocupando',
    eqDetails_00073: 'No disponible',
    eqDetails_00074: 'Fuera de línea',
    eqDetails_00075: 'Con error',
    eqDetails_00076: 'Reservado',
    eqDetails_00077: 'Disponible',
    eqDetails_00078: 'Preparando',
    eqDetails_00079: 'Cargando',
    eqDetails_00080: 'Vehículo eléctrico a mover',
    eqDetails_00081: 'Terminando',
    eqDetails_00082: 'Conector',
    eqDetails_00083: 'Iniciar carga',
    eqDetails_00084: '¡Guarde la operación anterior!',
    eqDetails_00085: 'El espacio actual tiene usuarios a los que se les está facturando por la ocupación del espacio, ¡modifique más tarde!',
    eqDetails_00086: 'El parámetro está vacío',
    eqDetails_00087: 'Máximo 20 caracteres',
    eqDetails_00088: 'Se ha enviado el comando de inicio de carga',
    eqDetails_00089: 'Se ha enviado el comando de finalización de carga',
    eqDetails_00090: 'Enviado correctamente',
    eqDetails_00091: 'Actualizar a código QR fijo',
    eqDetails_00092: 'El código QR se generó correctamente',
    eqDetails_00093: 'Disponible',
    eqDetails_00094: 'No disponible',
    eqDetails_00095: 'Bloqueo de plaza de aparcamiento controlado por la nube',
    eqDetails_00096: 'Bloqueo de plaza de aparcamiento controlado por estaca',
    eqDetails_00097: 'Por favor ingrese el contenido del código QR, el código QR que ha configurado se mostrará en la pila',
    eqDetails_00098: 'Nota: Solo se pueden generar códigos QR fijos y no se pueden restaurar a su estado original',
    eqDetails_00099: '¡El código QR personalizado no puede estar vacío!',
    eqDetails_00100: 'El código QR personalizado está vacío',
    eqDetails_00101: 'Error de configuración',
    eqDetails_00102: 'La longitud no puede superar los {0} caracteres',
    eqDetails_00103: 'Duración de carga actual (minutos)',
    eqDetails_00104: 'Tarifa por inactividad precargada actual ({0})',
    eqDetails_00105: 'Tarifa por inactividad revisada ({0})',
    eqDetails_00106: 'Por favor, introduzca el importe',
    eqDetails_00107: 'Por favor, introduzca la tarifa por inactividad revisada',
    eqDetails_00108: 'Por favor, introduzca el número correcto',
    eqDetails_00109: 'La finalización manual de la facturación de este cargo por ocupación fue exitosa',
    eqDetails_00110: 'La cantidad máxima de códigos QR fijos es 100.000 y no se puede recuperar, proceda con precaución, ¿está seguro de actualizar?',
    eqDetails_00111: 'Actualización exitosa',
    eqDetails_00112: 'Listo',
    eqDetails_00113: '¿Generó un código QR fijo para el dispositivo de gestión de estacionamiento de automóviles?',
    eqDetails_00114: 'No hay código QR en este momento',
    eqDetails_00115: 'Si el dispositivo está fuera de línea, solo los usuarios de la lista blanca pueden comenzar a cargar',
    eqDetails_00116: 'Borrar lista blanca',
    eqDetails_00117: 'Enviar lista blanca',
    eqDetails_00118: 'ID del cargador',
    eqDetails_00119: '',
    eqDetails_10000: '¿Está seguro de haber completado este mantenimiento para los {0} puntos de carga? Después de hacer clic en Aceptar, se vuelven a programar todos los {0} recordatorios de servicio requeridos. ',
    eqDetails_10001: 'Modificar todas las válvulas',
    eqDetails_10002: 'hora',
    eqDetails_10003: 'tiempo',
    eqDetails_10004: 'Consejos',
    eqDetails_10005: 'Aplicar a todos',
    eqDetails_10006: 'Restablecer todo',
    eqDetails_10007: 'Tiempo de servicio',
    eqDetails_10008: 'Usado',
    eqDetails_10009: 'Umbral de solicitud de tiempo de mantenimiento:',
    eqDetails_10010: 'El tiempo de uso acumulado desde el tiempo de restablecimiento es mayor o igual a',
    eqDetails_10011: 'Complete',
    eqDetails_10012: 'Módulo de potencia',
    eqDetails_10013: 'Batería de almacenamiento',
    eqDetails_10014: 'Ventilador',
    eqDetails_10015: 'Conector',
    eqDetails_10016: 'Contactor',
    eqDetails_10017: 'Tirador de cables',
    eqDetails_10018: 'Protector de sobretensión',
    eqDetails_10019: 'Pantalla LCD',
    eqDetails_10020: 'Resistencia de purga',
    eqDetails_10021: 'Carga forzada',
    eqDetails_10022: 'Descarga forzada',
    eqDetails_10023: 'Deshabilitado',
    eqDetails_10024: 'Ejecución automática',
    eqDetails_10025: 'Cargador info',
    eqDetails_10026: 'Configuración del cargador',
    eqDetails_10027: 'Acerca de la configuración',
    eqDetails_10028: 'Conectores',
    eqDetails_10029: 'Disponible',
    eqDetails_10030: 'Nombre del conector',
    eqDetails_10031: 'Modo de carga de la batería',
    eqDetails_10032: 'Modo de funcionamiento del aire acondicionado',
    eqDetails_10033: 'Configuración del modo de funcionamiento',
    eqDetails_10034: 'Baterías NZS',
    eqDetails_10035: 'Batería',
    eqDetails_10036: 'Nombre del armario de la batería',
    eqDetails_10037: 'Capacidad del gabinete de la batería (kWh)',
    eqDetails_10038: 'Tiempo de ciclo de la batería (veces)',
    eqDetails_10039: 'SOC del gabinete de la batería (%)',
    eqDetails_10040: 'SOH del gabinete de la batería (%)',
    eqDetails_10041: 'Reiniciar',
    eqDetails_10042: 'Enviar lista blanca',
    eqDetails_10043: 'Registros de fallos',
    eqDetails_10044: 'Descripción de la configuración',
    eqDetails_10045: 'El interruptor del cargador, el bloqueo electromagnético y otras configuraciones aplicarán automáticamente la configuración al cargador después de la modificación; debe esperar la respuesta del cargador antes de actualizar la página',
    eqDetails_10046: 'Si el cargador está fuera de línea, solo los usuarios en la lista blanca pueden comenzar a cargar',
    eqDetails_10047: 'Más',
    eqDetails_10048: 'Límite de SOC de carga',
    eqDetails_10049: 'Límite de SOC de descarga',
    eqDetails_10050: "Límite de potencia de carga de batería de NZS (kW)",
    eqDetails_10051: 'Límite de potencia de descarga de batería de NZS (kW)',
    eqDetails_10052: 'Límite de potencia de importación de red (kW)',
    eqDetails_10053: '¿Está seguro de reiniciar el dispositivo?',
    eqDetails_10054: 'Rango',
    eqDetails_10055: "Fallo",
    eqDetails_10056: "Sin límite {0}%",
    eqDetails_10057: "Reiniciar la batería",
    eqDetails_10058: "límite{0}%",
    eqDetails_10059: "El comando se ha enviado correctamente, el dispositivo se ejecutará después de la carga, actualice la página más tarde para ver el resultado de la ejecución",
    eqDetails_10060: "Cargado",
    eqDetails_10061: "La inserción se realizó correctamente, esperando que el dispositivo responda",
    eqDetails_10062: "El estado de funcionamiento del gabinete de batería se ha cambiado correctamente",
    eqDetails_10063: "No se pudo cambiar el estado de funcionamiento del gabinete de batería",
    eqDetails_10064: "El estado de funcionamiento del gabinete de batería se ha cambiado con exceso de tiempo",
    eqDetails_10065: "El conector se está cargando, no es necesario volver a cargarlo",
    eqDetails_10066: "Desactive primero el dispositivo de gestión de plazas de aparcamiento",
    eqDetails_10067: "Establecer como disponible",
    eqDetails_10068: "Esta función solo se utiliza para el procesamiento de emergencia cuando la información de la plataforma y la del cañón no están sincronizadas. ¿Está seguro de configurarla como disponible?",
    eqDetails_10069: "Ilimitado, la potencia de carga máxima nominal es {0} kW",
    eqDetails_10070: "Ilimitado, la corriente máxima nominal es {0} A",
    eqDetails_10071: "Duración de la carga",
    eqDetails_10072: "Se espera que esté lleno",
    detailDepot_00182: "Lista blanca de soporte del sitio",
    detailDepot_00545: "¡Guarde la operación anterior!",
    detailDepot_00112: "Se puede cargar",
    detailDepot_00106: "No se permite la carga",
    // #7662新增
    detailDepot_20000: "CPO",
    detailDepot_20001: "Versión del SO",
    detailDepot_20002: "Versión de la aplicación Android",
    detailDepot_20003: "Versión de DCB",
    detailDepot_20004: "Actualizar firmware",
    detailDepot_20005: "Bloquear el MagLock",
    detailDepot_20006: "Desbloquear el MagLock",
    detailDepot_20007: "Eliminar",
    detailDepot_20008: "¿Está seguro de eliminar este cargador?",
    detailDepot_20009: "Operación cancelada",
    detailDepot_20010: "Apague el interruptor de habilitación en la configuración del cargador antes de eliminar el cargador",
    eqDetails_10074: "La potencia de carga máxima nominal no está configurada en el sistema para este modelo de cargador y no se puede modificar temporalmente. Comuníquese con el administrador.",
    eqDetails_10075: "La corriente máxima nominal no está configurada en el sistema para este modelo de cargador y no se puede modificar temporalmente. Comuníquese con el administrador.",
    eqDetails_10073: "Ingrese el rango correcto",
    detailDepot_00107: "Conexión a red forzada",
    detailDepot_00108: 'Límite de potencia de la batería a la red (kW)',
    detailDepot_00109: 'Aplicar al cargador',
    detailDepot_00110: 'Se ha enviado el comando de reinicio del cargador y el proceso de reinicio tardará aproximadamente 5 minutos',
    detailDepot_00111: 'Plataforma dual',
    detailDepot_10112: "Se refiere a la potencia operativa máxima del módulo ACDC, que no se puede superar ya sea que se utilice para cargar la batería NZS o el vehículo.",
    //Información del producto (Información del producto)
    eqDetails_21000: "Medidor de CC",
    eqDetails_21001: "Medidor de CA",
    eqDetails_21002: "Relé de conmutación",
    eqDetails_21003: "Sensor de control de acceso",
    eqDetails_21004: "Sensor de punta",
    eqDetails_21005: "Sensor de humo",
    eqDetails_21006: "Sensor de nivel de líquido",
    eqDetails_21007: "Bomba de refrigeración de líquido",
    eqDetails_21008: "Ventilador de la bomba de refrigeración de líquido",
    eqDetails_21009: "Barra de luz",
    eqDetails_21010: "Filtro",
    eqDetails_21011: "Estado de la batería de almacenamiento de energía",
    eqDetails_21012: "Contactor de CA",
    eqDetails_21013: "Relé de punta de lanza (CC-)",
    eqDetails_21014: "Fabricante",
    eqDetails_21015: "UUGreenPower",
    eqDetails_21016: "INFY Power",
    eqDetails_21017: "Megmeet",
    eqDetails_21018: "Relé de punta de lanza (CC+)",
    eqDetails_21019: "Inclinación del gabinete de la batería",
    eqDetails_21020: "Corrección de inclinación",
    eqDetails_21021: "SOH es menor que",
    eqDetails_21022: "Corriente",
    //自检
    eqDetails_30001: "Estado de parada de emergencia de recuperación del cargador",
    eqDetails_30002: "Si se debe realizar una autoinspección automática después de eso",
    eqDetails_30003: "Autoinspección HW",
    eqDetails_30004: "1. Verifique el estado del hardware del dispositivo, lo que demora decenas de segundos",
    eqDetails_30005: "2. Esta verificación requiere que el número de versión de firmware de la aplicación Android para el cargador sea superior a 3.0.0",
    eqDetails_30006: "3. Esta verificación no es compatible con dispositivos de terceros",
    eqDetails_30007: "Resultado de la última inspección",
    eqDetails_30008: "Iniciar inspección",
    eqDetails_30009: "Espere durante la autoinspección",
    eqDetails_30010: "Re-inspección",
    eqDetails_30011: "Tiempo de inspección",
    eqDetails_30012: "Inspección de estado",
    eqDetails_30013: "Vida útil del hardware",
    eqDetails_30014: "Elementos de autoinspección",
    eqDetails_30015: "Confirmar y recuperarse de una parada de emergencia",
    eqDetails_30016: "El cargador está en uso, la autoinspección se canceló, opere después de que esté inactivo",
    eqDetails_30017: "El cargador actual está fuera de línea",
    eqDetails_30018: "Se agotó el tiempo de autoinspección del cargador, intente nuevamente",
    eqDetails_30020: "Más deficiente",
    eqDetails_30021: "Moderado",
    eqDetails_30022: "mejor",
    eqDetails_30023: "Esta inspección requiere que el número de versión de firmware de la aplicación Android para el cargador sea 3.0.0 o superior",
    eqDetails_30024: "No se encontró el resultado de la última autoinspección",
    eqDetails_30025: "Recuperación inmediata",
    eqDetails_30026: "Primero verifique el cargador, luego considere la recuperación",
    eqDetails_30027: "Descargo de responsabilidad",
    eqDetails_30028: "Para garantizar el funcionamiento seguro del cargador, le recomendamos que realice una verificación de hardware antes de restaurar el botón de parada de emergencia. Si encuentra alguna falla de hardware o peligro de seguridad, deje de usar el cargador y comuníquese con nuestro equipo de soporte técnico. Brindaremos una solución lo antes posible para garantizar que su cargador pueda funcionar de manera segura y estable.",
    eqDetails_30029: "Tenga en cuenta que este descargo de responsabilidad tiene como objetivo recordarle que elegir restaurar directamente el botón de parada de emergencia sin determinar primero el estado del cargador puede implicar ciertos riesgos y no seremos responsables de las consecuencias resultantes.",
    eqDetails_30030: "Confirmar recuperación directa",
    eqDetails_30031: "Elementos de autocomprobación",
    eqDetails_30032: "Estado",
    eqDetails_30033: "{msg},{code},la autoinspección se canceló, opere después del mantenimiento",
    EqDetails_41000: "Suspender",
    EqDetails_41001: "Reinicio del gabinete de la batería",
    eqDetails_50000: "Límite de corriente máxima",
    eqDetails_50001: "La corriente de carga máxima del equipo se puede limitar por períodos de tiempo, pero no puede superar la corriente máxima nominal de {0}A.",
    eqDetails_50002: "Límite de corriente de carga máxima (A)",
    eqDetails_50003: "Complete el período de tiempo correctamente",
    eqDetails_50004: "Hay períodos de tiempo en blanco o superpuestos en la configuración, verifique y guarde después de la modificación",
    eqDetails_50005: "La corriente de carga máxima limitada no puede superar el valor de corriente máxima nominal",
    // 10826
    eqDetails_51000: "Medidor de conectividad de backend/cargador rotativo de 7 días",
    eqDetails_51007: "Puertos",
    eqDetails_51008: "Baterías",
    eqDetails_51009: "Hora del evento",
    eqDetails_51010: "Evento",
    eqDetails_51011: "Nota",
    eqDetails_51012: "Desde: ",
    eqDetails_51013: "Hasta: ",
    eqDetails_51014: "Duración: ",
    eqDetails_51015: "Disponible",
    eqDetails_51016: "Cargador disponible",
    eqDetails_51017: "Error",
    eqDetails_51018: "Error",
    eqDetails_51019: "Error",
    eqDetails_51020: "Fuera de línea",
    eqDetails_51021: "Fuera de línea",
    eqDetails_51022: "Cargador fuera de línea",
    eqDetails_51023: "Pedido",
    eqDetails_51024: "Nueva sesión",
    eqDetails_51025: "ID de sesión：",
    eqDetails_51026: "Descargando",
    eqDetails_51027: "Cargando",
}