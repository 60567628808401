export default {
    group_00001: "Ingrese el nombre de la flota",
    group_00002: "Usuario",
    group_00003: "Gestión de grupos",
    group_00004: "Nombre de la flota",
    group_00005: "Pago",
    group_00006: "Saldo({unit})",
    group_00007: "Importe máximo no pagado por miembro({unit})",
    group_00008: "Número máximo de transacciones no pagadas por miembro",
    group_00009: "Pagado por el individuo",
    group_00010: "Pagado por la flota",
    group_00011: 'Incluir obsequio {promotionBalance}{unit}',
    group_00012: 'Transacciones',
    group_00013: 'Miembros',
    group_00014: 'Nueva flota',
    group_00015: 'Flotas',
    group_00016: 'Nombre de la flota',
    group_00017: 'Dar un nombre a la flota',
    group_00018: 'Importe máximo no pagado',
    group_00019: "(No se realizarán más cobros si el monto total de transacciones impagas alcanza el valor establecido)",
    group_00020: "(No se realizarán más cobros si el número total de transacciones impagas alcanza el valor establecido)",
    group_00021: "Guardar",
    group_00022: "Número máximo de transacciones pendientes de pago",
    group_00023: "¡Nuevo grupo creado con éxito!",
    group_00024: "Puede hacer lo siguiente en 「Centro de usuarios」「Grupos 「Detalles」:",
    group_00025: "1. Modificar el nombre del grupo;",
    group_00026: "2. Cambiar el método de pago;",
    group_00027: "3. Ajustar el monto y el número de veces que se limita el monto pendiente de pago;",
    group_00028: "4. Recargar la cuenta en línea (solo si el método de pago es pago a cuenta grupal);",
    group_00029: "Mejore la información del grupo",
    group_00030: "Error de entrada",
}