export default {
    auto_00001: "请通过微信扫描左侧二维码关注“智充”公众号，以便接收定制化通知。",
    auto_00002: "1、在公众号底部菜单中，点击“充电维保”-“维保下单”，进入“智维保”小程序。",
    auto_00003: "2、使用您的平台账号登录“智维保”小程序。",
    auto_00004: "3、完成上述步骤后，您将通过“智充”公众号接收与您的账号相关的维保通知，所有通知均基于公众号模板消息功能发送。",
    auto_00005: "设备故障（导致充电桩不可用）",
    auto_00006: "设备异常（桩的问题导致充电异常结束，但桩可继续使用）",
    auto_00007: "充电桩离线",
    auto_00008: "例如：急停按钮被按下、机箱温度报警等。",
    auto_00009: "例如：电磁锁上锁失败，电源模块报警等。",
    auto_00010: "通知类别",
    auto_00011: "通知对象",
    auto_00012: "操作",
    auto_00013: "编辑通知对象",
    auto_00014: "全部",
    auto_00015: "自动通知",
    auto_00016: "移除",
    auto_00017: "角色",
    auto_00018: "账号",
    auto_00019: "操作",

    auto_00020: "设置通知频次",
    auto_00021: "每",
    auto_00022: "日",
    auto_00023: "每台设备每种故障只通知",
    auto_00024: "每台设备所有故障只通知",
    auto_00025: "次",
    auto_00026: "默认",
    auto_00027: "每台设备离线只通知",
    auto_00028: "安全充电限制",
    auto_00029: "例如：在直流桩长时间低功率充电",
    auto_00030: "可在'安全充电限制'中，进行开关设置",
    auto_00031: "关注后，请按照以下步骤操作：",






}



