export default {
    not_begin: "Not started yet",
    in_progress: "In progress",
    paused: "Paused",
    stopped: "Finished",
    all: "All",
    promotion_type1:"Cash(after top-up)",
    promotion_type2:"Cash and coupon(after top-up)",
    promotion_type3:"Coupon(after top-up)",
    promotion_type4:"Coupon",
    promotion_type5: "Sale Coupon",
    copy_link_success:"Copy external chain successfully",
    member_00006: 'Open',
    member_00007: 'Close',

    handle_giv: "Manual",
    activate_limit:"Restrictions",
    user_scope:"Users qualified",
    level_giv:"Direct gift",
    add_givuser_text1: "The object of gift",

    add_givuser_text2: "The object of gift have not been added yet.",
    giv: "give",
    giv_error_info:"Failure cause",
    card_search_giv: "Please enter the nickename or telephone for searching the user.",
    search: "Search",
    head_portrait: "Profile",
    nickname: "Name",
    cell_phone_number: "Telephone",

    success_giv: "Send successfully",
    success_giv1: "Send successfully！",
    error_giv: "Send unsuccessfully",





    stored_00001: 'Marketing Center',
    stored_00002: 'Activity',
    stored_00003: 'Name',
    stored_00004: 'status',
    stored_00005: 'Reset',
    stored_00006: 'Search',
    stored_00007: 'Activity list',
    stored_00008: 'create',
    stored_00009: 'Activity name',
    stored_00010: 'Activity time',
    stored_00011: 'status',
    stored_00012: 'Activity entry',
    stored_00013: 'Activity outgoing special link',
    stored_00014: 'Copy link',
    stored_00015: 'Show or not',
    stored_00016: 'Car owner - my wallet - recharge',
    stored_00017: 'operation',
    stored_00018: 'details',
    stored_00019: 'Manual',
    stored_00020: 'Stored value return activity',
    stored_00021: 'Free collection activities',
    stored_00022: '活动ID',
    stored_00023: '活动入口(公众号)',
    stored_00024: '生效时段',
    stored_00025: '全天',
    stored_00026: '',
    stored_00027: '',
    stored_00028: '',
    stored_00029: '',
    stored_00030: '',
}
