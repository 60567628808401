export default {
    diversionServices_00001: 'Centro de flujo',
    diversionServices_00002: 'Pistas abiertas',
    diversionServices_00003: 'Pasos de integración de la plataforma de flujo',
    diversionServices_00004: 'Pasos de integración de la plataforma de flujo',
    diversionServices_00005: "Paso 1 Solicitud de tráfico Si desea acceder al lado del tráfico, comuníquese con la plataforma.",
    diversionServices_00006: "Paso 2 Negociación comercial Debe firmar un acuerdo de cooperación comercial con SmartChange, que vincule los derechos y obligaciones de ambas partes. Esto incluye, entre otros, tarifas de acoplamiento del sistema, liquidación financiera, etc.",
    diversionServices_00007: "Paso 3 Acoplamiento del sistema La plataforma completa el acoplamiento del sistema entre los operadores y las partes del tráfico.",
    diversionServices_00008: "Paso 4 Sitio Los operadores pueden ver el tráfico que se puede abrir en el módulo Open Leads y abrir y cerrar operaciones abiertas en los sitios operativos. ",
    diversionServices_00009: 'Sitio disponible',
    diversionServices_00010: 'Sitio sin abrir',
    diversionServices_00011: 'Accedido',
    diversionServices_00012: 'No accedido',
    diversionServices_00013: 'Ir a abrir',
    diversionServices_00014: 'Plataforma de mapas',
    diversionServices_00015: 'Plataforma de vehículos operativos',
    diversionServices_00016: 'Plataforma de agregación',
    diversionServices_00017: 'Plataforma OEM',
    diversionServices_00018: 'Plataforma gubernamental',
    diversionServices_00019: 'Gobierno',
    diversionServices_00020: 'Gratis',
    diversionServices_00021: 'Firma directa',
    diversionServices_00022: 'Contratista general de Xcharge',
    diversionServices_00023: 'Cómo acceder',
    marketCaptureTraffic_00054: "Acceso al método de tráfico de terceros",
    marketCaptureTraffic_00055: "El primer paso es la aplicación de tráfico",
    marketCaptureTraffic_00056: "Si desea acceder al tráfico, comuníquese con la plataforma.",
    marketCaptureTraffic_00057: "Paso 2: Negociación comercial",
    marketCaptureTraffic_00058: "Es necesario firmar un acuerdo de cooperación comercial con Zhichong para restringir los derechos y obligaciones de ambas partes.",
    marketCaptureTraffic_00059: "Incluye, entre otros, tarifas de conexión del sistema, liquidación de fondos, etc.",
    marketCaptureTraffic_00060: "Paso 3: Conexión del sistema",
    marketCaptureTraffic_00061: "La plataforma completa la conexión entre el operador y el sistema de tráfico de partes.",
    marketCaptureTraffic_00062: "Paso 4: Apertura del sitio",
    marketCaptureTraffic_00063: "Los operadores pueden ver el tráfico que se puede abrir en el módulo de desvío abierto",
    marketCaptureTraffic_00064: "Abra y cierre el sitio operativo usted mismo.",
    marketCaptureTraffic_00065: "Conocido",
}