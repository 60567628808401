export default {
  pia_00001:'Centro financiero',
  pia_00002:'Funciones comunes',
  pia_00003:'Las facturas solicitadas son facturas de IVA ordinarias, excluyendo el monto del obsequio, el monto preferencial y el monto de la asignación grupal.',
  pia_00004:'Estado de la solicitud',
  pia_00005:'Todo',
  pia_00006:'Pendiente',
  pia_00007:'Facturado',
  pia_00008:'Cancelado',
  pia_00009:'Usuarios de WeChat:',
  pia_000010:'Buscar',
  pia_000011:'Restablecer',
  pia_000012:'Exportar datos',
  pia_000013:'Números pendientes',
  pia_000014:'Importe total a procesar (€)',
  pia_000015:'Número de transacciones facturadas',
  pia_000016:'Importe total facturado (€)',
  pia_000017:'Apodo',
  pia_000018:'Tipo de solicitud',
  pia_000019:'Método de facturación',
  pia_000020:'Importe de la solicitud',
  pia_000021:'Consulta de orden de cobro',
  pia_000022:'Consulta de factura de ocupación de plazas de parking',
  pia_000023:'Motivos de la denegación',
  pia_000024:'Detalles de la factura',
  pia_000025:'Encabezado de la factura',
  pia_000026:'Número de identificación fiscal de la empresa',
  pia_000027:'Dirección de la empresa',
  pia_000028:'Rechazar',
  pia_000029:'Información de contacto',
  pia_000030:'Teléfono',
  pia_000031:'Buzón de correo',
  pia_000032:'Mostrar por página',
  pia_000033:'Mensaje del botón de rechazo de factura',
  pia_000034:'Por favor, rellene el motivo del rechazo',
  pia_000035:'Mensaje del botón de emisión de factura',
  pia_000036:'Factura en papel',
  pia_000037:'Factura electrónica',
  pia_000038:'Consejos',
  pia_000039:'Apodo de usuario / número de móvil',
  pia_000040:'Hora de solicitud:',
  pia_000041:'Fecha de inicio',
  pia_000042:'Fecha de finalización',
  pia_000043:'Factura de empresa',
  pia_000044:'Factura personal',
  pia_000045:'Número de oficina de la empresa',
  pia_000046:'Banco de depósito',
  pia_000047:'Número de cuenta',
  pia_000048:'Destinatario',
  pia_000049:'Dirección de recepción',
  pia_000050:'Sin datos',
  pia_000051:'Esta operación descargará el archivo Excel de la factura. ¿Quieres continuar?',
  pia_000052:'Consejos',
  pia_000053:'Confirmar',
  pia_000054:'Cancelar',
  pia_000055:'Iniciar descarga',
  pia_000056:'Cancelar descarga',
  pia_000057:'Listado de facturas',
  pia_000058:'Factura en papel y factura electrónica'
  }