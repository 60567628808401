export default {
  resetPassword_00001: "Informationsüberprüfung",
  
  resetPassword_00002: "Passwortänderung",
  
  resetPassword_00003: "Änderung abgeschlossen",
  
  resetPassword_00004: "Konto",
  
  resetPassword_00005: "Bitte geben Sie ein Konto ein, für das eine Passwortrücksetzung erforderlich ist",
  
  resetPassword_00006: "Mobilfunknummer",
  
  resetPassword_00007: "11-stellige Telefonnummer",
  
  resetPassword_00008: "Bitte geben Sie die an dieses Konto gebundene Mobilfunknummer ein",
  
  
  resetPassword_00009: "Code senden",
  
  
  resetPassword_00010: "Nach {0} s erneut anfordern",
  resetPassword_00011: "SMS-Bestätigungscode",
  resetPassword_00012: "6 Ziffern",
  resetPassword_00013: "Bitte geben Sie den SMS-Bestätigungscode ein",
  resetPassword_00014: "Weiter",
  resetPassword_00015: "Neues Passwort",
  
  resetPassword_00016: "Enthält mindestens 6 Zeichen, davon mindestens 1 Ziffer, 1 Buchstabe und 1 Sonderzeichen",
  
  resetPassword_00017: "Bitte geben Sie ein neues Passwort ein",
  
  resetPassword_00018: "Passwort bestätigen",
  
  resetPassword_00019: "dasselbe wie das neue Passwort",
  
  resetPassword_00020: "Bitte geben Sie das neue Passwort erneut ein",
  
  resetPassword_00021: "Speichern",
  
  resetPassword_00022: "Erfolgreich geändert",
  
  resetPassword_00023: "An der Betriebsplattform anmelden",
  
  resetPassword_00024: "Bitte geben Sie den Bestätigungscode ein",
  
  resetPassword_00025: "Bitte geben Sie einen numerischen Wert ein",
  resetPassword_00026: "Bitte 6 Ziffern eingeben",
  
  resetPassword_00027: "Bitte geben Sie das neue Passwort erneut ein",
  
  resetPassword_00028: "Die zweimal eingegebenen neuen Passwörter stimmen nicht überein!",
  
  resetPassword_00029: "Bitte geben Sie die Telefonnummer ein",
  
  resetPassword_00030: "Bitte geben Sie die korrekte 11-stellige Telefonnummer ein",
  
  resetPassword_00031: "Bitte geben Sie das Konto ein",
  
  resetPassword_00032: "Bitte geben Sie ein Passwort ein",
  
  resetPassword_00033: "",
  
  resetPassword_00034: "Die eingegebene Telefonnummer ist nicht die an das Konto gebundene Telefonnummer! Bitte wenden Sie sich ggf. an den Administrator!",
  
  resetPassword_00035: "Falscher Bestätigungscode",
  
  resetPassword_00036: "Der Bestätigungscode ist ungültig",
  
  resetPassword_00037: "Der Bestätigungscode ist abgelaufen. Bitte holen Sie sich den Bestätigungscode erneut",
  
  resetPassword_00038: "Passwort zurücksetzen",
  
  resetPassword_00039: "Bitte geben Sie ein neues Passwort gemäß den Regeln ein!",
  
  resetPassword_00040: "Mailbox",
  
  resetPassword_00041: "Bitte geben Sie die mit diesem Konto verknüpfte E-Mail-Adresse ein",
  
  resetPassword_00042: "Bitte geben Sie die richtige E-Mail-Adresse ein!",
  
  resetPassword_00043: "Die von Ihnen eingegebene E-Mail-Adresse ist nicht mit diesem Konto verknüpft! Bitte wenden Sie sich bei Bedarf an den Administrator!",
  
  resetPassword_00044: "E-Mail-Bestätigungscode",
  
  resetPassword_00045: "Bitte geben Sie den E-Mail-Bestätigungscode ein",
  
  }