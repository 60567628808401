export default {
    energyOperationMonitoring_00001: "Monitor de energía",
    energyOperationMonitoring_00002: "Pivote",
    energyOperationMonitoring_00003: "Cargador",
    energyOperationMonitoring_00004: "Batería NZS",
    energyOperationMonitoring_00005: "Red",
    energyOperationMonitoring_00006: "Uso del cargador",
    energyOperationMonitoring_00007: "Uso máximo de energía",
    energyOperationMonitoring_00008: "De la batería NZS",
    energyOperationMonitoring_00009: "Desde la red",
    energyOperationMonitoring_00010: "Descarga de batería NZS",
    energyOperationMonitoring_00011: "Potencia máxima cargada",
    energyOperationMonitoring_00012: "Estado de carga de la batería NZS",
    energyOperationMonitoring_00013: "Desde la red",
    energyOperationMonitoring_00014: "Al cargador",
    energyOperationMonitoring_00015: "A la batería",
    energyOperationMonitoring_00016: "Al cargador",
    energyOperationMonitoring_00017: "Uso de carga de batería de NZS",
    energyOperationMonitoring_00018: "Uso de descarga de batería NZS",
    energyOperationMonitoring_00019: "Bajo",
    energyOperationMonitoring_00020: "Promedio",
    energyOperationMonitoring_00021: "Alto",
    energyOperationMonitoring_00022: "Exportar datos",
    energyOperationMonitoring_00023: "Uso máximo de energía",
    energyOperationMonitoring_00024: "Uso del cargador",
    energyOperationMonitoring_00025: "Descarga de batería NZS",
    energyOperationMonitoring_00026: "Red neta importada",
    energyOperationMonitoring_00027: "Día",
    energyOperationMonitoring_00028: "Semana",
    energyOperationMonitoring_00029: "Mes",
    energyOperationMonitoring_00030: "Año",
    energyOperationMonitoring_00031: "Duración",
    energyOperationMonitoring_00032: "Uso del cargador de la batería NZS",
    energyOperationMonitoring_00033: "Uso del cargador desde la red",
    energyOperationMonitoring_00034: "Importación de red al cargador",
    energyOperationMonitoring_00035: "Importación de red a batería NZS",
    energyOperationMonitoring_00036: "Descarga NZS al cargador",
    energyOperationMonitoring_00037: "Enero",
    energyOperationMonitoring_00038: "febrero",
    energyOperationMonitoring_00039: "Marzo",
    energyOperationMonitoring_00040: "abril",
    energyOperationMonitoring_00041: "Mayo",
    energyOperationMonitoring_00042: "Junio",
    energyOperationMonitoring_00043: "julio",
    energyOperationMonitoring_00044: "Agosto",
    energyOperationMonitoring_00045: "Septiembre",
    energyOperationMonitoring_00046: "Octubre",
    energyOperationMonitoring_00047: "Noviembre",
    energyOperationMonitoring_00048: "Diciembre",

    energyOperationMonitoring_00049: "Mon",
    energyOperationMonitoring_00050: "mar",
    energyOperationMonitoring_00051: "miércoles",
    energyOperationMonitoring_00052: "jueves",
    energyOperationMonitoring_00053: "vie",
    energyOperationMonitoring_00054: "Sábado",
    energyOperationMonitoring_00055: "Sol",
    energyOperationMonitoring_00056: "Potencia de carga",
    energyOperationMonitoring_00057: "De la batería al vehículo",
    energyOperationMonitoring_00058: "De la red al vehículo",
    energyOperationMonitoring_00059: "A la batería",
    energyOperationMonitoring_00060: "Al vehículo",
    energyOperationMonitoring_00061: "Uso neto de la red",
    energyOperationMonitoring_00062: "{0} semana {1}",
    energyOperationMonitoring_00063: "PV", 
    energyOperationMonitoring_00064: "Desde PV",
    energyOperationMonitoring_00065: "A la red eléctrica",
    energyOperationMonitoring_00066: "A la red",
    energyOperationMonitoring_00067: "Energía exportada a la red",
    energyOperationMonitoring_00068: "Batería NZS descargada a la red",
    energyOperationMonitoring_00069: "Batería NZS descargada en el cargador",
    energyOperationMonitoring_00070: "Energía fotovoltaica suministrada",
    energyOperationMonitoring_00071: "Potencia cargada de la batería NZS",
    energyOperationMonitoring_00072: "Batería NZS descargada",
    energyOperationMonitoring_00073: "Energía fotovoltaica suministrada",
    energyOperationMonitoring_00074: "Energía fotovoltaica a batería NZS",
    energyOperationMonitoring_00075: "Energía importada de la red",
    energyOperationMonitoring_00076: "A la red",
    energyOperationMonitoring_00077: "Desde PV",
    energyOperationMonitoring_00078: "Al cargador",
    energyOperationMonitoring_00079: "El cargador utiliza energía fotovoltaica",
    energyOperationMonitoring_00080: "Energía fotovoltaica al cargador",
    energyOperationMonitoring_00081: "Eficiencia del suministro de energía fotovoltaica",
    energyOperationMonitoring_00082: "A la red",
    energyOperationMonitoring_00083: "Batería NZS cargada",
    energyOperationMonitoring_00084: "Batería NZS descargada",
    energyOperationMonitoring_00085: "El dispositivo no existe",
    energyOperationMonitoring_00086: "Ver sesiones",
    energyOperationMonitoring_00087: "Ver transacción"
}