export default {
    eventlogsphotovoltaic_00001: "Lista de facturas",
    eventlogsphotovoltaic_00002: "Exportar pedidos",
    eventlogsphotovoltaic_00003: "N.º de factura",
    eventlogsphotovoltaic_00004: "Fecha de inicio",
    eventlogsphotovoltaic_00005: "Fecha de finalización",
    eventlogsphotovoltaic_00006: "Restablecer",
    eventlogsphotovoltaic_00007: "Buscar",
    eventlogsphotovoltaic_00008: "Estado de la factura",
    eventlogsphotovoltaic_00009: "Todos",
    eventlogsphotovoltaic_00010: "Tipo de factura",
    eventlogsphotovoltaic_00011: "En cobro",
    eventlogsphotovoltaic_00012: "A pagar",
    eventlogsphotovoltaic_00013: "pagado",
    eventlogsphotovoltaic_00014: "Bloqueo directo a tierra del extremo del pilote",
    eventlogsphotovoltaic_00015: "Bloqueo directo a tierra de la plataforma",
    eventlogsphotovoltaic_00016: "Importe de la factura (€)",
    eventlogsphotovoltaic_00017: "Número de factura de ocupación",
    eventlogsphotovoltaic_00018: "Duración de la ocupación (min)",
    eventlogsphotovoltaic_00019: "Pila de carga - puerto de carga",
    eventlogsphotovoltaic_00020: "Hora de inicio de la ocupación",
    eventlogsphotovoltaic_00021: "Hora de finalización de la ocupación",
    eventlogsphotovoltaic_00022: "Duración de la carga",
    eventlogsphotovoltaic_00023: "día",
    eventlogsphotovoltaic_00024: "hora",
    eventlogsphotovoltaic_00025: "minuto",
    eventlogsphotovoltaic_00026: "Usuarios de WeChat",
    eventlogsphotovoltaic_00027: "grupo",
    eventlogsphotovoltaic_00028: "Tarjeta de valor almacenado sin conexión",
    eventlogsphotovoltaic_00029: "Tarjeta de identificación en línea",
    eventlogsphotovoltaic_00030: "Importe de factura actual",
    eventlogsphotovoltaic_00031: "Importe de pago real",
    eventlogsphotovoltaic_00032: "Detalles de la factura",
    eventlogsphotovoltaic_00033: "Propinas",
    eventlogsphotovoltaic_00034: "Esta operación descargará el archivo de Excel de la factura. ¿Desea continuar?",
    eventlogsphotovoltaic_00035: "confirmar",
    eventlogsphotovoltaic_00036: "cancelar",
    eventlogsphotovoltaic_00037: "Iniciar descarga",
    eventlogsphotovoltaic_00038: "Cancelar descarga",
    eventlogsphotovoltaic_00039: "Apodo de usuario, número de teléfono móvil y número de matrícula",
    eventlogsphotovoltaic_00040: "apodo",
    eventlogsphotovoltaic_00041: "teléfono",
    eventlogsphotovoltaic_00042: "número de matrícula",
    eventlogsphotovoltaic_00043: "Importe total de la factura",
    bill_00059: "hoy",
    bill_00060: "hace 7 días",
    bill_00061: "hace 15 días",
    bill_00077: "ayer",
    billet_00078: "hace 30 días",
}