export default {
  user_00001: "Nombre de usuario/teléfono/matrícula",
  user_00002: "Usuario",
  user_00003: "Seguido",
  user_00004: "Dejado de seguir",
  user_00005: "Exportar usuario",
  user_00006: "Esto descargará los archivos Excel de los usuarios, ¿continuar?",
  user_00007: "Lista",
  user_00008: "Usuarios que coinciden con esta categoría",
  user_00009: "Operar",
  user_00010: "La información del usuario no está autorizada",
  }