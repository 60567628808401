export default {
    feedback_00001: 'Atención al cliente de WeChat',
    feedback_00002: 'Atención al cliente',
    feedback_00003: 'Ingrese el apodo del usuario',
    feedback_00004: 'Ingrese el contenido',
    feedback_00005: 'Fecha de inicio',
    feedback_00006: 'Fecha de finalización',
    feedback_00007: '-',
    feedback_00008: 'Lista de mensajes',
    feedback_00009: 'Debido a las restricciones de la cuenta oficial de WeChat, no se puede responder a los mensajes dentro de las 48 horas',
    feedback_00010: 'Tiempo de respuesta agotado',
    feedback_00011: 'Tiempo de respuesta agotado',
    feedback_00012: 'Sin respuesta - ',
    feedback_00013: 'Respondió - ',
    feedback_00014: 'Enlace de fondo de cuenta oficial',
    feedback_00015: 'Responder',
    feedback_00016: 'Por favor, introduzca el contenido de la respuesta',
    feedback_00017: 'Enviar',
    feedback_00018: 'Hace una hora',
    feedback_00019: 'Hace minutos',
    feedback_00020: 'El mensaje de respuesta no puede estar vacío',
    feedback_00021: 'Responder correctamente',
    feedback_00022: 'Administrador',
    feedback_00023: "Mensaje de texto",
    feedback_00024: "Mensaje con imagen",
    feedback_00025: "Mensaje de voz",
    feedback_00026: "Mensaje de video",
    feedback_00027: "Pequeño mensaje de video",
    feedback_00028: "Mensaje de enlace",
    feedback_00029: "Mensaje de ubicación",
    feedback_00030: "El tipo de mensaje es {0}, ¡verifíquelo en segundo plano en la cuenta oficial de WeChat!",
}