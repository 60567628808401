export default {
    carDetail_00001: "Información del vehículo eléctrico",
    carDetail_00002: "Vehículo eléctrico",
    carDetail_00003: "Eliminar vehículo eléctrico",
    carDetail_00004: "Esta operación eliminará permanentemente la información del vehículo, ya sea para continuar",
    carDetail_00005: "Información del vehículo eléctrico",
    carDetail_00006: "Información del contacto",
    carDetail_00007: "Nombre del contacto",
    carDetail_00008: "Gestión de grupos",
    carDetail_00009: "Exportar miembro del grupo",
    carDetail_00010: "Eliminar grupo",
    carDetail_00011: "No se puede recuperar después de la eliminación de grupos",
    carDetail_00012: "Orden de búsqueda",
    carDetail_00013: "Esta operación descargará el archivo Excel del usuario del grupo, ¿desea continuar?",
}