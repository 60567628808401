export default {
  userAllocateRecord_00001: "Nombre del grupo",
  userAllocateRecord_00002: "Registro de asignación",
  userAllocateRecord_00003: "Información básica",
  userAllocateRecord_00004: "Asignar tiempo",
  userAllocateRecord_00005: "Cantidad de asignación ({0})",
  userAllocateRecord_00006: "Número de personas asignadas",
  userAllocateRecord_00007: "Canal de asignación",
  userAllocateRecord_00008: "Asignador",
  userAllocateRecord_00009: "Cuenta oficial de WeChat",
  userAllocateRecord_00010: "Plataforma",
  userAllocateRecord_00011: "Administración de versiones web",
  userAllocateRecord_00012: "Descargar detalles de asignación",
  userAllocateRecord_00013: "Para",
  userAllocateRecord_00014: "Fecha de inicio",
  userAllocateRecord_00015: "Fecha de finalización",
  userAllocateRecord_00016: "Operación",
  userAllocateRecord_00017: "Detalles",
  userAllocateRecord_00018: "Descargar",
  userAllocateRecord_00019: "Lista de registros",
  userAllocateRecord_00020: "Por favor seleccione una fecha",
  }