export default {
    analyzeIncome_00001: "Ingresos",
    analyzeIncome_00002: "Ingresos por estación",
    analyzeIncome_00003: "Ingresos por flota",
    analyzeIncome_00004: "Análisis de ingresos de origen de usuario",
    analyzeIncome_00005: "Ingresos por franquicia",
    analyzeIncome_00006: "Tendencia de ingresos",
    analyzeIncome_00007: "Análisis",
    analyzeIncome_00008: "Ingresos",
    analyzeIncome_00009: "Distribución de ingresos por modelo de cargador",
    analyzeIncome_00010: "Distribución de ingresos por ciudad",
    analyzeIncome_00011: "Comparación de estaciones por ingresos",
    analyzeIncome_00012: "Tendencias de ingresos de estaciones seleccionadas",
    analyzeIncome_00013: "Comparación de flotas por ingresos",
    analyzeIncome_00014: "Tendencias de ingresos de flotas seleccionadas",
    analyzeIncome_00015: "Comparación de franquiciados por ingresos",
    analyzeIncome_00016: "Tendencias de ingresos de franquiciados seleccionados",
    analyzeIncome_00017: "Seleccionar modelo de cargador",
    analyzeIncome_00018: "Comparación de utilización del sitio",
    analyzeIncome_00019: "Tendencia de utilización del sitio",
    analyzeIncome_00020: "Comparación de utilización del modelo de cargador",
    analyzeIncome_00021: "Tendencia de utilización del modelo de cargador",
    analyzeIncome_00022: "Utilización de carga",
    analyzeIncome_00023: "Comparación de utilización de franquiciados",
    analyzeIncome_00024: "Tendencias de utilización de franquiciados",
    analyzeIncome_00025: "Ciudad",
    analyzeIncome_00026: "Modelo de cargador",
    analyzeIncome_00027: "Tarifa de servicio",
    analyzeIncome_00028: "Tarifa de electricidad",
    analyzeIncome_00029: "Puerto de carga",
    analyzeIncome_00030: "Análisis de importe de dinero de la orden de carga",
    analyzeIncome_00034: "Participación de los ingresos de fuentes de usuarios",
    analyzeIncome_00035: "Participación de cada tráfico en el tráfico de terceros",
    analyzeIncome_00036: "Tendencia de ingresos de fuentes de usuarios",
    analyzeIncome_00037: "Tendencia de ingresos de terceros",
    analyzeIncome_00038: "Moneda",
    analyzeIncome_00039: 'Tendencia de ingresos por conector',
    analyzeIncome_00040: 'Tarifa por inactividad',
    analyzeIncome_00041: 'Tarifa de electricidad por conector',
    analyzeIncome_00042: 'Tarifa de servicio por conector',
    analyzeIncome_00043: 'Tarifa por inactividad por conector',
}