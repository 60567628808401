export default  {
  preferentialSite_00001: "Sitio preferencial",
  preferentialSite_00002: "Nombre del grupo",
  preferentialSite_00003: "nombre del sitio",
  preferentialSite_00004: "Plantilla de tarifa actual",
  preferentialSite_00005: "DC",
  preferentialSite_00006: "Comunicación",
  preferentialSite_00007: "Oferta Actual",
  preferentialSite_00008: "Operación",
  preferentialSite_00009: "Detalles del descuento de tarifa",
  preferentialSite_00010: "Tarifa del operador",
  preferentialSite_00011: "No hay descuento sobre el costo total de un solo cargo",
  preferentialSite_00012: "Sin descuento en tarifa de servicio de cobro único",
  preferentialSite_00013: "{des} descuento sobre el costo total de un solo cargo",
  preferentialSite_00014: "{des} descuento en tarifa de servicio de cobro único",
  preferentialSite_00015: "La tarifa del servicio de cobro único es gratuita",
  preferentialSite_00016: "El costo total de un solo cargo es gratis",
  preferentialSite_00017: "Detalles de tarifas",
  preferentialSite_00018: "Período",
  preferentialSite_00019: "Tarifa de carga (tarifa de electricidad + tarifa de servicio)",
  preferentialSite_00020: "Tarifa por retraso",
 }