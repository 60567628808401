export default {
    bill_00001: "Centro de pedidos",
    bill_00002: "Transacciones",
    bill_00003: "Estado",
    bill_00004: "Todo",
    bill_00005: "Cobro",
    bill_00006: "Por pagar",
    bill_00007: "Pagado/Pago de terceros",
    bill_00008: "Anormal",
    bill_00009: "Tipo de usuario",
    bill_00010: "Usuario de la aplicación",
    bill_00011: "Tarjeta en línea",
    bill_00012: "Tarjeta de depósito",
    bill_00013: "Tarjeta privada",
    bill_00014: "Administrador",
    bill_00015: "Flota",
    bill_00016: "Usuario de terceros",
    bill_00017: "Lista de transacciones",
    bill_00018: "Transacciones de exportación",
    bill_00019: "ID de transacciones",
    bill_00020: "Usuario del pedido",
    bill_00021: "Por favor, introduzca el nombre de usuario",
    bill_00022: "Equipo del sitio",
    bill_00023: "Restablecer",
    bill_00024: "Buscar",
    bill_00025: "Ingresos({0})",
    bill_00026: "Monto del pedido({0})",
    bill_00027: "Monto de la tarifa de electricidad ({0})",
    bill_00028: "Monto de la tarifa de servicio({0})",
    bill_00029: "Monto de la tarifa por inactividad({0})",
    bill_00030: "Electricidad vendida ({0})",
    bill_00031: "Duración media de carga (min)",
    bill_00032: "Sesiones totales",
    bill_00033: "Cargador",
    bill_00034: "kWh({0})",
    bill_00035: "Duración",
    bill_00036: "día",
    bill_00037: "hora",
    bill_00038: "minuto",
    bill_00039: "Detalles",
    bill_00040: "Usuario de la aplicación",
    bill_00041: "Pagado",
    bill_00042: "Pagado por terceros",
    bill_00043: "Cobrando",
    bill_00044: "Facturación",
    bill_00045: "Por pagar",
    bill_00046: "En pausa",
    bill_00047: "Terminado pero aún conectado",
    bill_00048: "Tarifa por inactividad de facturación",
    bill_00049: "Cancelado",
    bill_00050: "Creado",
    bill_00051: "Anormal",
    bill_00052: "Hora de inicio",
    bill_00053: "Hora de finalización",
    bill_00054: "Flota",
    bill_00055: "Monto de factura actual",
    bill_00056: "Pago real",
    bill_00057: "Fecha desde",
    bill_00058: "Fecha hasta",
    bill_00059: "Hoy",
    bill_00060: "Hace una semana",
    bill_00061: "Hace 15 días",
    bill_00062: "Recordatorio",
    bill_00063: "Esto descargará los archivos Excel de las transacciones, ¿desea continuar?",
    bill_00064: "Confirmar",
    bill_00065: "Cancelar",
    bill_00066: "Los datos están en preparación, espere pacientemente. No cierre ni actualice la página durante este período...",
    bill_00067: "Apodo del usuario",
    bill_00068: "Teléfono",
    bill_00069: "Número de tarjeta",
    bill_00070: "Número de matrícula",
    bill_00071: "El importe de la carga es inferior a 1 kWh",
    bill_00072: "La duración de la carga es demasiado larga",
    bill_00073: "Avería del dispositivo",
    bill_00074: "Avería del coche",
    bill_00075: "Transacción de doble conector",
    bill_00076: "Cuota de ocupación",
    bill_00077: "ayer",
    bill_00078: "Hace 30 días",
    bill_00079: "Tarjeta prepaga",
    bill_00080: "Tarjeta de inicio/parada",
    bill_00081: "Tarjeta ID",
    bill_00082: "Administrador",
    bill_00083: "Datos de copia de seguridad",
    bill_00084: "Usuario POS",
    bill_00085: "Unidad monetaria",
    bill_00086: "Pedidos de el usuario",
    bill_00087: "Apodo",
    bill_00088: "Móvil",
    bill_00089: "ID de tarjeta",
    bill_00090: "Número de licencia",
    bill_00091: "Conectar y cargar",
    bill_00101: "El importe de la carga es superior a 1000 kWh",
    bill_00102: "Capacidad de carga anormal",
    bill_00103: "Importe del pedido modificado ({0})",
    bill_00104: "Importe del descuento ({0})",
    bill_00115: "Número auxiliar",
}