export default {
  managementCharger_00001: 'Gestión de tarjetas de carga del operador',
  managementCharger_00002: 'Gestión de tarjetas de carga',
  managementCharger_00003: 'Gestión de tarjetas de carga Plug & Charge',
  managementCharger_00004: 'Gestión de tarjetas de inicio/parada sin conexión',
  managementCharger_00005: 'Gestión de tarjetas de valor almacenado sin conexión',
  managementCharger_00006: 'Introduzca el número de tarjeta',
  managementCharger_00007: 'Tarjetas de carga',
  managementCharger_00008: 'Nueva tarjeta de carga plug-and-charge',
  managementCharger_00009: 'Nueva tarjeta de identificación',
  managementCharger_00010: 'Descargar NFC (APP)',
  managementCharger_00011: 'Número de tarjeta',
  managementCharger_00012: 'Tipo de tarjeta',
  managementCharger_00013: 'Tarjeta de arranque y parada sin conexión',
  managementCharger_00014: 'Tarjeta de valor almacenado sin conexión',
  managementCharger_00015: 'Tarjeta para enchufar y cargar',
  managementCharger_00016: 'Tarjeta de identificación',
  managementCharger_00017: 'Enchufar y cargar',
  managementCharger_00018: 'Número auxiliar',
  managementCharger_00019: 'Número de matrícula del vehículo',
  managementCharger_00020: 'Nombre (móvil)',
  managementCharger_00021: 'Grupo de tarjetas',
  managementCharger_00022: 'Saldo',
  managementCharger_00023: 'operar',
  managementCharger_00024: 'Transacciones',
  managementCharger_00025: "{id}(Perdido informado)",
  managementCharger_00026: "Nueva tarjeta para enchufar y cargar",
  managementCharger_00027: "Consultar el vehículo que coincide con el usuario{type}",
  managementCharger_00028: "Completar información de la tarjeta",
  managementCharger_00029: "Únete al grupo",
  managementCharger_00030: "Completar nueva",
  managementCharger_00031: "Por favor, introduzca el número de teléfono móvil del usuario",
  managementCharger_00032: "Este número de teléfono móvil coincide con varios vehículos ({type}) en 2 semanas, por favor, determine manualmente el vehículo ({type})!",
  managementCharger_00033: "Avatar",
  managementCharger_00034: "Apodo del usuario|número de teléfono",
  managementCharger_00035: "vehículo{type}",
  managementCharger_00036: "Última vez|Hora de finalización de la carga",
  managementCharger_00037: "Última vez | Sitio de recarga",
  managementCharger_00038: "confirmar",
  managementCharger_00039: "número de tarjeta",
  managementCharger_00040: "origen",
  managementCharger_00041: "Número de Aux",
  managementCharger_00042: "Nombre del contacto",
  managementCharger_00043: "Celular",
  managementCharger_00044: "Número de matrícula del vehículo",
  managementCharger_00045: "Clave",
  managementCharger_00046: "Monto de recarga",
  managementCharger_00047: "Contraseña de inicio de sesión",
  managementCharger_00048: "Únase al grupo (obligatorio)",
  managementCharger_00049: "Seleccione un grupo de tarjetas",
  managementCharger_00050: "Tarjeta de recarga realizada correctamente",
  managementCharger_00051: "permitido",
  managementCharger_00052: "「Gestión de tarjetas de carga」",
  managementCharger_00053: "Grupo de tarjetas de carga",
  managementCharger_00054: "「detalle」",
  managementCharger_00055: "Realice las siguientes operaciones en:",
  managementCharger_00056: "1, Recargar tarjetas de carga de tipo almacenamiento;",
  managementCharger_00057: "2. Ver el registro de recarga;",
  managementCharger_00058: "3. Modificar la información de contacto de la tarjeta de recarga;",
  managementCharger_00059: "Anterior",
  managementCharger_00060: "Siguiente paso",
  managementCharger_00061: "confirmar",
  managementCharger_00062: "Por favor, introduzca el número de la tarjeta de identificación",
  managementCharger_00063: "Por favor, introduzca el número de tarjeta correcto",
  managementCharger_00064: "Por favor, introduzca la fuente de la tarjeta",
  managementCharger_00065: "Por favor, introduzca el número de matrícula correcto",
  managementCharger_00066: "El formato del importe de recarga es incorrecto",
  managementCharger_00067: "Por favor, introduzca la contraseña",
  managementCharger_00068: "La entrada de la tarjeta de identificación en línea se ha realizado correctamente",
  managementCharger_00069: "Nueva tarjeta de identificación",
  managementCharger_00070: "El número de tarjeta no puede contener chino",
  managementCharger_00071: "La fuente no es se permite rellenar el campo 'enchufar y cargar'",
  managementCharger_00072: "por favor, introduzca un número de teléfono válido",
  }