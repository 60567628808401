export default {
    charge_00001: "{num}",
    charge_00002: "Puede crear nuevas tarjetas sin conexión en este grupo|Las tarjetas sin conexión de este grupo deben configurarse en el cargador para poder usarse",
    charge_00003: "Gestión de tarjetas de carga CPO",
    charge_00004: "Nombre del grupo",
    charge_00005: "Grupos de tarjetas de carga",
    charge_00006: "Nuevo grupo de tarjetas",
    charge_00007: "Tipo de tarjeta",
    charge_00008: "Tarjeta de identificación",
    charge_00009: "Grupo de tarjetas sin conexión",
    charge_00010: "Grupo de supertarjetas",
    charge_00011: "Tarjetas",
    charge_00012: "Transacciones",
    charge_00013: "Operación",
    charge_00014: 'Esto se puede hacer en la sección "Detalles" de la tarjeta de carga "Gestión de tarjetas de carga" grupo de la siguiente manera:',
    charge_00015: "Nombre del grupo",
    charge_00016: "(Ejemplo: Personal interno de State House)",
    charge_00017: "Dale un nombre al grupo de tarjetas de carga",
    charge_00018: "Grupo de tarjetas en línea",
    charge_00019: "Puedes crear nuevas tarjetas en línea en este grupo",
    charge_00020: "Grupo de tarjetas fuera de línea",
    charge_00021: "1、Cambiar el nombre del grupo de tarjetas de carga;",
    charge_00022: "Nuevo grupo de tarjetas de carga creado exitosamente",
    charge_00023: "2、Creando tarjeta de configuración NFC;",
    charge_00024: "3、Creando tarjeta de carga NFC;",
    charge_00025: "Finalizar",
    charge_00026: "Por favor, ingresa el nombre del grupo de tarjetas de carga",
}