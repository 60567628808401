export default {
  
  energyOperationMonitoring_00001: "Überwachung des Energiebetriebs",
  energyOperationMonitoring_00002: "Ansicht",
  energyOperationMonitoring_00003: "Energie gespeichert",
  energyOperationMonitoring_00004: "Trend der Energiespeicherung",
  energyOperationMonitoring_00005: "motor",
  energyOperationMonitoring_00006: "Anteil",
  energyOperationMonitoring_00007: "Das aufladen des\nfahrzeugs nutzt die\nstromquelle",
  energyOperationMonitoring_00008: "Grid      ",
  energyOperationMonitoring_00009: "Speicher",
  energyOperationMonitoring_00010: "Das aufladen des\nfahrzeugs nutzt die\nstromquelle",
  energyOperationMonitoring_00011: "Lädt das auto auf",
  energyOperationMonitoring_00012: "Min",
  energyOperationMonitoring_00013: "Max",
  energyOperationMonitoring_00014: "Aktuell",
  energyOperationMonitoring_00015: "Echtzeit Energiekosten",
  energyOperationMonitoring_00016: "Ladung",
  energyOperationMonitoring_00017: "Stromausfall",
  energyOperationMonitoring_00018: "Lichter an ",
  energyOperationMonitoring_00019: "Grids",
  energyOperationMonitoring_00020: "Lädt den wagen",
  energyOperationMonitoring_00021: "Aufladung Energie für energiespeicher",
  energyOperationMonitoring_00022: "Entladungsenergie",
  energyOperationMonitoring_00023: "Energie von Grid",
  energyOperationMonitoring_00024: "status",
  energyOperationMonitoring_00025: "Ladepistole von NZS",
  energyOperationMonitoring_00026: "im Einsatz",
  energyOperationMonitoring_00027: "verfügbar",
  energyOperationMonitoring_00028: "Störung/Offline",
  energyOperationMonitoring_00029: "Energiespeicher von NZS",
  energyOperationMonitoring_00030: "Energiespeicher aufladen",
  energyOperationMonitoring_00031: "Energiespeicher entladen",

  energyOperationMonitoring_00032: "Ladegerät Verwendung von NZS Batterie",
  energyOperationMonitoring_00033: "Verwendung des Ladegeräts aus dem Netz",
  energyOperationMonitoring_00034: "Gitter-Import zum Ladegerät",
  energyOperationMonitoring_00035: "Gitter-Import in NZS-Batterie",
  energyOperationMonitoring_00036: "NZS Akku Entladung zum Ladegerät",

  energyOperationMonitoring_00037: "Jan",
  energyOperationMonitoring_00038: "Feb",
  energyOperationMonitoring_00039: "Mär",
  energyOperationMonitoring_00040: "Apr",
  energyOperationMonitoring_00041: "Mai",
  energyOperationMonitoring_00042: "Jun",
  energyOperationMonitoring_00043: "Jul",
  energyOperationMonitoring_00044: "Aug",
  energyOperationMonitoring_00045: "Sep",
  energyOperationMonitoring_00046: "Okt",
  energyOperationMonitoring_00047: "Nov",
  energyOperationMonitoring_00048: "Dez",

  energyOperationMonitoring_00049: "Mo.",
  energyOperationMonitoring_00050: "Di.",
  energyOperationMonitoring_00051: "Mi.",
  energyOperationMonitoring_00052: "Do.",
  energyOperationMonitoring_00053: "Fr.",
  energyOperationMonitoring_00054: "Sa.",
  energyOperationMonitoring_00055: "So.",

  energyOperationMonitoring_00056: "Ladeleistung",
  energyOperationMonitoring_00057: "Von Batterie zu Vechile",
  energyOperationMonitoring_00058: "Vom Raster nach Vechile",
  energyOperationMonitoring_00059: "Zur Batterie",
  energyOperationMonitoring_00060: "Zum Fahrzeug",
  energyOperationMonitoring_00061: "Netznetznutzung",
  energyOperationMonitoring_00062: "{0} Woche {1}",
  energyOperationMonitoring_00063: "PV", 
  energyOperationMonitoring_00064: "Aus PV", 
  energyOperationMonitoring_00065: "Zum Raster", 
  energyOperationMonitoring_00066: "Zum Raster", 
  energyOperationMonitoring_00067: "Strom in das Netz exportiert", 
  energyOperationMonitoring_00068: "NZS-Batterie im Netz entladen",
  energyOperationMonitoring_00069: "NZS Batterie aufgeladen zum Ladegerät",
  energyOperationMonitoring_00070: "PV-Stromversorgung",
  energyOperationMonitoring_00071: "NZS-Batterie geladen",
  energyOperationMonitoring_00072: "NZS-Batterie entladen",
  energyOperationMonitoring_00073: "PV-Stromversorgung",
  energyOperationMonitoring_00074: "Power of PV to NZS Battery",
  energyOperationMonitoring_00075: "Elektrische energie aus dem netz",
  energyOperationMonitoring_00076: "Zum Raster",
  energyOperationMonitoring_00077: "From PV",
  energyOperationMonitoring_00078: "To charger",
  energyOperationMonitoring_00079: "Charger use energy from PV",
  energyOperationMonitoring_00080: "PV energy to Charger",
  energyOperationMonitoring_00081: "Aber nur für photovoltaik",
  energyOperationMonitoring_00082: "To Grid",
  energyOperationMonitoring_00085: "Device does not exist",
  energyOperationMonitoring_00086: "View Sessions", 
  energyOperationMonitoring_00087: "View Transaction",
  energyOperationMonitoring_00088: "Bitte geben Sie die Geräte-SN ein",

}