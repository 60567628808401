export default {
    joinOperator_00001: "Invitar franquicia",
    joinOperator_00002: "Crear invitación",
    joinOperator_00003: "Invitación enviada",
    joinOperator_00004: "Buscar",
    joinOperator_00005: "Generar código de invitación",
    joinOperator_00006: "Modificar invitados",
    joinOperator_00007: "Código de invitación/invitado",
    joinOperator_00008: "Código de invitación",
    joinOperator_00009: "Hora de generación",
    joinOperator_000010: "Invitados",
    joinOperator_000011: "Estado",
    joinOperator_000012: "Operación",
    joinOperator_000013: "Por favor, introduzca los usuarios a los que va a invitar",
    joinOperator_000014: "Necesario, no más de 20 palabras",
    joinOperator_000015: "Generar",
    joinOperator_000016: "Estado",
    joinOperator_000017: "Todo",
    joinOperator_000018: "No utilizado",
    joinOperator_000019: "Utilizado",
    joinOperator_000020: "Código de invitación generado correctamente",
    joinOperator_000021: "Tiempo de espera de respuesta del servidor",
    joinOperator_000022: "¡Guarde la operación anterior!",
    joinOperator_000023: "Complete el objeto de invitación",
    joinOperator_000024: "La cantidad de palabras ingresadas no debe exceder las 20",
    joinOperator_000025: "Lista de invitaciones",
    joinOperator_000026: "",

    //---------已发送邀请-------------------------------
    screen_time: "Tiempo de proyección",
    joinOperator_000027: "Hora de inicio",
    joinOperator_000028: "Hora de finalización",
    joinOperator_000029: "Nombre del CPO/Código de invitación",
    joinOperator_000030: "Buscar",
    joinOperator_000031: "Nombre del CPO",
    joinOperator_000032: "Hora de creación",
    joinOperator_000033: "Nombre del contacto",
    joinOperator_000034: "Número de teléfono",
    joinOperator_000035: "Código de invitación",
    joinOperator_000036: "Correo electrónico"
    
}