export default {
analyzeXcharge_00001: "Utilización",
analyzeXcharge_00002: "Utilización por estación",
analyzeXcharge_00003: "Análisis de utilización del tipo de cargador",
analyzeXcharge_00004: "Horas punta",
analyzeXcharge_00005: "Análisis de potencia media",
analyzeXcharge_00006: "Utilización por franquiciado",
analyzeXcharge_00007: "Análisis",
analyzeXcharge_00008: "Análisis por electricidad",
analyzeXcharge_00009: "Análisis por duración",
analyzeXcharge_00010: "Tasa de utilización = volumen de carga diario / volumen de carga a plena carga diario.<br/>Volumen de carga a plena carga diario = potencia nominal del cargador * 24 horas",
analyzeXcharge_00011: "Tasa de utilización = horas de carga diarias en el conector de carga / 24 horas",
analyzeXcharge_00012: "Utilización de carga de equipos de CA (%)",
analyzeXcharge_00013: "Utilización de carga de equipos de CC (%)",
analyzeXcharge_00014: "Seleccionar franquiciado",
analyzeXcharge_00015: "Seleccionar sitio de carga",
analyzeXcharge_00016: "Seleccionar grupo",
analyzeXcharge_00017: "Seleccionar modelo de cargador",
analyzeXcharge_00018: "Comparación de utilización de estaciones",
analyzeXcharge_00019: "Tendencia de utilización de estaciones",
analyzeXcharge_00020: "Comparación de utilización de tipo de cargador",
analyzeXcharge_00021: "Tendencia de utilización de tipo de cargador",
analyzeXcharge_00022: "Utilización de carga",
analyzeXcharge_00023: "Comparación de utilización de franquiciados",
analyzeXcharge_00024: "Tendencia de utilización de franquiciados",
analyzeXcharge_00025: "Comparación de potencia media del sitio",
analyzeXcharge_00026: "Análisis de potencia media de los modelos de cargadores",
analyzeXcharge_00027: "Potencia media",
analyzeXcharge_00028: "Seleccione o introduzca una estación",
analyzeXcharge_00029: "Conectores en carga",
analyzeXcharge_00030: "Tasa de ocupación del conector del cargador",
analyzeXcharge_00031: "Número de conectores en carga",
analyzeXcharge_00032: "Tasa de ocupación del conector del cargador %",
analyzeXcharge_00033: "Tasa de ocupación del conector del cargador = Número de conectores en carga / Número total de conectores",
}